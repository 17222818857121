import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Input, Slider } from '@mui/material';
import axios from 'axios';
import Dropdown from './Dropdown';
import { useSearchParams } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function FilterModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedSort, setSelectedSort] = useState("Sort");
  const [selectedJobFilter, setSelectedJobFilter] = useState("All Jobs");
  const [NoticePeriod, setNoticePeriod] = useState("All");
  const [currencyType, setCurrencyType] = useState("INR");
  const [talentData, setTalentData] = useState([]);
  const [salaryRange, setSalaryRange] = useState([5000, 50000]);
  const [experienceRange, setExperienceRange] = useState([0, 8]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [allJobTitle, setAllJobTitle] = useState([]);
  const [selectedTalent, setSelectedTalent] = useState(null);
  const [showShortListPopup, setShowShortListPopup] = useState(false);
  const [status, setStatus] = useState("Status");

  const noticePeriods = [15, 30, 45, 60, 75, 90];
  const handleNoticeSelect = (days) => {
    setNoticePeriod(days);
    setSelectedNotice(days);
  };
  const token = localStorage.getItem("token");

  const handleFilter=()=>{
    fetchSearchData(searchTerm);
    handleClose();
  }

  const formatSalary = (salary) => {
    if (salary >= 1000000) {
      return `${salary / 1000000}m`;
    } else if (salary >= 1000) {
      return `${salary / 1000}k`;
    }
    return salary;
  };


  const fetchSearchData = async (searchValue) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/job/search-hiring-manager-talent`,
        {
          searchQuery: searchValue,
          locationQuery: location,
          experienceRange: experienceRange,
          salaryRange: salaryRange,
          noticePeriod: NoticePeriod,
          currencyType: currencyType,
          sortBy: selectedSort,
          jobFilter: selectedJobFilter,
          status: status,
        },

        {
          authorization: `Bearer ${token}`,
        }
      );

      setTalentData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error searching talent data:", error);
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>Filters</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className="experience_filter_left h-[90vh] overflow-auto flex flex-col gap-2 border-l px-5 border-gray-200 p-4 rounded-md">
              <div className="mb-3 flex justify-between items-center">
                <h1 className="text-2xl font-bold">Filter Applicants</h1>
                <p className="text-base text-blue-500">Reset All</p>
              </div>
              {/* Experience Filter */}
              <div className="filters">
                <div className="expFilter mx-2">
                  <div className="expText my-3 flex space-x-2 text-gray-400 items-center">
                    <span>Experience (in years)</span>
                  </div>
                  <div className="flex justify-between gap-4 my-4">
                    <Input
                      className="border px-2"
                      value={experienceRange[0]}
                      onChange={(e) =>
                        setExperienceRange([e.target.value, experienceRange[1]])
                      }
                    />
                    <Input
                      className="border px-2"
                      value={experienceRange[1]}
                      onChange={(e) =>
                        setExperienceRange([experienceRange[0], e.target.value])
                      }
                    />
                  </div>
                  <div className="flex justify-between">
                    <span>{experienceRange[0]} Yrs</span>
                    <span>{experienceRange[1]} Yrs</span>
                  </div>
                  <Slider
                    value={experienceRange}
                    onChange={(e, newValue) => setExperienceRange(newValue)}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    color="primary"
                    min={0}
                    max={8}
                  />
                </div>
              </div>
              {/* Location Filter */}
              <div className="location_filter_left">
                <div className="expText my-3 flex space-x-2 text-gray-500 items-center">
                  <span>Location</span>
                </div>
                <div className="filters">
                  <div className="locationFilter">
                    <input
                      type="text"
                      placeholder="Search Location"
                      className="w-full p-2 border border-gray-200 rounded-md outline-none"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {/* Notice Period Filter */}
              <div className="noticePeriod_filter_left">
                <div className="expText my-3 flex space-x-2 text-gray-500 items-center">
                  <span>Notice Period</span>
                </div>

                <div className="mb-4">
                  <div className="flex flex-wrap gap-4 mt-2">
                    {noticePeriods?.map((days) => (
                      <button
                        key={days}
                        onClick={() => handleNoticeSelect(days)}
                        className={`px-3 py-1 rounded-full text-sm border ${
                          selectedNotice === days
                            ? "bg-blue-500 text-white"
                            : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                        }`}
                      >
                        {days} days
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              {/* Salary Filter */}
              <div className="salary_filter_left">
                <div className="expText my-3 flex space-x-2 text-gray-400 items-center">
                  <span>Salary</span>
                </div>
                <div className="flex justify-between gap-4 my-4">
                  <div className="filters w-full bg-white flex h-9 p-1 rounded-md border border-gray-200">
                    <Dropdown
                      width="w-full"
                      options={["INR", "USD", "EUR"]}
                      selected={currencyType}
                      buttonType="button"
                      onSelect={setCurrencyType}
                      label="Salary"
                    />
                  </div>
                  <Input
                    className="border px-2"
                    value={salaryRange[0]}
                    onChange={(e) =>
                      setSalaryRange([e.target.value, salaryRange[1]])
                    }
                  />
                  <Input
                    className="border px-2"
                    value={salaryRange[1]}
                    onChange={(e) =>
                      setSalaryRange([salaryRange[0], e.target.value])
                    }
                  />
                </div>

                <div className="salaryRange my-4 mx-2">
                  <div className="flex justify-between">
                    <span>{formatSalary(salaryRange[0])}</span>
                    <span>{formatSalary(salaryRange[1])}</span>
                  </div>
                  <Slider
                    value={salaryRange}
                    onChange={(e, newValue) => setSalaryRange(newValue)}
                    color="primary"
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    min={5000}
                    max={50000}
                  />
                </div>
              </div>
              <button
                className="rounded-full bg-blue-500 text-white p-3"
                onClick={handleFilter}
              >
                Apply Filter
              </button>
              <div className="text mb-4 font-base text-gray-400 w-fit m-auto">
              Total {talentData?.length} Talent Found
            </div>
            </div>

        </Box>
      </Modal>
    </div>
  );
}
