import React, { useState, useRef } from "react";

const DropdownRight = ({textStyles, width, options, selected, onSelect, label, buttonType, defaultOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleOptionChange = (e) => {
    onSelect(e.target.value);
    setIsOpen(false);
  };

  const mouseEnter = () => {
    // Clear any existing timeout to prevent the dropdown from closing
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsOpen(true);
  };

  const mouseLeave = () => {
    // Set a timeout to close the dropdown after 1 second
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  return (
    <div className={`relative ${width} bg-white`}>
      <button
        className="flex rounded-md border items-center w-full h-full p-0"
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        <span
        className={`flex-1 text-left p-2 ${textStyles}`}
        >{selected}</span>
      </button>

      <div
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        className={`
          ${width}
          text-[15px]
          absolute bg-white w-full min-w-[9rem] top-10 p-2 shadow-md border border-gray-500 mt-1 z-[1000000] transition-all duration-300 transform 
        ${isOpen ? "opacity-100 scale-100" : "opacity-0 scale-95 pointer-events-none"}`}
      >
        {buttonType === "radio"
          ? options?.map((option) => (
              <label key={option} className={`block p-[6px] hover:bg-gray-100 ${width}`}>
                <input
                  onClick={() => setIsOpen(false)}
                  type={buttonType}
                  name={`${label}Option`}
                  defaultChecked={selected === defaultOption}
                  disabled={selected === option}
                  value={option}
                  checked={selected === option}
                  onChange={handleOptionChange}
                  className="mr-2"
                />
                {option}
              </label>
            ))
          : options?.map((option) => (
              <button
                key={option.value}
                className="block w-full text-left p-1 hover:bg-gray-100 z-40"
                onClick={() => {
                  onSelect(option);
                  setIsOpen(false);
                }}
              >
                {option.label}
              </button>
            ))}
      </div>
    </div>
  );
};

export default DropdownRight;
