import React from "react";
import Layout from "./Layout";
import { useAuth } from "../context/auth";
import ManagerTools from "./ManagerTools";
const AdminDashboard = () => {
  const {auth,setAuth}=useAuth()
  return (
    <Layout>
      <div className="">
        <div className="row">
          <div className="">
           <ManagerTools/>
          </div>
          <div className="mt-5">
            <div className="w-fit mx-auto">
              <h3>{auth?.user?.name}</h3>
              <h3>{auth?.user?.email}</h3>
              <h3>{auth?.user?.role}</h3>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashboard;