import React from 'react'
import Layout from '../../Component/Layout'
import ManagerTools from '../../Component/ManagerTools'

const Recruites = () => {
  return (
    <Layout>
      <div>
        <div>
            <ManagerTools/>
        </div>
        <div></div>
      </div>
    </Layout>
  )
}

export default Recruites
