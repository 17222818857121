
"use client"

import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { useState } from 'react'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

export default function Component({stateDate,setStateDate}) {
  

  return (
    <div className="date-range-wrapper">
      <DateRange
        editableDateInputs={true}
        onChange={item => setStateDate([item.selection])}
        moveRangeOnFirstSelection={false}
        ranges={stateDate}
        showDateDisplay={false}
        className='w-full h-[18rem]'
        rangeColors={['#3b82f6']} // Blue color for selection
        navigatorRenderer={(currentFocusedDate, changeShownDate) => (
          <div className="flex items-center justify-between px-4 py-2">
            <button
            type='button'
              className="p-1 hover:bg-gray-100 rounded-full"
              onClick={() => changeShownDate(new Date(currentFocusedDate.getFullYear(), currentFocusedDate.getMonth() - 1, 1))}
            >
              <ChevronLeft className="h-5 w-5 text-gray-600" />
            </button>
            <h2 className="text-lg font-bold">
              {currentFocusedDate.toLocaleString('default', { month: 'long' })} {currentFocusedDate.getFullYear()}
            </h2>
            <button
            type='button'
              className="p-1 hover:bg-gray-100 rounded-full"
              onClick={() => changeShownDate(new Date(currentFocusedDate.getFullYear(), currentFocusedDate.getMonth() + 1, 1))}
            >
              <ChevronRight className="h-5 w-5 text-gray-600" />
            </button>
          </div>
        )}
      />
      <style jsx global>{`
        .date-range-wrapper .rdrMonth {
          width: 100%;
          min-width: 280px;
        }
        
        .date-range-wrapper .rdrDayNumber span {
          color: #1a1a1a;
        }
        
        .date-range-wrapper .rdrDayPassive .rdrDayNumber span {
          color: #cdcdcd;
        }
        
        .date-range-wrapper .rdrSelected {
          background-color: #3b82f6;
        }
        
        .date-range-wrapper .rdrInRange {
          // background-color: #cad5df;
          color: #000000;
        }
        
        .date-range-wrapper .rdrStartEdge,
        .date-range-wrapper .rdrEndEdge {
          background-color: #3b82f6;
        }
        
        .date-range-wrapper .rdrDayStartPreview,
        .date-range-wrapper .rdrDayEndPreview {
          border-color: #3b82f6;
        }
        
        .date-range-wrapper .rdrDayInPreview {
          background-color: #e5edff;
        }
        
        .date-range-wrapper .rdrWeekDay {
          color: #6b7280;
          font-weight: 500;
        }
      `}</style>
    </div>
  )
}
