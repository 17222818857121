import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../Component/Layout";
import toast from "react-hot-toast";
import { format } from "date-fns";
import SyncLoader from "react-spinners/SyncLoader";
import { FaEdit, FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import GradingIcon from "@mui/icons-material/Grading";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const RecruiterDashboard = () => {
  const [talents, setTalents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingFeedback, setLoadingFeedback] = useState(null); // New state for feedback button loader
  const [searchQuery, setSearchQuery] = useState("");
  console.log(talents);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [activeTalentId, setActiveTalentId] = React.useState(null);
  const [menuAnchor1, setMenuAnchor1] = React.useState(null);
  const [activeTalentId1, setActiveTalentId1] = React.useState(null);
  const [menuAnchor2, setMenuAnchor2] = React.useState(null);
  const [activeTalentId2, setActiveTalentId2] = React.useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTalents = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_}/api/v1/job/get-my-talent`
        );
        setTalents(response.data.data);
      } catch (error) {
        console.error("Error fetching talent:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTalents();
  }, []);

  const changeTextColor = (talentStatus) => {
    switch (talentStatus) {
      case "No-Update":
        return "text-black";
      case "Select":
        return "text-[#00BF63]";
      case "Reject":
        return "text-[#F11010]";
      case "On-Hold":
        return "text-[#F2AD2B]";
      case "In-Process":
        return "text-[#FF914D]";
      default:
        return "";
    }
  };

  const askForFeedback = async (talentId) => {
    setLoadingFeedback(talentId); // Set loading state for the specific talent
    try {
      await axios.put(
        `${process.env.REACT_APP_API_}/api/v1/job/talent-feedback/${talentId}`
      );
      const updatedTalents = talents.map((talent) => {
        if (talent.id === talentId) {
          return { ...talent, askTalentFeedBack: true, bellVisibility: true };
        }
        return talent;
      });
      setTalents(updatedTalents);
      toast.success("Feedback Request sent to the Hiring Manager");
    } catch (error) {
      console.error("Error asking for feedback:", error);
      toast.error("Failed to send feedback request. Please try again later.");
    } finally {
      setLoadingFeedback(null); // Reset loading state after the request completes
    }
  };

  const isButtonClickable = (talent) => {
    const twentyFourHours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    const firestoreTimestamp = talent.talentReferredDate;
    const referredDate = new Date(
      firestoreTimestamp?._seconds * 1000 +
        firestoreTimestamp?._nanoseconds / 1000000
    );

    const eligibleDate = new Date(referredDate.getTime() + twentyFourHours);
    const currentDate = new Date();

    return (
      currentDate >= eligibleDate &&
      !talent.askTalentFeedBack &&
      !talent.bellVisibility
    );
  };

  const formatDate = (timestamp) => {
    try {
      // if (
      //   !timestamp ||
      //   timestamp?._seconds === undefined ||
      //   timestamp?._nanoseconds === undefined
      // ) {
      //   return "Invalid date";
      // }
      // const milliseconds =
      //   timestamp?._seconds * 1000 + timestamp?._nanoseconds / 1e6;
      const date = new Date(timestamp);
      return date.toLocaleDateString("en-IN", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid date";
    }
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return talents.slice(startIndex, startIndex + itemsPerPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = talents.length > 0 ? Math.ceil(talents.length / itemsPerPage) : 1;

  useEffect(() => {
    const updateItemsPerPage = () => {
      // Calculate available height for the table and adjust rows
      const tableHeight = window.innerHeight - 300; // Adjust 250px for header, footer, and margins
      const rowHeight = 50; // Approximate height for each row
      const rows = Math.floor(tableHeight / rowHeight);
      setItemsPerPage(rows > 0 ? rows : 1);
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);

  const handleMenuOpen = (event, talentId) => {
    setMenuAnchor(event.currentTarget);
    setActiveTalentId(talentId);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setActiveTalentId(null);
  };

  const handleMenuOpen1 = (event, talentId) => {
    setMenuAnchor1(event.currentTarget);
    setActiveTalentId1(talentId);
  };

  const handleMenuClose1 = () => {
    setMenuAnchor1(null);
    setActiveTalentId1(null);
  };

  const handleMenuOpen2 = (event, talentId) => {
    setMenuAnchor2(event.currentTarget);
    setActiveTalentId2(talentId);
  };

  const handleMenuClose2 = () => {
    setMenuAnchor2(null);
    setActiveTalentId2(null);
  };

  return (
    <Layout showFooter={true}>
      <div className="flex w-full p-10 flex-col h-[98%]">
        {/* Header */}
        <div className="grid grid-cols-4 gap-1 p-6">
          <div className="p-4 bg-[#F3F8FD] hover:bg-white shadow rounded-lg">
            <p className="text-xl text-black font-semibold">{talents.length}</p>
            <h3 className="text-[#A0AEC0] text-sm">Talents Referred</h3>
          </div>
          <div className="p-4 bg-[#F3F8FD] hover:bg-white shadow rounded-lg">
            <p className="text-xl text-black font-semibold">0</p>
            <h3 className="text-[#A0AEC0] text-sm">Talents In Process</h3>
          </div>
          <div className="p-4 bg-[#F3F8FD] hover:bg-white shadow rounded-lg">
            <p className="text-xl  text-black font-semibold">0</p>
            <h3 className="text-[#A0AEC0] text-sm">Talents Hired</h3>
          </div>
          <div className="p-4 bg-[#F3F8FD] hover:bg-white shadow rounded-lg">
            <p className="text-xl  text-black font-semibold">0</p>
            <h3 className="text-[#A0AEC0] text-sm">Talents Rejected</h3>
          </div>
        </div>

        <div className="relative mx-2">
          <div className="absolute ml-2 inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                stroke="#8C8C8C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.0004 21.0004L16.6504 16.6504"
                stroke="#8C8C8C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <input
            className="min-w-[8rem] p-2 pl-10 border ml-3 text-black rounded-3xl w-[40%] bg-white"
            placeholder="Search Talents"
            type="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {/* Talent Table */}
        <div className="flex-1 overflow-x-auto p-6">
          <table className="min-w-full bg-white rounded">
            <thead>
              <tr>
                <th className="py-2 px-2 md:px-4 text-left text-[#7D7D7D] font-medium">
                  Name
                </th>
                <th className="py-2 px-2 md:px-4 text-left text-[#7D7D7D] font-medium">
                  Referral Date
                </th>
                <th className="py-2 px-2 md:px-4 text-left text-[#7D7D7D] font-medium">
                  Refer for
                </th>
                <th className="py-2 px-2 md:px-4 text-left text-[#7D7D7D] font-medium">
                  Employment Type
                </th>
                <th className="py-2 px-2 md:px-4 text-left text-[#7D7D7D] font-medium">
                  Talent Score
                </th>
                <th className="py-2 px-2 md:px-4 text-left text-[#7D7D7D] font-medium">
                  Status
                </th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center items-center w-full mt-10">
                      <SyncLoader color="#38e3e0" />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : 
            ( talents.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center items-center w-full mt-10">
                      <p className="text-[#A0AEC0] text-[12px] mb-4 font-[Inter]">No talents found</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : ( 
              <tbody>
                {getPaginatedData().map((talent, index) => (
                  <tr key={index} className=" border-t">
                    <td onClick={
                     ()=>navigate(`/dashboard/talent-details/${talent?.talentDetails?.talentId}`)
                    } className="py-2 text-[#323C47] px-2 md:px-4 cursor-pointer">
                      {talent.talentDetails?.talentName}
                    </td>
                    <td className="py-2 text-[#7D7D7D] px-2 md:px-4">
                      {formatDate(talent.talentDetails?.talentReferredDate)}
                    </td>
                    <td className="py-2 text-[#7D7D7D] px-2 md:px-4">
                      {talent.job?.jobTitle}
                    </td>
                    <td className="py-2 text-[#7D7D7D] px-2 md:px-4">
                      {talent.job?.jobType}
                    </td>
                    <td className="py-2 text-[#7D7D7D] px-2 md:px-4">
                      <svg
                        width="31"
                        height="35"
                        viewBox="0 0 31 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M31 17.9096C31 27.2803 24.0604 34.8768 15.5 34.8768C6.93959 34.8768 0 27.2803 0 17.9096C0 8.53885 6.93959 0.942383 15.5 0.942383C24.0604 0.942383 31 8.53885 31 17.9096Z"
                          fill="#F0F0F0"
                        />
                        <path
                          d="M15.5 1.06562C19.4152 1.06562 23.1854 2.68756 26.0531 5.60555C28.9207 8.52355 30.6733 12.5214 30.9588 16.7958C31.2442 21.0703 30.0413 25.3046 27.5918 28.6481C25.1423 31.9915 21.6276 34.1964 17.754 34.8196L15.5 18.0328L15.5 1.06562Z"
                          fill="#FF6A6A"
                        />
                        <ellipse
                          cx="15.2971"
                          cy="18.005"
                          rx="12.3889"
                          ry="13.5616"
                          fill="white"
                        />
                        <path
                          d="M15.3753 19.4582H14.137V21.375H13.221V19.4582H9.2234V18.6666L13.1588 13.0632H14.137V18.61H15.3753V19.4582ZM13.221 18.61V15.9073C13.221 15.7 13.2229 15.5134 13.2267 15.3475C13.2304 15.1779 13.2361 15.0214 13.2436 14.8782C13.2512 14.7312 13.2568 14.5917 13.2606 14.4598C13.2681 14.3278 13.2738 14.1978 13.2775 14.0696H13.2323C13.1607 14.2204 13.0778 14.3806 12.9835 14.5502C12.893 14.7161 12.8007 14.865 12.7065 14.9969L10.1564 18.61H13.221ZM18.7848 16.32C19.3465 16.32 19.8347 16.4162 20.2493 16.6084C20.664 16.7969 20.9844 17.0721 21.2105 17.4339C21.4405 17.7958 21.5554 18.235 21.5554 18.7514C21.5554 19.3168 21.4329 19.805 21.1879 20.2159C20.9429 20.623 20.5904 20.9377 20.1306 21.1601C19.6707 21.3788 19.1203 21.4881 18.4795 21.4881C18.0498 21.4881 17.6502 21.4504 17.2808 21.375C16.9114 21.2996 16.6004 21.1865 16.3478 21.0357V20.1424C16.623 20.3082 16.9566 20.4402 17.3486 20.5382C17.7407 20.6362 18.1214 20.6852 18.4908 20.6852C18.9092 20.6852 19.2749 20.6192 19.5877 20.4873C19.9044 20.3516 20.1494 20.148 20.3228 19.8766C20.5 19.6014 20.5886 19.2565 20.5886 18.8419C20.5886 18.2915 20.4189 17.8674 20.0797 17.5696C19.7442 17.2681 19.2127 17.1173 18.4852 17.1173C18.2514 17.1173 17.9951 17.1362 17.7162 17.1738C17.4372 17.2115 17.2073 17.253 17.0263 17.2982L16.5514 16.9873L16.868 13.1084H20.9787V13.9679H17.6709L17.4617 16.4614C17.605 16.4312 17.7897 16.4011 18.0159 16.3709C18.242 16.337 18.4984 16.32 18.7848 16.32Z"
                          fill="black"
                        />
                      </svg>
                    </td>
                    <td className={`py-2 px-2 md:px-4`}>
                      <button
                        className={`px-2 md:px-4 rounded-lg ${
                          talent.talentStatus === "Hired"
                            ? "text-green-600 bg-green-200"
                            : talent.talentStatus === "Rejected"
                            ? "text-red-600 bg-red-200"
                            : "text-orange-600 bg-orange-200"
                        }`}
                      >
                        {talent.talentStatus}
                      </button>
                    </td>
                    <td className="py-2 text-[#7D7D7D]">
                      <div className="border hover:bg-gray-300 rounded-md text-center">
                        <IconButton
                          aria-label="settings"
                          onClick={(event) =>
                            handleMenuOpen(event, talent.talentDetails.talentId)
                          }
                        >
                          <GradingIcon />
                        </IconButton>
                        <Menu
                          className="text-sm"
                          id={`menu-${talent.talentDetails.talentId}`}
                          anchorEl={menuAnchor}
                          open={
                            activeTalentId === talent.talentDetails.talentId
                          }
                          onClose={handleMenuClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              backgroundColor: "white",
                              borderRadius: "7px",
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                              border: "1px solid #E5E7EB",
                              minWidth: "250px",
                              width: "300px",
                              padding: "0px",
                              marginTop: "-5px",
                            },
                          }}
                        >
                          <MenuItem
                            sx={{
                              borderBottom: "solid 1px #E3E3E3",
                              marginX: "4px",
                            }}
                            className="px-4 py-2 text-sm font-medium text-black hover:bg-gray-100 border-b border-gray-200"
                          >
                            <div className="flex items-center w-full justify-between">
                              <div className="flex flex-col">
                                <p className="text-gray-400">Phone Number</p>
                                <p className="text-sm">
                                  {talent.talentDetails.contactNumber}
                                </p>
                              </div>
                              <ContentCopyIcon />
                            </div>
                          </MenuItem>
                          <MenuItem
                            sx={{
                              marginX: "4px",
                            }}
                            className="px-4 py-2 text-sm font-medium text-black hover:bg-gray-100"
                          >
                            <div className="flex items-center w-full justify-between">
                              <div className="flex flex-col">
                                <p className="text-gray-400">Email</p>
                                <p className="text-sm">
                                  {talent.talentDetails.talentMail}
                                </p>
                              </div>
                              <ContentCopyIcon />
                            </div>
                          </MenuItem>
                        </Menu>
                      </div>
                    </td>
                    <td className="py-2 text-[#7D7D7D] ">
                      <div className="border hover:bg-gray-300 rounded-md text-center  p-0.5">
                        <IconButton
                          aria-label="settings1"
                          onClick={(event) =>
                            handleMenuOpen1(
                              event,
                              talent.talentDetails.talentId
                            )
                          }
                        >
                          <InfoOutlinedIcon />
                        </IconButton>
                        <Menu
                          className="text-sm"
                          id={`menu1-${talent.talentDetails.talentId}`}
                          anchorEl={menuAnchor1}
                          open={
                            activeTalentId1 === talent.talentDetails.talentId
                          }
                          onClose={handleMenuClose1}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              backgroundColor: "white",
                              borderRadius: "7px",
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                              border: "1px solid #E5E7EB",
                              minWidth: "150px",
                              width:"400px",
                              padding: "0px",
                              marginTop: "-5px",
                            },
                          }}
                        >
                          {talent.job?.JobInterviewRounds?.map(
                            (round, index) => (
                              <MenuItem
                                key={index}
                                sx={{
                                  borderBottom: "solid 1px #E3E3E3",
                                  marginX: "4px",
                                }}
                                className="px-4 py-2 text-sm font-medium text-black hover:bg-gray-100 border-b border-gray-200"
                              >
                                <div className="flex items-center w-full justify-between">
                                  <div className="flex flex-col">
                                    <p className="text-gray-400">{`Round ${round.roundNumber}`}</p>
                                    <p className="text-sm">{round.roundName}</p>
                                  </div>
                                  <p
                                    className={`px-2 md:px-4 rounded-lg ${
                                      index % 2 === 0
                                        ? "text-green-600 bg-green-200"
                                        : "text-orange-600 bg-orange-200"
                                    }`}
                                  >
                                    {index % 2 == 0 ? "completed" : "pending"}
                                  </p>
                                </div>
                              </MenuItem>
                            )
                          )}
                        </Menu>
                      </div>
                    </td>
                    <td className="py-2 text-[#7D7D7D]">
                      <div className="border rounded-md text-center  p-0.5">
                        <IconButton
                          aria-label="settings3"
                          onClick={(event) =>
                            handleMenuOpen2(
                              event,
                              talent.talentDetails.talentId
                            )
                          }
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          className="text-sm"
                          id={`menu2-${talent.talentDetails.talentId}`}
                          anchorEl={menuAnchor2}
                          open={
                            activeTalentId2 === talent.talentDetails.talentId
                          }
                          onClose={handleMenuClose2}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              backgroundColor: "white",
                              borderRadius: "7px",
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                              border: "1px solid #E5E7EB",
                              minWidth: "150px",
                              padding: "0px",
                              marginTop: "-5px",
                            },
                          }}
                        >
                          <MenuItem
                            sx={{
                              borderBottom: "solid 1px #E3E3E3",
                              marginX: "4px",
                            }}
                            className="px-4 py-2 text-sm font-medium text-black hover:bg-gray-100 border-b border-gray-200"
                          >
                            <p onClick={()=>navigate(`/dashboard/talent-details/${talent?.talentDetails?.talentId}`)} className="text-sm">{"View Details"}</p>
                          </MenuItem>
                          <MenuItem
                            sx={{
                              marginX: "4px",
                            }}
                            className="px-4 py-2 text-sm font-medium text-black hover:bg-gray-100"
                          >
                            <p onClick={()=>navigate(`/dashboard/user/${talent?.job?.jobId}?talentId=${talent?.talentDetails?.talentId}`)} className="text-sm">{"Edit Details"}</p>
                          </MenuItem>
                        </Menu>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            ))}
          </table>
        </div>

        {/* Pagination */}
        <div className="mx-6 flex items-center justify-between  py-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-4 py-2 text-sm font-semibold ${
              currentPage === 1
                ? "bg-gray-200 text-gray-400"
                : "bg-blue-500 text-white hover:bg-blue-600"
            } rounded`}
          >
            Previous
          </button>
          <div className="flex">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`px-4 py-2 text-sm font-semibold rounded ${
                  currentPage === index + 1
                    ? "bg-[#E5F0FF] text-[#006AFF]"
                    : " hover:bg-gray-300 text-gray-400 hover:text-[#006AFF]"
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={ currentPage === totalPages }
            className={`px-4 py-2 text-sm font-semibold ${
              currentPage === totalPages
                ? "bg-gray-200 text-gray-400"
                : "bg-blue-500 text-white hover:bg-blue-600"
            } rounded`}
          >
            Next
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default RecruiterDashboard;
