import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  Popover,
} from "@mui/material";
import { Close, Refresh, MoreVert } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function GetMeetLinkModal({text='Get Link'}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    handleClose();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div id={id} className="text">
        <div onClick={handleOpenModal} className="font-semibold text-[14px]">
          {text}
        </div>
      </div>

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className="h-full bg-gray-100 p-2">
              <svg
                width="22"
                height="16"
                viewBox="0 0 22 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.4167 8.00098V3.66764C16.4167 3.09301 16.1884 2.54191 15.7821 2.13558C15.3757 1.72925 14.8246 1.50098 14.25 1.50098H3.41667C2.84203 1.50098 2.29093 1.72925 1.8846 2.13558C1.47827 2.54191 1.25 3.09301 1.25 3.66764V12.3343C1.25 12.9089 1.47827 13.46 1.8846 13.8664C2.29093 14.2727 2.84203 14.501 3.41667 14.501H14.25C14.8246 14.501 15.3757 14.2727 15.7821 13.8664C16.1884 13.46 16.4167 12.9089 16.4167 12.3343V8.00098ZM16.4167 8.00098L20.75 3.66764V12.3343L16.4167 8.00098Z"
                  stroke="#848484"
                  stroke-width="2.16667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="">
              <Typography variant="h6">Meeting Ready</Typography>
              <Typography variant="body2" className="text-gray-400">
              Here’s the link of your Interview, Happy Interviewing.
              </Typography>
            </div>
          </div>
          <IconButton onClick={handleCloseModal} size="small">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="my-2 flex flex-col">
          <div
            fullWidth
            rows={2}
            variant="outlined"
            label="Why do you want to Reschedule or cancel?"
            placeholder="Write something.."
            className="flex justify-between rounded-2xl items-center p-2 my-2 bg-gray-100"
          >
            <p className="text-gray-500">meet. google . com / hvhbhbcdbjkdvreflense</p>
            <p className="text-blue-800">Copy</p>
          </div>

          <button variant="contained" className="p-2 text-center bg-blue-600 text-white rounded-lg mt-4" fullWidth color="primary" size="large">
            Join Now
          </button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
