import React, { createContext, useState, useContext } from "react";

const SidebarContext = createContext();

export const useSidebar = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, isSidebarOpenProvide] = useState(false);
  const [sidebarState, setSidebarState] = useState(false);

  const handleSidebarToggle = () => {
    isSidebarOpenProvide(!isSidebarOpen);
  };

  const SidebarOpenProvide = () => {
    console.log("Sidebar Open");
    setSidebarState(true);
    localStorage.setItem("sidebarState", true);
  };

  const SidebarCloseProvide = () => {
    console.log("Sidebar Open");

    setSidebarState(false);
    localStorage.setItem("sidebarState", false);
  };

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpenProvide,
        sidebarState,
        handleSidebarToggle,
        SidebarOpenProvide,
        SidebarCloseProvide,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
