import React, { useEffect, useState } from "react";

import {
  Stepper,
  Step,
  StepLabel,
  Box
} from "@mui/material";
import Layout from "../../Component/Layout";
import { FaCheck, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import toast, { LoaderIcon } from "react-hot-toast";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import SkillsExpertiseForm from "../../Component/SkillsExpertise";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import ExperienceTab from "../../Component/ExperienceTab";
import TalentTab from "../../Component/TalentTab";
import AdditionalInfoTab from "../../Component/AdditionalInfoTab";
import { SyncLoader } from "react-spinners";
import { generateDateCombinations } from "../../Utils/jobFutureSlots";
function convertToIST(slots) {
  return slots?.map(slot => {
    // Convert times to IST
    const timeFromIST = new Date(slot.timeFrom).toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    const endTimeIST = new Date(slot.endTime).toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    // Extract date in a readable format
    const dateIST = new Date(slot.timeFrom).toLocaleDateString("en-IN", {
      timeZone: "Asia/Kolkata",
      day: "2-digit",
      month: "long",
      year: "numeric",
    });

    // Format the result
    const formattedDate = `${dateIST}, ${timeFromIST} - ${endTimeIST}`;

    return {
      slotId: slot.slotId,
      date: formattedDate,
    };
  });
}

export const steps = [
  "Basic Informations",
  "Experience",
  "Skills & Expertise",
  "Additional Information",
];

const EditTalent = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const talentId = params.get("talentId");
  const existingTalent = params.get("existingTalent");

  const [state, setState] = useState({
    currentStep: 0,
    formData: {
      talentName: "", 
      talentMail: "", 
      contactNumber: "", 
      talentGender: "", 
      collegeName: "", 
      yearOfGraduation: "", 
      course: "", 
      field: "", 
      companyName: "", 
      talentDesignation: "", 
      jobSeniorityLevel: "Internship", 
      talentEmploymentType: "Full-Time", 
      talentWorkplaceType: "", 
      talentCurrentLocation: "", 
      talentPreferredLocation: "", 
      talenttotalExperience: 0, 
      talentCurrentIndustry: "",
      yearGap: 0, 
      talentCurrentCTC: 0, 
      talentExpectedCTC: [0, 0], 
      talentJobRoleExplain: "", 
      officialNoticePeriod: "Immediate-Joiner", 
      joiningTime: "", 
      comment: "", 
      primarySkills: [{ skillName: "C", checked: true, score: "", years: "" }],
      secondarySkills: [
        { skillName: "Revit", checked: false, score: "", years: "" },
      ],
      softwareSkills: [
        
      ],
      certification: [
        
      ],
      industry: "Fintech",
      availabilitySlots: [],
      resume: "",
    },
  });



  const [options,setOptions]= useState({
    primarySkillsOptions: [],
    secondarySkillsOptions: [],
    softwareOptions: [],
    certificationOptions: [],
    industryOptions: [],
    primarySkillText:""
  })

  useEffect(() => {
    const fetchTopIndustry = async () => {
      try {
        // Replace with your actual backend API URL
        const url = `${process.env.REACT_APP_API_}/api/v1/job/top-ten-industry`;
        const response = await axios.get(url);
        setOptions((prevOptions) => ({
          ...prevOptions,
          industryOptions: response.data.map((skill) => ({
            value: skill.concatCell,
            label: skill.concatCell,
          })),
        }));
      } catch (err) {
        // setError("Error fetching skills. Please try again.");
        console.error(err);
      }
    };

    const fetchTopSkills = async () => {
      try {
        // Replace with your actual backend API URL
        const url = `${process.env.REACT_APP_API_}/api/v1/job/top-ten-skills`;

        const response = await axios.get(url);

        setOptions((prevOptions) => ({
          ...prevOptions,
          primarySkillsOptions: response.data.map((skill) => ({
            value: skill.skillName,
            label: skill.skillName,
          })),
        }));
        setOptions((prevOptions) => ({
          ...prevOptions,
          secondarySkillsOptions: response.data.map((skill) => ({
            value: skill.skillName,
            label: skill.skillName,
          })),
        }));
      } catch (err) {
        // setError("Error fetching skills. Please try again.");
        console.error(err);
      }
    };

    const fetchTopTenSoftware = async () => {
      try {
        // Replace with your actual backend API URL
        const url = `${process.env.REACT_APP_API_}/api/v1/job/top-ten-software`;

        const response = await axios.get(url);

        setOptions((prevOptions) => ({
          ...prevOptions,
          softwareOptions: response.data.map((skill) => ({
            value: skill.software,
            label: skill.software,
          })),
        }));
      } catch (err) {
        // setError("Error fetching skills. Please try again.");
        console.error(err);
      }
    };

    const fetchTopTenCertification = async () => {
      try {
        // Replace with your actual backend API URL
        const url = `${process.env.REACT_APP_API_}/api/v1/job/top-ten-certification`;

        const response = await axios.get(url);

        setOptions((prevOptions) => ({
          ...prevOptions,
          certificationOptions: response.data.map((skill) => ({
            value: skill.certification,
            label: skill.certification,
          })),
        }));
      } catch (err) {
        // setError("Error fetching skills. Please try again.");
        console.error(err);
      }
    };

    // const fetchTopTenInterviewRound = async () => {
    //   try {
    //     // Replace with your actual backend API URL
    //     const url = `${process.env.REACT_APP_API_}/api/v1/job//top-ten-interview-rounds`;

    //     const response = await axios.get(url);

    //     setOptions(
    //       "interviewRoundOptions",
    //       response.data.map((skill) => ({
    //         value: skill.roundName,
    //         label: skill.roundName,
    //       }))
    //     );
    //   } catch (err) {
    //     // setError("Error fetching skills. Please try again.");
    //     console.error(err);
    //   }
    // };

    fetchTopSkills();
    fetchTopIndustry();
    fetchTopTenSoftware();
    fetchTopTenCertification();
    // fetchTopTenInterviewRound();
  }, []);

  const fetchPrimarySkills = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/skills?search=${query}`
      );
      console.log("responseresponse=", response);
      setOptions((prevOptions) => ({
        ...prevOptions,
        primarySkillsOptions: response.data.map((skill) => ({
          value: skill.skillName,
          label: skill.skillName,
        })),
      }));
    } catch (error) {
      console.error("Error fetching primary skills:", error);
    }
  };

  const fetchSoftwares = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/softwares?search=${query}`
      );
      setOptions((prevOptions) => ({
        ...prevOptions,
        softwareOptions: response.data.map((skill) => ({
          value: skill.software,
          label: skill.software,
        })),
      }));
    } catch (error) {
      console.error("Error fetching primary skills:", error);
    }
  };

  const fetchCertifications = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/certifications?search=${query}`
      );
      setOptions((prevOptions) => ({
        ...prevOptions,
        certificationOptions: response.data.map((skill) => ({
          value: skill.certification,
          label: skill.certification,
        })),
      }));
    } catch (error) {
      console.error("Error fetching primary skills:", error);
    }
  };

  const fetchIndustries = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/industries?search=${query}`
      );
      setOptions((prevOptions) => ({
        ...prevOptions,
        industryOptions: response.data.map((skill) => ({
          value: skill.concatCell,
          label: skill.concatCell,
        })),
      }));
    } catch (error) {
      console.error("Error fetching primary skills:", error);
    }
  };
  

  function validateRecruitingSpecifics() {
    const newErrors = { ...errors };
    let isValid = true;

    if (!state.formData.talentJobRoleExplain) {
      newErrors.talentJobRoleExplain = "Job role explaination is required";
      isValid = false;
    } else {
      newErrors.talentJobRoleExplain = "";
    }

    if (!state.formData.comment) {
      newErrors.comment = "Comment for talent is required";
      isValid = false;
    } else {
      newErrors.comment = "";
    }

    if (selectedSlots.length < 3 ) {
      newErrors.availabilitySlots = "select atleast three slot";
      isValid = false;
    } else {
      newErrors.availabilitySlots = "";
    }

    if (!resume) {
      newErrors.resume = "please upload resume";
      isValid = false;
    } else {
      newErrors.resume = "";
    }

    

    setErrors(newErrors);
    return isValid;
  }

  const [resume, setResume] = useState(null);
  const [currencyType, setCurrencyType] = useState("INR");
  const [yearOfGrad, setYearOfGrad] = useState(new Date());
  const [currentLoc, setCurrentLoc] = useState(
    state?.formData?.talentCurrentLocation || ""
  );

  const onChangeYearOfGrad = (date) => {
    setYearOfGrad(date);
  };



  const [prefferedLoc, setPrefferedLoc] = useState(
    state?.formData?.talentPreferredLocation || ""
  );
  const [currentSalary, setCurrentSalary] = useState(2);
  const [salaryRange, setSalaryRange] = useState([
    parseInt("500"),
    parseInt("500000"),
  ]);

  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleSlotChange = (slot, checked) => {    
    if (checked) {
      setSelectedSlots((prev) => [...prev, slot]);
    } else {
      setSelectedSlots((prev) =>
        prev.filter((s) => s.date !== slot.date || s.timeFrom !== slot.timeFrom)
      );
    }
  };

  const formatPrimarySkills = (talentData) => {
    // Extract TalentSkillsMappings
    const { TalentSkillsMappings } = talentData;
  
    // Transform the data
    return TalentSkillsMappings.filter((mapping) => {
      // Include only mappings where Skill is null and JobSkillsMapping.Skill exists
      return (
        mapping.Skill === null &&
        mapping.JobSkillsMapping &&
        ["Primary"].includes(mapping.JobSkillsMapping.Skill.skillType)
      );
    }).map((mapping) => ({
      skillName: mapping.JobSkillsMapping.Skill.skillName || "N/A", // Use "N/A" if no skill name
      checked: true, // Default value as true
      score: mapping.score || "", // Default empty if null
      years: mapping.yearOfExperience || "", // Default empty if null
      jobSkillMappingId: mapping.jobSkillsMappingId || "", // Default empty if null
    }));
  };

  const formatSecondarySkills = (talentData) => {
    // Extract TalentSkillsMappings
    const { TalentSkillsMappings } = talentData;
  
    // Transform the data
    return TalentSkillsMappings.filter((mapping) => {
      // Include only mappings where Skill is null and JobSkillsMapping.Skill exists
      return (
        mapping.Skill === null &&
        mapping.JobSkillsMapping &&
        ["Secondary"].includes(mapping.JobSkillsMapping.Skill.skillType)
      );
    }).map((mapping) => ({
      skillName: mapping.JobSkillsMapping.Skill.skillName || "N/A", // Use "N/A" if no skill name
      checked: true, // Default value as true
      score: mapping.score || "", // Default empty if null
      years: mapping.yearOfExperience || "", // Default empty if null
      jobSkillMappingId: mapping.jobSkillsMappingId || "", // Default empty if null
    }));
  };

  const formatPrimaryData = (data) => {
    const { TalentSkillsMappings } = data;
    return TalentSkillsMappings.filter(
      (item) =>
        item.JobSkillsMapping === null &&
        item.Skill &&
        item.Skill.skillType.toLowerCase() === "primary"
    )
    // Map to return the desired output format
    .map((item) => ({
      id:item.Skill.id,
      skillName: item.Skill.skillName, // Get skillName
      checked: true, // Set checked as true
      score: item.score, // Get score
      years: item.yearOfExperience, // Get years of experience
    }));
  };

  const formatSecondaryData = (data) => {
    const { TalentSkillsMappings } = data;
    return TalentSkillsMappings.filter(
      (item) =>
        item.JobSkillsMapping === null &&
        item.Skill &&
        item.Skill.skillType.toLowerCase() === "secondary"
    )
    // Map to return the desired output format
    .map((item) => ({
      id:item.Skill.id,
      skillName: item.Skill.skillName, // Get skillName
      checked: true, // Set checked as true
      score: item.score, // Get score
      years: item.yearOfExperience, // Get years of experience
    }));
  };

  useEffect(() => {
    if(talentId){
      setLoader(true);
      const fetchTalentDetails = async () => {
        try {
          
          const response = await axios.get(
            `${process.env.REACT_APP_API_}/api/v1/job/get-single-talent/${talentId}`
          );
          const talentData = response.data.data;
          console.log(talentData);
          const PS = formatPrimarySkills(talentData.Talent);
          const PPS = formatPrimaryData(talentData.Talent);
          const combinePrimary = [...PS,...PPS];
          // console.log(combinePrimary);
          const SS = formatSecondarySkills(talentData.Talent);
          const SSS = formatSecondaryData(talentData.Talent);
          const combineSecondary=[...SS,...SSS];
          const timeSlots = convertToIST(talentData?.Talent?.TalentSelectedAvailabilitySlots);
          console.log("timeSlots",timeSlots);

          setResume(talentData.Talent.resume);

          setState((prevState) => ({
            ...prevState,
            formData: {
              ...prevState.formData,
              talentName: talentData.Talent.talentName || "",
              talentMail: talentData.Talent.talentMail || "",
              contactNumber: talentData.Talent.contactNumber || "",
              talentGender: talentData.Talent.talentGender || "",
              collegeName: talentData.Talent.collegeName || "",
              yearOfGraduation: talentData.Talent.yearOfGraduation || "",
              course: talentData.Talent.course || "",
              field: talentData.Talent.field || "",
              companyName: talentData.Talent.companyName || "",
              talentDesignation: talentData.Talent.talentDesignation || "",
              jobSeniorityLevel: talentData.Talent.jobSeniorityLevel || "",
              talentEmploymentType: talentData.Talent.talentEmploymentType || "",
              talentWorkplaceType: talentData.Talent.talentWorkplaceType || "",
              talentCurrentLocation: talentData.Talent.talentCurrentLocation || "",
             talentPreferredLocation: talentData.Talent.talentPreferredLocation || "",
             talenttotalExperience: Number(talentData.Talent.talentTotalExperience) || 0,
             yearGap: Number(talentData.Talent.yearGap) || 0,
             talentCurrentCTC: Number(talentData.Talent.talentCurrentCTC) || 0,
             talentExpectedCTC:[Number(talentData.Talent.talentExpectedCTCMin),Number(talentData.Talent.talentExpectedCTCMax)]  || 0,
             officialNoticePeriod: talentData.Talent.officialNoticePeriod || "",
             talentCurrentIndustry:talentData.Talent.talentCurrentIndustry || "",
             industry:talentData.Talent.industryKnowledge || '',
             primarySkills:combinePrimary,
             secondarySkills:combineSecondary,
             softwareSkills:talentData.Talent.softwareProficiency || [],
             certification:talentData.Talent.certifications || [],
             talentJobRoleExplain:talentData.Talent.talentJobRoleExplain || "",
             comment:talentData.comment || "",
             availabilitySlots:timeSlots || [],
             resume: talentData.Talent.resume || "",
            },
          }
          ));
          
          
          // console.log(PS);
          
          // console.log(SS);
          
          // console.log(PPS);
          
          setSelectedSlots(timeSlots);
          setCurrentSalary(Number(talentData.Talent.talentCurrentCTC) || 0);
          setSalaryRange([Number(talentData.Talent.talentExpectedCTCMin),Number(talentData.Talent.talentExpectedCTCMax)])
          
        } catch (error) {
          console.error("Error fetching talent details:", error);
          // Handle error, such as displaying an error message to the user
          toast.error(`${error.message}`);
        }
        setLoader(false);
      };
  
      fetchTalentDetails();
    }
    
    
  }, [talentId]);

  useEffect(() => {
    if(existingTalent){
      setLoader(true);
      const fetchTalentDetails = async () => {
        try {
          
          const response = await axios.get(
            `${process.env.REACT_APP_API_}/api/v1/job/get-single-talent/${existingTalent}`
          );
          const talentData = response.data.data;
          console.log(talentData);

          setState((prevState) => ({
            ...prevState,
            formData: {
              ...prevState.formData,
              talentName: talentData.Talent.talentName || "",
              talentMail: talentData.Talent.talentMail || "",
              contactNumber: talentData.Talent.contactNumber || "",
              talentGender: talentData.Talent.talentGender || "",
              collegeName: talentData.Talent.collegeName || "",
              yearOfGraduation: talentData.Talent.yearOfGraduation || "",
              course: talentData.Talent.course || "",
              field: talentData.Talent.field || "",
              companyName: talentData.Talent.companyName || "",
              talentDesignation: talentData.Talent.talentDesignation || "",
              jobSeniorityLevel: talentData.Talent.jobSeniorityLevel || "",
              talentEmploymentType: talentData.Talent.talentEmploymentType || "",
              talentWorkplaceType: talentData.Talent.talentWorkplaceType || "",
              talentCurrentLocation: talentData.Talent.talentCurrentLocation || "",
             talentPreferredLocation: talentData.Talent.talentPreferredLocation || "",
             talenttotalExperience: Number(talentData.Talent.talentTotalExperience) || 0,
             yearGap: Number(talentData.Talent.yearGap) || 0,
             talentCurrentCTC: Number(talentData.Talent.talentCurrentCTC) || 0,
             talentExpectedCTC:[Number(talentData.Talent.talentExpectedCTCMin),Number(talentData.Talent.talentExpectedCTCMax)]  || 0,
             officialNoticePeriod: talentData.Talent.officialNoticePeriod || "",
             talentCurrentIndustry:talentData.Talent.talentCurrentIndustry || "",
             industry:talentData.Talent.industryKnowledge || '',
             softwareSkills:talentData.Talent.softwareProficiency || [],
             certification:talentData.Talent.certifications || [],
             talentJobRoleExplain:talentData.Talent.talentJobRoleExplain || "",
            },
          }
          ));
          setCurrentSalary(Number(talentData.Talent.talentCurrentCTC) || 0);
          setSalaryRange([Number(talentData.Talent.talentExpectedCTCMin),Number(talentData.Talent.talentExpectedCTCMax)])
          
        } catch (error) {
          console.error("Error fetching talent details:", error);
          // Handle error, such as displaying an error message to the user
          toast.error(`${error.message}`);
        }
        setLoader(false);
      };
  
      fetchTalentDetails();
    }
    
    
  }, [existingTalent]);

  
  

  // useEffect(()=>{
  //   console.log('selectedSlots=', selectedSlots);
  // }, [selectedSlots])

  const handleLocationSearch = async (searchValue) => {
    if (searchValue.length >= 2) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_}/api/v1/job/locations?search=${searchValue}`
        );
        const locationsToShow = response.data;
        console.log("locationsToShow=", locationsToShow);
        const arr = locationsToShow?.map((loc) => {
          return `${loc?.city} | ${loc?.state} | ${loc?.country}`;
        });
        console.log("arr=", arr);
        setLocationOptions(arr);
      } catch (error) {
        console.error("Error fetching location suggestions:", error);
      }
    } else {
      setLocationOptions([]);
    }
  };

  const handleResumeUpload = async (event) => {
    const file = event.target.files[0]; // Get the selected file

    const fileFormData = new FormData();
    fileFormData.append("file", file);

    try {
      setUploadLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/job/upload`,
        fileFormData
      );
      if (response.status === 200) {
        toast.success("Resume uploaded successfully");
        setResume(response.data.url);
      }else{
        toast.error("unable to upload please try again");
      }
      console.log("Resume uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading resume:", error);
    }
    finally {
      setUploadLoading(false);
    }
   

      

      // console.log("Selected resume:", file.name);
    }

  const { jobId } = useParams();

  const [errors, setErrors] = useState({
    talentName: "",
    talentMail: "",
    contactNumber: "",
    talentGender: "",
    collegeName: "",
    yearOfGraduation: "",
    course: "",
    field: "",
    talentCurrentLocation: "",
    talentPreferredLocation: "",

    companyName: "",
    talentDesignation: "",
    jobSeniorityLevel: "",
    talentEmploymentType: "",
    talentWorkplaceType: "",
    talenttotalExperience: "",
    talentCurrentIndustry: "",
    yearGap: "",
    talentCurrentCTC: "",
    talentExpectedCTC: "",
    officialNoticePeriod: "",

    primarySkills: [],
    secondarySkills: [],
    industry: "",

    talentJobRoleExplain: "",
    comment: "",
  });

  const clearErrors = () => {
    setErrors({
      talentName: "",
      talentMail: "",
      contactNumber: "",
      talentGender: "",
      collegeName: "",
      yearOfGraduation: "",
      course: "",
      field: "",
      talentCurrentLocation: "",
      talentPreferredLocation: "",

      companyName: "",
      talentDesignation: "",
      jobSeniorityLevel: "",
      talentEmploymentType: "",
      talentWorkplaceType: "",
      talenttotalExperience: "",
      talentCurrentIndustry: "",
      yearGap: "",
      talentCurrentCTC: "",
      talentExpectedCTC: "",
      officialNoticePeriod: "",

      primarySkills: [],
      secondarySkills: [],
      industry: "",
    });
  };
  const nav = useNavigate();

  const validateExperience = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!state.formData.companyName) {
      newErrors.companyName = "Company Name is required";
      isValid = false;
    } else {
      newErrors.companyName = "";
    }

    if (!state.formData.talentDesignation) {
      newErrors.talentDesignation = "Designation is required";
      isValid = false;
    } else {
      newErrors.talentDesignation = "";
    }

    if (!state.formData.jobSeniorityLevel) {
      console.log("jobSeniorityLevel", state.formData.jobSeniorityLevel);
      newErrors.jobSeniorityLevel = "Job Seniority Level is required";
      isValid = false;
    } else {
      newErrors.jobSeniorityLevel = "";
    }

    if (!state.formData.talentEmploymentType) {
      newErrors.talentEmploymentType = "Employment Type is required";
      isValid = false;
    } else {
      newErrors.talentEmploymentType = "";
    }

    if (!state.formData.talentCurrentIndustry) {
      newErrors.talentCurrentIndustry = "Workplace Industry is required";
      isValid = false;
    } else {
      newErrors.talentCurrentIndustry = "";
    }

    if (!String(state.formData.talenttotalExperience)) {
      newErrors.talenttotalExperience = "Total Experience is required";
      isValid = false;
    } else if (isNaN(state.formData.talenttotalExperience)) {
      newErrors.talenttotalExperience = "Total Experience must be a number";
      isValid = false;
    } else {
      newErrors.talenttotalExperience = "";
    }

    if (state.formData.yearGap && isNaN(state.formData.yearGap)) {
      newErrors.yearGap = "Year Gap must be a number";
      isValid = false;
    } else {
      newErrors.yearGap = "";
    }

    if (isNaN(currentSalary)) {
      newErrors.talentCurrentCTC = "Current CTC must be a number";
      isValid = false;
    } else {
      newErrors.talentCurrentCTC = "";
    }

    if (!salaryRange) {
      newErrors.talentExpectedCTC = "Expected CTC is required";
      isValid = false;
    } else {
      newErrors.talentExpectedCTC = "";
    }

    if (!state.formData.officialNoticePeriod) {
      newErrors.officialNoticePeriod = "Notice Period is required";
      isValid = false;
    } else {
      newErrors.officialNoticePeriod = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const [loader, setLoader] = useState(false);

  const validateBasicInformation = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!state.formData.talentName) {
      newErrors.talentName = "Talent Name is required";
      isValid = false;
    } else {
      newErrors.talentName = "";
    }

    if (!state.formData.talentMail) {
      newErrors.talentMail = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(state.formData.talentMail)) {
      newErrors.talentMail = "Email is invalid";
      isValid = false;
    } else {
      newErrors.talentMail = "";
    }

    if (!state.formData.contactNumber) {
      newErrors.contactNumber = "Contact Number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(state.formData.contactNumber)) {
      newErrors.contactNumber = "Contact Number should be 10 digits";
      isValid = false;
    } else {
      newErrors.contactNumber = "";
    }

    if (!state.formData.talentGender) {
      newErrors.talentGender = "Gender is required";
      isValid = false;
    } else {
      newErrors.talentGender = "";
    }

    if (!state.formData.talentCurrentLocation) {
      newErrors.talentCurrentLocation = "Current Location is required";
      isValid = false;
    } else {
      newErrors.talentCurrentLocation = "";
    }

    if (!state.formData.talentPreferredLocation) {
      newErrors.talentPreferredLocation = "Preferred Location is required";
      isValid = false;
    } else {
      newErrors.talentPreferredLocation = "";
    }

    if (!state.formData.course) {
      newErrors.course = "Course is required";
      isValid = false;
    } else {
      newErrors.course = "";
    }

    if (!state.formData.field) {
      newErrors.field = "Field is required";
      isValid = false;
    } else {
      newErrors.field = "";
    }

    if (!state.formData.collegeName) {
      newErrors.collegeName = "College Name is required";
      isValid = false;
    } else {
      newErrors.collegeName = "";
    }

    if (!state.formData.yearOfGraduation) {
      newErrors.yearOfGraduation = "Year of Graduation is required";
      isValid = false;
    } else if (
      parseInt(state.formData.yearOfGraduation) <= 1950 ||
      parseInt(state.formData.yearOfGraduation) >= 2100
    ) {
      newErrors.yearOfGraduation =
        "Year of Graduation should be between 1950 and 2100";
      isValid = false;
    } else {
      newErrors.yearOfGraduation = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const validateSkillsAndExpertise = () => {
    let isValid = true;
    const newErrors = { ...errors };
    // console.log("formdata-", state.formData);

    // Validate primary skills
    state.formData.primarySkills.forEach((skill, index) => {
      if (!skill.score || !skill.years) {
        newErrors.primarySkills = newErrors.primarySkills || [];
        newErrors.primarySkills[index] =
          "Score and years of experience are required";
        isValid = false;
      }
    });

    // Validate secondary skills
    state.formData.secondarySkills.forEach((skill, index) => {
      if (!skill.score || !skill.years) {
        newErrors.secondarySkills = newErrors.secondarySkills || [];
        newErrors.secondarySkills[index] =
          "Score and years of experience are required";
        isValid = false;
      }
    });

    // Validate industry knowledge
    if (!state.formData.industry) {
      newErrors.industry = "Industry knowledge is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    // Cleanup function to remove localStorage when EditTalent unmounts
    return () => {
      localStorage.removeItem("initialCounts");
    };
  }, []);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_}/api/v1/job/get-jobs-skills-and-slots/${jobId}`
          // `${process.env.REACT_APP_API_}/api/v1/job/get-single-job/${jobId}`
        );

        // console.log("response -> ", response.data.job);
        const jobData = response.data.job;

        const extractedSlots = jobData?.availabilitySlots.map(slot => ({
          day: slot?.day,
          timeFrom: slot.timeFrom,
          duration: slot.duration,
          recurring:slot.recurring,
          date: slot?.date
      }));

      console.log("extractedSlot", extractedSlots);
       
      const slots = generateDateCombinations(extractedSlots,jobData?.roundDuration);

      if(!talentId){
        setState({
          ...state,
          formData: {
            ...state.formData,
            primarySkills: jobData.primarySkills,
            secondarySkills: jobData.secondarySkills,
            availabilitySlots: slots,
          },
        });

        // setSelectedSlots(slots);
      }

        if (response.status === 200) {
          const existingJob = response.data.job; // Accessing job details from response
          console.log(existingJob); // Logging job details to console
          return existingJob;
        } else {
          // Handle case where API request is successful but response indicates failure
          toast.error(response.data.message || "Failed to fetch job details");
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
        // Handle error, such as displaying an error message to the user
        toast.error(`${error.message}`);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  const handlePrev = () => {
    clearErrors();
    setState((prevState) => ({
      ...prevState,
      currentStep: prevState.currentStep - 1,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("name value-", name, value);

    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  const handleSkillScoreChange = (index, event) => {
    const newValue = event.target.value;
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        skills:
          Array.isArray(prevState?.formData?.skills) &&
          prevState?.formData?.skills?.map((s, i) =>
            i === index ? { ...s, score: newValue } : s
          ),
      },
    }));
  };

  // console.log("state.formData=", state.formData);
  const handleExperienceChange = (index, event) => {
    const newValue = event.target.value;

    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        skills:
          Array.isArray(prevState?.formData?.skills) &&
          prevState?.formData?.skills?.map((s, i) =>
            i === index ? { ...s, exp: newValue } : s
          ),
      },
    }));
  };

  const handleRemoveSkill = (index) => {
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        skills:
          Array.isArray(prevState?.formData?.skills) &&
          prevState?.formData?.skills?.filter((_, i) => i !== index),
      },
    }));
  };

  const handleExpectedSalaryChange = (event, newValue) => {
    console.log("newValue=", newValue);

    // Check if newValue is valid
    if (Array.isArray(newValue) && newValue.length === 2) {
      setSalaryRange(newValue);
    } else {
      console.error(
        "Expected newValue to be an array of two numbers, but received:",
        newValue
      );
    }
  };

  //next blocker
  const handleNextBlocker = () => {
    switch (state.currentStep) {
      case 0:
        return (
          state.formData.companyName === "" ||
          state.formData.companyIndustry === ""
        );
      case 1:
        return (
          state.formData.talentDesignation === "" ||
          state.formData.talentEmploymentType === "" ||
          state.formData.jobSeniorityLevel === "" ||
          state.formData.talentWorkplaceType === "" ||
          state.formData.engagementType === ""
        );
      case 2:
        return (
          state.formData.talentName === "" ||
          state.formData.talentMail === "" ||
          state.formData.contactNumber === "" ||
          state.formData.talentGender === ""
        );
      case 3:
        return state.formData.skills?.some((s) => s.skillName === "");
      default:
        return false;
    }
  };

  function handleNext() {
    console.log("called");
    if (state.currentStep === 0) {
      const isValid = validateBasicInformation();
      // const isValid=true;
      if (isValid) {
        setState((prevState) => ({
          ...prevState,
          currentStep: prevState.currentStep + 1,
        }));
      }
    } else if (state.currentStep === 1) {
      const isValid = validateExperience();
      // const isValid=true;
      if (isValid) {
        setState((prevState) => ({
          ...prevState,
          currentStep: prevState.currentStep + 1,
        }));
      }
    } else if (state.currentStep === 2) {
      const isValid = validateSkillsAndExpertise();
      // const isValid=true;
      if (isValid) {
        setState((prevState) => ({
          ...prevState,
          currentStep: prevState.currentStep + 1,
        }));
      }
    } else if (state.currentStep === 3) {
      const isValid = validateRecruitingSpecifics();
      // const isValid=true;
      if (isValid) {
        setState((prevState) => ({
          ...prevState,
          currentStep: prevState.currentStep + 1,
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        currentStep: prevState.currentStep + 1,
      }));
    }
    console.log("currentStep==", state.currentStep);
  }

  

  const handleSubmit = async () => {
    if (handleNextBlocker()) {
      toast.error("Please fill all the required fields");
      return;
    }
    const isValid = validateRecruitingSpecifics();
    if (!isValid) return;

    if(talentId){
      setLoader(true);
      const updatedFormData = {
        ...state.formData,
        talentCurrentCTC: currentSalary,
        talentExpectedCTC: salaryRange,
        jobId:jobId,
        resume: resume,
      };

      console.log("edit data",updatedFormData);
      const url = `${process.env.REACT_APP_API_}/api/v1/job/edit-talent/${talentId}`;
      try {

        const response = await axios.put(url, updatedFormData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Response:", response.data);
        if(response.status==200){
          toast.success("Talent updated Successfully");
          nav("/dashboard/user/my-talent");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Something went wrong", error.message);
        // nav("/dashboard/user/my-talent");
      }
      setLoader(false);

    }else{
      const updatedFormData = {
        ...state.formData,
        talentCurrentCTC: currentSalary,
        talentExpectedCTC: salaryRange,
        availabilitySlots: selectedSlots,
        resume: resume,
      };
  
      setState(updatedFormData);
      setState((prevState)=> ({
        formData: prevState.formData,
      }))
      const formData = new FormData();
      for (const key in state.formData) {
        if (key === "skills") {
          formData.append(
            key,
            JSON.stringify(
              state?.formData[key]?.map((s) => ({ ...s, exp: Number(s.exp) }))
            )
          );
        } else if (key === "talentCurrentCTC") {
          formData.append(key, currentSalary);
        } else if (key === "talentExpectedCTC") {
          formData.append(key, JSON.stringify(salaryRange));
        } else if (key === "availabilitySlots") {
          formData.append(key, JSON.stringify(selectedSlots));
        } else {
          formData.append(key, state.formData[key]);
        }
      }
      formData.append("photo", resume);
      console.log("Form Data:", state.formData);
  
      setLoader(true);
      //api
      // const url = `${process.env.REACT_APP_API_}/api/v1/job/edit-talent/${jobId}`;
      const url = `${process.env.REACT_APP_API_}/api/v1/job/create-talent/${jobId}`;
      console.log("formData==", state.formData);
  
      try {
        const response = await axios.post(url, updatedFormData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Response:", response);
        if(response.data.success){
          toast.success("Talent created Successfully");
          nav("/activejoblist");
        }else{
          toast.error(response.data.message);
          // nav("/activejoblist");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Something went wrong", error.message);
        // nav("/dashboard/user/my-talent");
      }
      setLoader(false);

    }

    
  };

  const formatSalary = (salary) => {
    if (salary >= 1000000) {
      return `${salary / 1000000}m`;
    } else if (salary >= 1000) {
      return `${salary / 1000}k`;
    }
    return salary;
  };

  const handleSkillChange = (index, field, value, skillType) => {
    console.log("handleSkillChange==", index, field, value, skillType);

    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState?.formData,
        [skillType === "primary" ? "primarySkills" : "secondarySkills"]:
          prevState.formData[
            skillType === "primary" ? "primarySkills" : "secondarySkills"
          ].map((skill, i) =>
            i === index ? { ...skill, [field]: value } : skill
          ),
      },
    }));
  };

  const handleSoftwareSkillChange = (index, field, value) => {
    console.log("handleSoftwareSkillChange==", index, field, value);

    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState?.formData,
        softwareSkills: prevState?.formData?.softwareSkills?.map((skill, i) =>
          i === index ? { ...skill, [field]: value } : skill
        ),
      },
    }));
  };

  const handleCertificationChange = (index, field, value) => {
    console.log("handleCertificationChange==", index, field, value);

    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState?.formData,
        certification: prevState?.formData?.certification?.map((cert, i) =>
          i === index ? { ...cert, [field]: value } : cert
        ),
      },
    }));
  };

  const handleAddSkill = (skillType) => {
    const newSkill = { skillName: "", checked: false, score: "5", years: "5" };
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [skillType === "primary" ? "primarySkills" : "secondarySkills"]: [
          ...prevState.formData[
            skillType === "primary" ? "primarySkills" : "secondarySkills"
          ],
          newSkill,
        ],
      },
    }));
  };

  const handleAddSoftwareSkill = () => {
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        softwareSkills: Array.isArray(prevState.formData.softwareSkills)
          ? [
              ...prevState.formData.softwareSkills,
              { name: "", checked: false, level: "Beginner" },
            ]
          : [{ name: "", checked: false, level: "Beginner" }],
      },
    }));
  };

  const handleAddCertification = () => {
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        certification: Array.isArray(prevState.formData.certification)
          ? [...prevState.formData.certification, { name: "", checked: false }]
          : [{ name: "", checked: false }],
      },
    }));
  };

  const handleDeleteSkill = (index, skillType) => {
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [skillType === "primary" ? "primarySkills" : "secondarySkills"]:
          prevState.formData[
            skillType === "primary" ? "primarySkills" : "secondarySkills"
          ]?.filter((_, i) => i !== index),
      },
    }));
  };

  const handleDeleteSoftwareSkill = (index) => {
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState?.formData,
        softwareSkills: prevState?.formData?.softwareSkills?.filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  const handleDeleteCertification = (index) => {
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState?.formData,
        certification: prevState?.formData?.certification?.filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  const handleIndustryChange = (selectedOption) => {
    console.log("handleIndustryChange=", selectedOption);
  
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        industry: selectedOption || "", // Get the value from the selected option
      },
    }));
  };

  const skillsAndExpertiseTab = (
    <div className="flex justify-center min-h-scree h-full overflow-auto no-scrollbar w-full">
      <div className="w-full md:w-3/4 overflow-aut no-scrollbar">
        <Box mb={4}>
          <Stepper activeStep={2} alternativeLabel>
            {steps?.map((label, index) => (
              <Step key={index}>
                <StepLabel sx={{ fontWeight: 900 }}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <div>
          <div className="w-full overflow-aut m-auto no-scrollbar">
            <div className="border bg-white rounded-xl p-4 md:p-6 shadow-lg no-scrollbar">
              {/* <div className="text-xl font-bold mb-1">Skills & Expertise</div> */}
              <p className="text-base text-gray-400 mb-4 leading-6">
                This Job post requires the following set of skills and
                expertise. You can check the checklist and select the matching
                skills or add alternative skills.
              </p>
              <div>
                {/* <SkillsExpertiseForm /> */}

                <SkillsExpertiseForm
                options={options}
                fetchPrimarySkills={fetchPrimarySkills}
                fetchCertifications={fetchCertifications}
                fetchSoftwares={fetchSoftwares}
                fetchIndustries={fetchIndustries}
                  primarySkills={state.formData?.primarySkills || []}
                  secondarySkills={state.formData?.secondarySkills || []}
                  softwareSkills={state.formData?.softwareSkills || []}
                  certifications={state.formData?.certification || []}
                  industry={state.formData?.industry}
                  onPrimarySkillChange={(index, field, value) =>
                    handleSkillChange(index, field, value, "primary")
                  }
                  onSecondarySkillChange={(index, field, value) =>
                    handleSkillChange(index, field, value, "secondary")
                  }
                  onSoftwareSkillChange={handleSoftwareSkillChange}
                  onCertificationChange={handleCertificationChange}
                  onIndustryChange={handleIndustryChange}
                  onAddSkill={handleAddSkill}
                  onAddSoftwareSkill={handleAddSoftwareSkill}
                  onAddCertification={handleAddCertification}
                  onDeleteSkill={handleDeleteSkill}
                  onDeleteSoftwareSkill={handleDeleteSoftwareSkill}
                  onDeleteCertification={handleDeleteCertification}
                  errors={errors}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const stepComponents = [
    <TalentTab
      setState={setState}
      handleChange={handleChange}
      handleLocationSearch={handleLocationSearch}
      state={state}
      errors={errors}
      setErrors={setErrors}
      locationOptions={locationOptions}
    />,
    <ExperienceTab
      handleChange={handleChange}
      errors={errors}
      state={state}
      currencyType={currencyType}
      setCurrencyType={setCurrencyType}
      currentSalary={currentSalary}
      setCurrentSalary={setCurrentSalary}
      handleExpectedSalaryChange={handleExpectedSalaryChange}
      salaryRange={salaryRange}
      setSalaryRange={setSalaryRange}
    />,
    skillsAndExpertiseTab,
    <AdditionalInfoTab
      state={state}
      handleChange={handleChange}
      uploadLoading={uploadLoading}
      availabilitySlots={state.formData?.availabilitySlots || []}
      handleResumeUpload={handleResumeUpload}
      handleSlotChange={handleSlotChange}
      selectedSlots={selectedSlots}
      resume={resume}
      setResume={setResume}
      errors={errors}
    />,
  ];

  if (loader) {
    return (
      <Layout showFooter={true}>
        <div className="flex justify-center items-center h-screen">
          <SyncLoader color="#38e3e0" />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="container mx-auto h-full overflow-auto relative pb-16 mt-16">
        {/* {renderFormStep()} */}
        {stepComponents[state.currentStep]}
        <div className="flex justify-center fixed bottom-0 right-0 w-full p-4 ml-20 lg:pl-20 bg-white border-t-2 border-gray-200">
          <div className="w-full flex justify-between gap-2 lg:px-28">
            {state.currentStep > 0 ? (
              <button
                onClick={handlePrev}
                className="flex items-center px-4 py-2 lg:ml-20 bg-blue-500 text-white rounded"
              >
                <FaChevronLeft className="mr-1" /> Previous
              </button>
            ) : (
              <button></button>
            )}
            {state.currentStep < steps.length - 1 && (
              <button
                onClick={handleNext}
                className="flex items-center px-4 py-2 bg-blue-500 text-white rounded"
              >
                Next <FaChevronRight className="ml-1" />
              </button>
            )}
            {state.currentStep === steps.length - 1 && (
              <button
                onClick={handleSubmit}
                className="flex items-center px-4 py-2 bg-yellow-500 text-white rounded"
              >
                Submit <FaCheck className="ml-1" />
              </button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditTalent;
