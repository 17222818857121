import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Spinner from "../Spinner";
import axios from "axios";
import { useAuth } from "../../context/auth";
import SyncLoader from "react-spinners/SyncLoader";

export default function PrivateRoute() {
  const [ok, setOk] = useState(false);
  const {auth, setAuth} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const authCheck = async () => {
      try{
        const res = await axios.get(`${process.env.REACT_APP_API_}/api/v1/auth/user-auth`,
          {
            headers: {
              authorization: `Bearer ${auth?.token}`,
            }
          }
        );
        if (res.data.ok) {
          setOk(true);
        } else {
          navigate("/login");
          setOk(false);
        }
      }catch(error){
        console.error("Authentication check failed:", error);
        setOk(false);
        navigate("/login");
      }
      
    };
    
      if (auth?.token){
        console.log()
        authCheck();
      }else{
        const relativePath = window.location.pathname + window.location.search; // Includes the path and query params

        localStorage.setItem("redirectAfterLogin", relativePath);
        navigate("/login");
      }
    
    
  }, [auth?.token,navigate]);

  return ok ? <Outlet /> : (
    <>
    <div className="flex justify-center items-center h-[90vh]">
      <SyncLoader />
    </div>
    </>
  );
}