import React, { useState, useEffect } from "react";
import Layout from "../../Component/Layout";
import axios from "axios";
import { Link, useSearchParams } from "react-router-dom";
import SearchInput from "../../Component/SearchInput";
import { Tooltip } from "@material-tailwind/react";
import { SyncLoader } from "react-spinners";
import { routes } from "../../App";
import toast, { Toaster } from "react-hot-toast";
import { Input } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  FaChevronDown,
  FaChevronUp,
  FaMoneyBill,
  FaSearch,
} from "react-icons/fa";
import { LocationOnOutlined } from "@mui/icons-material";
import { formatDistanceToNow } from "date-fns";
import AvailabilitySlotsModal from "../../Component/AvailabilitySlotsModal";
import ReferExistingTalentModal from "../../Component/ReferExistingTalentModal";

const ActiveJobList = () => {
  const [jobs, setJobs] = useState([]);
  const [showSlider, setShowSlider] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJobIndex, setSelectedJobIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [seachedText, setSeachedText] = useState("");
  const [searchedJobs, setSearchedJobs] = useState([]);
  const [myJobs, setMyJobs] = useState([]);
  const [myJobId, setMyJobsId] = useState([]);
  const [isJobSummaryOpen, setIsJobSummaryOpen] = useState(true);
  const [isJobDescriptionOpen, setIsJobDescriptionOpen] = useState(false);
  const jobId = localStorage.getItem("jobId");
  const [searchParams, setSearchParams] = useSearchParams();
  const params = new URLSearchParams(searchParams);

  // console.log(searchedJobs);
  // console.log(seachedText);
  const ChevronDown = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m6 9 6 6 6-6" />
    </svg>
  );

  const UserIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
      <circle cx="12" cy="7" r="4" />
    </svg>
  );
  const sortJob = (jobsArray, id) => {
    // Separate the matching job and the rest of the jobs
    const matchingJobs = jobsArray.filter((job) => job.jobId === id);
    const otherJobs = jobsArray.filter((job) => job.jobId !== id);

    // Concatenate the matching jobs at the top with the remaining jobs
    return [...matchingJobs, ...otherJobs];
  };
  const getAllJobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/get-all-jobs`
      );
      // console.log("response.data=", response.data);

      if (jobId) {
        setJobs(response.data.job);
        setSelectedJob(response.data.job.find((job) => job.jobId === jobId));
        localStorage.removeItem("jobId");
      } else {
        if (params.get("id")) {
          setJobs(sortJob(response.data.job, params.get("id")));
          setSelectedJob(
            response.data.job.find((job) => job.jobId === params.get("id"))
          );
        } else {
          setJobs(response.data.job);
          setSelectedJob(response.data.job[0]);
        }
        // setSelectedJob(response.data.job[0]);
      }
    } catch (error) {
      console.error("Error fetching job data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllJobs();
    console.log(selectedJob);
  }, []);
  const getMyJobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/get-hiring-manager-jobs`
      );
      //const sortedJobs = sortJobs(response.data); // sort all jobs received
      setMyJobs(response.data);
      setMyJobsId(response.data.data?.map((job) => job.jobId));
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
  };
  // useEffect(() => {
  //   getMyJobs();
  // }, []);
  // console.log(myJobId);
  useEffect(() => {
    if (showSlider) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showSlider]);

  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    const filtered = jobs?.filter(
      (job) =>
        job.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        job.jobTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
        job.jobLocation.toLowerCase().includes(searchQuery.toLowerCase()) ||
        job.JobSkillsMappings?.some((mapping) =>
          mapping.Skill?.skillName
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
    );
    setSearchedJobs(filtered);
  }, [searchQuery]);

  const handleClose = () => {
    setShowSlider(false);
    setSelectedJob(null);
  };

  const handleJobCardClick = (job, i) => {
    console.log("jobjob=", job);

    setSelectedJob(job);
    setShowSlider(true);
  };
  const formatWithCommas = (value) => {
    if (!value) return "";
    return Number(value).toLocaleString();
  };
  const isHTMLContent = (content) => {
    console.log("content=", content);
    const doc = new DOMParser().parseFromString(content, "text/html");
    return Array.from(doc.body.childNodes).some(
      (node) => node.nodeType === 1 || node.nodeType === 3
    );
  };
  // useEffect(() => {
  //   const params = new URLSearchParams(searchParams);
  //   const jobId = params.get("id");
  //   if (jobId) {
  //     setSelectedJob(jobs.find((job) => job.jobId === jobId));

  //   }

  // }, [searchParams]);

  useEffect(() => {
    if (selectedJob) {
      params.set("id", selectedJob.jobId);
      setSearchParams(params);
    }
  }, [selectedJob]);

  // useEffect(() => {
  //   setSelectedJob(jobs[0]);
  // }, [jobs]);

  const repostJob = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/job/repost-job/${id}`
      );
      console.log(response);
      getAllJobs();
      toast.success("Job reposted successfully", {
        duration: 4000,
        position: "top-left",
      });
    } catch (error) {
      console.error("Error reposting job:", error);
      toast.error("Error reposting job", {
        duration: 4000,
        position: "top-left",
      });
    }
  };

  function extractCurrencySymbol(currencyString) {
    const match = currencyString.match(/\((.*?)\)/); // Extract text inside parentheses
    return match ? match[1] : null; // Return the symbol if found, otherwise null
  }

  const formatFrequency = (inputString) => {
    // Create a mapping of input values to their corresponding output
    const mapping = {
      "Per Hour": "/hour",
      "Per Week": "/week",
      "Per Month": "/month",
      "Per Annum": "/annum",
    };

    // Return the formatted value or default to the input if not found
    return mapping[inputString] || inputString;
  };

  // console.log(jobs)

  // useEffect(() => {
  //   setSelectedJob(() => jobs[selectedJobIndex]);
  // }, [selectedJobIndex]);

  return (
    <Layout showFooter={false}>
      <div className="w-full bg-[#F7F7F7]">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <SyncLoader color="#38e3e0" />
          </div>
        ) : (
          <div className="flex p-10 w-full gap-4">
            <div className="w-full lg:w-2/5 h-[100vh] overflow-auto border-r ">
              <div className="relative mx-2">
                <input
                  className="min-w-[8rem] p-2 pl-10 border rounded-3xl w-full bg-white"
                  placeholder="Search Jobs"
                  type="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <FaSearch className="w-4 h-4 text-gray-500" />
                </div>
              </div>
              <div className=" overflow-y-auto p-2 ">
                <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
                  <div className="text-gray-500 mt-4">
                    Showing {searchQuery ? searchedJobs?.length : jobs?.length}{" "}
                    results
                  </div>
                  <div className="w-fit">
                    {/* <SearchInput
                setSearchedJobs={setSearchedJobs}
                searchedText={setSeachedText}
              /> */}
                  </div>
                  {searchedJobs.length > 0 ? (
                    searchedJobs?.map((job) => (
                      <div
                        key={job?.id}
                        className={`bg-white p-4 shadow-md rounded-md mb-2 ${
                          selectedJob.jobId === job.jobId
                            ? "border-blue-500 border-2"
                            : ""
                        }  cursor-pointer hover:shadow-xl`}
                        onClick={() => handleJobCardClick(job, job?.jobId)}
                      >
                        <div className="flex items-center justify-between gap-2">
                          <h1 className="text-xl capitalize font-semibold">
                            {job?.companyName}
                          </h1>
                          <div>
                            <div className="flex gap-1 text-[12px] items-center text-gray-400">
                              <svg
                                width="13"
                                height="14"
                                viewBox="0 0 11 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.75781 0.583008C8.62718 0.583008 10.9531 3.00805 10.9531 5.99967C10.9531 8.9913 8.62718 11.4163 5.75781 11.4163C2.88844 11.4163 0.5625 8.9913 0.5625 5.99967C0.5625 3.00805 2.88844 0.583008 5.75781 0.583008ZM5.75781 1.66634C4.65551 1.66634 3.59835 2.12289 2.8189 2.93555C2.03945 3.7482 1.60156 4.8504 1.60156 5.99967C1.60156 7.14895 2.03945 8.25115 2.8189 9.0638C3.59835 9.87646 4.65551 10.333 5.75781 10.333C6.86012 10.333 7.91728 9.87646 8.69672 9.0638C9.47617 8.25115 9.91406 7.14895 9.91406 5.99967C9.91406 4.8504 9.47617 3.7482 8.69672 2.93555C7.91728 2.12289 6.86012 1.66634 5.75781 1.66634ZM5.75781 2.74967C5.88506 2.74969 6.00788 2.7984 6.10297 2.88656C6.19807 2.97472 6.25882 3.09621 6.27371 3.22797L6.27734 3.29134V5.77543L7.68371 7.24172C7.77689 7.33919 7.83099 7.46999 7.83502 7.60755C7.83905 7.74511 7.7927 7.8791 7.70541 7.98233C7.61811 8.08555 7.4964 8.15026 7.365 8.16331C7.23359 8.17636 7.10236 8.13677 6.99793 8.05259L6.9491 8.00763L5.3905 6.38263C5.30976 6.29837 5.2579 6.18872 5.24296 6.07063L5.23828 5.99967V3.29134C5.23828 3.14768 5.29302 3.00991 5.39045 2.90833C5.48788 2.80674 5.62002 2.74967 5.75781 2.74967Z"
                                  fill="#9D9D9D"
                                />
                              </svg>
                              <span>Posted</span>
                              {formatDistanceToNow(new Date(job?.createdAt), {
                                addSuffix: true,
                              })}
                            </div>
                          </div>
                        </div>
                        <p className="text-gray-400 capitalize text-base">
                          {job?.jobTitle}
                        </p>
                        <div className="flex flex-wrap gap-2 items-center mt-2">
                          {job?.JobSkillsMappings?.map((jsm) => (
                            <div className="w-fit  px-1 flex capitalize text-[#284B80] text-[10px] font-regular font-[Inter] p-1 cursor-default  text-xs">
                              #{jsm?.Skill?.skillName}
                            </div>
                          ))}
                        </div>
                        <div className="flex items-center text-gray-400 justify-between mt-2">
                          <div className="flex items-center text-[14px]">
                          <LocationOnOutlined
                              sx={{
                                height: "0.8rem", // Decrease the height
                                fontSize: "0.8rem", // Decrease the font size
                                color: "gray", // Keep the gray color
                              }}
                            />
                            {job?.jobLocation}
                          </div>
                          <div className="flex text-green-700 gap-2 items-center">
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              id="Layer_1"
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                            >
                              <path
                                d="m23.5,19h-5.375c-.084,0-.16.04-.233.08l-4.098-3.68c-.597-.537-1.507-.509-2.07.063l-1.26,1.279c-.187.189-.517.189-.707,0-.094-.095-.146-.22-.146-.354s.052-.259.146-.354l3.535-3.496c.37-.348.854-.54,1.362-.54.368,0,1.156.21,2.161.576.772.281,1.595.424,2.445.424h4.24c.276,0,.5-.224.5-.5s-.224-.5-.5-.5h-4.24c-.733,0-1.441-.122-2.104-.363-.302-.11-.58-.204-.838-.286,1.377-1.226,2.181-2.979,2.181-4.851,0-3.584-2.916-6.5-6.5-6.5s-6.5,2.916-6.5,6.5c0,1.841.78,3.564,2.115,4.786-.212.071-.437.153-.679.25-.766.308-1.552.464-2.337.464H.5c-.276,0-.5.224-.5.5s.224.5.5.5h4.098c.913,0,1.825-.18,2.71-.536,1.004-.404,1.476-.464,1.691-.464.496,0,.971.184,1.338.518l.809.737-2.097,2.073c-.283.283-.439.66-.439,1.061s.156.777.439,1.061c.567.567,1.554.568,2.124-.002l1.263-1.282c.187-.19.49-.2.69-.021l3.946,3.543-3.592,2.803c-.842.656-2.119.656-2.961,0l-4.337-3.385c-.088-.069-.196-.106-.308-.106H.5c-.276,0-.5.224-.5.5s.224.5.5.5h5.203l4.201,3.279c.596.465,1.34.721,2.096.721s1.5-.256,2.096-.721l4.201-3.279h5.203c.276,0,.5-.224.5-.5s-.224-.5-.5-.5Zm-11.648-6.456l-.84-.765c-.551-.502-1.266-.779-2.035-.779-.034,0-.078.008-.114.01-1.479-1.031-2.362-2.704-2.362-4.51,0-3.033,2.467-5.5,5.5-5.5s5.5,2.467,5.5,5.5c0,1.822-.898,3.513-2.403,4.541-.169-.027-.317-.041-.444-.041-.764,0-1.491.289-2.037.803l-.759.75s-.004-.006-.007-.009Zm1.404-2.544h-.756v.5c0,.276-.224.5-.5.5s-.5-.224-.5-.5v-.5h-.757c-.817,0-1.528-.568-1.728-1.38-.066-.268.098-.539.366-.605.268-.068.539.098.605.366.09.365.401.62.757.62h2.513c.432,0,.783-.373.783-.83,0-.371-.235-.7-.572-.799l-3.094-.777c-.779-.23-1.309-.953-1.309-1.764,0-1.009.8-1.83,1.783-1.83h.653v-.5c0-.276.224-.5.5-.5s.5.224.5.5v.5h.757c.817,0,1.528.568,1.728,1.38.066.268-.098.539-.366.605-.266.069-.539-.098-.605-.366-.09-.365-.401-.62-.757-.62h-2.411c-.432,0-.783.373-.783.83,0,.371.235.7.572.799l3.094.777c.779.23,1.309.953,1.309,1.764,0,1.009-.8,1.83-1.783,1.83Z"
                                fill="green"
                                // stroke="green"
                                // stroke-width="1"
                              />
                            </svg>
                            <p className="flex text-green-700">
                              {job?.recruiterIncentiveType === "percent"
                                ? ""
                                : `${extractCurrencySymbol(job?.currencyType)}`}
                              {job?.recruiterIncentiveValue}
                              {job?.recruiterIncentiveType === "percent"
                              ? "%"
                              : ""}
                            </p>
                            {/* <p className="text-green-700">{job?.recruiterIncentiveType}</p> */}
                            
                            {/* <FaMoneyBill className="ml-0.5" /> */}
                            
                          </div>
                        </div>
                      </div>
                    ))
                  ) : searchQuery ? (
                    <div className="text-center text-[#A0AEC0] text-[12px] font-[Inter] mt-8">
                      No results found for "{searchQuery}"
                    </div>
                  ) : (
                    jobs?.map((job) => (
                      <div
                        key={job?.id}
                        className={`bg-white p-4 shadow-md rounded-md mb-2 ${
                          selectedJob.jobId === job.jobId
                            ? "border-blue-500 border-2"
                            : ""
                        }  cursor-pointer hover:shadow-xl`}
                        onClick={() => handleJobCardClick(job, job?.jobId)}
                      >
                        <div className="flex items-center justify-between gap-2">
                          <h1 className="text-xl capitalize font-semibold">
                            {job?.companyName}
                          </h1>
                          <div>
                            <div className="flex gap-1 text-[12px] items-center text-gray-400">
                              <svg
                                width="13"
                                height="14"
                                viewBox="0 0 11 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.75781 0.583008C8.62718 0.583008 10.9531 3.00805 10.9531 5.99967C10.9531 8.9913 8.62718 11.4163 5.75781 11.4163C2.88844 11.4163 0.5625 8.9913 0.5625 5.99967C0.5625 3.00805 2.88844 0.583008 5.75781 0.583008ZM5.75781 1.66634C4.65551 1.66634 3.59835 2.12289 2.8189 2.93555C2.03945 3.7482 1.60156 4.8504 1.60156 5.99967C1.60156 7.14895 2.03945 8.25115 2.8189 9.0638C3.59835 9.87646 4.65551 10.333 5.75781 10.333C6.86012 10.333 7.91728 9.87646 8.69672 9.0638C9.47617 8.25115 9.91406 7.14895 9.91406 5.99967C9.91406 4.8504 9.47617 3.7482 8.69672 2.93555C7.91728 2.12289 6.86012 1.66634 5.75781 1.66634ZM5.75781 2.74967C5.88506 2.74969 6.00788 2.7984 6.10297 2.88656C6.19807 2.97472 6.25882 3.09621 6.27371 3.22797L6.27734 3.29134V5.77543L7.68371 7.24172C7.77689 7.33919 7.83099 7.46999 7.83502 7.60755C7.83905 7.74511 7.7927 7.8791 7.70541 7.98233C7.61811 8.08555 7.4964 8.15026 7.365 8.16331C7.23359 8.17636 7.10236 8.13677 6.99793 8.05259L6.9491 8.00763L5.3905 6.38263C5.30976 6.29837 5.2579 6.18872 5.24296 6.07063L5.23828 5.99967V3.29134C5.23828 3.14768 5.29302 3.00991 5.39045 2.90833C5.48788 2.80674 5.62002 2.74967 5.75781 2.74967Z"
                                  fill="#9D9D9D"
                                />
                              </svg>
                              <span>Posted</span>
                              {formatDistanceToNow(new Date(job?.createdAt), {
                                addSuffix: true,
                              })}
                            </div>
                          </div>
                        </div>
                        <p className="text-gray-400 capitalize text-lg">
                          {job?.jobTitle}
                        </p>
                        <div className="flex flex-wrap gap-2 items-center mt-2">
                          {job?.JobSkillsMappings?.map((jsm) => (
                            <div className="w-fit  px-1 flex capitalize text-[#284B80] text-[10px] font-regular font-[Inter] p-1 cursor-default  text-xs">
                              #{jsm?.Skill?.skillName}
                            </div>
                          ))}
                        </div>
                        <div className="flex items-center text-gray-400 justify-between mt-2">
                        <div className="flex items-center text-[14px]">
                          <LocationOnOutlined
                              sx={{
                                height: "0.8rem", // Decrease the height
                                fontSize: "0.8rem", // Decrease the font size
                                color: "gray", // Keep the gray color
                              }}
                            />
                            {job?.jobLocation}
                          </div>
                          <div className="flex gap-2 text-green-700 items-center">
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              id="Layer_1"
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                            >
                              <path
                                d="m23.5,19h-5.375c-.084,0-.16.04-.233.08l-4.098-3.68c-.597-.537-1.507-.509-2.07.063l-1.26,1.279c-.187.189-.517.189-.707,0-.094-.095-.146-.22-.146-.354s.052-.259.146-.354l3.535-3.496c.37-.348.854-.54,1.362-.54.368,0,1.156.21,2.161.576.772.281,1.595.424,2.445.424h4.24c.276,0,.5-.224.5-.5s-.224-.5-.5-.5h-4.24c-.733,0-1.441-.122-2.104-.363-.302-.11-.58-.204-.838-.286,1.377-1.226,2.181-2.979,2.181-4.851,0-3.584-2.916-6.5-6.5-6.5s-6.5,2.916-6.5,6.5c0,1.841.78,3.564,2.115,4.786-.212.071-.437.153-.679.25-.766.308-1.552.464-2.337.464H.5c-.276,0-.5.224-.5.5s.224.5.5.5h4.098c.913,0,1.825-.18,2.71-.536,1.004-.404,1.476-.464,1.691-.464.496,0,.971.184,1.338.518l.809.737-2.097,2.073c-.283.283-.439.66-.439,1.061s.156.777.439,1.061c.567.567,1.554.568,2.124-.002l1.263-1.282c.187-.19.49-.2.69-.021l3.946,3.543-3.592,2.803c-.842.656-2.119.656-2.961,0l-4.337-3.385c-.088-.069-.196-.106-.308-.106H.5c-.276,0-.5.224-.5.5s.224.5.5.5h5.203l4.201,3.279c.596.465,1.34.721,2.096.721s1.5-.256,2.096-.721l4.201-3.279h5.203c.276,0,.5-.224.5-.5s-.224-.5-.5-.5Zm-11.648-6.456l-.84-.765c-.551-.502-1.266-.779-2.035-.779-.034,0-.078.008-.114.01-1.479-1.031-2.362-2.704-2.362-4.51,0-3.033,2.467-5.5,5.5-5.5s5.5,2.467,5.5,5.5c0,1.822-.898,3.513-2.403,4.541-.169-.027-.317-.041-.444-.041-.764,0-1.491.289-2.037.803l-.759.75s-.004-.006-.007-.009Zm1.404-2.544h-.756v.5c0,.276-.224.5-.5.5s-.5-.224-.5-.5v-.5h-.757c-.817,0-1.528-.568-1.728-1.38-.066-.268.098-.539.366-.605.268-.068.539.098.605.366.09.365.401.62.757.62h2.513c.432,0,.783-.373.783-.83,0-.371-.235-.7-.572-.799l-3.094-.777c-.779-.23-1.309-.953-1.309-1.764,0-1.009.8-1.83,1.783-1.83h.653v-.5c0-.276.224-.5.5-.5s.5.224.5.5v.5h.757c.817,0,1.528.568,1.728,1.38.066.268-.098.539-.366.605-.266.069-.539-.098-.605-.366-.09-.365-.401-.62-.757-.62h-2.411c-.432,0-.783.373-.783.83,0,.371.235.7.572.799l3.094.777c.779.23,1.309.953,1.309,1.764,0,1.009-.8,1.83-1.783,1.83Z"
                                fill="green"
                                // stroke="green"
                                // stroke-width="1"
                              />
                            </svg>
                            <p className="flex text-green-700">
                              {job?.recruiterIncentiveType === "percent"
                                ? ""
                                : `${extractCurrencySymbol(job?.currencyType)}`}
                              {job?.recruiterIncentiveValue}
                              {job?.recruiterIncentiveType === "percent"
                              ? "%"
                              : ""}
                            </p>
                            {/* <p className="text-green-700">{job?.recruiterIncentiveType}</p> */}
                            
                            {/* <FaMoneyBill className="ml-0.5" /> */}
                            
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <div className="w-full lg:w-3/5 overflow-auto h-[100vh]">
              <div className="max-w-4xl mx-auto p-6 bg-white">
                <div className="flex gap-2 items-center justify-between mb-8">
                  <div className="flex flex-col items-start">
                    <h1 className="text-2xl capitalize font-semibold">
                      {selectedJob?.companyName}
                    </h1>
                    <p className="text-gray-400 capitalize text-xl">
                      {selectedJob?.jobTitle}
                    </p>
                  </div>

                  <div className="flex gap-4">
                    {selectedJob && !selectedJob?.isMyJob ? (
                      <Link
                        to={`/dashboard/user/${selectedJob?.jobId}`}
                        onClick={() => {
                          localStorage.setItem("jobId", selectedJob?.jobId);
                        }}
                      >
                        <button className="flex items-center gap-2 bg-blue-500 text-white px-3 py-1.5 rounded-md">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.15625 2C6.51807 2 5.90603 2.25352 5.45477 2.70477C5.00352 3.15603 4.75 3.76807 4.75 4.40625C4.75 5.04443 5.00352 5.65647 5.45477 6.10773C5.90603 6.55898 6.51807 6.8125 7.15625 6.8125C7.79443 6.8125 8.40647 6.55898 8.85773 6.10773C9.30898 5.65647 9.5625 5.04443 9.5625 4.40625C9.5625 3.76807 9.30898 3.15603 8.85773 2.70477C8.40647 2.25352 7.79443 2 7.15625 2ZM3.375 4.40625C3.375 3.4034 3.77338 2.44162 4.4825 1.7325C5.19162 1.02338 6.1534 0.625 7.15625 0.625C8.1591 0.625 9.12088 1.02338 9.83 1.7325C10.5391 2.44162 10.9375 3.4034 10.9375 4.40625C10.9375 5.4091 10.5391 6.37088 9.83 7.08C9.12088 7.78912 8.1591 8.1875 7.15625 8.1875C6.1534 8.1875 5.19162 7.78912 4.4825 7.08C3.77338 6.37088 3.375 5.4091 3.375 4.40625ZM12.3125 8.1875V10.9375H15.0625V12.3125H12.3125V15.0625H10.9375V12.3125H8.1875V10.9375H10.9375V8.1875H12.3125ZM4.75 10.25C4.02065 10.25 3.32118 10.5397 2.80546 11.0555C2.28973 11.5712 2 12.2707 2 13H7.53438V14.375H0.625V13C0.625 11.906 1.0596 10.8568 1.83318 10.0832C2.60677 9.3096 3.65598 8.875 4.75 8.875H7.5V10.25H4.75Z"
                              fill="white"
                            />
                          </svg>
                          Refer New Talents
                        </button>
                      </Link>
                    ) : (
                      <span>
                        <button className="flex items-center gap-2 bg-gray-200 text-black pointer-events-none px-3 py-1.5 rounded-md">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="black"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.15625 2C6.51807 2 5.90603 2.25352 5.45477 2.70477C5.00352 3.15603 4.75 3.76807 4.75 4.40625C4.75 5.04443 5.00352 5.65647 5.45477 6.10773C5.90603 6.55898 6.51807 6.8125 7.15625 6.8125C7.79443 6.8125 8.40647 6.55898 8.85773 6.10773C9.30898 5.65647 9.5625 5.04443 9.5625 4.40625C9.5625 3.76807 9.30898 3.15603 8.85773 2.70477C8.40647 2.25352 7.79443 2 7.15625 2ZM3.375 4.40625C3.375 3.4034 3.77338 2.44162 4.4825 1.7325C5.19162 1.02338 6.1534 0.625 7.15625 0.625C8.1591 0.625 9.12088 1.02338 9.83 1.7325C10.5391 2.44162 10.9375 3.4034 10.9375 4.40625C10.9375 5.4091 10.5391 6.37088 9.83 7.08C9.12088 7.78912 8.1591 8.1875 7.15625 8.1875C6.1534 8.1875 5.19162 7.78912 4.4825 7.08C3.77338 6.37088 3.375 5.4091 3.375 4.40625ZM12.3125 8.1875V10.9375H15.0625V12.3125H12.3125V15.0625H10.9375V12.3125H8.1875V10.9375H10.9375V8.1875H12.3125ZM4.75 10.25C4.02065 10.25 3.32118 10.5397 2.80546 11.0555C2.28973 11.5712 2 12.2707 2 13H7.53438V14.375H0.625V13C0.625 11.906 1.0596 10.8568 1.83318 10.0832C2.60677 9.3096 3.65598 8.875 4.75 8.875H7.5V10.25H4.75Z"
                              fill="black"
                            />
                          </svg>
                          Refer New Talents
                        </button>
                      </span>
                    )}

                    <ReferExistingTalentModal
                      isMyJob={selectedJob?.isMyJob}
                      jobId={selectedJob?.jobId}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6 mb-8">
                  <div>
                    <p className="text-gray-500 mb-1">Job location</p>
                    <p>{selectedJob?.jobLocation}</p>
                  </div>
                  <div>
                    <p className="text-gray-500 mb-1">Workplace type</p>
                    <p>{selectedJob?.workplaceType}</p>
                  </div>
                  <div>
                    <p className="text-gray-500 mb-1">Company Industry</p>
                    <p>{selectedJob?.companyIndustry}</p>
                  </div>
                  <div>
                    <p className="text-gray-500 mb-1">Employment Type</p>
                    <p>{selectedJob?.jobLevel}</p>
                  </div>
                </div>

                <div className="border rounded-lg mb-4">
                  <button
                    className="w-full flex justify-between items-center p-4"
                    onClick={() => setIsJobSummaryOpen(!isJobSummaryOpen)}
                  >
                    <h2 className="text-lg font-semibold">Job Summary</h2>
                    {isJobSummaryOpen ? (
                      <FaChevronUp
                        className={`transform transition-transform text-gray-500`}
                      />
                    ) : (
                      <FaChevronDown
                        className={`transform transition-transform text-gray-500 ${
                          isJobSummaryOpen ? "rotate-180" : ""
                        }`}
                      />
                    )}
                  </button>
                  {isJobSummaryOpen && (
                    <div className="p-4 pt-0">
                      <p className="text-gray-600">
                        {selectedJob?.describeCandidate}
                      </p>
                    </div>
                  )}
                </div>

                <div className="border rounded-lg">
                  <button
                    className="w-full flex justify-between items-center p-4"
                    onClick={() =>
                      setIsJobDescriptionOpen(!isJobDescriptionOpen)
                    }
                  >
                    <h2 className="text-lg font-semibold">Job Description</h2>
                    {isJobDescriptionOpen ? (
                      <FaChevronUp
                        className={`transform transition-transform text-gray-500`}
                      />
                    ) : (
                      <FaChevronDown
                        className={`transform transition-transform text-gray-500 ${
                          isJobDescriptionOpen ? "rotate-180" : ""
                        }`}
                      />
                    )}
                  </button>
                  {isJobDescriptionOpen && (
                    <div className="p-4 pt-0">
                      {selectedJob?.roleAndResponsibilites ? (
                        isHTMLContent(selectedJob.roleAndResponsibilites) ? (
                          <div
                            className="text-gray-600"
                            dangerouslySetInnerHTML={{
                              __html: selectedJob?.roleAndResponsibilites,
                            }}
                          ></div>
                        ) : (
                          <p className="text-gray-600">
                            {selectedJob.roleAndResponsibilites}
                          </p>
                        )
                      ) : (
                        <p className="text-gray-600">
                          No role and responsibilities provided.
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="p-4 pt-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <p className="text-gray-500 mb-1">Job seniority level</p>
                    <p>{selectedJob?.jobLevel}</p>
                  </div>
                  <div>
                    <p className="text-gray-500 mb-1">Notice period</p>
                    <p>{selectedJob?.officialNoticePeriod}</p>
                  </div>
                  <div>
                    <p className="text-gray-500 mb-1">Team structure</p>
                    <p>{selectedJob?.teamStructure}</p>
                  </div>
                  <div>
                    <p className="text-gray-500 mb-1">Engagement type</p>
                    <p>{selectedJob?.engagementType}</p>
                  </div>
                  <div>
                    <p className="text-gray-500 mb-1">Experience Range</p>
                    <p>
                      {selectedJob?.minYearsExperience} -{" "}
                      {selectedJob?.maxYearsExperience} years
                    </p>
                  </div>
                  {/* <div>
                    <p className="text-gray-500 mb-1">Talent experience Min</p>
                    <p></p>
                  </div> */}
                  <div>
                    <p className="text-gray-500 mb-1">Budget Range</p>
                    <p>
                      {extractCurrencySymbol(selectedJob?.currencyType)}
                      {formatWithCommas(selectedJob?.minCTC)} -{" "}
                      {extractCurrencySymbol(selectedJob?.currencyType)}
                      {formatWithCommas(selectedJob?.maxCTC)}{" "}
                      {formatFrequency(selectedJob?.salaryFrequency)}
                    </p>
                  </div>
                  {/* <div>
                    <p className="text-gray-500 mb-1">Max Budget</p>
                    <p></p>
                  </div> */}
                </div>

                <div className="grid grid-cols-1 mt-4 md:grid-cols-2 gap-2">
                  <div className="">
                    <p className="text-gray-500 mb-2">Primary Skill</p>
                    <div className="flex flex-wrap gap-2">
                      {selectedJob?.JobSkillsMappings?.map((skill) => (
                        <span
                          key={skill}
                          className={`px-3 py-2 bg-white border rounded-lg text-sm ${
                            skill?.Skill?.skillType === "Primary"
                              ? ""
                              : "hidden"
                          }`}
                        >
                          {skill?.Skill?.skillName}: {skill?.yearExpRequired}{" "}
                          yrs
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="mb-1">
                    <p className="text-gray-500 mb-2">Secondary Skill</p>
                    <div className="flex flex-wrap gap-2">
                      {selectedJob?.JobSkillsMappings?.map((skill) => (
                        <span
                          key={skill}
                          className={`px-3 py-2 bg-white border rounded-lg text-sm ${
                            skill?.Skill?.skillType === "Secondary"
                              ? ""
                              : "hidden"
                          }`}
                        >
                          {skill?.Skill?.skillName}: {skill?.yearExpRequired}{" "}
                          yrs
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="mb-6">
                    <p className="text-gray-500 mb-2">Software proficiency</p>
                    <div className="flex flex-wrap gap-2">
                      {selectedJob?.softwareProficiency[0]?.name}
                      {/* {[
                        "Revit: Expert",
                        "Grasshopper : Intermediate",
                        "Rhinocerous : Intermediate",
                      ].map((skill) => (
                        <span
                          key={skill}
                          className="px-3 py-2 bg-white border rounded-lg text-sm"
                        >
                          {skill}
                        </span>
                      ))} */}
                    </div>
                  </div>

                  <div className="mb-6">
                    <p className="text-gray-500 mb-2">Certification</p>
                    <div className="flex flex-wrap gap-2">
                      {selectedJob?.relevantCertification[0]?.name}
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <p className="text-gray-500 mb-1">Industry Knowledge</p>
                    <p>{selectedJob?.industryKnowledge}</p>
                  </div>
                  <div>
                    <p className="text-gray-500 mb-1">No. of vacancies</p>
                    <p>{selectedJob?.jobNumbers}</p>
                  </div>

                  <div className="mb-1">
                    <p className="text-gray-500 mb-2">Interview Rounds</p>
                    <div className="flex flex-wrap gap-2">
                      {selectedJob?.JobInterviewRounds?.map((round) => (
                        <span
                          key={round?.roundName}
                          className={`px-3 py-2 bg-white text-black border rounded-lg text-sm`}
                        >
                          {round?.roundName}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div>
                    <p className="text-gray-500 mb-1">
                      Incentive for Recruiter
                    </p>
                    <div className="flex gap-2">
                      <p className="flex">
                        {selectedJob?.recruiterIncentiveType === "percent"
                          ? ""
                          : `${extractCurrencySymbol(selectedJob?.currencyType)}`}
                        {selectedJob?.recruiterIncentiveValue}
                        {selectedJob?.recruiterIncentiveType === "percent"
                          ? "%"
                          : ""},
                      </p>
                      {/* <p>{selectedJob?.recruiterIncentiveType}</p> */}
                      <p>payable after {selectedJob?.incentivePeriod} days</p>
                    </div>
                  </div>
                  <div className="mb-1">
                    <p className="text-gray-500 mb-1">Screening Questions</p>
                    <p>{selectedJob?.screeningQuestion}</p>
                  </div>
                </div>
                <div className="flex items-center gap-1 justify-between px-6 p-3 rounded-lg bg-[#EEF5FC]">
                  <p>
                    {selectedJob?.JobAvailabilitySlots?.length} Slots available
                    this week
                  </p>
                  <AvailabilitySlotsModal
                    availabilitySlots={selectedJob?.JobAvailabilitySlots}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {<></>}
      <Toaster />
    </Layout>
  );
};

export default ActiveJobList;
