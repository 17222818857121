import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { AuthProvider , useAuth } from './context/auth';
import { SearchProvider } from './context/search';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Toaster } from 'react-hot-toast';
import "./firebase-config"
import { SidebarProvider } from './context/SidebarContext';
import  setupAxiosInterceptors  from './context/axiosSetup';
import { useNavigate } from 'react-router-dom';

const AppWithAxiosSetup = () => {
  const { setAuth } = useAuth(); // Access setAuth from the Auth context
  const navigate = useNavigate(); // Initialize navigate

  // Initialize interceptors
  React.useEffect(() => {
    setupAxiosInterceptors(setAuth, navigate);
  }, [setAuth, navigate]);

  return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
   <SearchProvider>
    <BrowserRouter>
     <SidebarProvider>
     <AppWithAxiosSetup />
     </SidebarProvider>
      <Toaster/>
    </BrowserRouter>
   </SearchProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
