import React from 'react';
import { useLocation } from 'react-router-dom';

const EmailConfirmation = () => {
  const location = useLocation();
  const { email } = location.state || {};
  console.log("email ->")
  console.log(location.state)
  return (
    <div className="w-fit mx-auto mt-10 p-6 bg-white rounded-md shadow-2xl shadow-cyan-500/50">
      <h1 className="text-3xl font-bold text-center mb-4">Email Sent!</h1>
      <p className="text-lg text-center mb-4">Thank you for joining us.</p>
      <p className="text-lg text-center">Verify your account by following the email we sent to {email}</p>
    </div>
  );
}

export default EmailConfirmation;
