import React from "react";
import { FaLinkedin } from "react-icons/fa";
const LinkedInLoginButton = () => {
  const linkedInClientId = "86v4fsv4hwzzzb";
  const redirectUri = `${process.env.REACT_APP_BASE_URL}/auth/linkedin/callback`;
  console.log(redirectUri)
  
  const handleLoginWithLinkedIn = () => {
    const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedInClientId}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&scope=openid%20profile%20email`;
      

    window.location.href = linkedInAuthUrl;
  };

  return (
    <div className="flex items-center justify-center mt-3">
        <button type="button"
            className="flex items-center px-5 py-2 bg-[#0077B5] text-white rounded-md hover:bg-[#005f8d] focus:outline-none"
            onClick={handleLoginWithLinkedIn}
        >
            <FaLinkedin className="mr-2 text-xl" />
            Login with LinkedIn
        </button>
    </div>
  );
};

export default LinkedInLoginButton;