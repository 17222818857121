import React from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../App";
const ManagerTools = () => {
  return (
    <>
      <div className="text-center">
        <div className="list-group dashboard-menu">
          <h4 className="my-5">Hiring Manager Panel</h4>
          <NavLink
            to={routes.create_jobs}
            className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 me-2 mb-2"
          >
            Create Jobs
          </NavLink>
          <NavLink
            to={routes.job_list}
            className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 me-2 mb-2"
          >
            My Jobs list
          </NavLink>
          <NavLink
            to={routes.recruites}
            className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 me-2 mb-2"
          >
            Recruites
          </NavLink>
          
        </div>
      </div>
    </>
  );
};

export default ManagerTools;