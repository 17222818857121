import React from 'react'
import Layout from '../Component/Layout'

const Pagenotfound = () => {
  return (
    <Layout>
      Page Not found
    </Layout>
  )
}

export default Pagenotfound
