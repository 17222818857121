// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDyDU8zvcRmvuhtuOkNuec87EWjhqfWqmA",
  authDomain: "reflense-2.firebaseapp.com",
  databaseURL: "https://reflense-2-default-rtdb.firebaseio.com",
  projectId: "reflense-2",
  storageBucket: "reflense-2.appspot.com",
  messagingSenderId: "1050633448504",
  appId: "1:1050633448504:web:c968644871f0420f368acd",
  measurementId: "G-J1C77Z9GG9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);