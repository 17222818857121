import React, { useEffect, useState } from 'react'
import Layout from '../../Component/Layout'
import axios from 'axios'
import Select from 'react-select';
import toast from 'react-hot-toast'
import { MdDelete } from "react-icons/md";
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../App';
import { IoMdAddCircleOutline } from 'react-icons/io'
import SyncLoader from "react-spinners/SyncLoader";

//Using Material-UI for the Accordion and Progress Stepper
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { FaX } from 'react-icons/fa6';

const steps = [
  'Company and Job Details',
  'Requirements & Skills',
  'Project & Team',
  'Additional Information',
];

const UpdateJobs = () => {
  const [state, setState] = useState({
    loading: false,
    newPrimarySkill: '',
    newSecondarySkill: '',
    primarySkillsOptions: [],
    secondarySkillsOptions: [],
    companyIndustryOption: [],
    jobLocationOption: [],
    currentStep: 0,
    showPopup: false,
    newLocation: null,
    newCompanyIndustry: null,
    showTableHeader: false,
  });
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    companyName: '',
    jobTitle: '',
    jobLocation: '',
    jobNumbers: '',
    jobLevel: '',
    jobStatus: 'Active',
    describeCandidate:'',
    jobType: '',
    screeningQuestion:'',
    minYearsExperience: '',
    maxYearsExperience: '',
    primarySkills: [],
    secondarySkills: [],
    primarySkillText: '',
    secondarySkillText: '',
    minCTC: '',
    maxCTC: '',
    workplaceType: '',
    recruiterIncentiveType: '',
    recruiterIncentiveValue: '',
    roleAndResponsibilites:'',
    companyIndustry:'',
    officialNoticePeriod:'',
    availabilitySlots: []
  });

  const updateState = (key, value) => {
    setState(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const updateFormState = (key, value) => {
    setFormData(prevState => ({
      ...prevState,
      [key]: value
    }));
  };
 
  const { jobId } = useParams();

  // Function to handle adding availability slots
const addAvailabilitySlot = () => {
  // You can add a new slot to the formData state
  updateState('showTableHeader',true);
  setFormData((prevData) => ({
    ...prevData,
    availabilitySlots: [
      ...prevData.availabilitySlots,
      { date: '', timeFrom: '', duration: '1' }, // Default duration to 1 hour
    ],
  }));
};

const areSlotsFilled = () => {
  const requiredFields = [
    'companyName', 'jobTitle', 'jobLocation', 'jobNumbers', 'jobLevel', 
    'jobType', 'workplaceType', 'minYearsExperience', 'maxYearsExperience', 
    'minCTC', 'maxCTC', 'recruiterIncentiveValue', 'recruiterIncentiveType', 
    'companyIndustry', 'officialNoticePeriod' , 'roleAndResponsibilites' , 'screeningQuestion',
    'describeCandidate' , 'relevantCertification' , 'engagementType' ,'softwareProficiency' , 
    'industryKnowledge' , 'projectTeamSize' , 'teamStructure' , 'interviewRounds',
  ];

  const allRequiredFieldsFilled = requiredFields.every(field => 
    formData[field] && typeof formData[field] === 'string' && formData[field].trim() !== ''
  );
  
  // Check if at least one primary and one secondary skill is added
  const hasSkills = formData.primarySkills?.length > 0 && formData.secondarySkills?.length > 0;

  // Check if all availability slots are filled correctly
  const allSlotsFilled = formData.availabilitySlots.every(slot => 
    slot.day && slot.timeFrom && slot.duration
  );

  return allRequiredFieldsFilled && hasSkills && allSlotsFilled;
};

   // Function to render availability slots form fields
   const renderAvailabilitySlots = () => {
    return formData.availabilitySlots?.map((slot, index) => (
      <div key={index} className="flex gap-4">
        <input
          type="date"
          value={slot.date}
          onChange={(e) => handleSlotChange(index, 'date', e.target.value)}
          className="border rounded-md p-2 focus:outline-none focus:border-blue-500"
        />
        <input
          type="time"
          value={slot.timeFrom}
          onChange={(e) => {
            const newTimeFrom = e.target.value;
            const newTimeTo = addOneHour(newTimeFrom); // Function to add 1 hour to timeFrom
            handleSlotChange(index, 'timeFrom', newTimeFrom, newTimeTo);
          }}
          className="border rounded-md p-2 focus:outline-none focus:border-blue-500"
        />
        <input
          type="time"
          value={slot.timeTo}
          onChange={(e) => handleSlotChange(index, 'timeTo', e.target.value)}
          className="border rounded-md p-2 focus:outline-none focus:border-blue-500"
        />
      </div>
    ));
  };
  
  const addOneHour = (timeString) => {
    const [hours, minutes] = timeString.split(':')?.map(Number);
    const totalMinutes = hours * 60 + minutes + 60;
    const newHours = Math.floor(totalMinutes / 60) % 24;
    const newMinutes = totalMinutes % 60;
    return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
  };
  

  // Function to handle changes in availability slot fields
  const handleSlotChange = (index, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      availabilitySlots: prevData.availabilitySlots?.map((slot, i) => 
        i === index ? { ...slot, [field]: value === '' ? '1' : value } : slot
      ),
    }));
  };

useEffect(() => {
  console.log('jobIdjobId=', jobId);
  const fetchJobDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_}/api/v1/job/get-single-job/${jobId}`);
      console.log('responseresponse=', response);      
      setFormData(response.data?.job); // Change from response.job to response.data
    } catch (error) {
      console.error('Error fetching job details:', error.message);
    }
  };

  fetchJobDetails();        
}, [jobId]);

useEffect(()=>{
  console.log('formData=', formData);
}, [formData])

  const fetchCompanyIndustry = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_}/api/v1/job/fetch-company-industry`);
      if(response.data.success){
        const options = response.data.data?.map(industry => ({ value: industry.name, label: industry.name }));
        updateState('companyIndustryOption',options);
      }
    } catch (error) {
      console.error('Error fetching company industries:', error);
      return [];
    }
  };

// Function to handle company industry change
const handleCompanyIndustryChange = async (selectedOption) => {
  if (selectedOption) {
    // Update form data with the selected company industry
    setFormData((prevData) => ({
      ...prevData,
      companyIndustry: selectedOption.label,
    }));
  }
};


// Function to create a new company industry
const createNewCompanyIndustry = async (newIndustry) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_}/api/v1/job/create-company-industry`, { name: newIndustry });
    if (response.data.success) {
      await fetchCompanyIndustry();
    } else {
      console.error('Failed to create new company industry:', response.data.message);
    }
  } catch (error) {
    console.error('Error creating new company industry:', error);
  }
};

const fetchLocation = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_}/api/v1/job/fetch-location`);
    if(response.data.success){
      const options = response.data.data?.map(industry => ({ value: industry.name, label: industry.name }));
      updateState('jobLocationOption',options);
    }
  } catch (error) {
    console.error('Error fetching company industries:', error);
    return [];
  }
};

// Function to handle company industry change
const handleLocationChange = async (selectedOption) => {
if (selectedOption) {
  // Update form data with the selected company industry
  setFormData((prevData) => ({
    ...prevData,
    jobLocation: selectedOption.label,
  }));
}
};

// Function to create a new location
const createjobLocation = async (newLocation) => {
try {
  const response = await axios.post(`${process.env.REACT_APP_API_}/api/v1/job/create-location`, { name: newLocation });
  if (response.data.success) {
    await fetchLocation();
  } else {
    console.error('Failed to create new location:', response.data.message);
  }
} catch (error) {
  console.error('Error creating new location:', error);
}
};

const handleNext = () => {

  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$','i' // fragment locator
  );
  
  if (formData.companyWebsite && !urlPattern.test(formData.companyWebsite.trim())) {
    toast.error('Company Website is not a valid URL');
    return;
  }

  // Check if minCTC is greater than maxCTC
  if (formData.minCTC && formData.maxCTC && parseFloat(formData.minCTC) > parseFloat(formData.maxCTC)) {
    toast.error('Minimum CTC should not be greater than Maximum CTC');
    return;
  }

  // Check if minYearsExperience is greater than maxYearsExperience
  if (formData.minYearsExperience && formData.maxYearsExperience && parseInt(formData.minYearsExperience) > parseInt(formData.maxYearsExperience)) {
    toast.error('Minimum Years of Experience should not be greater than Maximum Years of Experience');
    return;
  }

  updateState('currentStep',state.currentStep + 1);
};


  const handlePrev = () => {
    updateState('currentStep',state.currentStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    
  };

  const stepIndicator = () => {
    const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4'];
    return (
      <div className="flex items-center justify-center space-x-4 mt-5">
        {steps?.map((step, index) => (
          <div
            key={index}
            className={`w-8 h-8 flex items-center justify-center rounded-full ${
              index + 1 === state.currentStep ? 'bg-blue-500 text-white' : 'bg-gray-300'
            }`}
          >
            {index + 1}
          </div>
        ))}
      </div>
    );
  };

  const verifyUniqueJob = async (formData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_}/api/v1/job/verify-unique-job`, formData);
      return response.data.isUnique;
    } catch (error) {
      console.error('Error verifying job uniqueness:', error?.response?.data?.message || error.message);
      toast.error('Failed to verify job uniqueness. Please try again.');
      return false;
    }
  };
   
  const handleSubmit = async (e) => {
    e.preventDefault(); 
    if (formData.jobStatus === 'Active' && !areSlotsFilled()) {  
      toast.error("Please Fill All Details");
      return;
    }
    if (formData.jobStatus === 'Draft' && (!formData.companyName || !formData.jobTitle)) {
      toast.error("Please fill at least Company Name and Job Title");
      return;
    }
    try {
      const isUniqueJob = await verifyUniqueJob(formData);
        if (!isUniqueJob) {
          toast.error("Job already exists. Please create a unique job.");
          updateState('loading', false);
          return;
        }
      await axios.put(`${process.env.REACT_APP_API_}/api/v1/job/updateJob/${jobId}`, formData);
      toast.success("Job Edited Successfully");
      navigate(`/dashboard/user/job/${jobId}`);
    } catch (error) {
      console.error('Error updating job:', error.response.data.message);
      toast.error("An error occurred while creating the job. Please try again.");
    }
  };
  

// Function to fetch primary skills suggestions
const fetchPrimarySkills = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_}/api/v1/job/primary-skills`);
    updateState('primarySkillsOptions',response.data.data?.map(skill => ({ value: skill._id, label: skill.name })));
  } catch (error) {
    console.error('Error fetching primary skills:', error);
  }
}; 

// Function to handle changes in primary skill text input
const handlePrimarySkillChange = async (selectedOption) => {
  setFormData((prevData) => ({
    ...prevData,
    primarySkillText: selectedOption ? selectedOption.label : '',
  }));
  if (!selectedOption) {
    await createPrimarySkill(formData.primarySkillText);
    await fetchPrimarySkills();
  }
};

// Function to create a new company industry
const createPrimarySkillIndustry = async (newSkill) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_}/api/v1/job/create-primay-skills`, { name: newSkill });
    if (response.data.success) {
      await fetchPrimarySkills();
    } else {
      console.error('Failed to create new company industry:', response.data.message);
    }
  } catch (error) {
    console.error('Error creating new company industry:', error);
  }
};

// Function to create a new primary skill
const createPrimarySkill = async (newSkill) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_}/api/v1/job/create-primay-skills`, { name: newSkill });
    if (response.data.success) {
    } else {
      console.error('Failed to create new primary skill:', response.data.message);
    }
  } catch (error) {
    console.error('Error creating new primary skill:', error);
  }
};

const createSecondarySkill = async (newSkill) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_}/api/v1/job/create-secondary-skills`, { name: newSkill });
    if (response.data.success) {
    } else {
      console.error('Failed to create new secondary skill:', response.data.message);
    }
  } catch (error) {
    console.error('Error creating new secondary skill:', error);
  }
};  


//remove a slot
const removeAvailabilitySlot = (index) => {
  setFormData(prevData => ({
    ...prevData,
    availabilitySlots: prevData.availabilitySlots.filter((_, i) => i !== index)
  }));
};

  // Function to fetch secondary skills suggestions
  const fetchSecondarySkills = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_}/api/v1/job/secondary-skills`);
      updateState('secondarySkillsOptions',response.data.data?.map(skill => ({ value: skill._id, label: skill.name })));
    } catch (error) {
      console.error('Error fetching secondary skills:', error);
    }
  };

// Function to handle changes in secondary skill text input
const handleSecondarySkillChange = async (selectedOption) => {
  setFormData((prevData) => ({
    ...prevData,
    secondarySkillText: selectedOption ? selectedOption.label : '',
  }));
  if (!selectedOption) {
    await createSecondarySkill(formData.secondarySkillText);
    await fetchSecondarySkills();
  }
};

// Function to create a new company industry
const createSecondarySkillIndustry = async (newSkill) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_}/api/v1/job/create-primay-skills`, { name: newSkill });
    if (response.data.success) {
      await fetchSecondarySkills();
    } else {
      console.error('Failed to create new company industry:', response.data.message);
    }
  } catch (error) {
    console.error('Error creating new company industry:', error);
  }
};

const addPrimarySkill =async (e) => {
  e.preventDefault(); // Prevent default form submission
  const trimmedPrimarySkillText = formData.primarySkillText.trim();
  const trimmedPrimarySkillExperience = formData.primarySkillExperience.trim();
  
  if (trimmedPrimarySkillText && trimmedPrimarySkillExperience) {
    // Parse experience to a number
    const experience = parseInt(trimmedPrimarySkillExperience);
    
    setFormData((prevData) => ({
      ...prevData,
      primarySkills: [
        ...prevData.primarySkills,
        { 
          text: trimmedPrimarySkillText, 
          experience: experience 
        }
      ],
      primarySkillText: '',
      primarySkillExperience: ''
    }));
  }
  // if (!primarySkillsOptions.find(option => option.value === trimmedPrimarySkillText)) {
  //   // Skill doesn't exist, add it await createjobLocation(e.target.value);
  //   await createPrimarySkillIndustry(trimmedPrimarySkillText);
  // } 
};

  
  const addSecondarySkill =async (e) => {
    e.preventDefault(); // Prevent default form submission
    const trimmedSecondarySkillText = formData.secondarySkillText.trim();
    const trimmedSecondarySkillExperience = formData.secondarySkillExperience.trim();
    
    if (trimmedSecondarySkillText && trimmedSecondarySkillExperience) {
      setFormData((prevData) => ({
        ...prevData,
        secondarySkills: [
          ...prevData.secondarySkills,
          { 
            text: trimmedSecondarySkillText, 
            experience: trimmedSecondarySkillExperience 
          }
        ],
        secondarySkillText: '',
        secondarySkillExperience: ''
      }));
    }
    // if (!secondarySkillsOptions.find(option => option.value === trimmedSecondarySkillText)) {
    //   // Skill doesn't exist, add it
    //   await createSecondarySkillIndustry(trimmedSecondarySkillText);
    // } 
  };

  const deletePrimarySkill = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      primarySkills: prevData.primarySkills.filter((_, i) => i !== index)
    }));
  };

  const deleteSecondarySkill = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      secondarySkills: prevData.secondarySkills.filter((_, i) => i !== index)
    }));
  };

  useEffect(() => {
    fetchPrimarySkills();
    fetchSecondarySkills();
    fetchCompanyIndustry();
    fetchLocation();

      toast.dismiss(); // Clear all toast messages
    
  }, []);
  
  const renderFormStep = () => {
    switch (state.currentStep) {
      case 0:
        return (
          <div className="flex justify-center min-h-screen pb-20">
            <div className="w-full md:w-2/3">
              <Box mb={4}>
                  <Stepper activeStep={0} alternativeLabel>
                    {steps?.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
            <Accordion className="border-t-2 border-blue-500" defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: '#3182CE' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="text-lg font-semibold">Company Details: </Typography>
            </AccordionSummary>
            <AccordionDetails className="p-4">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <label className="block mb-1">
                  Company Name <span className="text-red-500">*</span>
                  <input
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                    required
                  />
                </label>
                <label className="block mb-1">
                  Company Website
                  <input
                    type="text"
                    name="companyWebsite"
                    value={formData.companyWebsite}
                    onChange={handleChange}
                    className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                  />
                </label>
                <label className="block mb-1">
                  <span className="text-gray-700">Company Industry</span><span className="text-red-500">*</span>
                  <Select
                    type="text"
                    name="companyIndustry"
                    options={state.companyIndustryOption}
                    value={{ value: formData.companyIndustry, label: formData.companyIndustry }}
                    onChange={handleCompanyIndustryChange}
                    className='w-full border rounded-md mt-2 focus:outline-none focus:border-blue-500'
                    onInputChange={(inputValue, actionMeta) => {
                      if (actionMeta.action === 'input-change') {
                        setFormData((prevData) => ({
                          ...prevData,
                          companyIndustry: inputValue,
                        }));
                        updateState('newCompanyIndustry', inputValue)
                      }
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: 'none',
                      }),
                    }}
                  />
                </label> 
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border-t-2 border-blue-500 mt-4 pb-19" >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: '#3182CE' }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className="text-lg font-semibold">Job Details: </Typography>
            </AccordionSummary>
            <AccordionDetails className="p-4">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <label className="block mb-1">
                  Job Title <span className="text-red-500">*</span>
                  <input
                    type="text"
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleChange}
                    className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                    required
                  />
                </label>
                <label className="block mb-1">
                  <span className="text-gray-700">Job Location</span> <span className="text-red-500">*</span>
                  <Select
                    type="text"
                    name="jobLocation"
                    options={state.jobLocationOption}
                    value={{ value: formData.jobLocation, label: formData.jobLocation }}
                    onChange={handleLocationChange}
                    className='w-full border rounded-md mt-2 focus:outline-none focus:border-blue-500'
                    onInputChange={(inputValue, actionMeta) => {
                      if (actionMeta.action === 'input-change') {
                        setFormData((prevData) => ({
                          ...prevData,
                          jobLocation: inputValue,
                        }));
                        updateState('newLocation', inputValue)
                      }
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: 'none',
                      }),
                    }}
                  />
                </label>
                <label className="block mb-4">
                  Workplace Type <span className="text-red-500">*</span>
                  <select
                    name="workplaceType"
                    value={formData.workplaceType}
                    onChange={handleChange}
                    className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                  >
                    <option value=''>Choose the Workplace Type</option>
                    <option value="On-Site">On-Site</option>
                    <option value="Hybrid">Hybrid</option>
                    <option value="Remote">Remote</option>
                  </select>
                </label>
                <label className="block mb-4">
                  Employment Type <span className="text-red-500">*</span>
                  <select
                    name="jobType"
                    value={formData.jobType}
                    onChange={handleChange}
                    className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                  >
                    <option value="">Choose the Job Type</option>
                    <option value="Full-Time">Full-Time</option>
                    <option value="Part-Time">Part-Time</option>
                    <option value="Contract">Contract</option>
                    <option value="Temporary">Temporary</option>
                    <option value="Volunteer">Volunteer</option>
                    <option value="Internship">Internship</option>
                    <option value="C2H Model">C2H Model</option>
                    <option value="Third Party Payroll">Third Party Payroll</option>
                  </select>
                </label>
                <label className="block">
                    <span className="text-gray-700">Engagement Type</span>
                    <select
                      name="engagementType"
                      value={formData.engagementType}
                      onChange={handleChange}
                      className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                    >
                      <option value=''>Choose Engagement Type</option>
                      <option value="Short-Term (1-3 months)">Short-Term (1-3 months)</option>
                      <option value="Medium-Term (3-6 months)">Medium-Term (3-6 months)</option>
                      <option value="Long-Term (6+ months)">Long-Term (6+ months)</option>
                      <option value="Permanent">Permanent</option>
                    </select>
                  </label>
                
                
                
              </div>
            </AccordionDetails>
          </Accordion>
      </div>
        
    </div>
        );
      case 1:
        return (<div className="flex justify-center min-h-screen pb-20">
          <div className="w-full md:w-2/3">
            <Box mb={4}>
              <Stepper activeStep={1} alternativeLabel>
                {steps?.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
        
            <Accordion className="border-t-2 border-blue-500" defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#3182CE' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="text-lg font-semibold">Experience: </Typography>
              </AccordionSummary>
              <AccordionDetails className="p-4">
                <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                <label className="block mb-4">
                  Min Years of Experience <span className="text-red-500">*</span>
                  <input
                    type="number"
                    name="minYearsExperience"
                    value={formData.minYearsExperience}
                    onChange={handleChange}
                    min="0"
                    className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                  />
                </label>
                <label className="block mb-4">
                  Max Years of Experience <span className="text-red-500">*</span>
                  <input
                    type="number"
                    name="maxYearsExperience"
                    value={formData.maxYearsExperience}
                    onChange={handleChange}
                    min={formData.minYearsExperience}
                    className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                  />
                </label>
                <label className="block mb-4">
                  Min CTC (INR) <span className="text-red-500">*</span>
                  <input
                    type="number"
                    name="minCTC"
                    min="0"
                    value={formData.minCTC}
                    onChange={handleChange}
                    className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                  />
                </label>
                <label className="block mb-4">
                  Max CTC (INR) <span className="text-red-500">*</span>
                  <input
                    type="number"
                    name="maxCTC"
                    value={formData.maxCTC}
                    onChange={handleChange}
                    min={formData.minCTC}
                    className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                  />
                </label>  
                </div>
              </AccordionDetails>
            </Accordion>
        
            <Accordion className="border-t-2 border-blue-500 mt-4 pb-4">
  <AccordionSummary
    expandIcon={<ExpandMoreIcon style={{ color: '#3182CE' }} />}
    aria-controls="panel2a-content"
    id="panel2a-header"
  >
    <Typography className="text-lg font-semibold">Skill Details: </Typography>
  </AccordionSummary>
  <AccordionDetails className="p-4">
    <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
      <div>
        <span className='block mb-2 font-medium'>Primary Skills</span>
        <div className='flex gap-2 mb-2'>
          <Select
            name="primarySkillText"
            options={state.primarySkillsOptions}
            value={{ value: formData.primarySkillText, label: formData.primarySkillText }}
            onChange={handlePrimarySkillChange}
            className="w-full rounded-md focus:outline-none focus:border-blue-500"
            onInputChange={(inputValue, actionMeta) => {
              if (actionMeta.action === 'input-change') {
                setFormData((prevData) => ({
                  ...prevData,
                  primarySkillText: inputValue,
                }));
              }
            }}
          />
          <input
            type="number"
            name="primarySkillExperience"
            value={formData.primarySkillExperience}
            onChange={handleChange}
            placeholder='Exp'
            className="w-20 border rounded-md p-2 focus:outline-none focus:border-blue-500"
            min="0"
          />
          <button
            onClick={addPrimarySkill}
            className="text-black border border-gray-200 px-2 rounded-full flex items-center"
          >
            <IoMdAddCircleOutline className="mr-1" /> Skill
          </button>
        </div>
        <ul className="space-y-2 max-h-60 overflow-y-auto">
          {formData.JobSkillsMappings?.map((skill, index) => (
            <li
              key={index}
              className={`flex items-center bg-[#F8F8F8] justify-between px-3 py-2 rounded ${skill.Skill.skillType==="Primary"? "": "hidden"}`}
            >
              <span className='w-3/4'>{skill.Skill.skillName}</span>
              <span className='w-1/6 text-center'>{skill.yearExpRequired}</span>
              <button
                onClick={() => deletePrimarySkill(skill.Skill.skillId)}
                className="text-red-500 text-xl"
              >
                <MdDelete />
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <span className='block mb-2 font-medium'>Secondary Skills</span>
        <div className='flex gap-2 mb-2'>
          <Select
            name="secondarySkillText"
            options={state.secondarySkillsOptions}
            value={{ value: formData.secondarySkillText, label: formData.secondarySkillText }}
            onChange={handleSecondarySkillChange}
            className="w-full rounded-md focus:outline-none focus:border-blue-500"
            onInputChange={(inputValue, actionMeta) => {
              if (actionMeta.action === 'input-change') {
                setFormData((prevData) => ({
                  ...prevData,
                  secondarySkillText: inputValue,
                }));
              }
            }}
          />
          <input
            type="number"
            name="secondarySkillExperience"
            value={formData.secondarySkillExperience}
            min="0"
            onChange={handleChange}
            className="w-20 border rounded-md p-2 focus:outline-none focus:border-blue-500"
            placeholder="Exp"
          />
          <button
            onClick={addSecondarySkill}
            className="text-black border border-gray-200 px-2 rounded-full flex items-center"
          >
            <IoMdAddCircleOutline className="mr-1" /> Skill
          </button>
        </div>
        <ul className="space-y-2 max-h-60 overflow-y-auto">
        {formData.JobSkillsMappings?.map((skill, index) => (
            <li
              key={index}
              className={`flex items-center bg-[#F8F8F8] justify-between px-3 py-2 rounded ${skill.Skill.skillType==="Secondary"? "": "hidden"}`}
            >
              <span className='w-3/4'>{skill.Skill.skillName}</span>
              <span className='w-1/6 text-center'>{skill.yearExpRequired}</span>
              <button
                onClick={() => deleteSecondarySkill(skill.Skill.skillId)}
                className="text-red-500 text-xl"
              >
                <MdDelete />
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="col-span-2">
        <div className="grid grid-cols-3 gap-4">
          <label className="block">
            <span className="text-gray-700 font-medium">Software Proficiency</span>
            <select
              name="softwareProficiency"
              value={formData.softwareProficiency}
              onChange={handleChange}
              className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
            >
              <option value=''>Choose Software Proficiency</option>
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Expert">Expert</option>
            </select>
          </label>
          <label className="block">
            <span className="text-gray-700 font-medium">Industry Knowledge</span>
            <select
              name="industryKnowledge"
              value={formData.industryKnowledge}
              onChange={handleChange}
              className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
            >
              <option value=''>Choose Industry Knowledge</option>
              <option value="Basic (0-1 year)">Basic (0-1 year)</option>
              <option value="Intermediate (1-3 years)">Intermediate (1-3 years)</option>
              <option value="Advanced (3-5 years)">Advanced (3-5 years)</option>
              <option value="Expert (5+ years)">Expert (5+ years)</option>
            </select>
          </label>
          <label className="block">
            <span className="text-gray-700 font-medium">Relevant Certification</span>
            <select
              name="relevantCertification"
              value={formData.relevantCertification}
              onChange={handleChange}
              className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
            >
              <option value=''>Choose Relevant Certification</option>
              <option value="Mandatory">Mandatory</option>
              <option value="Not Mandatory">Not Mandatory</option>
              <option value="Preferred">Preferred</option>
            </select>
          </label>
        </div>
      </div>
    </div>
  </AccordionDetails>
</Accordion>
          </div>
        </div>
        )
      case 2:
        return (
        <div className="flex justify-center min-h-screen pb-20">
        <div className="w-full md:w-2/3">
          <Box mb={4}>
            <Stepper activeStep={2} alternativeLabel>
              {steps?.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Accordion className="border-t-2 border-blue-500" defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: '#3182CE' }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="text-lg font-semibold">Project and Team Details:</Typography>
          </AccordionSummary>
          <AccordionDetails className="p-4">
            <div className="grid gap-6 grid-cols-1 lg:grid-cols-3">
              <div className="lg:col-span-2 grid gap-4 grid-cols-1 sm:grid-cols-2">
                <label className="block">
                  <span className="text-gray-700">Project Team Size</span>
                  <input
                    type="number"
                    name="projectTeamSize"
                    value={formData.projectTeamSize}
                    onChange={handleChange}
                    min="1"
                    className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                  />
                </label>
                <label className="block">
                  <span className="text-gray-700">Job Seniority Level <span className="text-red-500">*</span></span>
                  <select
                    name="jobLevel"
                    value={formData.jobLevel}
                    onChange={handleChange}
                    className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                  >
                    <option value="">Choose the Level of the Job</option>
                    <option value="Internship">Internship</option>
                    <option value="Entry-Level">Entry-Level</option>
                    <option value="Associate">Associate</option>
                    <option value="Mid-Senior Level">Mid-Senior Level</option>
                    <option value="Director">Director</option>
                    <option value="Executive">Executive</option>
                    <option value="Not-Applicable">Not-Applicable</option>
                  </select>
                </label>
                <label className="block sm:col-span-2">
                  <span className="text-gray-700">Team Structure</span>
                  <input
                    type="text"
                    name="teamStructure"
                    value={formData.teamStructure}
                    onChange={handleChange}
                    className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                  />
                </label>
                <label className="block">
                  <span className="text-gray-700">Number of Positions Available <span className="text-red-500">*</span></span>
                  <input
                    type="number"
                    name="jobNumbers"
                    value={formData.jobNumbers}
                    onChange={handleChange}
                    min="0"
                    className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                  />
                </label>
              </div>
              <div className="lg:col-span-3 grid gap-4 grid-cols-1 lg:grid-cols-2">
                <label className="block">
                  <span className="text-gray-700">Role and Responsibilities</span>
                  <textarea
                    name="roleAndResponsibilites"
                    value={formData.roleAndResponsibilites}
                    onChange={handleChange}
                    className="w-full border h-48 rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                  />
                </label>
                <label className="block">
                  <span className="text-gray-700">Screening Questions for Recruiters</span>
                  <textarea
                    name="screeningQuestion"
                    value={formData.screeningQuestion}
                    onChange={handleChange}
                    className="w-full border rounded-md h-48 mt-2 p-2 focus:outline-none focus:border-blue-500"
                  />
                </label>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
  </div>
</div>

        )
        case 3:
        return (
        <div className="flex justify-center min-h-screen pb-20">
        <div className="w-full md:w-2/3">
          <Box mb={4}>
            <Stepper activeStep={3} alternativeLabel>
              {steps?.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Accordion className="border-t-2 border-blue-500" defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: '#3182CE' }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="text-lg font-semibold">Additional Information: </Typography>
          </AccordionSummary>
          <AccordionDetails className="p-4">
            <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              <label className="block">
                <span className="text-gray-700">Official Notice Period<span className="text-red-500">*</span></span>
                <select
                  name="officialNoticePeriod"
                  value={formData.officialNoticePeriod}
                  onChange={handleChange}
                  className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                >
                  <option value=''>Choose Official Notice Period</option>
                  <option value="Should be serving Notice">Should be serving Notice</option>
                  <option value="7 Days">7 Days</option>
                  <option value="15 Days">15 Days</option>
                  <option value="60 Days">60 Days</option>
                  <option value="90 Days">90 Days</option>
                </select>
              </label>

              <label className="block">
                <span className="text-gray-700">Interview Rounds</span>
                <input
                  type="number"
                  name="interviewRounds"
                  value={formData.interviewRounds}
                  onChange={handleChange}
                  min="1"
                  className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                />
              </label>

              <label className="block">
                <span className="text-gray-700">Describe the Ideal Candidate</span>
                <input
                  type="text"
                  name="describeCandidate"
                  value={formData.describeCandidate}
                  onChange={handleChange}
                  maxLength="100"
                  className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                />
              </label>

              <label className="block">
                <span className="text-gray-700">Incentive Value <span className="text-red-500">*</span></span>
                <input
                  type="number"
                  name="recruiterIncentiveValue"
                  value={formData.recruiterIncentiveValue}
                  onChange={handleChange}
                  min="0"
                  className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                />
              </label>

              <label className="block">
                <span className="text-gray-700">Incentive Type <span className="text-red-500">*</span></span>
                <select
                  name="recruiterIncentiveType"
                  value={formData.recruiterIncentiveType}
                  onChange={handleChange}
                  className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                >
                  <option value=''>Choose the Incentive Type</option>
                  <option value="percent">Percent</option>
                  <option value="fixed">Fixed</option>
                </select>
              </label>
            </div>

            <div className="mt-8">
              <h3 className="text-lg font-semibold mb-4">Availability Slots</h3>
              {formData.JobAvailabilitySlots && formData.JobAvailabilitySlots?.length > 0 ? (
                <div className="bg-gray-100 p-4 rounded-lg mb-4 overflow-x-auto">
                  <table className="w-full">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="px-4 py-2 text-left">Day</th>
                        <th className="px-4 py-2 text-left">Time</th>
                        <th className="px-4 py-2 text-left">Duration</th>
                        <th className="px-4 py-2 text-left">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.JobAvailabilitySlots?.map((slot, index) => (
                        <tr key={index} className="bg-white border-b">
                          <td className="px-4 py-2">{slot.day}</td>
                          <td className="px-4 py-2">{slot.timeFrom}</td>
                          <td className="px-4 py-2">{slot.duration} hour(s)</td>
                          <td className="px-4 py-2">
                            <button
                              onClick={() => removeAvailabilitySlot(index)}
                              className="text-red-500 hover:text-red-700"
                            >
                              <MdDelete size={20} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-gray-500 italic mb-4">No availability slots added yet.</p>
              )}
              <div className="flex justify-center mt-4">
                <button
                  type="button"
                  onClick={() => updateState('showPopup', true)}
                  className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 transition duration-300 flex items-center"
                >
                  <IoMdAddCircleOutline className="mr-2" />
                  {formData.availabilitySlots?.length > 0 ? 'Edit Availability Slots' : 'Add Availability Slots'}
                </button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
  </div>
</div>

        );
      default:
        return null;
    }
  };

  return (
    <Layout showFooter={false}>
    {state.loading && <SyncLoader />}
    <div className="container mx-auto z-10 mt-24">
      <form onSubmit={handleSubmit} className="mx-9 w-6/7">
        {renderFormStep()}

        {/* Navigation buttons */}
        <div className="flex justify-between">
      <footer className="fixed bottom-0 left-0 right-0 bg-white py-3 px-6 flex justify-between items-center shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] z-10">
        <div>
          {state.currentStep > 0 && (
            <button
              type="button"
              onClick={handlePrev}
              className="bg-blue-500 text-white rounded-full py-2 px-6 focus:outline-none focus:border-blue-500"
            >
              Previous
            </button>
          )}
        </div>
        
        <div className="flex space-x-4">
          <button
            type="submit"
            onClick={() => updateFormState('jobStatus', 'Draft')}
            className="bg-gray-500 text-white rounded-full py-2 px-6 focus:outline-none focus:border-gray-500"
          >
            Save As Draft
          </button>
          
          {state.currentStep < 3 && (
            <button
              type="button"
              onClick={handleNext}
              className="bg-blue-500 text-white rounded-full py-2 px-6 focus:outline-none focus:border-blue-500"
            >
              Next
            </button>
          )}
          {state.currentStep > 2 && (
            <button
              type="submit"
              onClick={() => updateFormState('jobStatus', 'Active')}
              className={`bg-green-500 text-white rounded-full py-2 px-6 focus:outline-none focus:border-green-500`}
            >
              Submit
            </button>
          )}
        </div>
      </footer>
      </div>
      </form>
    </div>

    {/* Popup for availability slots */}
    
    {state.showPopup && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
    <div className="relative bg-white p-8 rounded-lg">
      <FaX className="absolute top-2 right-2 text-gray-500 cursor-pointer" onClick={() => updateState('showPopup', false)} />
    <h2 className="text-lg font-semibold mb-4">
      {state.showTableHeader 
        ? `Availability Slots (${formData.availabilitySlots?.length})`
        : 'No Availability Slots Added'}
    </h2>
      <table>
      {state.showTableHeader && (
        <thead>
          <tr className="bg-gray-200 border-b">
          <th className="px-4 py-2 text-left">Day</th>
          <th className="px-4 py-2 text-left">Time</th>
          <th className="px-4 py-2 text-left">Duration</th>
         </tr>
        </thead>
      )}
   <tbody>
    {formData.availabilitySlots?.map((slot, index) => (
      <tr key={index}>
        <td>
        <select
        value={slot.day}
        onChange={(e) => handleSlotChange(index, 'day', e.target.value)}
        className="border w-24 rounded-md py-[0.9vh] mx-2 focus:outline-none focus:border-blue-500"
      >
        <option value="">Select Day</option>
        <option value="Monday">Monday</option>
        <option value="Tuesday">Tuesday</option>
        <option value="Wednesday">Wednesday</option>
        <option value="Thursday">Thursday</option>
        <option value="Friday">Friday</option>
        <option value="Saturday">Saturday</option>
        <option value="Sunday">Sunday</option>
      </select>
        </td>
        <td>
          <input
            type="time"
            value={slot.timeFrom}
            onChange={(e) => handleSlotChange(index, 'timeFrom', e.target.value)}
            className="border rounded-md p-1 mx-2 focus:outline-none focus:border-blue-500"
          />
        </td>
        <td>
          <select
            value={slot.duration}
            onChange={(e) => handleSlotChange(index, 'duration', e.target.value)}
            className="border w-24 rounded-md py-[0.9vh] mx-2 focus:outline-none focus:border-blue-500"
          >
            <option selected >Duration</option>
            <option value="0.5">30 mins</option>
            <option value="1">1 hrs</option>
            <option value="1.5">1.5 hrs</option>
            <option value="2">2 hrs</option>
            <option value="2.5">2.5 hrs</option>
            <option value="3">3 hrs</option>
            <option value="3.5">3.5 hrs</option>
          </select>
        </td>
      </tr>
    ))}
  </tbody>
</table>

      {formData.availabilitySlots?.length < 5 && (
        <button
          onClick={addAvailabilitySlot} 
          className="bg-blue-500 text-white px-5 py-1 mt-3 rounded-full"
        >
          Add Slot
        </button>
      )}
      <button
        onClick={() => updateState('showPopup',false)} 
        className="bg-green-500 text-white px-5 py-1 ml-4  mt-3 rounded-full"
      >
        Save
      </button>
    </div>
  </div>
)}

  </Layout>
  );
}

export default UpdateJobs;