import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  Popover,
} from '@mui/material';
import { Close, Refresh, MoreVert } from '@mui/icons-material';

export default function ScheduleEventModal() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState('tuesday-late');

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    handleClose();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const timeSlots = [
    { id: "monday-early", day: "Monday", time: "10AM - 11AM" },
    { id: "monday-late", day: "Monday", time: "12AM - 01AM" },
    { id: "tuesday-early", day: "Tuesday", time: "10AM - 11AM" },
    { id: "tuesday-late", day: "Tuesday", time: "12AM - 01AM" },
    { id: "wednesday-early", day: "Wednesday", time: "10AM - 11AM" },
    { id: "wednesday-late", day: "Wednesday", time: "12AM - 01AM" },
  ];

  return (
    <div>
      <div
        id={id}
        className='text'
      >
        <div onClick={handleOpenModal} className="">Schedule Next Round</div>
      </div>

      <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className="rounded-full bg-gray-100 p-2">
            <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46257 2.43262C5.21556 0.91688 7.5007 0 10 0C15.5228 0 20 4.47715 20 10C20 12.1361 19.3302 14.1158 18.1892 15.7406L15 10H18C18 5.58172 14.4183 2 10 2C7.84982 2 5.89777 2.84827 4.46023 4.22842L3.46257 2.43262ZM16.5374 17.5674C14.7844 19.0831 12.4993 20 10 20C4.47715 20 0 15.5228 0 10C0 7.86386 0.66979 5.88416 1.8108 4.25944L5 10H2C2 14.4183 5.58172 18 10 18C12.1502 18 14.1022 17.1517 15.5398 15.7716L16.5374 17.5674Z"
                  fill="#6B7280"
                />
              </svg>
            </div>
            <div className=''>
            <Typography variant="h6">When do you want to Reschedule?</Typography>
            <Typography variant='body2' className='text-gray-400'>You can select the slots or create a custom slot</Typography>
            </div>
          </div>
          <IconButton onClick={handleCloseModal} size="small">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className='my-2 flex flex-col'>
          <div fullWidth variant="outlined" className="mb-4 flex gap-2">
            <InputLabel>Interview scheduled for</InputLabel>
            <select defaultValue="coding" className='text-blue-500 text-lg'>
              <option value="coding">Coding Round</option>
              <option value="technical">Technical Round</option>
              <option value="hr">HR Round</option>
            </select>
          </div>
          <div className="grid grid-cols-2 gap-3 mb-4">
            {timeSlots.map((slot) => (
              <div
                key={slot.id}
                variant={selectedSlot === slot.id ? "contained" : "outlined"}
                className={`h-auto flex-col py-4 normal-case ${selectedSlot === slot.id ? "border border-blue-800 text-blue-800 bg-blue-100": ""} border px-2 rounded-lg m-auto w-full`}
                onClick={() => setSelectedSlot(slot.id)}
              >
                <div className='m-auto w-fit'>
                <span className="font-normal">{slot.day}, </span>
                <span>{slot.time}</span>
                </div>
              </div>
            ))}
          </div>
          <button
            variant="outlined"
            fullWidth
            className="mb-4 w-fit bg-blue-100 text-blue-800 p-1 px-2 rounded-lg m-auto"
          >
            + Custom Slot
          </button>
          <Button variant="contained" fullWidth color="primary" size="large">
            Confirm Reschedule
          </Button>
        </DialogContent>
        <DialogActions>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
