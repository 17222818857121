import {
  AccordionDetails,
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React from "react";
import { steps } from "../pages/manager/EditTalent";
import { FaCross } from "react-icons/fa6";
import { Close } from "@mui/icons-material";
import Spinner from "./Spinner";
import { SyncLoader } from "react-spinners";

function AdditionalInfoTab({
  state,
  handleChange,
  handleResumeUpload,
  uploadLoading,
  availabilitySlots,
  handleSlotChange,
  selectedSlots,
  resume,
  setResume,
  errors,
}) {
  // Function to convert 24hr time to 12hr format with AM/PM
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const talentId = params.get("talentId");

  // const formatTime = (time, addHours = 0) => {
  //   const [hours, minutes] = time.split(":").map(Number);
  //   const totalHours = hours + addHours;
  //   const period = totalHours >= 12 ? "PM" : "AM";
  //   const hour12 = totalHours % 12 || 12;
  //   return `${hour12}${minutes ? `:${minutes}` : ""}${period}`;
  // };

  // Function to format the full slot time range
  // const formatTimeSlot = (slot) => {
  //   const startTime = formatTime(slot.timeFrom);
  //   const endTime = formatTime(slot.timeFrom, parseInt(slot.duration));
  //   return `${startTime} - ${endTime}`;
  // };

  const formatDate = (dateString) => {
    // Create a Date object from the input string
    const date = new Date(dateString);
  
    // Format the date using toLocaleDateString with custom options
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',   // Day of the month (e.g., "24")
      month: 'short',   // Short month name (e.g., "Jan")
      year: 'numeric',  // Full year (e.g., "2025")
    });
  };

  const formatTime = (timeString) => {
    // Split the input string into hours and minutes
    const [hours, minutes] = timeString.split(':').map(Number);
  
    // Determine AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';
  
    // Convert to 12-hour format
    const formattedHours = hours % 12 || 12; // Handle 0 as 12 for midnight
  
    // Return formatted time
    return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
  };

  console.log("selectedSlots=", selectedSlots);

  return (
    <div className="flex justify-center min-h-scree h-full overflow-auto no-scrollbar">
      <div className="w-full md:w-3/4 max-h-scree h-full overflow-auto scrollbar-hide no-scrollbar">
        <Box mb={4}>
          <Stepper activeStep={3} alternativeLabel>
            {steps?.map((label, index) => (
              <Step key={index}>
                <StepLabel sx={{ fontWeight: 900 }}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <div>
          <div className="p-4 overflow-auto scrollbar-hide no-scrollbar">
            <div className="flex flex-col gap-4">
              <div className="border bg-white rounded-xl p-4 md:p-6 shadow-lg">
                {/* <div className="text-xl font-bold mb-4">
                  Recruiting Specifics
                </div> */}
                <div className="grid grid-cols-1 md:grid-cols-1 text-[#576675] font-medium gap-4 md:gap-x-8 md:gap-y-2 overflow-auto">
                  <label className="block">
                    Please explain talent current job in one line
                    <input
                      type="text"
                      name="talentJobRoleExplain"
                      placeholder="Eg, He is skilled in developing fintech products."
                      value={state.formData.talentJobRoleExplain}
                      onChange={handleChange}
                      className="w-full border rounded-md p-3 focus:outline-none focus:border-blue-500"
                      required
                    />
                    {errors.talentJobRoleExplain && !state.formData.talentJobRoleExplain && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.talentJobRoleExplain}
                      </p>
                    )}
                  </label>
                  <label className="block">
                    Recruiter comments for talent
                    <input
                      type="text"
                      name="comment"
                      placeholder="Eg, He is skilled in developing fintech products."
                      value={state.formData.comment}
                      onChange={handleChange}
                      className="w-full border rounded-md p-3 focus:outline-none focus:border-blue-500"
                      required
                    />
                    {errors.comment && !state.formData.comment && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.comment}
                      </p>
                    )}
                  </label>
                  

                  <div className="w-full">
                    <label className="mb-5">
                      Confirm candidate availability
                    </label>
                    <div className="grid grid-cols-2 gap-2 overflow-y-auto max-h-96 p-4 ">
                    {availabilitySlots?.map((slot, index) => (
                      <div key={index} className="flex items-center my-2">
                        <Checkbox
                              id={`slot-${index}`}
                              disabled={talentId ? true : false}
                              checked={selectedSlots.some(
                                (s) =>
                                  s.date === slot.date &&
                                  s.timeFrom === slot.timeFrom
                              )}
                              onChange={(e) =>
                              {
                                console.log('checked==', e.target.checked);
                                handleSlotChange(slot, e.target.checked);
                              }
                              }
                            />
                        <Paper
                          className="flex-grow py-4 border px-3 shadow-sm"
                          elevation={0}
                        >
                          <Typography variant="body2" className="text-gray-600">
                            {talentId ? `${slot.date}` :`${formatDate(slot.date)} at ${formatTime(slot.timeFrom)}-${formatTime(slot.endTime)}` }
                          </Typography>
                        </Paper>
                      </div>
                    ))}
                    </div>
                    {errors.availabilitySlots && selectedSlots.length < 3 && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.availabilitySlots}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="border bg-white rounded-xl p-4 md:p-6 shadow-lg">
                {/* <div className="text-lg font-bold mb-4">Additional notes</div> */}
                <div className="flex flex-col gap-2">
                  <label className="block text-base">Upload Resume</label>
                  {resume ? (
                    <div className="relative flex gap-2 w-fit border rounded-xl p-2">
                      <p className="">{resume}</p>
                      <button
                        className="absolute right-[-1.5rem] rounded-full top-[-1.5rem p-0"
                        onClick={() => setResume(null)}
                      >
                        <Close
                          sx={{
                            width: "1rem",
                            border: "solid 1px",
                            borderRadius: "2rem",
                          }}
                        />
                      </button>
                    </div>
                  ) : (
                    !uploadLoading ?
                    (<div>
                      <input
                        id="fileInput"
                        type="file"
                        name="resume"
                        placeholder="Upload file"
                        onChange={handleResumeUpload}
                        className="hidden w-[8rem] border rounded-md p-2 focus:outline-none focus:border-blue-500"
                        accept=".pdf,.doc,.docx"
                        required
                      />
                      <label
                        htmlFor="fileInput"
                        className="border p-1 px-3 w-fit rounded-lg bg-[#E8E9EA] text-[#555E6D]"
                      >
                        Upload File
                      </label>
                    </div>) :
                    (  <SyncLoader color="#38e3e0" />)
                  )}
                  {errors.resume && !resume && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.resume}
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdditionalInfoTab;
