import React, { useEffect, useState } from "react";
import { Modal, Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FaUser } from "react-icons/fa";
import { FaX } from "react-icons/fa6";
import { SearchOffOutlined, SearchOutlined } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";

const ReferExistingTalentModal = ({ jobId,isMyJob }) => {
  const [selectedTalent, setSelectedTalent] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [talents, setTalents] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const talents = [
  //   { id: 1, name: "Shifat", company: "Amazon", role: "Software Developer" },
  //   { id: 2, name: "Sushil", company: "Amazon", role: "Software Developer" },
  //   { id: 3, name: "Jaivignesh", company: "CRED", role: "UI UX designer" },
  //   { id: 4, name: "Sushil", company: "Amazon", role: "Software Developer" },
  //   { id: 5, name: "Amit", company: "Amazon", role: "Software Developer" },
  // ];

  const filteredTalents = talents.filter(
    (talent) =>
      talent.Talent.talentName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      talent.Talent.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      talent.Talent.talentDesignation.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleReferTalent = () => {
    if (selectedTalent) {
      console.log("Referring talent:", selectedTalent);
      localStorage.setItem("jobId",jobId);
      console.log("jobId set",localStorage.getItem("jobId"));
      navigate(`/dashboard/user/${jobId}?existingTalent=${selectedTalent}`);
      setModalOpen(false);
      
    }
  };
  const UserIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
      <circle cx="12" cy="7" r="4" />
    </svg>
  );

  useEffect(() => {
    const fetchTalents = async ()=>{
      setLoading(true);
      try{
       const response = await axios.get(`${process.env.REACT_APP_API_}/api/v1/job/get-existing-talent`);
      //  console.log("response",response?.data?.data);
       setTalents(response?.data?.data);
        
      }catch(error){
        console.error('Error fetching talent details:', error.message);
      }finally{
        setLoading(false);
      }
    };
    fetchTalents();
  }, [])

  return (
    <div className="">
      <button
      disabled={isMyJob}
        onClick={() => setModalOpen(true)}
        className={`${isMyJob && "pointer-events-none"} flex items-center gap-2 border border-gray-300 px-3 py-1.5 rounded-md`}
      >
        {/* <UserIcon /> */}
        <img src={"/assets/user-3-png.png"} className="w-4 h-4" />
        Refer Existing Talents
      </button>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="flex w-full items-center justify-between">
          <div className="flex items-start w-full justify-between">
            <div className="flex items-center gap-4">
              <div className="bg-gray-200 p-3 rounded-lg">
                <img src={"/assets/user-3-png.png"} className="w-4 h-4" />
              </div>
              <div>
                <h2 className="text-lg font-semibold">
                  Refer Existing Talents
                </h2>
                <p className="text-sm text-gray-500">
                  Select a Talent from the list to refer to the Job
                </p>
              </div>
            </div>
            <button
              onClick={() => setModalOpen(false)}
              className="text-gray-400 hover:text-gray-600 rounded-full bg-gray-200 p-2"
            >
              <FaX className="w-3 h-3" />
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box className="bg-white rounded-lg w-full max-w-lg mx-4 outline-none">
            <div className="p-2">
              {/* Search */}
              <div className="relative mb-6">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full px-4 py-2 pl-4 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {/* <SearchOutlined className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" /> */}
              </div>

              {/* Talent List */}
              <div className="space-y-3 mb-6">
                {loading ? <div className="flex justify-center items-center h-20"><SyncLoader color="#000" size={5} /></div> : filteredTalents?.map((talent) => (
                  <label
                    key={talent.id}
                    className="flex items-center p-3 rounded-lg border border-gray-200 cursor-pointer hover:bg-gray-50"
                  >
                    <input
                      type="radio"
                      name="talent"
                      value={talent?.Talent?.talentId}
                      checked={selectedTalent === talent?.Talent?.talentId}
                      onChange={() => setSelectedTalent(talent?.Talent?.talentId)}
                      className="w-4 h-4 appearance-none border-2 border-gray-300 rounded-full bg-white checked:bg-black checked:border-[2.5px] focus:ring-1 focus:ring-black"
                    />
                    <div className="ml-3">
                      <div className="font-semibold text-black">
                        {talent?.Talent?.talentName}
                      </div>
                      <div className="text-sm text-gray-500">
                        {talent?.Talent?.companyName} - {talent?.Talent?.talentDesignation}
                      </div>
                    </div>
                  </label>
                ))}
              </div>

              {/* Footer */}
              <button
                onClick={handleReferTalent}
                disabled={!selectedTalent}
                className="w-full py-2.5 px-4 bg-blue-500 text-white rounded-lg font-medium
                     disabled:opacity-50 disabled:cursor-not-allowed
                     hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Refer Talent
              </button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ReferExistingTalentModal;
