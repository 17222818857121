import React, { useEffect, useState } from "react";
import Layout from "../../Component/Layout";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { SyncLoader } from "react-spinners";

const ProgressStep = ({ number, label, active }) => (
  <div className="flex flex-col items-center">
    <div
      className={`w-8 h-8 rounded-full flex items-center justify-center ${
        active ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-600"
      }`}
    >
      {number}
    </div>
    <p className="mt-2 text-sm text-gray-600">{label}</p>
  </div>
);

const ProgressLine = ({ active }) => (
  <div className="flex-1 h-[2px] bg-gray-200">
    <div className={`h-full ${active ? "bg-blue-500" : ""}`} />
  </div>
);

export default function TalentDetail() {
  const navigate = useNavigate();
  const { talentId } = useParams();
  const [talent, setTalent] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentStage, setCurrentStage] = useState(null);
  function getCurrentStage(talentStatus) {
    switch (talentStatus) {
      case "Reffered":
        setCurrentStage(1);
        break;
      case "Shortlisted":
        setCurrentStage(2);
        break;
      case "InProgress":
        setCurrentStage(3);
        break;
      case "Hired":
        setCurrentStage(4);
        break;
      default:
        setCurrentStage(0); // Default stage if no matching talentStatus
    }
    console.log(currentStage);
  }

  const skills = [
    { name: "Revit", years: 5 },
    { name: "Postgres", years: 8 },
    { name: "Kotlin", years: 3 },
  ];

  const eventHistory = [
    { label: "Total Events", count: 5 },
    { label: "Upcoming Events", count: 1 },
    { label: "Round Completed", count: 1 },
    { label: "Total Feedback", count: 0 },
    // { label: "No Show Events", count: 1 },
    // { label: "Cancelled Events", count: 0 },
  ];

  const fetchTalentDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/get-single-talent/${talentId}`
      );
      // console.log("response -> ");
      // console.log(response);
      const talentData = response.data.data;
      setTalent(talentData);
      getCurrentStage(talentData?.talentStatus);

      // if (response.status === 200) {
      //   const existingTalent = response.data; // Accessing talent details from response
      //   setFormData(existingTalent);
      // }
    } catch (error) {
      console.error("Error fetching talent details:", error);
      // Handle error, such as displaying an error message to the user
      toast.error(`${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTalentDetails();
  }, [talentId]);

  // console.log(talent.Job.jobId)
  console.log(talent);
  const filterSkills = (talent) => {
    const skills = talent?.Talent?.TalentSkillsMappings?.map((mapping) => {
      const skillData = mapping.Skill || mapping.JobSkillsMapping?.Skill;
      return skillData
        ? {
            skillName: skillData.skillName,
            skillType: skillData.skillType,
            score: mapping.score,
            yearOfExperience: mapping.yearOfExperience,
          }
        : null; // Exclude if no skill data exists
    }).filter(Boolean); // Remove null values

    // console.log(skills);
    return skills;
  };

  function formatDate(isoString) {
    const dateObject = new Date(isoString); // Convert ISO string to Date object
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(dateObject);
}

  return (
    <Layout>
      {loading ? (
        <div className="flex justify-center h-full items-center">
          <SyncLoader />
        </div>
      ) : (
        <>
          <div className="max-w-[90rem] mx-auto p-4 bg-[#F7F7F7]">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="md:col-span-2 space-y-4">
                <div className="flex justify-between items-center my-8">
                  <div>
                    <h1 className="text-2xl font-bold text-blue-600">
                      {talent?.Talent?.talentName}
                    </h1>
                    <p className="text-gray-600">Referred</p>
                  </div>
                  <div className="flex bg-white gap-4">
                    <button
                      onClick={() =>
                        navigate(
                          `/dashboard/user/${talent?.Job?.jobId}?talentId=${talentId}`
                        )
                      }
                      className="flex gap-2 items-center px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
                    >
                      <svg
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2155 2.76246L11.7368 4.28371M11.1935 1.4223L7.07816 5.53764C6.86489 5.74951 6.71976 6.02026 6.66138 6.31516L6.28125 8.21797L8.18407 7.83712C8.47869 7.7782 8.74888 7.63376 8.96158 7.42106L13.0769 3.30571C13.2006 3.18205 13.2987 3.03523 13.3656 2.87365C13.4326 2.71207 13.467 2.53889 13.467 2.364C13.467 2.18911 13.4326 2.01593 13.3656 1.85435C13.2987 1.69278 13.2006 1.54596 13.0769 1.4223C12.9533 1.29863 12.8064 1.20053 12.6449 1.1336C12.4833 1.06667 12.3101 1.03223 12.1352 1.03223C11.9603 1.03223 11.7871 1.06667 11.6256 1.1336C11.464 1.20053 11.3172 1.29863 11.1935 1.4223Z"
                          stroke="#6B7280"
                          stroke-width="1.43717"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.0298 9.6556V11.8114C12.0298 12.1925 11.8784 12.5581 11.6088 12.8276C11.3393 13.0971 10.9738 13.2485 10.5926 13.2485H2.68815C2.30699 13.2485 1.94144 13.0971 1.67192 12.8276C1.40239 12.5581 1.25098 12.1925 1.25098 11.8114V3.9069C1.25098 3.52574 1.40239 3.16019 1.67192 2.89067C1.94144 2.62114 2.30699 2.46973 2.68815 2.46973H4.84391"
                          stroke="#6B7280"
                          stroke-width="1.43717"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Edit talent Details
                    </button>
                    <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                      View Job Details
                    </button>
                  </div>
                </div>

                <div className="p-2 rounded-lg">
                  <div className="flex gap-4">
                    {eventHistory.map((event) => (
                      <div
                        key={event.label}
                        className="p-2 w-[25%] shadow-md rounded-md"
                      >
                        <p className="text-2xl font-bold text-black">
                          {event.count}
                        </p>
                        <p className="text-sm text-gray-400">{event.label}</p>
                      </div>
                    ))}
                  </div>
                </div>
                {/* Progress Steps */}
                <div className="p-2 px-8 rounded-lg shadow-sm">
                  <div className="flex items-center justify-between">
                    <ProgressStep
                      number={1}
                      label="Referred"
                      active={currentStage >= 1}
                    />
                    <ProgressLine active={currentStage >= 2} />
                    <ProgressStep
                      number={2}
                      label="Shortlisted"
                      active={currentStage >= 2}
                    />
                    <ProgressLine active={currentStage >= 3} />
                    <ProgressStep
                      number={3}
                      label="In Process"
                      active={currentStage >= 3}
                    />
                    <ProgressLine active={currentStage >= 4} />
                    <ProgressStep
                      number={4}
                      label="Hired"
                      active={currentStage >= 4}
                    />
                  </div>
                </div>

                {/* Job Status */}
                <div className="p-2 rounded-lg shadow-sm">
                  <h2 className="text-xl font-semibold mb-4">Job Status</h2>
                  <p className="text-gray-600 text-base">
                    {talent.Job?.jobStatus}
                  </p>
                </div>

                {/* Skills */}
                {/* <div className="p-2 rounded-lg shadow-sm">
              <h2 className="text-lg font-semibold mb-4">Skills</h2>
              <div className="flex gap-4">
                {skills.map((skill) => (
                  <span
                    key={skill.name}
                    className="px-4 py-2 bg-white border border-gray-300 text-gray-800 rounded-lg text-sm"
                  >
                    {skill.name}: {skill.years} yrs
                  </span>
                ))}
              </div>
            </div> */}

                {/* Recruiter Comments */}
                {/* <div className="p-2 rounded-lg shadow-sm">
              <h2 className="text-lg font-semibold mb-4">Recruiter Comments</h2>
              <p className="text-gray-600">
                Good suit for the required role.Has a lot of experience in this
                work.
              </p>
            </div> */}

                {/* Event History */}

                {/* Feedback History */}
                {/* <div className="p-2 rounded-lg shadow-sm">
              <h2 className="text-lg font-semibold mb-4">Feedback History</h2>
              <p className="text-gray-500">None</p>
            </div> */}

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="flex flex-col">
                    <p className="text-lg px-4 text-[#16161D] font-semibold mt-3">
                      Primary Skills
                    </p>
                    <table className="table-auto w-full">
                      <thead>
                        <tr className="">
                          <th className="px-4 py-2  text-left">Skill</th>
                          <th className="px-4 py-2 text-left">Exp</th>
                          <th className="px-4 py-2  text-right">Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterSkills(talent)
                          ?.filter((skill) => skill.skillType === "Primary")
                          .map((skill, index) => (
                            <tr key={index} className="">
                              <td className="px-4">{skill.skillName}</td>
                              <td className="px-4">
                                {skill.yearOfExperience} Yrs
                              </td>
                              <td className="px-4 text-right">
                                {skill.score}/5
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <p className="text-lg px-4 text-[#16161D] font-semibold mt-3">
                      Secondary Skills
                    </p>
                    <table className="table-auto w-full mb-5">
                      <thead>
                        <tr className="">
                          <th className="px-4 py-2  text-left">Skill</th>
                          <th className="px-4 py-2 text-left">Exp</th>
                          <th className="px-4 py-2  text-right">Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterSkills(talent)
                          ?.filter((skill) => skill.skillType === "Secondary")
                          .map((skill, index) => (
                            <tr key={index} className="">
                              <td className="px-4">{skill.skillName}</td>
                              <td className="px-4">
                                {skill.yearOfExperience} Yrs
                              </td>
                              <td className="px-4 text-right">
                                {skill.score}/5
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-lg mb-4 text-[#16161D] font-semibold mt-3">
                      Software proficiency
                    </p>
                    <div className="flex gap-2">
                      {talent?.Talent?.softwareProficiency?.map((software) => (
                        <p className="p-2 border border-[#C5CAD1] rounded-md">
                          {software?.name}
                        </p>
                      ))}
                    </div>
                    <p className="text-lg mb-4 text-[#16161D] font-semibold mt-3">
                      Certifications
                    </p>
                    <div className="flex gap-2">
                      {talent?.Talent?.certifications?.map((cert) => (
                        <p className="p-2 border border-[#C5CAD1] rounded-md">
                          {cert?.name}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-1">
                <div className=" p-4 grid grid-cols-2 border h-[90vh] bg-white  border-gray-300 rounded-2xl shadow-sm">
                  <div className="flex flex-col items-center gap-4 text-gray-600">
                    <div className="flex flex-col w-full">
                    <p className="text-sm text-gray-500 ">{"Reffered Date"}</p>
                    <p className="text-sm font-semibold">{formatDate(talent?.Talent?.talentReferredDate)}</p>
                      
                    </div>
                    <div className="flex flex-col w-full">
                    <p className="text-sm text-gray-500 ">{"Role"}</p>
                    <p className="text-sm font-semibold">{talent?.Talent?.talentJobRoleExplain}</p>
                     
                    </div>
                    <div className="flex flex-col w-full">
                    <p className="text-sm text-gray-500">{"Current location"}</p>
                    <p className="text-sm font-semibold">{talent?.Talent?.talentCurrentLocation}</p>
                     
                    </div>
                    <div className="flex flex-col w-full">
                    <p className="text-sm text-gray-500 ">{"Current company"}</p>
                    <p className="text-sm font-semibold">{talent?.Talent?.companyName}</p>
                      
                    </div>
                    <div className="flex flex-col w-full">
                    <p className="text-sm text-gray-500">{"Current job seniority level"}</p>
                    <p className="text-sm font-semibold ">{talent?.Talent?.jobSeniorityLevel}</p>
                      
                    </div>
                    <div className="flex flex-col w-full">
                    <p className="text-sm  text-gray-500 ">{"No of Gaps"}</p>
                    <p className="text-sm font-semibold">{talent?.Talent?.yearGap}</p>
                      
                    </div>
                    <div className="flex flex-col w-full">
                    <p className="text-sm text-gray-500 ">{"Current salary"}</p>
                    <p className="text-sm font-semibold">{talent?.Talent?.talentCurrentCTC} lpa</p>
                      
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-4 text-gray-600">
                    <div className="flex flex-col w-full">
                    <p className="text-sm text-gray-500 ">{"Employment type"}</p>
                    <p className="text-sm font-semibold">{talent?.Talent?.talentEmploymentType}</p>
                     
                    </div>
                    <div className="flex flex-col w-full">
                    <p className="text-sm text-gray-500 ">{"Company"}</p>
                    <p className="text-sm font-semibold">{talent?.Job?.companyName}</p>
                      
                    </div>
                    <div className="flex flex-col w-full">
                    <p className="text-sm text-gray-500 ">{"Preferred location"}</p>
                    <p className="text-sm font-semibold">{talent?.Talent?.talentPreferredLocation}</p>
                      
                     
                    </div>

                    <div className="flex flex-col w-full">
                    <p className="text-sm text-gray-500 ">{"Current Desigation"}</p>
                    <p className="text-sm font-semibold">{talent?.Talent?.talentDesignation}</p>
                     
                    </div>
                    <div className="flex flex-col w-full">
                    <p className="text-sm text-gray-500 ">{"Current employment type"}</p>
                    <p className="text-sm font-semibold ">{talent?.Talent?.talentEmploymentType}</p>
                      
                    </div>
                    <div className="flex flex-col w-full">
                    <p className="text-sm  text-gray-500">{"Notice period"}</p>
                    <p className="text-sm font-semibold ">{talent?.Talent?.officialNoticePeriod}</p>
                    
                      
                    </div>
                    <div className="flex flex-col w-full">
                    <p className="text-sm text-gray-500 ">{"Expected Salary range"}</p>
                    <p className="text-sm font-semibold">${talent?.Talent?.talentExpectedCTCMin} - ${talent?.Talent?.talentExpectedCTCMax}</p>
                     
                      
                    </div>
                  </div>
                  <div className="flex col-span-2 flex-col w-full mt-[-13vh]">
                  <p className="text-sm text-gray-500">{"Recruiter comments"}</p>
                    <p className="text-sm text-gray-600 font-semibold">{"the talent is good and has good experience in this role and has good communication skills and good fit for the company"}</p>
                      
                    </div>
                </div>
                
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}
