import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
// import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { routes } from "../App";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../pages/signup.css";

const Signup = () => {
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  const areFieldsEmpty = () => {
    return (
      email === "" ||
      password === "" ||
      fname === "" ||
      lname === "" ||
      confirmPassword === "" ||
      phone === ""
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/auth/signup`,
        {
          fname,
          lname,
          email,
          phone,
          countryCode,
          password,
          confirmPassword,
        }
      );
      if (res && res.data.message) {
        toast.success(res.data.message);
        if(res.data.message === "User updated successfully please login"){
          navigate(routes.login);
        }else{
          navigate(routes.EmailConfirmation, { state: { email: email } });
        }
        if (res.data.confirmationMessage) {
          setConfirmationMessage(res.data.confirmationMessage);
        }
      }
    } catch (error) {
      console.log("Error found " + error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);
    setCountryCode(country.dialCode);
  };

  return (
    <div className="flex flex-col bg-gradient-to-r from-blue-50 from-5% to-blue-400 to-100% min-h-screen">
      <div className="flex justify-between items-center p-2 lg:px-8">
        <img
          src="https://i.ibb.co/QQ2BCjZ/reflense-Logo.png"
          alt="Reflense Open"
          className={`h-[4rem] transition-all duration-300 float-left"`}
        />
        <div className="flex flex-col lg:flex-row justify-between text-black gap-2 min-w-[15rem] lg:min-w-[30rem] font-semibold text-[1rem]">
          <Link to={"#"}>About</Link>
          <Link to={"#"}>Contact us</Link>
          <Link to={"#"}>Pricing</Link>
        </div>
        <div></div>
        <div></div>
      </div>
      <div className="flex flex-col md:flex-row lg:px-3 py-10 justify-center w-full m-auto lg:w-[1000px] h-full lg:h-[85vh]">
        <div className="flex w-full lg:w-[40%] rounded-l-xl bg-white bg-opacity-20 flex-col justify-between p-4">
        <div className="text-center flex flex-col items-center justify-center h-screen mb-32 w-fit m-auto">
        <h1
              className="bg-gradient-to-r from-[#5C9CE6] from-25% to-[#000000] to-100% bg-clip-text text-transparent text-[2.5rem] mb-4"
              style={{ fontWeight: 900 }}
            >
              Glad to see you!
            </h1>
            <p className="text-xl font-bold text-black">
            Together, let’s simplify and accelerate recruitment.
            </p>
          </div>
        </div>
        <div className="flex w-full lg:w-[60%] bg-white items-center justify-center rounded-r-xl">
          <div className="p-4 w-full h-full flex items-center justify-center">
            <form onSubmit={handleSubmit} className="h-fit m-auto">
              <h1 className="text-3xl font-extrabold w-fit my-2 ml-2">
              Join Reflense Network!
              </h1>

              <div className="flex gap-x-2 pt-1 w-full">
                <div className="w-1/2">
                  <input
                    type="text"
                    id="f-name"
                    placeholder="First name"
                    value={fname}
                    onChange={(e) => setFName(e.target.value)}
                    className="w-full px-2 py-1 md:py-2 my-1 border rounded-md"
                    required
                  />
                </div>
                <div className="w-1/2">
                  <input
                    type="text"
                    id="l-name"
                    placeholder="Last name"
                    value={lname}
                    onChange={(e) => setLName(e.target.value)}
                    className="w-full px-2 py-1 md:py-2 my-1 border rounded-md"
                    required
                  />
                </div>
              </div>

              <div className="pt-1">
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-2 py-1 md:py-2 my-1 border rounded-md"
                  required
                  placeholder="Email"
                />
              </div>

              <div className="pt-1">
                <PhoneInput
                  country={"in"}
                  value={phone}
                  onChange={handlePhoneChange}
                  inputProps={{
                    name: "phone",
                    required: true,
                    style: { border: "none" },
                  }}
                  containerClass="w-full px-2 bg-white py-1 my-1 border bg-white rounded-md"
                  inputClass="form-control"
                  placeholder="Enter your number"
                />
              </div>

              <div className="pt-1">
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-2 py-1 md:py-2 my-1 border rounded-md pr-10"
                    required
                  />
                  <button
                    type="button"
                    className="absolute top-1/2 right-2 transform -translate-y-1/2"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>
              </div>
              <div className="pt-1">
                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-2 py-1 md:py-2 my-1 border rounded-md pr-10"
                    required
                  />
                </div>
              </div>
              <div className="flex">
                <input type="checkbox" className="mt-2" required />
                <span className="mx-2">
                  <p className=" mt-2 text-sm">
                    By continuing you agree to the
                    <a href="#" className="underline mx-1">
                      Term of use
                    </a>{" "}
                    and
                    <a href="#" className="underline mx-1">
                      Privacy Policy
                    </a>
                  </p>
                </span>
              </div>
              <div className="flex">
                <input type="checkbox" className="mt" />
                <span className="mx-2 mt-2 text-sm">
                  By creating an account, you consent to receiving SMS messages
                  and emails, including product updates, event notifications,
                  and marketing promotions.
                </span>
              </div>
              <div className="justify-center mt-6 flex items-center">
                <div className="w-[50%] gap-2 mx-auto">
                  <button
                    type="submit"
                    disabled={areFieldsEmpty()}
                    className={`w-[100%] mb-1 mx-auto py-1 px-2 bg-[#5C9CE6] font-bold text-black rounded-2xl`}
                  >
                    Sign up
                  </button>
                </div>
                <p className="w-fit mx-2 text-gray-400">
                  Already have an account?{" "}
                  <Link to={routes.login} className="text-black underline">
                    Log in
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
