import React from "react";
import { FaCalendar, FaCalendarAlt, FaSquare } from "react-icons/fa";
import { FaLocationPin, FaLocationPinLock } from "react-icons/fa6";
import { CiLocationOn } from "react-icons/ci";
import { SlCalender } from "react-icons/sl";
import { CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import { MdAccountBalanceWallet, MdWork } from "react-icons/md";
import {styled} from '@mui/system';
import Recruiter_Candidate from "./Recruiter_Candidate";
import RescheduleEvent from "./RescheduleEventModal";
import { SyncLoader } from "react-spinners";

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    success: {
      main: '#4caf50',
    },
    warning: {
      main: '#ff9800',
    },
    grey: {
      200: '#eeeeee',
    },
  },
});

// Styled component for the CircularProgress
const StyledCircularProgress = styled(CircularProgress)<{ color: 'success' | 'warning' }>(({ theme, color }) => ({
  color: color === 'success' ? theme.palette.success?.main || '#4caf50' : theme.palette.warning?.main || '#ff9800',
  backgroundColor: theme.palette.grey[200] || '#eeeeee',
  borderRadius: '50%',
}));

const TalentCard = ({
  onCardClick,
  recruiterScore,
  jobTitle,
  jobSeniorityLevel,
  talentName,
  talentCurrentCTC,
  field,
  collegeName,
  talentExpectedCTCMax,
  talentPreferredLocation,
  talentTotalExperience: talentTotalExperience,
  talentCurrentLocation,
  talentDesignation,
  companyName,
  skills,
  isNew,
  officialNoticePeriod,
  status,
  job,
  onShortlistClick,
  referralId,
  onRejectClick,
  loading2
}) => {
// console.log('skills==', skills);

  return (
    <div
       // Trigger onCardClick when the card is clicked
      className="box-border relative p-4 border my-3 rounded-lg bg-white shadow-md flex flex-col min-h-40 space-y-2 w-full hover:shadow-xl cursor-default"
    >
      <div className="flex justify-between space-x-3 items-center">
        <div className="flex flex-col">
          <h2 onClick={onCardClick} className="text-[15px] font-[Inter] text-[#004389] capitalize cursor-pointer font-semibold">{talentName}</h2>
          <div className="text-[12px] text-[#757575] font-[Inter] font-regular">
            Experience: {<span className="cursor-default">{talentTotalExperience} years</span>}
          </div>
        </div>
        {/* <div className="flex gap-2 mr-14">
          <div className="flex items-center justify-center gap-2 rounded-lg text-black border-black p-1 relative">
            <span className="text-gray-400">Recruiter</span>
            <CircularProgress
              variant="determinate"
              value={recruiterScore}
              size={50}
              thickness={6}
              color="success"
              className="text-black ml-2"
            />
            <span className="absolute top-2.5 right-[23px] text-base">
              {recruiterScore}
            </span>
          </div>
          <div className="flex items-center justify-center gap-2 rounded-lg mr-10 text-black border-black p-1 relative">
            <CircularProgress
              variant="determinate"
              value={recruiterScore}
              size={50}
              thickness={6}
              color="warning"
              className="text-black ml-2"
            />
            <span className="absolute top-2.5 right-24 text-base">
              {recruiterScore}
            </span>
            <span className="text-gray-400">Candidate</span>
          </div>
        </div> */}

        <Recruiter_Candidate recruiterScore={recruiterScore} candidateScore={recruiterScore} />

        {/* <span className="text-red-500 text-sm font-semibold">
          {isNew ? "New" : null}
        </span> */}
      </div>
      <div className="flex justify-between">
        <div className="text-sm flex items-center gap-1 space-x-1 font-semibold text-black">
        </div>
        
      </div>
      <div className="flex gap-4 justify-between items-center font-normal cursor-default">
        <div className="flex flex-col gap-2 w-[30rem] text-base cursor-default">
          <div className="flex flex-col cursor-default">
            <label className="text-[#A7B5BE] font-[Inter] text-[10px] cursor-default">Working as </label>
            <p className="text-black font-medium text-[13px] font-[Inter] cursor-default">
              {talentDesignation.charAt(0).toUpperCase() + talentDesignation.slice(1)} at {companyName.charAt(0).toUpperCase() + companyName.slice(1)}
            </p>
          </div>
          <div className="flex flex-col cursor-default">
            <label className="text-[#A7B5BE] font-[Inter] text-[10px] cursor-default">Education </label>
            <p className="text-black font-medium text-[13px] font-[Inter] cursor-default">
              {field.charAt(0).toUpperCase() + field.slice(1)} at {collegeName.charAt(0).toUpperCase() + collegeName.slice(1)}
            </p>
          </div>
          <div className="flex flex-col cursor-default">
            <label className="text-[#A7B5BE] font-[Inter] text-[10px] cursor-default">Skills</label>
            <div className="flex space-x-1 bg-white items-center cursor-default">
              {skills?.map((skill, index) => (
                <span
                  key={index}
                  className="flex capitalize text-[#284B80] text-[10px] font-regular font-[Inter] p-1 cursor-default"
                >
                  #{skill.skillName} ({skill.score}/5)
                </span>
              ))}
              {skills?.length === 0 && (
                <span className="text-[#A7B5BE] font-[Inter] text-[10px] cursor-default">Not Mentioned</span>
              )}
            </div>
          </div>
        </div>
        <div className="flex  gap-4 cursor-default">
          <div className="flex flex-col gap-2 min-w-[10rem] mx-2 text-right text-base cursor-default">
            <div className="flex flex-col cursor-default">
              <label className="text-[#A7B5BE] font-[Inter] text-[10px] cursor-default">Salary</label>
              <p className="text-black font-medium text-[13px] font-[Inter] cursor-default">{talentCurrentCTC} lpa</p>
            </div>
            <div className="flex flex-col cursor-default">
              <label className="text-[#A7B5BE] font-[Inter] text-[10px] cursor-default">Current Location</label>
              <p className="text-black font-medium text-[13px] font-[Inter] cursor-default">
                {talentCurrentLocation}
              </p>
            </div>
            <div className="flex flex-col cursor-default">
              <label className="text-[#A7B5BE] font-[Inter] text-[10px] cursor-default">Notice Period</label>
              <p className="text-black font-medium text-[13px] font-[Inter] cursor-default">{officialNoticePeriod}</p>
            </div>
          </div>
          <div className="flex flex-col gap-2 min-w-[10rem] mx-2 text-base cursor-default">
            <div className="flex flex-col cursor-default">
              <label className="text-[#A7B5BE] font-[Inter] text-[10px] cursor-default">Expected CTC</label>
              <p className="text-black font-medium text-[13px] font-[Inter] cursor-default">{talentExpectedCTCMax} lpa</p>
            </div>
            <div className="flex flex-col cursor-default">
              <label className="text-[#A7B5BE] font-[Inter] text-[10px] cursor-default">Expected Location</label>
              <p contenteditable="false" className="text-black font-medium text-[13px] font-[Inter] cursor-default">
                {talentPreferredLocation}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-2">
      {status === "Reffered" ? (
          <div className="flex space-x-2 mt-4">
            {loading2 ? <div className="flex justify-center items-center w-full mt-5 mb-5">
                            <SyncLoader color="#38e3e0" />
                          </div> :<><button
              className="border w-full bg-blue-500 text-white border-blue-600 text-sm px-3 py-2 rounded hover:bg-blue-5"
              onClick={(e) => {
                e.stopPropagation(); // Prevent the card click event from firing
                onShortlistClick(
                  referralId,
                );
              }}
            >
              Shortlist{/* <RescheduleEvent interview={talent} text={'Shortlist'} /> */}
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation(); // Prevent the card click event from firing
                onRejectClick(
                  referralId,
                  );
              }}
              className="border bg-white w-full  text-red-600 text-sm px-3 py-2 rounded hover:bg-red-50"
            >
              Reject
            </button></>}
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <div></div>
            <span
            className={`${
              status === "Shortlisted"
                ? "text-blue-600"
                : "text-red-600"
            } text-base font-bold px-2 py-2 rounded`}
          >
            {status}
          </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TalentCard;
