import React from 'react';
import Layout from './Layout';
import { Link, useLocation, useParams } from 'react-router-dom';
import { routes } from '../App';
const ThankTalent = () => {
  const { jobId } = useParams();
  
  return (
    <Layout>
        <div className="min-h-screen mt-[1vh] bg-white flex items-center justify-center">
      <div className="my-auto">
        <h1 className="text-6xl font-bold mb-4 text-center">Thank You!</h1>
        <div>
        <img src='http://res.cloudinary.com/dwmwpmrpo/image/upload/v1716487924/jtsrrc8xwhzesclkfyrb.png'
         className='ml-[15vh] mb-5'/>
        </div>
        <p className="text-gray-700 text-3xl font-bold mb-6 text-center">
        You have successfully referred the job
        </p>
        <div className="flex justify-center">
          <div className="text-2xl text-gray-400">
          <Link className='underline underline-offset-1' to={`${routes.preRecruiter}${jobId}`}>Click here</Link> to add 1 more talent for this job.
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default ThankTalent;
