import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { routes } from '../App';

const Confirmation = () => {
  const { token } = useParams();
  const [confirmationStatus, setConfirmationStatus] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const confirmAccount = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_}/api/v1/auth/confirm-account/${token}`);
        if (res && res.data.message) {
          setConfirmationStatus(res.data.message);
          console.log("token generated -> ",token)
        }
        if (res.data.userConfirmed) {
          navigate(routes.login)
        }
      } catch (error) {
        console.error('Error confirming account:', error.message);
        setConfirmationStatus('Error confirming account');
      }
    };

    confirmAccount();
  }, [token]);

  return (
    <div className="container mx-auto mt-8">
      <div className="max-w-md mx-auto bg-gray-300 p-6 rounded-md shadow-md">
        <h2 className="text-2xl font-bold mb-4">Email Confirmation</h2>
        <p>{confirmationStatus}</p>
        <button className='bg-blue-500 px-2 py-1 rounded-2xl' onClick={()=>{navigate(routes.login)}}><span className='text-white'>Login</span></button>
      </div>
    </div>
  );
};

export default Confirmation;
