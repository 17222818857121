import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { MdDelete } from 'react-icons/md';

const InterviewScheduling = ({formData, setFormData }) => {
  const [isRecurring, setIsRecurring] = useState(false);
  const [date, setDate] = useState('');
  const [dayOfWeek, setDayOfWeek] = useState('');
  const [startTime, setStartTime] = useState('');
  const [duration, setDuration] = useState(60);
  const [endDate, setEndDate] = useState('');
  const [slots, setSlots] = useState( []);
  const [selectedDate, setSelectedDate] = useState('');
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);

  useEffect(() => {
    updateAvailableSlots();
  }, [selectedDate, slots]);

  const updateAvailableSlots = () => {
    const filtered = slots.filter(slot => {
      if (slot.type === 'one-time') {
        return slot.date === selectedDate;
      } else {
        const slotDay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][new Date(selectedDate).getDay()];
        return slot.dayOfWeek === slotDay && new Date(selectedDate) <= new Date(slot.endDate);
      }
    });
    setAvailableSlots(filtered);
  };

  const handleAddSlot = () => {
    if (isRecurring && (!dayOfWeek || !startTime || !endDate)) {
      toast.error('Please fill in all fields for recurring slots');
      return;
    }
    if (!isRecurring && (!date || !startTime)) {
      toast.error('Please fill in all fields for one-time slots');
      return;
    }

    const newSlot = {
      id: Math.random().toString(36).substr(2, 9),
      type: isRecurring ? 'recurring' : 'one-time',
      startTime,
      duration,
      ...(isRecurring ? { dayOfWeek, endDate } : { date }),
    };

    setSlots([...slots, newSlot]);
    resetForm();
  };

  const handleRemoveSlot = (id) => {
    setSlots(slots.filter(slot => slot.id !== id));
  };

  const resetForm = () => {
    setDate('');
    setDayOfWeek('');
    setStartTime('');
    setDuration(60);
    setEndDate('');
  };

  const formatTimeRange = (startTime, duration) => {
    const start = new Date(`1970-01-01T${startTime}`);
    const end = new Date(start.getTime() + duration * 60000);
    return `${formatTime(start)} - ${formatTime(end)}`;
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const handleSelectSlot = (slot) => {
    setSelectedSlot(slot);
  };

  // const formatDate = (dateString) => {
  //   const [year, month, day] = dateString.split("-");
  //   return `${day}-${month}-${year}`;
  // };

  useEffect(() => {
    const formattedSlots = formData?.availabilitySlots.map(slot => {
      const isRecurring = slot.recurring;
    
      return {
        ...slot,
        id: Math.random().toString(36).substr(2, 9), // Add unique random ID
        type: isRecurring ? "recurring" : "one-time", // Set type
        recurring: isRecurring ? "recurring" : "one-time", // Adjust recurring field
        dayOfWeek: slot.day, // Rename 'day' to 'dayOfWeek'
        startTime: slot.timeFrom, // Rename 'timeFrom' to 'startTime'
        ...(isRecurring ? { endDate: slot.date } : { date: slot.date }), // Handle 'date' or 'endDate'
      };
    });

    setSlots(formattedSlots);
  }, [])

  const formatDate2 = (dateString) => {
    const date = new Date(dateString); // Parse the string into a Date object
    return date.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
};

console.log(slots)
  



  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      availabilitySlots: slots
    }));
  }, [slots])

  console.log(formData,slots)

  const formatDayAsPossessive = (dayString) => {
    return `${dayString.trim()}'s`;
  };


  return (
    <div className="max-w-7xl mx-auto mt-6 font-sans">
      {/* <h1 className="text-xl font-bold text-center mb-2">Interview Scheduling</h1> */}
      <div className="flex gap-3">
        <div className="flex-1  min-w-[300px]">
          <span className="text-gray-700">
            Set Interview Availability
            <span className="text-red-500">*</span>
          </span>
          <div className="mb-4 mt-4 flex items-center">
            <input
              type="checkbox"
              id="recurring"
              checked={isRecurring}
              onChange={(e) => setIsRecurring(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="recurring" className="text-sm font-medium">Recurring slot</label>
          </div>
          {!isRecurring && (
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="w-full mb-4 p-2 border border-gray-300 rounded-md"
            />
          )}

          {isRecurring && (
            <>
              <select
                value={dayOfWeek}
                onChange={(e) => setDayOfWeek(e.target.value)}
                className="w-full mb-4 p-2 border border-gray-300 rounded-md"
              >
                <option value="">Select day of week</option>
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                  <option key={day} value={day}>{day}</option>
                ))}
              </select>
              <span className="text-sm font-medium ml-1 mb-3">End Date</span>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full mb-4 p-2 border border-gray-300 rounded-md"
              />
            </>
          )}

          <input
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            className="w-full mb-4 p-2 border border-gray-300 rounded-md"
          />
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Duration (minutes): {duration}</label>
            <input
              type="range"
              min="15"
              max="240"
              step="15"
              value={duration}
              onChange={(e) => setDuration(Number(e.target.value))}
              className="w-full"
            />
          </div>
          <button
            type="button"
            onClick={handleAddSlot}
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
          >
            Add Slot
          </button>
        </div>
        <div className="flex-1 min-w-[300px]">
        <div className="mt-8">
            <h3 className="text-xl font-semibold mb-4">Added Slots</h3>
            {slots.map((slot) => (
              <div key={slot.id} className="flex justify-between items-center bg-gray-100 p-3 rounded-md mb-2">
                <span>
                  {slot.type === 'one-time' 
                    ? `${formatDate2(slot.date)} ${formatTimeRange(slot.startTime, slot.duration)}`
                    : `${formatDayAsPossessive(slot?.dayOfWeek)} ${formatTimeRange(slot.startTime, slot.duration)} (until ${formatDate2(slot.endDate)})`
                  }
                </span>
                <button
                  type="button"
                  onClick={() => handleRemoveSlot(slot.id)}
                  className="text-red-500 text-xl"
                >
                  <MdDelete />
                </button>
              </div>
            ))}
          </div>
          {/* <h2 className="text-2xl font-semibold mb-4">Book an Interview</h2>
          <input 
            type="date" 
            value={selectedDate} 
            onChange={(e) => setSelectedDate(e.target.value)}
            className="w-full mb-4 p-2 border border-gray-300 rounded-md"
          />
          <div className="max-h-[300px] overflow-y-auto border border-gray-300 rounded-md p-4">
            {availableSlots.length > 0 ? (
              availableSlots.map((slot) => (
                <button 
                  type="button"
                  key={slot.id} 
                  onClick={() => handleSelectSlot(slot)}
                  className="w-full text-left bg-white border border-gray-300 p-2 rounded-md mb-2 hover:bg-gray-100 transition duration-300"
                >
                  {formatTimeRange(slot.startTime, slot.duration)}
                </button>
              ))
            ) : (
              <p className="text-center text-gray-500">No available slots for this date.</p>
            )}
          </div>
          {selectedSlot && (
            <div className="mt-8 bg-gray-100 p-4 rounded-md">
              <h3 className="text-xl font-semibold mb-2">Selected Slot</h3>
              <p>Date: {selectedSlot.date || `${selectedSlot.dayOfWeek} (Recurring)`}</p>
              <p>Time: {formatTimeRange(selectedSlot.startTime, selectedSlot.duration)}</p>
              <p>Duration: {selectedSlot.duration} minutes</p>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default InterviewScheduling;

