import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Layout from "../../Component/Layout";
import RichTextArea from "../../Component/RichTextEditorComponent";
import InterviewScheduling from "../../Component/InterviewScheduling";
import axios from "axios";
import Select from "react-select";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { MdDelete } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import "./CreateJob.css";
import { IoMdAddCircleOutline } from "react-icons/io";
import SyncLoader from "react-spinners/SyncLoader";
//Using Material-UI for the Accordion and Progress Stepper
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { FaX } from "react-icons/fa6";
import { FaInfoCircle } from "react-icons/fa";
import { Autocomplete, debounce, TextField } from "@mui/material";
import Dropdown from "../../Component/Dropdown";
import { add } from "date-fns";

const steps = [
  "Company and Job Details",
  "Requirements & Skills",
  "Project & Team",
  "Additional Information",
];

const currencies = [
  "INR (₹)",
  "USD ($)",
  "EUR (€)",
  "GBP (£)",
  "CAD (C$)",
  "SGD (S$)",
  "AED (د.إ)",
];

const CustomEngagementOption = (props) => {
  const { data, innerRef, innerProps } = props;

  // Tooltip component that renders in a portal
  const Tooltip = ({ children, position }) => {
    return ReactDOM.createPortal(
      <div
        className="absolute bg-black text-white text-xs p-2 rounded shadow-lg z-[9999]"
        style={{
          top: position.top,
          left: position.left,
          transform: "translate(-50%, -100%)",
          whiteSpace: "normal",
        }}
      >
        {children}
      </div>,
      document.body // Render tooltip in the <body>
    );
  };

  // Tooltip visibility and position state
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [tooltipPosition, setTooltipPosition] = React.useState({
    top: 0,
    left: 0,
  });

  const handleMouseEnter = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setTooltipPosition({ top: rect.top, left: rect.left + rect.width / 2 });
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="flex items-center justify-between px-2 py-2 hover:bg-gray-100 cursor-pointer"
    >
      <span>{data.label}</span>
      <div
        className="relative group"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <FaInfoCircle className="text-gray-400 hover:text-gray-600" />
        {showTooltip && (
          <Tooltip position={tooltipPosition}>{data.info}</Tooltip>
        )}
      </div>
    </div>
  );
};
const CreateJobs = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const jobId = params.get("jobId");
  const [loading, setLoading] = useState(false);
  const location2 = useLocation();
  const [previousPath, setPreviousPath] = React.useState(location2.pathname);

  useEffect(() => {
    if (location2.pathname !== previousPath) {
      setPreviousPath(location2.pathname);
      window.location.reload();
    }
  }, [location2.pathname, previousPath]);

  const [state, setState] = useState({
    loading: false,
    newPrimarySkill: "",
    newSecondarySkill: "",
    primarySkillsOptions: [],
    secondarySkillsOptions: [],
    softwareOptions: [],
    certificationOptions: [],
    industryOptions: [],
    interviewRoundOptions: [],
    companyIndustryOption: [],
    jobLocationOption: [],
    currentStep: 0,
    showPopup: false,
    newLocation: null,
    newCompanyIndustry: null,
    showTableHeader: false,
  });
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    companyName: "",
    companyWebsite: "",
    jobTitle: "",
    jobLocation: "",
    jobNumbers: "",
    jobLevel: "Internship",
    jobStatus: "Active",
    describeCandidate: "",
    currencyType: "INR (₹)",
    jobType: "Full-Time",
    softwareSkillText: "",
    softwareSkillLevel: "Beginner",
    certificationText: "",
    roundDuration: "15",
    incentivePeriod: "",

    // softwareProficiency: [],
    softwareSkills: [],
    certifications: [],

    softwareProficiency: [],

    engagementType: "Direct/Internal Hiring",
    industryKnowledge: "",
    screeningQuestion: "",
    relevantCertification: [],
    minYearsExperience: 0,
    maxYearsExperience: 50,
    primarySkills: [],
    secondarySkills: [],
    primarySkillText: "",
    secondarySkillText: "",
    interviewRounds: [],
    interviewName: "",
    teamStructure: "Hierarchial",
    projectTeamSize: "",
    minCTC: 1000,
    maxCTC: 500000,
    salaryFrequency: "Per Annum",
    workplaceType: "On-Site",
    recruiterIncentiveType: "percent",
    recruiterIncentiveValue: "",
    roleAndResponsibilites: "",
    companyIndustry: "",
    officialNoticePeriod: "Immediate-Joiner",
    availabilitySlots: [],
  });

  const EngagementOptions = [
    {
      value: "Direct/Internal Hiring",
      label: "Direct/Internal Hiring",
      info: "Hiring managers who want to fill the position directly through Reflense.",
    },
    {
      value: "Contract",
      label: "Contract",
      info: "Hiring managers who want to hire a candidate as an independent contractor for a specific project or timeframe. The hiring manager is responsible for the contract and payments directly with the candidate.",
    },
    {
      value: "Contract-to-Hire",
      label: "Contract-to-Hire",
      info: "Hiring managers who want to initially hire a candidate as a contractor with the possibility of converting them to a full-time employee later.",
    },
    {
      value: "Consultant",
      label: "Consultant",
      info: "Hiring managers who want to use a subject matter expert to work on a specific project or provide ongoing consulting services.",
    },
    {
      value: "3rd Party Payroll",
      label: "3rd Party Payroll",
      info: "Hiring managers who want to use Reflense to find and interview candidates, but the candidate will be employed by a third-party staffing company. The staffing company will handle payroll, taxes, and benefits for the employee.",
    },
    {
      value: "Managed Service Provider",
      label: "Managed Service Provider",
      info: "Hiring managers who want to outsource their temporary staffing needs to a staffing company that manages a pool of workers to fulfill specific requirements.",
    },
    {
      value: "Temp-to-Perm",
      label: "Temp-to-Perm",
      info: "Hiring managers who want to fill temporary positions with the potential for them to become permanent.",
    },
    {
      value: "Client-to-Hire",
      label: "Client-to-Hire",
      info: "Hiring managers who want to use Reflense to find and interview candidates, but will hire them as independent contractors without employee benefits.",
    },
  ];
  const TeamStructureOptions = [
    { value: "Hierarchical", label: "Hierarchical", info: "Top-down" },
    {
      value: "Flat",
      label: "Flat",
      info: "Employees report to multiple managers.",
    },
    {
      value: "Matrix",
      label: "Matrix",
      info: "Employees report to multiple managers",
    },
    {
      value: "Functional",
      label: "Functional",
      info: "Organized by department",
    },
    {
      value: "Cross-Functional",
      label: "Cross-Functional",
      info: "Teams combine members from different departments",
    },
    { value: "Others", label: "Others", info: "" },
  ];
  // const [selectedEngagementOption, setSelectedEngagementOption] = useState({ value: "Direct/Internal Hiring", label: "Direct/Internal Hiring", info: "Hiring managers who want to fill the position directly through Reflense." },);
  // const [selectedTeamStructOption, setselectedTeamStructOption] = useState({ value: "Hierarchical", label: "Hierarchical", info: "Top-down" });

  const NoticePeriodOptions = [
    { value: "Immediate-Joiner", label: "Immediate Joiner" },
    { value: "Serving", label: "Serving" },
    { value: "7 days or less", label: "7 days or less" },
    { value: "15 days or less", label: "15 days or less" },
    { value: "30 days or less", label: "30 days or less" },
    { value: "60 days or less", label: "60 days or less" },
    { value: "90 days or less", label: "90 days or less" },
    { value: "Any", label: "Any" },
  ];

  useEffect(() => {
    const fetchJobDetails = async () => {
      if (!jobId) return;

      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_}/api/v1/job/get-single-job/${jobId}`
        );
        // setFormData(response.data?.job); // Change from response.job to response.data
        const jobData = response.data?.job;
        console.log("job", response.data.job);
        // setSelectedEngagementOption(EngagementOptions.find(option => option.value === jobData.engagementType));
        // setselectedTeamStructOption(TeamStructureOptions.find(option => option.value === jobData.teamStructure));

        // console.log(EngagementOptions.find(option => option.value === jobData.engagementType))
        if (jobData) {
          setFormData((prevData) => ({
            ...prevData,
            companyName: jobData.companyName || "",
            companyWebsite: jobData.companyWebsite || "",
            jobTitle: jobData.jobTitle || "",
            jobLocation: jobData.jobLocation || "",
            jobNumbers: jobData.jobNumbers || "",
            jobLevel: jobData.jobLevel || "Internship",
            jobStatus: jobData.jobStatus || "Active",
            describeCandidate: jobData.describeCandidate || "",
            currencyType: jobData.currencyType || "INR (₹)",
            jobType: jobData.jobType || "Full-Time",
            softwareSkills: jobData.softwareProficiency || [],
            engagementType: jobData.engagementType || "Short-Term (1-3 months)",
            industryKnowledge: jobData.industryKnowledge || "",
            screeningQuestion: jobData.screeningQuestion || "",
            certifications: jobData.relevantCertification || [],
            minYearsExperience:
              jobData.minYearsExperience === 0 ? 0 : jobData.minYearsExperience,
            maxYearsExperience:
              jobData.maxYearsExperience === 0 ? 0 : jobData.maxYearsExperience,
            primarySkills:
              jobData.JobSkillsMappings?.filter(
                (skill) => skill.Skill?.skillType === "Primary"
              ) || [],
            secondarySkills:
              jobData.JobSkillsMappings?.filter(
                (skill) => skill.Skill?.skillType === "Secondary"
              ) || [],
            interviewRounds:
              jobData.JobInterviewRounds?.sort(
                (a, b) => a.roundNumber - b.roundNumber
              ) || [],
            teamStructure: jobData.teamStructure || "",
            projectTeamSize: jobData.projectTeamSize || "",
            minCTC: +jobData.minCTC || 0,
            maxCTC: jobData.maxCTC || 500000,
            incentivePeriod: jobData.incentivePeriod || "",
            currencyType: jobData.currencyType,
            salaryFrequency: jobData.salaryFrequency,
            workplaceType: jobData.workplaceType || "On-Site",
            recruiterIncentiveType: jobData.recruiterIncentiveType || "percent",
            recruiterIncentiveValue: jobData.recruiterIncentiveValue || "",
            roleAndResponsibilites: jobData.roleAndResponsibilites || "",
            companyIndustry: jobData.companyIndustry || "",
            officialNoticePeriod:
              jobData.officialNoticePeriod || "Should be serving Notice",
            availabilitySlots: jobData.JobAvailabilitySlots || [],
          }));
        }
      } catch (error) {
        console.error("Error fetching job details:", error.message);
      } finally {
        setLoading(false); // Stop loader once data is fetched or error occurs
      }
    };

    fetchJobDetails();
  }, [jobId]);

  const [errors, setErrors] = useState({
    companyName: "",
    jobTitle: "",
    companyIndustry: "",
    jobLocation: "",
    workplaceType: "",
    jobType: "",
    minYearsExperience: "",
    maxYearsExperience: "",
    minCTC: "",
    maxCTC: "",
    primarySkills: "",
    secondarySkills: "",
    projectTeamSize: "",
    jobLevel: "",
    jobNumbers: "",
    roleAndResponsibilites: "",
    officialNoticePeriod: "",
    recruiterIncentiveType: "",
    recruiterIncentiveValue: "",
    availabilitySlots: "",
    softwareSkills: "",
  });
  // const [availableSlots, setAvailableSlots] = useState([])
  const workplaceTypes = ["On-Site", "Hybrid", "Remote"];
  const [location, setLocation] = useState([]);
  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // console.log("form",formData)

  const updateFormState = (key, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const areSlotsFilled = () => {
    // const requiredFields = [
    //   "companyName",
    //   "jobTitle",
    //   "jobLocation",
    //   "jobNumbers",
    //   "jobLevel",
    //   "jobType",
    //   "workplaceType",
    //   "minYearsExperience",
    //   "maxYearsExperience",
    //   "minCTC",
    //   "maxCTC",
    //   "recruiterIncentiveValue",
    //   "recruiterIncentiveType",
    //   "companyIndustry",
    //   "officialNoticePeriod",
    //   "roleAndResponsibilites",
    //   "screeningQuestion",
    //   "describeCandidate",
    //   "relevantCertification",
    //   "engagementType",
    //   "softwareProficiency",
    //   "industryKnowledge",
    //   "projectTeamSize",
    //   "teamStructure",
    //   "interviewRounds",
    // ];

    // const allRequiredFieldsFilled = requiredFields.every(
    //   (field) =>
    //     formData[field] &&
    //     typeof formData[field] === "string" &&
    //     formData[field].trim() !== ""
    // );

    // Check if at least one primary and one secondary skill is added
    const hasSkills =
      formData.primarySkills.length > 0 && formData.secondarySkills.length > 0;

    const hasInterviewRounds = formData.interviewRounds.length > 0;

    // Check if all availability slots are filled correctly
    const allSlotsFilled = formData.availabilitySlots.every(
      (slot) => slot.duration && slot.startTime
    );

    return hasSkills && hasInterviewRounds && allSlotsFilled;
  };

  const handleEngagementChange = (option) => {
    // setSelectedEngagementOption(option);
    setFormData({
      ...formData,
      engagementType: option.value,
    });
    console.log("Selected Value:", option.value);
  };
  const handleTeamStructChange = (option) => {
    // setselectedTeamStructOption(option);
    setFormData({
      ...formData,
      teamStructure: option.value,
    });
  };
  const handleNoticePeriodChange = (selectedOption) => {
    const event = {
      target: {
        name: "officialNoticePeriod",
        value: selectedOption.value,
      },
    };
    handleChange(event); // Reuse your existing handleChange function
  };

  // Function to handle company industry change
  const handleCompanyIndustryChange = async (selectedOption) => {
    if (selectedOption) {
      // Update form data with the selected company industry
      setFormData((prevData) => ({
        ...prevData,
        companyIndustry: selectedOption.label,
      }));
    }
  };

  // Function to create a new location
  // const createjobLocation = async (newLocation) => {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_}/api/v1/job/create-location`,
  //       { name: newLocation }
  //     );
  //     if (response.data.success) {
  //       await fetchLocation();
  //     } else {
  //       console.error("Failed to create new location:", response.data.message);
  //     }
  //   } catch (error) {
  //     console.error("Error creating new location:", error);
  //   }
  // };

  // useEffect(() => {
  //   // fetchCompanyIndustry();
  // console.log(formData)
  // }, [setFormData]);
  const formatWithCommas = (value) => {
    if (!value) return "";
    return Number(value).toLocaleString();
  };
  const [jobLocations, setJobLocations] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const fetchJobLocations = useCallback(async (inputValue) => {
    if (inputValue.length < 2) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/locations?search=${inputValue}`
      );
      const locationsToShow = response.data;
      const newOptions = locationsToShow.map((loc) => ({
        value: `${loc.city} | ${loc.state} | ${loc.country}`,
        label: `${loc.city} | ${loc.state} | ${loc.country}`,
      }));
      setJobLocations(newOptions);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    }
  }, []);

  const debouncedFetchJobLocations = useCallback(
    debounce(fetchJobLocations, 300),
    [fetchJobLocations]
  );

  const handleLocationChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      jobLocation: newValue ? newValue.label : "",
    }));
  };

  const handleLocationInputChange = (event, newInputValue) => {
    debouncedFetchJobLocations(newInputValue);
  };

  const validateCompanyAndJobDetails = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!formData.companyName) {
      newErrors.companyName = "Company name is required";
      isValid = false;
    } else {
      newErrors.companyName = "";
    }

    if (!formData.jobTitle) {
      newErrors.jobTitle = "Job title is required";
      isValid = false;
    } else {
      newErrors.jobTitle = "";
    }

    if (!formData.companyIndustry) {
      newErrors.companyIndustry = "Company industry is required";
      isValid = false;
    } else {
      newErrors.companyIndustry = "";
    }

    if (!formData.jobLocation) {
      newErrors.jobLocation = "Job location is required";
      isValid = false;
    } else {
      newErrors.jobLocation = "";
    }

    if (!formData.workplaceType) {
      newErrors.workplaceType = "Workplace type is required";
      isValid = false;
    } else {
      newErrors.workplaceType = "";
    }

    if (!formData.jobType) {
      newErrors.jobType = "Job type is required";
      isValid = false;
    } else {
      newErrors.jobType = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const validateRequirementsAndSkills = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!String(formData.minYearsExperience)) {
      newErrors.minYearsExperience = "Minimum experience is required";
      isValid = false;
    } else {
      newErrors.minYearsExperience = "";
    }

    if (!formData.maxYearsExperience) {
      newErrors.maxYearsExperience = "Maximum experience is required";
      isValid = false;
    } else if (
      Number(formData.minYearsExperience) > Number(formData.maxYearsExperience)
    ) {
      newErrors.maxYearsExperience =
        "Maximum experience should be greater than minimum";
      isValid = false;
    } else {
      newErrors.maxYearsExperience = "";
    }

    if (!formData.minCTC) {
      newErrors.minCTC = "Minimum CTC is required";
      isValid = false;
    } else {
      newErrors.minCTC = "";
    }

    if (!formData.maxCTC) {
      newErrors.maxCTC = "Maximum CTC is required";
      isValid = false;
    } else if (formData.minCTC > formData.maxCTC) {
      newErrors.maxCTC = "Maximum CTC should be greater than minimum";
      isValid = false;
    } else {
      newErrors.maxCTC = "";
    }

    if (formData.primarySkills.length === 0) {
      newErrors.primarySkills = "Please add primary skill and experience";
      isValid = false;
    } else {
      newErrors.primarySkills = "";
    }
    console.log(errors.primarySkills);
    console.log(formData.primarySkills);

    setErrors(newErrors);
    return isValid;
  };

  const validateProjectAndTeam = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!formData.projectTeamSize) {
      newErrors.projectTeamSize = "Project team size is required";
      isValid = false;
    } else {
      newErrors.projectTeamSize = "";
    }

    if (!formData.jobLevel) {
      newErrors.jobLevel = "Job level is required";
      isValid = false;
    } else {
      newErrors.jobLevel = "";
    }

    if (!formData.jobNumbers) {
      newErrors.jobNumbers = "Number of open positions is required";
      isValid = false;
    } else {
      newErrors.jobNumbers = "";
    }

    if (!formData.roleAndResponsibilites) {
      newErrors.roleAndResponsibilites =
        "Roles and responsibilities are required";
      isValid = false;
    } else {
      newErrors.roleAndResponsibilites = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const validateAdditionalInformation = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (formData.interviewRounds.length === 0) {
      newErrors.interviewRounds =
        "at least one interview round and duration should be added";
      isValid = false;
    } else {
      newErrors.interviewRounds = "";
    }

    if (!formData.officialNoticePeriod) {
      newErrors.officialNoticePeriod = "Official notice period is required";
      isValid = false;
    } else {
      newErrors.officialNoticePeriod = "";
    }

    if (!formData.recruiterIncentiveType) {
      newErrors.recruiterIncentiveType = "Recruiter incentive type is required";
      isValid = false;
    } else {
      newErrors.recruiterIncentiveType = "";
    }

    if (!formData.recruiterIncentiveValue) {
      newErrors.recruiterIncentiveValue =
        "Recruiter incentive value is required";
      isValid = false;
    } else {
      newErrors.recruiterIncentiveValue = "";
    }

    if (!formData.incentivePeriod) {
      newErrors.incentivePeriod = "Recruiter incentive period is required";
      isValid = false;
    } else {
      newErrors.incentivePeriod = "";
    }

    if (formData.availabilitySlots.length === 0) {
      newErrors.availabilitySlots =
        "At least one availability slot is required";
      isValid = false;
    } else {
      newErrors.availabilitySlots = "";
    }
    console.log("error", errors);
    setErrors(newErrors);
    return isValid;
  };

  const handleNext = () => {
    let isValid = false;
    switch (state.currentStep) {
      case 0:
        isValid = validateCompanyAndJobDetails();
        break;
      case 1:
        isValid = validateRequirementsAndSkills();
        break;
      case 2:
        isValid = validateProjectAndTeam();
        break;
      case 3:
        isValid = validateAdditionalInformation();
        break;
      default:
        isValid = true;
    }

    if (isValid) {
      updateState("currentStep", state.currentStep + 1);
    }
  };

  const handlePrev = () => {
    updateState("currentStep", state.currentStep - 1);
  };
  // const handleRolesAndResponsibilitiesChange = (value) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     roleAndResponsibilities: value,
  //   }));
  // }
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "minCTC" || name === "maxCTC") {
      const numericValue = value.replace(/,/g, "");
      setFormData((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // const handleSoftwareSkillChange = (index, field, value) => {
  //   console.log(
  //     "Updating skill at index:",
  //     index,
  //     "Field:",
  //     field,
  //     "Value:",
  //     value
  //   );
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     softwareSkills: prevState.softwareSkills.map((skill, i) =>
  //       i === index ? { ...skill, [field]: value } : skill
  //     ),
  //   }));
  // };
  // const handleSoftwareSkillChange = (index, field, value) => {
  //   setFormData((prevData) => {
  //     const updatedSkills = [...prevData.softwareSkills];
  //     updatedSkills[index][field] = value; // Update specific skill
  //     return { ...prevData, softwareSkills: updatedSkills };
  //   });
  // };
  // const handleEditorFocus = (e) => {
  //   e.preventDefault(); // Prevent losing focus when interacting with dropdowns
  // };
  // const handleCertificationChange = (index, field, value) => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     certifications: prevState.certifications.map((cert, i) =>
  //       i === index ? { ...cert, [field]: value } : cert
  //     ),
  //   }));
  // };

  const addSoftwareSkill = () => {
    setFormData((prevState) => ({
      ...prevState,
      softwareSkills: [
        ...prevState.softwareSkills,
        {
          id: uuidv4(),
          name: formData.softwareSkillText,
          checked: true,
          level: formData.softwareSkillLevel,
        },
      ],
      softwareSkillText: "",
      softwareSkillLevel: "Beginner",
    }));
  };

  const addCertification = () => {
    setFormData((prevState) => ({
      ...prevState,
      certifications: [
        ...prevState.certifications,
        { name: formData.certificationText, checked: true },
      ],
      certificationText: "",
    }));
  };

  const removeSoftwareSkill = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      softwareSkills: prevState.softwareSkills.filter((_, i) => i !== index),
    }));
  };

  const removeCertification = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      certifications: prevState.certifications.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    if (
      formData.jobStatus === "Active" &&
      !validateAdditionalInformation() &&
      !areSlotsFilled()
    ) {
      toast.error("fill the availability Slots");
      return;
    }
    setSubmitLoading(true);
    if (
      formData.jobStatus === "Draft" &&
      (!formData.companyName || !formData.jobTitle)
    ) {
      toast.error("Please fill at least Company Name and Job Title");
      return;
    }
    // console.log("formData=", formData);

    updateState("loading", true);

    try {
      // const isUniqueJob = await verifyUniqueJob(formData);
      // if (!isUniqueJob) {
      //   toast.error("Job already exists. Please create a unique job.");
      //   return;
      // }

      if (jobId) {
        console.log("form data----2", formData);
        await axios.put(
          `${process.env.REACT_APP_API_}/api/v1/job/updateJob/${jobId}`,
          formData
        );
        toast.success("Job Edited Successfully");
        navigate(`/dashboard/user/job/${jobId}`);
      } else {
        // console.log("formData", formData);
        const response = await axios.post(
          `${process.env.REACT_APP_API_}/api/v1/job/createjob`,
          formData
        );
        if (response.data) {
          toast.success("JOB CREATED SUCCESSFULLY");
          updateState("currentStep", 1);
          navigate(`/dashboard/user/job/${response.data.data.jobId}`);
        }
      }
    } catch (error) {
      setSubmitLoading(false);
      console.error(
        "Error creating job:",
        error.response?.data?.message || error.message
      );
      toast.error(
        "An error occurred while creating the job. Please try again."
      );
    } finally {
      updateState("loading", false);
    }
  };

  // Function to fetch primary skills suggestions
  const fetchPrimarySkills = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/skills?search=${query}`
      );
      // console.log("responseresponse=", response);
      updateState(
        "primarySkillsOptions",
        response.data.map((skill) => ({
          value: skill.skillName,
          label: skill.skillName,
        }))
      );
    } catch (error) {
      console.error("Error fetching primary skills:", error);
    }
  };

  useEffect(() => {
    const fetchTopIndustry = async () => {
      try {
        // Replace with your actual backend API URL
        const url = `${process.env.REACT_APP_API_}/api/v1/job/top-ten-industry`;
        const response = await axios.get(url);
        updateState(
          "companyIndustryOption",
          response.data.map((skill) => ({
            value: skill.concatCell,
            label: skill.concatCell,
          }))
        );
        updateState(
          "industryOptions",
          response.data.map((skill) => ({
            value: skill.concatCell,
            label: skill.concatCell,
          }))
        );
      } catch (err) {
        // setError("Error fetching skills. Please try again.");
        console.error(err);
      }
    };

    const fetchTopSkills = async () => {
      try {
        // Replace with your actual backend API URL
        const url = `${process.env.REACT_APP_API_}/api/v1/job/top-ten-skills`;

        const response = await axios.get(url);

        updateState(
          "primarySkillsOptions",
          response.data.map((skill) => ({
            value: skill.skillName,
            label: skill.skillName,
          }))
        );
        updateState(
          "secondarySkillsOptions",
          response.data.map((skill) => ({
            value: skill.skillName,
            label: skill.skillName,
          }))
        );
      } catch (err) {
        // setError("Error fetching skills. Please try again.");
        console.error(err);
      }
    };

    const fetchTopTenSoftware = async () => {
      try {
        // Replace with your actual backend API URL
        const url = `${process.env.REACT_APP_API_}/api/v1/job/top-ten-software`;

        const response = await axios.get(url);

        updateState(
          "softwareOptions",
          response.data.map((skill) => ({
            value: skill.software,
            label: skill.software,
          }))
        );
      } catch (err) {
        // setError("Error fetching skills. Please try again.");
        console.error(err);
      }
    };

    const fetchTopTenCertification = async () => {
      try {
        // Replace with your actual backend API URL
        const url = `${process.env.REACT_APP_API_}/api/v1/job/top-ten-certification`;

        const response = await axios.get(url);

        updateState(
          "certificationOptions",
          response.data.map((skill) => ({
            value: skill.certification,
            label: skill.certification,
          }))
        );
      } catch (err) {
        // setError("Error fetching skills. Please try again.");
        console.error(err);
      }
    };

    const fetchTopTenInterviewRound = async () => {
      try {
        // Replace with your actual backend API URL
        const url = `${process.env.REACT_APP_API_}/api/v1/job//top-ten-interview-rounds`;

        const response = await axios.get(url);

        updateState(
          "interviewRoundOptions",
          response.data.map((skill) => ({
            value: skill.roundName,
            label: skill.roundName,
          }))
        );
      } catch (err) {
        // setError("Error fetching skills. Please try again.");
        console.error(err);
      }
    };

    fetchTopSkills();
    fetchTopIndustry();
    fetchTopTenSoftware();
    fetchTopTenCertification();
    fetchTopTenInterviewRound();
  }, []);

  const fetchIndustries = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/industries?search=${query}`
      );
      updateState(
        "industryOptions",
        response.data.map((skill) => ({
          value: skill.concatCell,
          label: skill.concatCell,
        }))
      );
      updateState(
        "companyIndustryOption",
        response.data.map((skill) => ({
          value: skill.concatCell,
          label: skill.concatCell,
        }))
      );
    } catch (error) {
      console.error("Error fetching primary skills:", error);
    }
  };

  const fetchCertifications = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/certifications?search=${query}`
      );
      updateState(
        "certificationOptions",
        response.data.map((skill) => ({
          value: skill.certification,
          label: skill.certification,
        }))
      );
    } catch (error) {
      console.error("Error fetching primary skills:", error);
    }
  };

  const fetchSoftwares = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/softwares?search=${query}`
      );
      // console.log("software responseresponse=", response);
      updateState(
        "softwareOptions",
        response.data.map((skill) => ({
          value: skill.software,
          label: skill.software,
        }))
      );
    } catch (error) {
      console.error("Error fetching primary skills:", error);
    }
  };

  const fetchInterviewRounds = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/interview-rounds?search=${query}`
      );
      // console.log("industry responseresponse=", response);
      updateState(
        "interviewRoundOptions",
        response.data.map((skill) => ({
          value: skill.roundName,
          label: skill.roundName,
        }))
      );
    } catch (error) {
      console.error("Error fetching primary skills:", error);
    }
  };

  // Function to handle changes in primary skill text input
  const handlePrimarySkillChange = async (selectedOption, val) => {
    // console.log("valval=", val, selectedOption);
    setFormData((prevData) => ({
      ...prevData,
      primarySkillText: selectedOption ? selectedOption.label : "",
    }));
    if (!selectedOption) {
      await createPrimarySkill(formData.primarySkillText);
      await fetchPrimarySkills();
    }
  };

  const handleInterviewChange = async (selectedOption, val) => {
    // console.log("interview valval=", val, selectedOption);
    setFormData((prevData) => ({
      ...prevData,
      interviewName: selectedOption ? selectedOption.label : "",
    }));
  };

  // const handleSoftwareProficiencyChange = async (selectedOption, val) => {
  //   console.log("valval=", val, selectedOption);
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     softwareProficiency: selectedOption ? selectedOption.label : "",
  //   }));
  // };

  // const handleIndustryKnowledgeChange = async (selectedOption, val) => {
  //   console.log("valval=", val, selectedOption);
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     industryKnowledge: selectedOption ? selectedOption.label : "",
  //   }));
  // };

  // const handleRelevantCertificationChange = async (selectedOption, val) => {
  //   console.log("valval=", val, selectedOption);
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     relevantCertification: selectedOption ? selectedOption.label : "",
  //   }));
  // };

  // Function to create a new company industry
  // const createPrimarySkillIndustry = async (newSkill) => {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_}/api/v1/job/create-primay-skills`,
  //       { name: newSkill }
  //     );
  //     if (response.data.success) {
  //       // await fetchPrimarySkills();
  //     } else {
  //       console.error(
  //         "Failed to create new company industry:",
  //         response.data.message
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error creating new company industry:", error);
  //   }
  // };

  // Function to create a new primary skill
  const createPrimarySkill = async (newSkill) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/job/create-primay-skills`,
        { name: newSkill }
      );
      if (response.data.success) {
      } else {
        console.error(
          "Failed to create new primary skill:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error creating new primary skill:", error);
    }
  };

  const createSecondarySkill = async (newSkill) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/job/create-secondary-skills`,
        { name: newSkill }
      );
      if (response.data.success) {
      } else {
        console.error(
          "Failed to create new secondary skill:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error creating new secondary skill:", error);
    }
  };

  //remove a slot
  // const removeAvailabilitySlot = (index) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     availabilitySlots: prevData.availabilitySlots.filter(
  //       (_, i) => i !== index
  //     ),
  //   }));
  // };

  // Function to fetch secondary skills suggestions
  const fetchSecondarySkills = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/skills?search=${query}`
      );
      updateState(
        "secondarySkillsOptions",
        response.data.map((skill) => ({
          value: skill.skillName,
          label: skill.skillName,
        }))
      );
    } catch (error) {
      console.error("Error fetching secondary skills:", error);
    }
  };

  // Function to handle changes in secondary skill text input
  const handleSecondarySkillChange = async (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      secondarySkillText: selectedOption ? selectedOption.label : "",
    }));
    if (!selectedOption) {
      await createSecondarySkill(formData.secondarySkillText);
      await fetchSecondarySkills();
    }
  };

  // Function to create a new company industry
  // const createSecondarySkillIndustry = async (newSkill) => {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_}/api/v1/job/create-primay-skills`,
  //       { name: newSkill }
  //     );
  //     if (response.data.success) {
  //       // await fetchSecondarySkills();
  //     } else {
  //       console.error(
  //         "Failed to create new company industry:",
  //         response.data.message
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error creating new company industry:", error);
  //   }
  // };
  // const handleAddSoftwareSkill = () => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //       softwareSkills: Array.isArray(formData.softwareSkills)
  //         ? [
  //             ...formData.softwareSkills,
  //             { name: "", checked: false, level: "Beginner" },
  //           ]
  //         : [{ name: "", checked: false, level: "Beginner" }],
  //     },
  //   ))
  // };

  const addInterviewRound = async (e) => {
    e.preventDefault(); // Prevent default form submission
    const trimmedInterviewName = formData.interviewName
      ? formData.interviewName.trim()
      : "";
    const trimmedDuration = formData.roundDuration
      ? formData.roundDuration.trim()
      : "";

    if (!trimmedInterviewName || !trimmedDuration) {
      setErrors({
        ...errors,
        interviewRounds: "Please add interview name and duration",
      });
      return;
    }

    if (trimmedInterviewName && trimmedDuration) {
      if (jobId) {
        setFormData((prevData) => ({
          ...prevData,
          interviewRounds: [
            ...prevData.interviewRounds,
            {
              roundNumber: formData.interviewRounds?.length + 1,
              roundName: trimmedInterviewName,
              roundDuration: trimmedDuration,
            },
          ],
          interviewName: "",
          roundDuration: "15",
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          interviewRounds: [
            ...prevData.interviewRounds,
            {
              round: formData.interviewRounds?.length + 1,
              name: trimmedInterviewName,
              roundDuration: trimmedDuration,
            },
          ],
          interviewName: "",
          roundDuration: "15",
        }));
      }
    }
    setErrors({
      ...errors,
      interviewRounds: "",
    });
  };

  const addInterviewRoundOnEnter = async () => {
    // Prevent default form submission
    const trimmedInterviewName = formData.interviewName
      ? formData.interviewName.trim()
      : "";
    const trimmedDuration = formData.roundDuration
      ? formData.roundDuration.trim()
      : "";

    if (!trimmedInterviewName || !trimmedDuration) {
      setErrors({
        ...errors,
        interview: "Please add interview name and duration",
      });
      return;
    }

    if (trimmedInterviewName && trimmedDuration) {
      if (jobId) {
        setFormData((prevData) => ({
          ...prevData,
          interviewRounds: [
            ...prevData.interviewRounds,
            {
              roundNumber: formData.interviewRounds?.length + 1,
              roundName: trimmedInterviewName,
              roundDuration: trimmedDuration,
            },
          ],
          interviewName: "",
          roundDuration: "15",
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          interviewRounds: [
            ...prevData.interviewRounds,
            {
              round: formData.interviewRounds?.length + 1,
              name: trimmedInterviewName,
              roundDuration: trimmedDuration,
            },
          ],
          interviewName: "",
          roundDuration: "15",
        }));
      }
    }
    setErrors({
      ...errors,
      interviewRounds: "",
    });
  };

  const addPrimarySkill = async (e) => {
    e.preventDefault();
    // Prevent default form submission
    const primarySkillText = formData.primarySkillText || ""; // Default to empty string if undefined
    const primarySkillExperience = formData.primarySkillExperience || "";

    const trimmedPrimarySkillText = primarySkillText.trim();

    const trimmedPrimarySkillExperience = primarySkillExperience.trim();

    if (!trimmedPrimarySkillText || !trimmedPrimarySkillExperience) {
      setErrors({
        ...errors,
        primarySkills: "Please add primary skill and experience",
      });
      console.log(errors);
      return;
    }
    if (trimmedPrimarySkillText && trimmedPrimarySkillExperience) {
      // Parse experience to a number
      const experience = parseInt(trimmedPrimarySkillExperience);

      if (jobId) {
        const newPrimarySkill = {
          Skill: {
            skillName: trimmedPrimarySkillText,
          },
          yearExpRequired: experience,
        };
        setFormData((prevData) => ({
          ...prevData,
          primarySkills: [...prevData.primarySkills, newPrimarySkill],
          primarySkillText: "",
          primarySkillExperience: "",
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          primarySkills: [
            ...prevData.primarySkills,
            {
              skillName: trimmedPrimarySkillText,
              exp: experience,
            },
          ],
          primarySkillText: "",
          primarySkillExperience: "",
        }));
      }
      setErrors({
        ...errors,
        primarySkills: "",
      });
    }
    // if (!primarySkillsOptions.find(option => option.value === trimmedPrimarySkillText)) {
    //   // Skill doesn't exist, add it await createjobLocation(e.target.value);
    //   await createPrimarySkillIndustry(trimmedPrimarySkillText);
    // }
  };

  const addPrimarySkillOnEnter = async () => {
    // Prevent default form submission
    const primarySkillText = formData.primarySkillText || ""; // Default to empty string if undefined
    const primarySkillExperience = formData.primarySkillExperience || "";

    const trimmedPrimarySkillText = primarySkillText.trim();

    const trimmedPrimarySkillExperience = primarySkillExperience.trim();

    if (!trimmedPrimarySkillText || !trimmedPrimarySkillExperience) {
      setErrors({
        ...errors,
        primarySkills: "Please add primary skill and experience",
      });
      console.log(errors);
      return;
    }
    if (trimmedPrimarySkillText && trimmedPrimarySkillExperience) {
      // Parse experience to a number
      const experience = parseInt(trimmedPrimarySkillExperience);

      if (jobId) {
        const newPrimarySkill = {
          Skill: {
            skillName: trimmedPrimarySkillText,
          },
          yearExpRequired: experience,
        };
        setFormData((prevData) => ({
          ...prevData,
          primarySkills: [...prevData.primarySkills, newPrimarySkill],
          primarySkillText: "",
          primarySkillExperience: "",
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          primarySkills: [
            ...prevData.primarySkills,
            {
              skillName: trimmedPrimarySkillText,
              exp: experience,
            },
          ],
          primarySkillText: "",
          primarySkillExperience: "",
        }));
      }
      setErrors({
        ...errors,
        primarySkills: "",
      });
    }
    // if (!primarySkillsOptions.find(option => option.value === trimmedPrimarySkillText)) {
    //   // Skill doesn't exist, add it await createjobLocation(e.target.value);
    //   await createPrimarySkillIndustry(trimmedPrimarySkillText);
    // }
  };

  const addSecondarySkill = async (e) => {
    e.preventDefault(); // Prevent default form submission

    const secondarySkillText = formData.secondarySkillText || ""; // Default to empty string if undefined
    const secondarySkillExperience = formData.secondarySkillExperience || "";
    const trimmedSecondarySkillText = secondarySkillText.trim();
    const trimmedSecondarySkillExperience = secondarySkillExperience.trim();

    if (!trimmedSecondarySkillText || !trimmedSecondarySkillExperience) {
      setErrors({
        ...errors,
        secondarySkills: "Please add secondary skill and experience",
      });
      console.log(errors);
      return;
    }

    if (trimmedSecondarySkillText && trimmedSecondarySkillExperience) {
      if (jobId) {
        const newSecondarySkill = {
          Skill: {
            skillName: trimmedSecondarySkillText,
          },
          yearExpRequired: trimmedSecondarySkillExperience,
        };
        setFormData((prevData) => ({
          ...prevData,
          secondarySkills: [...prevData.secondarySkills, newSecondarySkill],
          secondarySkillText: "",
          secondarySkillExperience: "",
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          secondarySkills: [
            ...prevData.secondarySkills,
            {
              skillName: trimmedSecondarySkillText,
              exp: trimmedSecondarySkillExperience,
            },
          ],
          secondarySkillText: "",
          secondarySkillExperience: "",
        }));
      }
      setErrors({
        ...errors,
        secondarySkills: "",
      });
    }
    // if (!secondarySkillsOptions.find(option => option.value === trimmedSecondarySkillText)) {
    //   // Skill doesn't exist, add it
    //   await createSecondarySkillIndustry(trimmedSecondarySkillText);
    // }
  };

  const addSecondarySkillOnEnter = async () => {
    const secondarySkillText = formData.secondarySkillText || ""; // Default to empty string if undefined
    const secondarySkillExperience = formData.secondarySkillExperience || "";
    const trimmedSecondarySkillText = secondarySkillText.trim();
    const trimmedSecondarySkillExperience = secondarySkillExperience.trim();

    if (!trimmedSecondarySkillText || !trimmedSecondarySkillExperience) {
      setErrors({
        ...errors,
        secondarySkills: "Please add secondary skill and experience",
      });
      console.log(errors);
      return;
    }

    if (trimmedSecondarySkillText && trimmedSecondarySkillExperience) {
      if (jobId) {
        const newSecondarySkill = {
          Skill: {
            skillName: trimmedSecondarySkillText,
          },
          yearExpRequired: trimmedSecondarySkillExperience,
        };
        setFormData((prevData) => ({
          ...prevData,
          secondarySkills: [...prevData.secondarySkills, newSecondarySkill],
          secondarySkillText: "",
          secondarySkillExperience: "",
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          secondarySkills: [
            ...prevData.secondarySkills,
            {
              skillName: trimmedSecondarySkillText,
              exp: trimmedSecondarySkillExperience,
            },
          ],
          secondarySkillText: "",
          secondarySkillExperience: "",
        }));
      }
      setErrors({
        ...errors,
        secondarySkills: "",
      });
    }
  };

  const deleteInterviewRound = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      interviewRounds: prevData.interviewRounds?.filter((_, i) => i !== index),
    }));
  };

  const deletePrimarySkill = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      primarySkills: prevData.primarySkills.filter((_, i) => i !== index),
    }));
  };

  const deleteSecondarySkill = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      secondarySkills: prevData.secondarySkills.filter((_, i) => i !== index),
    }));
  };

  if (submitLoading) {
    return (
      <div className="h-screen flex justify-center items-center gap-2">
        <SyncLoader />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="h-screen flex justify-center items-center gap-2">
        <SyncLoader />
      </div>
    );
  }

  // const sidebar = useSidebar();
  // console.log("sidebar.sidebarState=", sidebar.sidebarState);
  const renderFormStep = () => {
    switch (state.currentStep) {
      case 0:
        return (
          <div className="flex justify-center min-h-scree h-full overflow-auto no-scrollbar w-full my-4">
            <div className="w-full md:w-3/4 overflow-aut no-scrollbar">
              <Box mb={4}>
                <Stepper activeStep={0} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <div className="border bg-white p-2 rounded-lg shadow-lg">
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {/* <h1 className="text-lg font-bold">Company Details </h1> */}
                </AccordionSummary>
                <AccordionDetails className="p-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <label className="block mb-1">
                      What's the name of your company{" "}
                      <span className="text-red-500">*</span>
                      <input
                        type="text"
                        name="companyName"
                        placeholder="Enter company name"
                        value={formData.companyName}
                        onChange={handleChange}
                        className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                        required
                      />
                      {errors.companyName && !formData.companyName && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.companyName}
                        </p>
                      )}
                    </label>
                    <label className="block mb-1">
                      Could you share your company’s website?
                      <input
                        type="text"
                        name="companyWebsite"
                        placeholder="Eg, www.xyz.com"
                        value={formData.companyWebsite}
                        onChange={handleChange}
                        className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                      />
                    </label>
                    <label className="block mb-1">
                      <span className="text-gray-700">
                        Which industry does your company belong to?
                      </span>
                      <span className="text-red-500">*</span>
                      <Select
                        type="text"
                        name="companyIndustry"
                        placeholder="search your industry Eg, Fintech"
                        options={state.companyIndustryOption}
                        value={
                          formData.companyIndustry
                            ? {
                                value: formData.companyIndustry,
                                label: formData.companyIndustry,
                              }
                            : null
                        }
                        onChange={handleCompanyIndustryChange}
                        className="w-full border rounded-md mt-2 focus:outline-none focus:border-blue-500"
                        onInputChange={(inputValue, actionMeta) => {
                          if (actionMeta.action === "input-change") {
                            setFormData((prevData) => ({
                              ...prevData,
                              companyIndustry: inputValue,
                            }));
                            updateState("newCompanyIndustry", inputValue);
                            fetchIndustries(inputValue);
                          }
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "none",
                          }),
                        }}
                      />
                      {errors.companyIndustry && !formData.companyIndustry && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.companyIndustry}
                        </p>
                      )}
                    </label>
                  </div>
                </AccordionDetails>
              </div>
              <div className="border bg-white p-2 rounded-lg shadow-lg my-4 mb-8">
                <AccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  {/* <h1 className="text-lg font-bold">Job Details </h1> */}
                </AccordionSummary>
                <AccordionDetails className="p-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <label className="block mb-1">
                      Job Title <span className="text-red-500">*</span>
                      <input
                        type="text"
                        name="jobTitle"
                        placeholder="Eg, Software Developer"
                        value={formData.jobTitle}
                        onChange={handleChange}
                        className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                        required
                      />
                      {errors.jobTitle && !formData.jobTitle && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.jobTitle}
                        </p>
                      )}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Job Location</span>{" "}
                      <span className="text-red-500">*</span>
                      <div className="mt-2">
                        <div className="w-full">
                          <div className="w-full">
                            {/* ... other form fields */}
                            <label className="block mb-1">
                              <Autocomplete
                                options={jobLocations}
                                value={
                                  formData.jobLocation
                                    ? {
                                        label: formData.jobLocation,
                                        value: formData.jobLocation,
                                      }
                                    : null
                                }
                                onChange={handleLocationChange}
                                onInputChange={handleLocationInputChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: { height: "40px" }, // Reduce the height of the input
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      style: { padding: "8px 14px" }, // Adjust padding to center the text vertically
                                    }}
                                    placeholder="Eg: Bengaluru, Karnataka"
                                    className="w-full bg-white border rounded-md mt-2 focus:outline-none focus:border-blue-500"
                                  />
                                )}
                                onKeyDown={(event) => {
                                  if (event.key === "Enter") {
                                    event.preventDefault(); // Prevent the form submission
                                  }
                                }}
                                loading={jobLocations.length === 0}
                                loadingText="Loading locations..."
                                noOptionsText="No locations found"
                              />
                            </label>
                            {errors.jobLocation && !formData.jobLocation && (
                              <p className="text-red-500 text-sm mt-1">
                                {errors.jobLocation}
                              </p>
                            )}
                            {/* ... other form fields */}
                          </div>
                        </div>
                      </div>
                      {/* <Select
                        type="text"
                        name="jobLocation"
                        options={state.jobLocationOption}
                        value={{
                          value: formData.jobLocation,
                          label: formData.jobLocation,
                        }}
                        onChange={handleLocationChange}
                        className="w-full border rounded-md mt-2 focus:outline-none focus:border-blue-500"
                        onInputChange={(inputValue, actionMeta) => {
                          if (actionMeta.action === "input-change") {
                            setFormData((prevData) => ({
                              ...prevData,
                              jobLocation: inputValue,
                            }));
                            updateState("newLocation", inputValue);
                          }
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "none",
                          }),
                        }}
                      /> */}
                      {/* <Autocomplete
                        options={jobLocations}
                        value={
                          formData.jobLocation
                            ? {
                                label: formData.jobLocation,
                                value: formData.jobLocation,
                              }
                            : null
                        }
                        onChange={handleLocationChange}
                        onInputChange={handleLocationInputChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Enter job location"
                            className="w-full border rounded-md mt-2 focus:outline-none focus:border-blue-500"
                          />
                        )}
                      /> */}
                    </label>
                    <label className="block mb-4">
                      Workplace Type <span className="text-red-500">*</span>
                      <div className="flex flex-wrap overflow-auto gap-4 mt-2">
                        {workplaceTypes?.map((workType) => (
                          <div
                            key={workType}
                            type="button"
                            onClick={() => {
                              // console.log("Selected:", workType);
                              setFormData({
                                ...formData,
                                workplaceType: workType,
                              });
                            }}
                            className={`flex px-3 py-1 w-fit rounded-2xl text-sm border ${
                              formData.workplaceType === workType
                                ? "bg-blue-500 text-white"
                                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                            }`}
                          >
                            {workType}
                          </div>
                        ))}
                      </div>
                      {errors.workplaceType && !formData.workplaceType && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.workplaceType}
                        </p>
                      )}
                    </label>
                    {/* <label></label> */}
                    <label className="block mb-4">
                      Employment Type <span className="text-red-500">*</span>
                      <select
                        name="jobType"
                        value={formData.jobType}
                        onChange={handleChange}
                        className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                      >
                        {/* <option value="">Choose the Job Type</option> */}
                        <option value="Full-Time">Full-Time</option>
                        <option value="Part-Time">Part-Time</option>
                        <option value="Contract">Contract</option>
                        <option value="Temporary">Temporary</option>
                        <option value="Volunteer">Volunteer</option>
                        <option value="Internship">Internship</option>
                        <option value="Consultant">Consultant</option>
                        {/* <option value="Third Party Payroll"> */}
                        {/* Third Party Payroll */}
                        {/* </option> */}
                      </select>
                      {errors.jobType && !formData.jobType && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.jobType}
                        </p>
                      )}
                    </label>
                    <label className="block">
                      <div className="w-full mt-2">
                        <label
                          htmlFor="engagementType"
                          className="block text-sm font-medium"
                        >
                          Choose Engagement Type
                        </label>
                        <Select
                          options={EngagementOptions}
                          value={EngagementOptions.find(
                            (option) => option.value === formData.engagementType
                          )}
                          onChange={handleEngagementChange}
                          placeholder="Choose Engagement Type"
                          components={{ Option: CustomEngagementOption }}
                          menuPortalTarget={document.body} // Render dropdown outside parent container
                          menuPosition="fixed"
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          className="mt-1"
                          classNamePrefix="react-select"
                        />
                      </div>
                    </label>
                  </div>
                </AccordionDetails>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="flex justify-center min-h-scree h-full overflow-auto no-scrollbar w-full my-4">
            <div className="w-full md:w-3/4 overflow-aut no-scrollbar">
              <Box mb={4}>
                <Stepper activeStep={1}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              <div className="border bg-white p-2 rounded-lg shadow-lg">
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {/* <h1 className="text-lg font-bold">Experience</h1> */}
                </AccordionSummary>
                <AccordionDetails className="p-4">
                  <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
                    <div className="flex flex-col gap-2">
                      <h1>Experience (in years)</h1>
                      <div className="flex gap-2 justify-between">
                        <label className=" mb-4">
                          <input
                            type="number"
                            name="minYearsExperience"
                            value={formData.minYearsExperience}
                            onChange={handleChange}
                            min="0"
                            placeholder="Min Exp."
                            className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                          />
                          {errors.minYearsExperience &&
                            !formData.minYearsExperience && (
                              <p className="text-red-500 text-sm mt-1">
                                {errors.minYearsExperience}
                              </p>
                            )}
                        </label>
                        <label className=" mb-4">
                          <input
                            type="number"
                            name="maxYearsExperience"
                            value={formData.maxYearsExperience}
                            onChange={handleChange}
                            placeholder="Max Exp."
                            min={formData.minYearsExperience}
                            className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                          />
                          {errors.maxYearsExperience &&
                            !formData.maxYearsExperience && (
                              <p className="text-red-500 text-sm mt-1">
                                {errors.maxYearsExperience}
                              </p>
                            )}
                        </label>
                      </div>
                      {/* <div className="mt-4 mx-4">
                        <div className="flex justify-between text-base mt-1 text-blue-500 font-semibold">
                          <span>{formData.minYearsExperience}</span>
                          <span>{formData.maxYearsExperience} Yrs</span>
                        </div>
                        <CustomSlider
                          value={[
                            formData.minYearsExperience,
                            formData.maxYearsExperience,
                          ]}
                          onChange={(newVal) => {
                            console.log("newVal==", newVal);
                            setFormData({
                              ...formData,
                              minYearsExperience: newVal[0],
                              maxYearsExperience: newVal[1],
                            });
                          }}
                          aria-labelledby="expected-salary-slider"
                          valueLabelDisplay="auto"
                          step={0.5}
                          min={0}
                          max={100}
                        />
                      </div> */}
                    </div>
                    <div className="flex flex-col gap-2">
                      <h1>Salary</h1>
                      <div className="flex justify-between items-center gap-2">
                        <div className="filters m-auto w-[8rem] bg-white flex h-10 mr-2 mt-2 rounded-md border border-gray-200">
                          <Dropdown
                            width="w-full"
                            options={currencies}
                            selected={formData.currencyType}
                            onSelect={(val) => {
                              setFormData({
                                ...formData,
                                currencyType: val,
                              });
                            }}
                            label="Salary"
                          />
                        </div>
                        <label className="mb-4">
                          <input
                            type="text"
                            name="minCTC"
                            value={formatWithCommas(formData.minCTC)}
                            onChange={handleChange}
                            className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                            placeholder="Enter min CTC"
                          />
                          {errors.minCTC && !formData.minCTC && (
                            <p className="text-red-500 text-sm mt-1">
                              {errors.minCTC}
                            </p>
                          )}
                        </label>
                        <label className="mb-4">
                          <input
                            type="text"
                            name="maxCTC"
                            value={formatWithCommas(formData.maxCTC)}
                            onChange={handleChange}
                            min={formData.minCTC}
                            className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                            placeholder="Enter max CTC"
                          />
                          {errors.maxCTC && !formData.maxCTC && (
                            <p className="text-red-500 text-sm mt-1">
                              {errors.maxCTC}
                            </p>
                          )}
                        </label>
                        <div className="filters m-auto w-[9rem] bg-white flex h-10 mr-2 mt-2 rounded-md border border-gray-200">
                          <Dropdown
                            width="w-full"
                            options={[
                              "Per Hour",
                              "Per Week",
                              "Per Month",
                              "Per Annum",
                            ]}
                            selected={formData.salaryFrequency}
                            onSelect={(val) => {
                              setFormData({
                                ...formData,
                                salaryFrequency: val,
                              });
                            }}
                            label="Salary"
                          />
                        </div>
                      </div>
                      {/* <div className="mt-4 mx-4">
                        <div className="flex justify-between text-base mt-1 text-blue-500 font-semibold">
                          <span>{formData.minCTC} PA</span>
                          <span>{formData.maxCTC} PA</span>
                        </div>
                        <CustomSlider
                          value={[formData.minCTC, formData.maxCTC]}
                          onChange={(newVal) => {
                            console.log("newVal==", newVal);
                            setFormData({
                              ...formData,
                              maxCTC: newVal[1],
                              minCTC: newVal[0],
                            });
                          }}
                          aria-labelledby="expected-salary-slider"
                          valueLabelDisplay="auto"
                          step={10000}
                          min={0}
                          max={500000}
                        />
                      </div> */}
                    </div>
                  </div>
                </AccordionDetails>
              </div>

              <div className="border bg-white p-2 rounded-lg shadow-lg my-4 mb-8">
                <AccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  {/* <h1 className="text-lg font-bold">Skill Details </h1> */}
                </AccordionSummary>
                <AccordionDetails className="p-4">
                  <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
                    <div>
                      <span className="block mb-2 font-medium">
                        Primary Skills
                      </span>
                      <div className="flex flex-col md:flex-row gap-2 mb-2">
                        <Select
                          placeholder="Search Skills"
                          name="primarySkillText"
                          options={state.primarySkillsOptions}
                          value={
                            formData.primarySkillText
                              ? {
                                  value: formData.primarySkillText,
                                  label: formData.primarySkillText,
                                }
                              : null
                          }
                          onChange={handlePrimarySkillChange}
                          className="w-full rounded-md focus:outline-none focus:border-blue-500"
                          onInputChange={(inputValue, actionMeta) => {
                            // console.log("inputValue=", inputValue, actionMeta);
                            if (actionMeta.action === "input-change") {
                              setFormData((prevData) => ({
                                ...prevData,
                                primarySkillText: inputValue,
                              }));
                              fetchPrimarySkills(inputValue);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent default browser behavior
                              if (
                                formData.primarySkillText &&
                                formData.primarySkillExperience
                              ) {
                                addPrimarySkillOnEnter();
                              } else {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  primarySkills:
                                    "Please enter a skill and experience",
                                }));
                              }
                              // Call the function to add the skill
                            }
                          }}
                        />
                        <input
                          type="number"
                          name="primarySkillExperience"
                          value={formData.primarySkillExperience}
                          onChange={handleChange}
                          placeholder="Exp"
                          className="w-20 border rounded-md p-2 focus:outline-none focus:border-blue-500"
                          min="0"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent default browser behavior
                              addPrimarySkillOnEnter(); // Call the function to add the skill
                            }
                          }}
                        />
                      </div>
                      {errors.primarySkills &&
                        (!formData.primarySkillText ||
                          !formData.primarySkillExperience) && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.primarySkills}
                          </p>
                        )}
                      <button
                        onClick={addPrimarySkill}
                        className="border m-auto bg-blue-50 text-blue-500 px-2 rounded-full flex items-center"
                      >
                        <IoMdAddCircleOutline className="mr-1" /> Skill
                      </button>
                      <ul className="space-y-2 max-h-60 overflow-y-auto">
                        {formData.primarySkills.map((skill, index) => (
                          <li
                            key={index}
                            className="flex items-center bg-[#F8F8F8] justify-between px-3 py-2 rounded"
                          >
                            <span className="w-3/4">
                              {jobId
                                ? skill?.Skill?.skillName
                                : skill.skillName}
                            </span>
                            <span className="w-1/6 text-center">
                              {jobId ? skill.yearExpRequired : skill.exp}
                            </span>
                            <button
                              type="button"
                              onClick={() => deletePrimarySkill(index)}
                              className="text-red-500 text-xl"
                            >
                              <MdDelete />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div>
                      <span className="block mb-2 font-medium">
                        Secondary Skills
                      </span>
                      <div className="flex flex-col md:flex-row gap-2 mb-2">
                        <Select
                          placeholder="Search Skills"
                          name="secondarySkillText"
                          options={state.secondarySkillsOptions}
                          value={
                            formData.secondarySkillText
                              ? {
                                  value: formData.secondarySkillText,
                                  label: formData.secondarySkillText,
                                }
                              : null
                          }
                          onChange={handleSecondarySkillChange}
                          className="w-full rounded-md focus:outline-none focus:border-blue-500"
                          onInputChange={(inputValue, actionMeta) => {
                            if (actionMeta.action === "input-change") {
                              setFormData((prevData) => ({
                                ...prevData,
                                secondarySkillText: inputValue,
                              }));
                              fetchSecondarySkills(inputValue);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent default browser behavior
                              if (
                                formData.secondarySkillText &&
                                formData.secondarySkillExperience
                              ) {
                                addSecondarySkillOnEnter();
                              } else {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  secondarySkills:
                                    "Please enter a skill and experience",
                                }));
                              }
                              // Call the function to add the skill
                            }
                          }}
                        />
                        <input
                          type="number"
                          name="secondarySkillExperience"
                          value={formData.secondarySkillExperience}
                          min="0"
                          onChange={handleChange}
                          className="w-20 border rounded-md p-2 focus:outline-none focus:border-blue-500"
                          placeholder="Exp"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent default browser behavior
                              addSecondarySkillOnEnter(); // Call the function to add the skill
                            }
                          }}
                        />
                      </div>
                      {errors.secondarySkills &&
                        (!formData.secondarySkillText ||
                          !formData.secondarySkillExperience) && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.secondarySkills}
                          </p>
                        )}
                      <button
                        onClick={addSecondarySkill}
                        className="border m-auto bg-blue-50 text-blue-500 px-2 rounded-full flex items-center"
                      >
                        <IoMdAddCircleOutline className="mr-1 bg-blue-50 text-blue-500" />{" "}
                        Skill
                      </button>
                      <ul className="space-y-2 max-h-60 overflow-y-auto">
                        {formData.secondarySkills.map((skill, index) => (
                          <li
                            key={index}
                            className="flex items-center bg-[#F8F8F8] justify-between px-3 py-2 rounded"
                          >
                            <span className="w-3/4">
                              {jobId
                                ? skill?.Skill?.skillName
                                : skill.skillName}
                            </span>
                            <span className="w-1/6 text-center">
                              {jobId ? skill.yearExpRequired : skill.exp}
                            </span>
                            <button
                              type="button"
                              onClick={() => deleteSecondarySkill(index)}
                              className="text-red-500 text-xl"
                            >
                              <MdDelete />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="col-span-2">
                      <div className="grid grid-cols-1 gap-4">
                        <label className="block">
                          {/* <span className="text-gray-700 font-medium">
                            Software Proficiency
                          </span>
                          <Select
                            placeholder="search your siftware Proficiency..."
                            name="softwareProficiency"
                            options={state.softwareOptions}
                            value={formData.softwareProficiency
                              ? { value: formData.softwareProficiency, label: formData.softwareProficiency }
                              : null}
                            // onChange={handleSoftwareProficiencyChange}
                            className="w-full rounded-md focus:outline-none focus:border-blue-500"
                            onInputChange={(inputValue, actionMeta) => {
                              console.log(
                                "inputValue=",
                                inputValue,
                                actionMeta
                              );
                              if (actionMeta.action === "input-change") {
                                setFormData((prevData) => ({
                                  ...prevData,
                                  softwareProficiency: inputValue,
                                }));
                                fetchSoftwares(inputValue);
                              }
                            }}
                          /> */}

                          <div className="p-2 my-4 w-full">
                            <span className="block mb-2 font-medium">
                              Software Proficiency
                            </span>
                            <div className="flex md:flex-row gap-2 mb-2">
                              <Select
                                placeholder="Search Softwares"
                                name="softwareSkillText"
                                options={state.softwareOptions}
                                value={
                                  formData.softwareSkillText
                                    ? {
                                        value: formData.softwareSkillText,
                                        label: formData.softwareSkillText,
                                      }
                                    : null
                                }
                                onChange={(selectedOption) => {
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    softwareSkillText:
                                      selectedOption?.value || "",
                                  }));
                                }}
                                className="w-full rounded-md focus:outline-none focus:border-blue-500"
                                onInputChange={(inputValue, actionMeta) => {
                                  if (actionMeta.action === "input-change") {
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      softwareSkillText: inputValue,
                                    }));
                                    fetchSoftwares(inputValue); // Fetch options dynamically
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    if (
                                      formData.softwareSkillText &&
                                      formData.softwareSkillLevel
                                    ) {
                                      addSoftwareSkill();
                                    } // Call the function to add the skill
                                  }
                                }}
                              />
                              <select
                                name="softwareSkillLevel"
                                value={formData.softwareSkillLevel}
                                onChange={(e) => {
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    softwareSkillLevel: e.target.value,
                                  }));
                                }}
                                className="border rounded-md p-2 focus:outline-none focus:border-blue-500"
                              >
                                <option value="Beginner">Beginner</option>
                                <option value="Intermediate">
                                  Intermediate
                                </option>
                                <option value="Expert">Expert</option>
                              </select>
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                if (
                                  formData.softwareSkillText &&
                                  formData.softwareSkillLevel
                                ) {
                                  addSoftwareSkill();
                                } else {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    softwareSkills:
                                      "Please enter a skill and level",
                                  }));
                                }
                              }}
                              className="border mb-2 m-auto bg-blue-50 text-blue-500 px-2 rounded-full flex items-center"
                            >
                              <IoMdAddCircleOutline className="mr-1 bg-blue-50 text-blue-500" />{" "}
                              Add Software
                            </button>
                            <ul className="space-y-2 max-h-60 overflow-y-auto">
                              {formData.softwareSkills.map((skill, index) => (
                                <li
                                  key={index}
                                  className="flex items-center bg-[#F8F8F8] justify-between px-3 py-2 rounded"
                                >
                                  <span className="w-3/4">{skill.name}</span>
                                  <span className="w-1/6 text-center">
                                    {skill.level}
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() => removeSoftwareSkill(index)}
                                    className="text-red-500 text-xl"
                                  >
                                    <MdDelete />
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </label>
                        <div className="grid grid-cols-2 gap-4">
                          <label className="block">
                            <span className="text-gray-700 font-medium">
                              Talent Past Industry Experience
                            </span>
                            <input
                              type="text"
                              placeholder="Add Industry"
                              name="industryKnowledge"
                              value={formData.industryKnowledge}
                              onChange={handleChange}
                              maxLength="200"
                              className="w-full border rounded-md p-2 focus:outline-none focus:border-blue-500"
                            />
                            {/* <Select
                              placeholder="search your industry Knowledge...."
                              name="industryKnowledge"
                              options={state.industryOptions}
                              value={
                                formData.industryKnowledge
                                  ? {
                                      value: formData.industryKnowledge,
                                      label: formData.industryKnowledge,
                                    }
                                  : null
                              }
                              onChange={handleIndustryKnowledgeChange}
                              className="w-full rounded-md focus:outline-none focus:border-blue-500"
                              onInputChange={(inputValue, actionMeta) => {
                                console.log(
                                  "inputValue=",
                                  inputValue,
                                  actionMeta
                                );
                                if (actionMeta.action === "input-change") {
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    industryKnowledge: inputValue,
                                  }));
                                  fetchIndustries(inputValue);
                                }
                              }}
                            /> */}

                            {/* <select
                              name="industryKnowledge"
                              value={formData.industryKnowledge}
                              onChange={handleChange}
                              className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                            >
                              <option value="Basic (0-1 year)">
                                Basic (0-1 year)
                              </option>
                              <option value="Intermediate (1-3 years)">
                                Intermediate (1-3 years)
                              </option>
                              <option value="Advanced (3-5 years)">
                                Advanced (3-5 years)
                              </option>
                              <option value="Expert (5+ years)">
                                Expert (5+ years)
                              </option>
                            </select> */}
                          </label>
                          <label className="block">
                            {/* <span className="text-gray-700 font-medium">
                              Certification
                            </span>

                            <Select
                              placeholder="search your certifications..."
                              name="relevantCertification"
                              options={state.certificationOptions}
                              value={formData.relevantCertification
                                ? { value: formData.relevantCertification, label: formData.relevantCertification }
                                : null}
                              onChange={handleRelevantCertificationChange}
                              className="w-full rounded-md focus:outline-none focus:border-blue-500"
                              onInputChange={(inputValue, actionMeta) => {
                                console.log(
                                  "inputValue=",
                                  inputValue,
                                  actionMeta
                                );
                                if (actionMeta.action === "input-change") {
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    relevantCertification: inputValue,
                                  }));
                                  fetchCertifications(inputValue);
                                }
                              }}
                            /> */}
                            <div className="">
                              <span className="text-gray-700 font-medium">
                                Certifications
                              </span>
                              <div className="flex flex-col gap-2">
                                <div className="flex flex-col md:flex-row gap-2 mb-2">
                                  <Select
                                    placeholder="Search Certificates"
                                    name="certificationText"
                                    options={state.certificationOptions}
                                    value={
                                      formData.certificationText
                                        ? {
                                            value: formData.certificationText,
                                            label: formData.certificationText,
                                          }
                                        : null
                                    }
                                    onChange={(selectedOption) => {
                                      setFormData((prevData) => ({
                                        ...prevData,
                                        certificationText:
                                          selectedOption?.value || "",
                                      }));
                                    }}
                                    className="w-full rounded-md focus:outline-none focus:border-blue-500"
                                    onInputChange={(inputValue, actionMeta) => {
                                      if (
                                        actionMeta.action === "input-change"
                                      ) {
                                        setFormData((prevData) => ({
                                          ...prevData,
                                          certificationText: inputValue,
                                        }));
                                        fetchCertifications(inputValue); // Fetch options dynamically
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                        if (formData.certificationText) {
                                          addCertification();
                                        } // Call the function to add the skill
                                      }
                                    }}
                                  />
                                </div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (formData.certificationText) {
                                      addCertification();
                                    }
                                  }}
                                  className="border m-auto bg-blue-50 text-blue-500 px-2 rounded-full flex items-center"
                                >
                                  <IoMdAddCircleOutline className="mr-1" /> Add
                                  Certification
                                </button>
                                <ul className="space-y-2 max-h-60 overflow-y-auto mt-2">
                                  {formData.certifications.map(
                                    (cert, index) => (
                                      <li
                                        key={index}
                                        className="flex items-center bg-[#F8F8F8] justify-between px-3 py-2 rounded"
                                      >
                                        <span className="w-1/2">
                                          {cert.name}
                                        </span>
                                        <span className="w-1/3 text-center">
                                          {cert.issuer}
                                        </span>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            removeCertification(index)
                                          }
                                          className="text-red-500 text-xl"
                                        >
                                          <MdDelete />
                                        </button>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>

                            {/* <div className="">
                              <span className="text-gray-700 font-medium">
                                Certifications
                              </span>
                              {formData.certifications.map((cert, index) => (
                                <div
                                  key={index}
                                  className="flex items-center mb-2"
                                >
                                  <input
                                    type="text"
                                    value={cert.name}
                                    onChange={(e) =>
                                      handleCertificationChange(
                                        index,
                                        "name",
                                        e.target.value
                                      )
                                    }
                                    className="border rounded-md w-full p-2 mr-2"
                                    placeholder="Certification name"
                                  />
                                  {formData.certifications.length > 1 && (
                                    <button
                                      type="button"
                                      onClick={() => removeCertification(index)}
                                      className="text-red-500"
                                    >
                                      <Delete />
                                    </button>
                                  )}
                                </div>
                              ))}
                              <button
                                type="button"
                                onClick={addCertification}
                                className="border m-auto bg-blue-50 text-blue-500 px-2 rounded-full flex items-center"
                              >
                                <IoMdAddCircleOutline className="mr-1" />{" "}
                                Certification
                              </button>
                            </div> */}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="flex justify-center min-h-screen pb-20">
            <div className="w-full md:w-2/3">
              <Box mb={4}>
                <Stepper activeStep={2} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              <div className="border bg-white p-2 rounded-lg shadow-lg">
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {/* <h1 className="text-lg font-bold">
                    Project and Team Details
                  </h1> */}
                </AccordionSummary>
                <AccordionDetails className="p-4">
                  <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
                    <div className="lg:col-span-2 grid gap-4 grid-cols-1 sm:grid-cols-2">
                      <label className="block">
                        <span className="text-gray-700">
                          What's your Project Team Size
                        </span>
                        <input
                          type="number"
                          name="projectTeamSize"
                          value={formData.projectTeamSize}
                          onChange={handleChange}
                          min="1"
                          className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                        />
                        {errors.projectTeamSize &&
                          !formData.projectTeamSize && (
                            <p className="text-red-500 text-sm mt-1">
                              {errors.projectTeamSize}
                            </p>
                          )}
                      </label>
                      <label className="block">
                        <span className="text-gray-700">
                          Job Seniority Level{" "}
                          <span className="text-red-500">*</span>
                        </span>
                        <select
                          name="jobLevel"
                          value={formData.jobLevel}
                          onChange={handleChange}
                          className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                        >
                          {/* <option value="">Choose the Level of the Job</option> */}
                          <option value="Internship">Internship</option>
                          <option value="Entry-Level">Entry-Level</option>
                          <option value="Associate">Associate</option>
                          <option value="Mid-Senior Level">
                            Mid-Senior Level
                          </option>
                          <option value="Director">Director</option>
                          <option value="Executive">Executive</option>
                          <option value="Not-Applicable">Not-Applicable</option>
                        </select>
                        {errors.jobLevel && !formData.jobLevel && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.jobLevel}
                          </p>
                        )}
                      </label>
                      <label className="sm:col-span-1">
                        <span className="text-gray-700">
                          Choose your team structure
                        </span>
                        {/* <select
                          name="teamStructure"
                          value={formData.teamStructure}
                          onChange={handleChange}
                          className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                        >
                          <option value="">Choose the Level of the Job</option>
                          <option value="Hierarchial">Hierarchial</option>
                          <option value="Flat">Flat</option>
                          <option value="Matrix">Matrix</option>
                          <option value="Functional">Functional</option>
                          <option value="Cross-Functional">
                            Cross-Functional
                          </option>
                          <option value="Others">Others</option>
                          <option value="Not-Applicable">Not-Applicable</option>
                        </select> */}
                        <Select
                          options={TeamStructureOptions}
                          value={TeamStructureOptions.find(
                            (option) => option.value === formData.teamStructure
                          )}
                          onChange={handleTeamStructChange}
                          placeholder="select team structure"
                          components={{ Option: CustomEngagementOption }}
                          menuPortalTarget={document.body} // Render dropdown outside parent container
                          menuPosition="fixed"
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          className="mt-2"
                          classNamePrefix="react-select"
                        />
                        {/* <input
                          type="text"
                          name="teamStructure"
                          placeholder="Eg, Hierarchical"
                          value={formData.teamStructure}
                          onChange={handleChange}
                          className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                        /> */}

                        {/* {errors. && <p className="text-red-500 text-sm mt-1">{errors.}</p>} */}
                      </label>
                      <div className="flex flex-col">
                        <label className="">
                          <span className="text-gray-700">
                            How many open positions are you hiring for ?
                          </span>
                          <input
                            type="number"
                            name="jobNumbers"
                            placeholder="Type here"
                            value={formData.jobNumbers}
                            onChange={handleChange}
                            min="0"
                            className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                          />
                        </label>
                        {errors.jobNumbers && !formData.jobNumbers && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.jobNumbers}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full my-4">
                    <label className="col-span-1">
                      <span className="text-gray-700">
                        What are the main roles and responsibilities for this
                        role
                      </span>
                    </label>
                    <RichTextArea
                      value={formData.roleAndResponsibilites}
                      onChange={handleChange}
                      placeholder="Eg, Is responsible for designing, developing, and maintaining server-side logic.."
                    />
                    {errors.roleAndResponsibilites &&
                      !formData.roleAndResponsibilites && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.roleAndResponsibilites}
                        </p>
                      )}

                    <label className="col-span-1 mt-4">
                      <span className="text-gray-700">Screening Questions</span>
                    </label>
                    <textarea
                      placeholder="Suggest mandatory checklist for the recruiter to screen talent before referring"
                      name="screeningQuestion"
                      value={formData.screeningQuestion}
                      onChange={handleChange}
                      className="w-full border rounded-md h-48 mt-2 p-2 focus:outline-none focus:border-blue-500"
                    />
                  </div>
                </AccordionDetails>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="flex  justify-center min-h-screen pb-20">
            <div className="w-full  md:w-2/3">
              <Box mb={4}>
                <Stepper activeStep={3} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              <div className="border bg-white p-2 rounded-lg shadow-lg">
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {/* <h1 className="text-lg font-bold">Additional Information</h1> */}
                </AccordionSummary>
                <AccordionDetails className="p-4">
                  <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                    <label className="w-full">
                      <span className="text-gray-700">
                        Interview Rounds and Duration (in minutes){" "}
                        <span className="text-red-500">*</span>{" "}
                      </span>
                      <div className="flex flex-col gap-2 my-2">
                        <div className="flex gap-2 items-center justify-center">
                          <Select
                            placeholder="Add Interview Rounds"
                            name="interviewName"
                            options={state.interviewRoundOptions}
                            value={
                              formData.interviewName
                                ? {
                                    value: formData.interviewName,
                                    label: formData.interviewName,
                                  }
                                : null
                            }
                            onChange={handleInterviewChange}
                            className="w-full rounded-md focus:outline-none focus:border-blue-500"
                            onInputChange={(inputValue, actionMeta) => {
                              if (actionMeta.action === "input-change") {
                                setFormData((prevData) => ({
                                  ...prevData,
                                  interviewName: inputValue,
                                }));
                                fetchInterviewRounds(inputValue);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault(); // Prevent default browser behavior
                                if (
                                  formData.interviewName &&
                                  formData.roundDuration
                                ) {
                                  addInterviewRoundOnEnter();
                                } else {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    interviewRounds:
                                      "Please enter round name and duration",
                                  }));
                                }
                                // Call the function to add the skill
                              }
                            }}
                          />
                          {/* <input
                            type="number"
                            name="roundDuration"
                            value={formData.roundDuration}
                            onChange={handleChange}
                            placeholder="minutes"
                            className="w-20 border-2 rounded-md py-2 px-1 focus:outline-none focus:border-blue-500"
                            min="0"
                            
                          /> */}

                          
                           <select
                            name="roundDuration"
                            value={formData.roundDuration}
                            onChange={handleChange}
                            className="w-20 border-2 rounded-md py-2 px-1 focus:outline-none focus:border-blue-500"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault(); // Prevent default browser behavior
                                addInterviewRoundOnEnter();
                                }
                                // Call the function to add the skill
                              }
                            }
                          >
                            {/* <option value="">Choose the Incentive Type</option> */}
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="45">45</option>
                            <option value="60">60</option>
                          </select>
                         
                          
                        </div>
                        {((errors.interviewRounds &&
                          (!formData.interviewName ||
                            !formData.roundDuration)) ||
                          formData.interviewRounds?.length === 0) && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.interviewRounds}
                          </p>
                        )}

                        {/* <input
                          type="text"
                          name="interviewName"
                          placeholder="Interview Round Name..."
                          value={formData.interviewName}
                          onChange={handleChange}
                          min="1"
                          className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                        /> */}
                      </div>
                      <button
                        onClick={addInterviewRound}
                        className="bg-blue-50  text-blue-500 my-2 m-auto px-2 p-1 rounded-full flex items-center gap-2"
                      >
                        <IoMdAddCircleOutline className="rounded-lg h-full" />{" "}
                        Round
                      </button>
                      <div className="my-2">
                        <ul className="space-y-2 max-h-60 overflow-y-auto">
                          {formData?.interviewRounds?.map((intName, index) => (
                            <li
                              key={index}
                              className="flex items-center bg-[#F8F8F8] justify-between px-3 py-2 rounded"
                            >
                              <span className="w-1/12 text-center">
                                {jobId ? intName.roundNumber : index + 1}.
                              </span>
                              <span className="w-3/4">
                                {jobId ? intName?.roundName : intName?.name}
                              </span>
                              <span className="w-1/4">
                                {jobId
                                  ? `${intName?.roundDuration} minutes`
                                  : `${intName?.roundDuration} minutes`}
                              </span>
                              <button
                                type="button"
                                onClick={() => deleteInterviewRound(index)}
                                className="text-red-500 text-xl"
                              >
                                <MdDelete />
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </label>

                    <label className="block">
                      <span className="text-gray-700">
                        Official Notice Period
                        <span className="text-red-500">*</span>
                      </span>
                      <div className="flex flex-col gap-2 my-2">
                        <Select
                          options={NoticePeriodOptions}
                          value={NoticePeriodOptions.find(
                            (option) =>
                              option.value === formData.officialNoticePeriod
                          )}
                          onChange={handleNoticePeriodChange}
                          className="w-full rounded-md focus:outline-none focus:border-blue-500"
                          placeholder="Choose Official Notice Period"
                        />
                        {errors.officialNoticePeriod &&
                          !formData.officialNoticePeriod && (
                            <p className="text-red-500 text-sm mt-1">
                              {errors.officialNoticePeriod}
                            </p>
                          )}
                      </div>
                    </label>

                    <label className="col-span-2">
                      <span className="text-gray-700">
                        Describe the Ideal Candidate in 1 line
                      </span>
                      <input
                        type="text"
                        placeholder="Eg, Backend Developer, worked on scalable products with Java... (Max 150 Chars)"
                        name="describeCandidate"
                        value={formData.describeCandidate}
                        onChange={handleChange}
                        maxLength="200"
                        className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                      />
                    </label>
                    <div className="col-span-2">
                      <div className="grid grid-cols-3 gap-4">
                        <label className="block">
                          <span className="text-gray-700">
                            Incentive Value{" "}
                            <span className="text-red-500">*</span>
                          </span>
                          <input
                            type="number"
                            name="recruiterIncentiveValue"
                            value={formData.recruiterIncentiveValue}
                            onChange={handleChange}
                            min="0"
                            className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                          />
                          {errors.recruiterIncentiveValue &&
                            !formData.recruiterIncentiveValue && (
                              <p className="text-red-500 text-sm mt-1">
                                {errors.recruiterIncentiveValue}
                              </p>
                            )}
                        </label>

                        <label className="block">
                          <span className="text-gray-700">
                            Incentive Type{" "}
                            <span className="text-red-500">*</span>
                          </span>
                          <select
                            name="recruiterIncentiveType"
                            value={formData.recruiterIncentiveType}
                            onChange={handleChange}
                            className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                          >
                            {/* <option value="">Choose the Incentive Type</option> */}
                            <option value="percent">Percent</option>
                            <option value="fixed">Fixed</option>
                          </select>
                          {errors.recruiterIncentiveType &&
                            !formData.recruiterIncentiveType && (
                              <p className="text-red-500 text-sm mt-1">
                                {errors.recruiterIncentiveType}
                              </p>
                            )}
                        </label>

                        <label className="block">
                          <span className="text-gray-700">
                            Incentive period{" "}
                            <span className="text-red-500">*</span>
                          </span>
                          <input
                            type="number"
                            name="incentivePeriod"
                            placeholder="in days"
                            value={formData.incentivePeriod}
                            onChange={handleChange}
                            min="0"
                            className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                          />
                          {errors.incentivePeriod &&
                            !formData.incentivePeriod && (
                              <p className="text-red-500 text-sm mt-1">
                                {errors.incentivePeriod}
                              </p>
                            )}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <InterviewScheduling
                      jobId={jobId}
                      formData={formData}
                      setFormData={setFormData}
                    />
                    {/* <h3 className="text-lg font-semibold mb-4">
                      Availability Slots
                    </h3> */}

                    {/* {formData.availabilitySlots &&
                    formData.availabilitySlots.length > 0 ? (
                      <div className="bg-gray-100 p-4 rounded-lg mb-4 overflow-x-auto">
                        <table className="w-full">
                          <thead>
                            <tr className="bg-gray-200">
                              <th className="px-4 py-2 text-left">Day</th>
                              <th className="px-4 py-2 text-left">Time</th>
                              <th className="px-4 py-2 text-left">Duration</th>
                              <th className="px-4 py-2 text-left">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData.availabilitySlots.map((slot, index) => (
                              <tr key={index} className="bg-white border-b">
                                <td className="px-4 py-2">{slot.day}</td>
                                <td className="px-4 py-2">{slot.timeFrom}</td>
                                <td className="px-4 py-2">
                                  {slot.duration} hour(s)
                                </td>
                                <td className="px-4 py-2">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      removeAvailabilitySlot(index)
                                    }
                                    className="text-red-500 hover:text-red-700"
                                  >
                                    <MdDelete size={20} />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p className="text-gray-500 italic mb-4">
                        No availability slots added yet.
                      </p>
                    )} */}
                    {/* <div className="flex justify-start mt-4">
                      <button
                        type="button"
                        onClick={() => updateState("showPopup", true)}
                        className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 transition duration-300 flex items-center"
                      >
                        <IoMdAddCircleOutline className="mr-2" />
                        {formData.availabilitySlots.length > 0
                          ? "Edit Availability Slots"
                          : "Add Availability Slots"}
                      </button>
                    </div> */}
                  </div>
                  {errors.availabilitySlots && !formData.availabilitySlots && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.availabilitySlots}
                    </p>
                  )}
                </AccordionDetails>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  //when ever checkdiv size is changed, this function will be called

  return (
    <Layout showFooter={false}>
      {state.loading && <SyncLoader />}
      <div className="container mx-auto z-10 mt-14">
        <form onSubmit={(e) => handleSubmit(e)} className="mx-9 w-6/7">
          {renderFormStep()}

          {/* Navigation buttons */}
          {/* <div className="flex justify-between mx-auto mt-8">
            <div
              className={`
    ${sidebar.sidebarState ? "w-[77%]" : "w-[94%]"}
    fixed bottom-0 right-0 bg-white py-3 px-6 flex justify-between items-center shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] z-10
    transition-all duration-300
  `}
            > */}
          <div className="flex justify-center fixed bottom-0 right-0 w-full p-2 ml-20 lg:pl-40 bg-white border-t-2 border-gray-200">
            <div className="w-full flex justify-between gap-2 lg:px-28">
              <div>
                {state.currentStep > 0 && (
                  <button
                    type="button"
                    onClick={handlePrev}
                    className="bg-blue-500 text-white rounded-full py-2 px-6 focus:outline-none focus:border-blue-500"
                  >
                    Previous
                  </button>
                )}
              </div>

              <div className="flex space-x-4">
                <button
                  type="submit"
                  onClick={() => updateFormState("jobStatus", "Draft")}
                  className="bg-gray-500 text-white rounded-full py-2 px-6 focus:outline-none focus:border-gray-500"
                >
                  {jobId ? "Save" : "Save as draft"}
                </button>

                {state.currentStep < 3 && (
                  <button
                    type="button"
                    onClick={handleNext}
                    className="bg-blue-500 text-white rounded-full py-2 px-6 focus:outline-none focus:border-blue-500"
                  >
                    Next
                  </button>
                )}
                {state.currentStep > 2 && (
                  <button
                    type="submit"
                    disabled={submitLoading}
                    onClick={() => updateFormState("jobStatus", "Active")}
                    className={`bg-green-500 text-white rounded-full py-2 px-6 focus:outline-none focus:border-green-500`}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* Popup for availability slots */}

      {/* {state.showPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg relative">
            <FaX
              className="absolute top-2 right-2 text-gray-500 cursor-pointer"
              onClick={() => updateState("showPopup", false)}
            />
            <h2 className="text-lg font-semibold mb-4">
              {state.showTableHeader
                ? `Availability Slots (${formData.availabilitySlots.length})`
                : "No Availability Slots Added"}
            </h2>
            <table>
              {state.showTableHeader && (
                <thead>
                  <tr className="bg-gray-200 border-b">
                    <th className="px-4 py-2 text-left">Day</th>
                    <th className="px-4 py-2 text-left">Time</th>
                    <th className="px-4 py-2 text-left">Duration</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {formData.availabilitySlots.map((slot, index) => (
                  <tr key={index}>
                    <td>
                      <select
                        value={slot.day}
                        onChange={(e) =>
                          handleSlotChange(index, "day", e.target.value)
                        }
                        className="border w-24 rounded-md py-[0.9vh] mx-2 focus:outline-none focus:border-blue-500"
                      >
                        <option value="">Select Day</option>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                        <option value="Sunday">Sunday</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="time"
                        value={slot.timeFrom}
                        onChange={(e) =>
                          handleSlotChange(index, "timeFrom", e.target.value)
                        }
                        className="border rounded-md p-1 mx-2 focus:outline-none focus:border-blue-500"
                      />
                    </td>
                    <td>
                      <select
                        value={slot.duration}
                        onChange={(e) =>
                          handleSlotChange(index, "duration", e.target.value)
                        }
                        className="border w-24 rounded-md py-[0.9vh] mx-2 focus:outline-none focus:border-blue-500"
                      >
                        <option selected>Duration</option>
                        <option value="0.5">30 mins</option>
                        <option value="1">1 hrs</option>
                        <option value="1.5">1.5 hrs</option>
                        <option value="2">2 hrs</option>
                        <option value="2.5">2.5 hrs</option>
                        <option value="3">3 hrs</option>
                        <option value="3.5">3.5 hrs</option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {formData.availabilitySlots.length < 5 && (
              <button
                onClick={addAvailabilitySlot}
                className="bg-blue-500 text-white px-5 py-1 mt-3 rounded-full"
              >
                Add Slot
              </button>
            )}
            <button
              onClick={() => updateState("showPopup", false)}
              className="bg-green-500 text-white px-5 py-1 ml-4  mt-3 rounded-full"
            >
              Save
            </button>
          </div>
        </div>
      )} */}
    </Layout>
  );
};

export default CreateJobs;
