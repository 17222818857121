import React from 'react'
import GetMeetLinkModal from '../Component/GetMeetLinkModal'
import RescheduleEvent from '../Component/RescheduleEventModal'
import { Close } from "@mui/icons-material";

function convertToISTAndReturnDate(utcDateString) {
    const date = new Date(utcDateString);
  
    // Convert date to IST timezone string and extract only the date part
    const istDateString = date.toLocaleDateString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
  
    return istDateString; // Returns the date as a string in 'dd/mm/yyyy' format
  }

  const convertToISTAndTime = (utcDateString) => {
    // Create a Date object from the UTC date string
    const date = new Date(utcDateString);
  
    // Convert the date to IST using the 'en-IN' locale and specify time zone as 'Asia/Kolkata'
    const options = { 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: true,  // To return AM/PM
    };
  
    // Convert and return the formatted time as AM/PM in IST
    const istTime = date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', ...options });
    return istTime;
  };
const InterviewPageTab = ({interview,setClickedCard,setShowSlider,showSlider,index,data,calculateEndTime,getStatusColor}) => {

  const getTimeFromForRound1 = (slots) => {
    return slots
      .filter(slot => slot.roundDetails.roundNumber === 1)  // Filter for roundNumber 1
      .map(slot => slot.selectedAvailabilitySlotDetails?.timeFrom);  // Extract `timeFrom`
  };

  const getEndFromForRound1 = (slots) => {
    return slots
      .filter(slot => slot.roundDetails.roundNumber === 1)  // Filter for roundNumber 1
      .map(slot => slot.selectedAvailabilitySlotDetails?.endTime);  // Extract `timeFrom`
  };

  const getRoundNameForRound1 = (slots) => {
    // Filter slots where roundNumber is 1 and extract the roundName
    return slots
      .filter(slot => slot.roundDetails.roundNumber === 1)  // Filter for roundNumber 1
      .map(slot => slot.roundDetails.roundName);  // Extract roundName
  };

  return (
    <div
    key={interview.talentDetails}
    className={`shadow-md border-none h-full rounded-lg p-4 mt-2 bg-white ${
      interview?.status === "Completed"
        ? "#FAFAFA"
        : "#FFFFFF"
    }`}
    onClick={() => setClickedCard(data[index])}
  >
    <div className="flex justify-between items-start border-none w-full">
      <div className="w-full">
        <div
          onClick={() => setShowSlider(!showSlider)}
          className="flex w-full items-center justify-between gap-2 mb-2"
        >
          <p
            className="font-bold text-[18px]"
          >
            {interview?.talentDetails.talentName}
          </p>
          <div
            label={interview?.talentDetails.recruiterTalent}
            className={`text-base flex gap-2 text-[10px] rounded-2xl items-center justify-center p px-3 ${
              interview?.talentDetails.recruiterTalent === true
                ? "text-[#EF865C] border border-[#EF865C]"
                : "text-[#DFBE00] border border-[#DFBE00]"
            }`}
          >
            {interview?.talentDetails.recruiterTalent === true ? (
              <svg
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 9.83333C4 9.83333 8.66667 10.5 10.6667 12.5H11.3333C11.7015 12.5 12 12.2015 12 11.8333V7.79133C12.5751 7.64333 13 7.12127 13 6.5C13 5.87873 12.5751 5.35667 12 5.20867V1.16667C12 0.79848 11.7015 0.5 11.3333 0.5H10.6667C8.66667 2.5 4 3.16667 4 3.16667H1.33333C0.596953 3.16667 0 3.76362 0 4.5V8.5C0 9.2364 0.596953 9.83333 1.33333 9.83333H2L2.66667 13.1667H4V9.83333ZM5.33333 4.27413C5.78887 4.1764 6.35167 4.04129 6.95953 3.86249C8.07833 3.53343 9.5 3.01508 10.6667 2.21639V10.7836C9.5 9.98493 8.07833 9.4666 6.95953 9.13753C6.35167 8.95873 5.78887 8.8236 5.33333 8.72587V4.27413ZM1.33333 4.5H4V8.5H1.33333V4.5Z"
                  fill={`#EF865C`}
                />
              </svg>
            ) : (
              <svg
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 9.83333C4 9.83333 8.66667 10.5 10.6667 12.5H11.3333C11.7015 12.5 12 12.2015 12 11.8333V7.79133C12.5751 7.64333 13 7.12127 13 6.5C13 5.87873 12.5751 5.35667 12 5.20867V1.16667C12 0.79848 11.7015 0.5 11.3333 0.5H10.6667C8.66667 2.5 4 3.16667 4 3.16667H1.33333C0.596953 3.16667 0 3.76362 0 4.5V8.5C0 9.2364 0.596953 9.83333 1.33333 9.83333H2L2.66667 13.1667H4V9.83333ZM5.33333 4.27413C5.78887 4.1764 6.35167 4.04129 6.95953 3.86249C8.07833 3.53343 9.5 3.01508 10.6667 2.21639V10.7836C9.5 9.98493 8.07833 9.4666 6.95953 9.13753C6.35167 8.95873 5.78887 8.8236 5.33333 8.72587V4.27413ZM1.33333 4.5H4V8.5H1.33333V4.5Z"
                  fill={`#DFBE00`}
                />
              </svg>
            )}

            {interview?.talentDetails.recruiterTalent===true?"Talent Event":"Job Event"}
          </div>
        </div>
        <div
          onClick={() => setShowSlider(true)}
          className="flex w-full text-gray-500 items-center justify-between gap-1 my-"
        >
          <p
            variant="body2"
            color="text.secondary"
            className="mb-1 text-[12px] flex items-center gap-3"
          >
            {interview?.talentDetails.talentDesignation}{" "}
            <div className="w-1.5 h-1.5 bg-gray-300 rounded-full"></div>
            {interview?.talentDetails.companyName}
          </p>
          <p
            variant="body2"
            className="flex text-[12px] items-center justify-end text-gray-500"
          >
            {convertToISTAndReturnDate(getTimeFromForRound1(interview.allocatedAvailabilitySlots))}
          </p>
        </div>
        <div
          className="flex text-gray-500 w-full items-center justify-between gap-"
          onClick={() => setShowSlider(true)}
        >
          <p
            variant="body2"
            color="text.secondary"
            className="mb-2 text-[12px]"
          >
            {getRoundNameForRound1(interview.allocatedAvailabilitySlots)}
          </p>
          <div className="text-right flex flex-col justify-center items-cente">
            <p
              variant="body2"
              className=" text-[12px]"
            >
              {convertToISTAndTime(getTimeFromForRound1(interview.allocatedAvailabilitySlots))} - {convertToISTAndTime(getEndFromForRound1(interview.allocatedAvailabilitySlots))}
            </p>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-2 mt- text-[#6B7280]">
          <div
            className={`flex items-center justify-center rounded-sm font-semibold space-x-2 ${getStatusColor(
              interview?.talentStatus
            )}`}
          >
            {(interview?.talentStatus === "Reffered" ||
              interview?.talentStatus === "Reffered") && (
              <div className="flex gap-4">
                <button className="flex items-center justify-center gap-2 px-4 py-2">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.7732 10.6519L11.7126 9.59121L12.7732 8.53056C14.2377 7.06611 14.2377 4.69173 12.7732 3.22726C11.3088 1.76279 8.93434 1.76279 7.46989 3.22726L6.40923 4.28792L5.34858 3.22726L6.40923 2.1666C8.45952 0.116343 11.7836 0.116343 13.8339 2.1666C15.8841 4.21685 15.8841 7.54093 13.8339 9.59121L12.7732 10.6519ZM10.6518 12.7732L9.59119 13.8339C7.54099 15.8841 4.21685 15.8841 2.1666 13.8339C0.116343 11.7836 0.116343 8.45946 2.1666 6.40924L3.22725 5.34858L4.28792 6.40924L3.22725 7.46991C1.76279 8.93436 1.76279 11.3087 3.22725 12.7732C4.69172 14.2377 7.06609 14.2377 8.53054 12.7732L9.59119 11.7125L10.6518 12.7732ZM10.1215 4.81825L11.1822 5.87891L5.87891 11.1822L4.81824 10.1215L10.1215 4.81825Z"
                      fill="#0671E0"
                    />
                  </svg>
                  {/* Join Now */}
                  <GetMeetLinkModal text='Join Now' />
                </button>
              </div>
            )}
            {interview?.talentStatus === "On Going" && (
              <div className="flex gap-4">
                <button className="flex p-6 items-center justify-center gap-2 px-4 py-2">
                  <div className="w-2 h-2 text-[14px] bg-[#EF865C] rounded-full"></div>
                  {interview?.status}
                </button>
              </div>
            )}
            {interview?.talentStatus === "Cancelled" && (
              <div className="flex gap-4">
                <button className="flex text-[14px] items-center justify-center gap-2 px-4 py-2">
                  <Close sx={{ width: "1rem" }} />
                  {interview?.status}
                </button>
              </div>
            )}
            {interview?.talentStatus === "Completed" && (
              <div className="flex gap-4">
                <button className="flex text-[14px] items-center justify-center gap-2 px-4 py-2">
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9977 7.14725C11.5579 9.34668 9.89949 11.4177 7.5725 11.8804C6.43759 12.1064 5.2603 11.9686 4.20827 11.4866C3.15623 11.0046 2.28308 10.203 1.71315 9.19588C1.14321 8.18876 0.90554 7.02751 1.03398 5.87746C1.16242 4.72741 1.65042 3.6472 2.42849 2.79064C4.02439 1.03286 6.71913 0.548987 8.91855 1.42876"
                      stroke="#20AE5C"
                      stroke-width="1.31965"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.51953 6.26712L6.71895 8.46655L11.9976 2.74805"
                      stroke="#20AE5C"
                      stroke-width="1.31965"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {interview?.talentStatus}
                </button>
              </div>
            )}
            {interview?.talentStatus === "No Show" && (
              <div className="flex gap-4">
                <button className="flex text-[14px] items-center text-[#FF6A6A] justify-center gap-2 px-4 py-2">
                  <svg
                    width="13"
                    height="15"
                    viewBox="0 0 13 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.0625 2.25L1.0225 1.3125L7.945 8.25L13 13.29L12.0475 14.25L9.7975 12H1V9.75C1 8.355 2.9575 7.44 4.8175 7.02L0.0625 2.25ZM13 9.75V11.385L11.575 9.96V9.75C11.575 9.555 11.2 9.2625 10.6 9L8.5 6.885C10.5325 7.2225 13 8.1825 13 9.75ZM2.425 9.75V10.575H8.3725L6.04 8.25C4.1425 8.475 2.425 9.3375 2.425 9.75ZM7 0C7.79565 0 8.55871 0.31607 9.12132 0.87868C9.68393 1.44129 10 2.20435 10 3C10 4.4625 8.95 5.685 7.5625 5.9475L4.0525 2.4375C4.18394 1.75197 4.55009 1.13364 5.088 0.688807C5.62591 0.243971 6.30198 0.000412436 7 0ZM7 1.5C6.60218 1.5 6.22064 1.65804 5.93934 1.93934C5.65804 2.22064 5.5 2.60218 5.5 3C5.5 3.39782 5.65804 3.77936 5.93934 4.06066C6.22064 4.34196 6.60218 4.5 7 4.5C7.39782 4.5 7.77936 4.34196 8.06066 4.06066C8.34196 3.77936 8.5 3.39782 8.5 3C8.5 2.60218 8.34196 2.22064 8.06066 1.93934C7.77936 1.65804 7.39782 1.5 7 1.5Z"
                      fill="#FF6A6A"
                    />
                  </svg>

                  {interview?.talentStatus}
                </button>
              </div>
            )}
          </div>
          {(interview?.status === "Rescheduled" ||
            interview?.status === "Scheduled") && (
            <div className="flex gap-4">
              <button className="flex text-[14px] border border-gray-100 rounded-md items-center justify-center gap-2 px-4 py-2">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46257 2.43262C5.21556 0.91688 7.5007 0 10 0C15.5228 0 20 4.47715 20 10C20 12.1361 19.3302 14.1158 18.1892 15.7406L15 10H18C18 5.58172 14.4183 2 10 2C7.84982 2 5.89777 2.84827 4.46023 4.22842L3.46257 2.43262ZM16.5374 17.5674C14.7844 19.0831 12.4993 20 10 20C4.47715 20 0 15.5228 0 10C0 7.86386 0.66979 5.88416 1.8108 4.25944L5 10H2C2 14.4183 5.58172 18 10 18C12.1502 18 14.1022 17.1517 15.5398 15.7716L16.5374 17.5674Z"
                    fill="#6B7280"
                  />
                </svg>
                <RescheduleEvent text={'Reschedule'} />
              </button>
              {/* <button className="flex gap-2 px-4 py-2 border-gray-300 text-gray-700 rounded-md hover:bg-gray-50">
                <Close sx={{ width: "1.2rem" }} />
                Cancel
              </button> */}
            </div>
          )}
          {(interview?.talentStatus === "Cancelled" ||
            interview?.talentStatus === "Reffered") && (
            <div className="flex gap-4">
              <button className="flex border text-[14px] border-gray-100 rounded-lg items-center justify-center gap-2 px-4 py-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46257 2.43262C5.21556 0.91688 7.5007 0 10 0C15.5228 0 20 4.47715 20 10C20 12.1361 19.3302 14.1158 18.1892 15.7406L15 10H18C18 5.58172 14.4183 2 10 2C7.84982 2 5.89777 2.84827 4.46023 4.22842L3.46257 2.43262ZM16.5374 17.5674C14.7844 19.0831 12.4993 20 10 20C4.47715 20 0 15.5228 0 10C0 7.86386 0.66979 5.88416 1.8108 4.25944L5 10H2C2 14.4183 5.58172 18 10 18C12.1502 18 14.1022 17.1517 15.5398 15.7716L16.5374 17.5674Z"
                    fill="#6B7280"
                  />
                </svg>
                <RescheduleEvent interview={interview} text="Reschedule" />
              </button>
              <button className="flex border border-gray-100 rounded-md items-center justify-center gap-2 px-4 py-2 text-gray-700 hover:bg-gray-50">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 8.81725C7.1355 8.81725 7.24625 8.77425 7.33225 8.68825C7.41875 8.60225 7.462 8.4915 7.462 8.356C7.462 8.22 7.41875 8.109 7.33225 8.023C7.24625 7.937 7.1355 7.894 7 7.894C6.8645 7.894 6.75375 7.937 6.66775 8.023C6.58175 8.109 6.5385 8.22 6.538 8.356C6.5375 8.492 6.58075 8.60275 6.66775 8.68825C6.75475 8.77375 6.8655 8.81675 7 8.81725ZM7 6.5965C7.107 6.5965 7.19625 6.5605 7.26775 6.4885C7.33925 6.4165 7.375 6.3275 7.375 6.2215V2.41375C7.375 2.30675 7.33925 2.2175 7.26775 2.146C7.19625 2.0745 7.107 2.03875 7 2.03875C6.893 2.03875 6.80375 2.0745 6.73225 2.146C6.66075 2.2175 6.625 2.30675 6.625 2.41375V6.2215C6.625 6.328 6.66075 6.417 6.73225 6.4885C6.80375 6.56 6.893 6.596 7 6.5965ZM2.55775 10.75L1.27975 12.028C1.09025 12.2175 0.87125 12.261 0.62275 12.1585C0.37425 12.056 0.25 11.8698 0.25 11.5998V1.462C0.25 1.1165 0.36575 0.82825 0.59725 0.59725C0.82875 0.36625 1.117 0.2505 1.462 0.25H12.5387C12.8837 0.25 13.172 0.36575 13.4035 0.59725C13.635 0.82875 13.7505 1.117 13.75 1.462V9.53875C13.75 9.88375 13.6345 10.172 13.4035 10.4035C13.1725 10.635 12.8843 10.7505 12.5387 10.75H2.55775ZM2.2375 10H12.5387C12.6538 10 12.7595 9.952 12.856 9.856C12.9525 9.76 13.0005 9.65425 13 9.53875V1.46125C13 1.34625 12.952 1.2405 12.856 1.144C12.76 1.0475 12.6543 0.9995 12.5387 1H1.46125C1.34625 1 1.2405 1.048 1.144 1.144C1.0475 1.24 0.9995 1.34575 1 1.46125V11.2338L2.2375 10Z"
                    fill="#6B7280"
                  />
                </svg>
              </button>
            </div>
          )}
          {interview?.talentStatus === "Completed" && (
            <div className="flex gap-4"></div>
          )}
          {interview?.talentStatus === "On Going" && (
            <div className="flex gap-4"></div>
          )}
        </div>
      </div>
    </div>
  </div>
  )
}

export default InterviewPageTab