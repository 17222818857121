import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  Popover,
} from "@mui/material";
import { Close, Refresh, MoreVert } from "@mui/icons-material";
import RescheduleEvent from "./RescheduleEventModal";

export default function CancelEvent() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    handleClose();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div id={id} className="text">
        <div onClick={handleOpenModal} className="">
          Cancel Event
        </div>
      </div>

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className="h-full bg-gray-100 p-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46257 2.43262C5.21556 0.91688 7.5007 0 10 0C15.5228 0 20 4.47715 20 10C20 12.1361 19.3302 14.1158 18.1892 15.7406L15 10H18C18 5.58172 14.4183 2 10 2C7.84982 2 5.89777 2.84827 4.46023 4.22842L3.46257 2.43262ZM16.5374 17.5674C14.7844 19.0831 12.4993 20 10 20C4.47715 20 0 15.5228 0 10C0 7.86386 0.66979 5.88416 1.8108 4.25944L5 10H2C2 14.4183 5.58172 18 10 18C12.1502 18 14.1022 17.1517 15.5398 15.7716L16.5374 17.5674Z"
                  fill="#6B7280"
                />
              </svg>
            </div>
            <div className="">
              <Typography variant="h6">Sure you want to cancel?</Typography>
              <Typography variant="body2" className="text-gray-400">
                You can Reschedule the Event
              </Typography>
            </div>
          </div>
          <IconButton onClick={handleCloseModal} size="small">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="my-2 flex flex-col">
          <label className="text-gray-500 mt-2">
            Why do you want to Reschedule or cancel?
          </label>
          <input
            fullWidth
            rows={2}
            variant="outlined"
            label="Why do you want to Reschedule or cancel?"
            placeholder="Write something.."
            className="mb-4 w-full p-2"
          />

          <div
            variant="outlined"
            fullWidth
            onClick={handleCloseModal}
            className="mb-4 w-fit p-1 px-2 cursor-pointer rounded-lg font-semibold m-auto"
          >
            Yes, Cancel. I will Reschedule Later.
          </div>
          <button
            variant="contained"
            className="bg-blue-600 text-white py-2 rounded-lg"
            fullWidth
            color="primary"
            size="large"
          >
            <RescheduleEvent text='Yes, Reschedule' />
          </button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
