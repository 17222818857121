import React, { useEffect, useState } from "react";
import Layout from "../Component/Layout";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../App";
import { useAuth } from "../context/auth";
import { MdLaunch } from "react-icons/md";
import InterviewPageTab from "./InterviewPageTab";
import axios from "axios";
// import {getStatusColor, calculateEndTime} from "./InterviewPage";


const calculateEndTime = (startTime, duration) => {
  if (!startTime || typeof startTime !== "string") {
    console.error("Invalid startTime provided:", startTime);
    return "00:00";
  }
  const [hours, minutes] = startTime.split(":").map(Number); 
  const durationInMinutes = duration * 60; 

  const startDate = new Date();
  startDate.setHours(hours, minutes, 0, 0);

  const endDate = new Date(startDate.getTime() + durationInMinutes * 60000);

  const endHours = String(endDate.getHours()).padStart(2, "0");
  const endMinutes = String(endDate.getMinutes()).padStart(2, "0");

  return `${endHours}:${endMinutes}`;
};

function getStatusColor(status) {
  switch (status) {
    case "Reffered":
      return "text-[#0671E0] bg-blue-100";
      break;
    case "Rescheduled":
      return "text-[#0671E0] bg-blue-100";
      break;
    case "Cancelled":
      return "text-[#FF0000] bg-red-100";
    case "Completed":
      return "text-[#54C180] bg-green-100";
    case "No Show":
      return "text-[#FF6A6A] bg-red-100";
    case "On Going":
      return "text-[#EF865C] bg-orange-100";
      break;
    default:
      return "text-[#0671E0] bg-blue-100";
  }
}


const HomePage = () => {
  const { auth, setAuth } = useAuth();
  
  
  const navigate = useNavigate();
  const [clickedCard, setClickedCard] = useState(null);
  const [showSlider, setShowSlider] = useState(false);
  const [dummyData, setDummyData] = useState([{
    "talentDetails": {
        "recruiterTalent": true,
        "talentId": "b1504f60-7fa8-4a8f-b74a-7569f845511c",
        "talentName": "Aditya",
        "talentMail": "aditya@gmail.com",
        "contactNumber": "9140946956",
        "talentGender": "male",
        "collegeName": "DU",
        "yearOfGraduation": "2022-01-01",
        "companyName": "Amazon",
        "talentDesignation": "software developer",
        "talentCurrentLocation": "Agra | Uttar Pradesh | India",
        "officialNoticePeriod": "Serving 30 days",
        "talentReferredDate": "2024-12-10T10:52:22.610Z",
        "availabilitySlots": [
            {
                "slotId": 45,
                "timeFrom": "2024-12-17T07:30:00.000Z",
                "endTime": "2024-12-17T09:30:00.000Z",
                "selectedSlot": false,
                "jobAvailabilitySlot": {
                    "id": 109,
                    "JobA": "b27bc892-9541-49e6-851f-f3ce282e89c7",
                    "day": "Tuesday",
                    "time": "13:00",
                    "dura": "2",
                    "avai": false,
                    "jobI": 113,
                    "crea": "2024-11-30T08:42:51.609Z",
                    "upda": "2024-11-30T08:42:51.609Z"
                }
            },
            {
                "slotId": 46,
                "timeFrom": "2024-12-24T07:30:00.000Z",
                "endTime": "2024-12-24T09:30:00.000Z",
                "selectedSlot": false,
                "jobAvailabilitySlot": null
            }
        ]
    },
    "jobDetails": {
        "jobId": "be5926ed-5ca2-4c23-8efd-9fd8f74f2f49",
        "jobTitle": "Engineering Manager",
        "companyName": "Test SS",
        "minYearsExperience": 17,
        "maxYearsExperience": 27.5,
        "jobType": "Full-Time",
        "engagementType": "Short-Term (1-3 months)",
        "workplaceType": "Hybrid",
        "jobLocation": "North West Delhi | Delhi | India",
        "interviewRounds": [
            {
                "roundId": 71,
                "roundName": "Behavioral Round",
                "roundNumber": 1
            },
            {
                "roundId": 72,
                "roundName": "Case Study Round",
                "roundNumber": 2
            }
        ]
    },
    "allocatedAvailabilitySlots": [
        {
            "slotId": 24,
            "roundId": 72,
            "referralId": 30,
            "roundStatus": "scheduled",
            "selectedAvailabilitySlotId": null,
            "selectedAvailabilitySlotDetails": null,
            "roundDetails": {
                "roundName": "Case Study Round",
                "roundNumber": 2
            }
        },
        {
            "slotId": 23,
            "roundId": 71,
            "referralId": 30,
            "roundStatus": "scheduled",
            "selectedAvailabilitySlotId": 45,
            "selectedAvailabilitySlotDetails": {
                "timeFrom": "2024-12-17T07:30:00.000Z",
                "endTime": "2024-12-17T09:30:00.000Z"
            },
            "roundDetails": {
                "roundName": "Behavioral Round",
                "roundNumber": 1
            }
        }
    ],
    "comment": "comment of recruiter",
    "referralId": 30,
    "talentStatus": "Reffered",
    "askTalentFeedback": false,
    "bellVisibility": false,
    "recruiterScore": 0
},
{
    "talentDetails": {
        "recruiterTalent": true,
        "talentId": "7964e150-9512-48f9-8e9d-ea0e414cb57a",
        "talentName": "Ashutosh",
        "talentMail": "ashutosh@gmail.com",
        "contactNumber": "5356386868",
        "talentGender": "male",
        "collegeName": "DU",
        "yearOfGraduation": "2022-01-01",
        "companyName": "google",
        "talentDesignation": "software developer",
        "talentCurrentLocation": "Agra | Uttar Pradesh | India",
        "officialNoticePeriod": "Serving 7 days",
        "talentReferredDate": "2024-12-09T19:14:38.844Z",
        "availabilitySlots": [
            {
                "slotId": 43,
                "timeFrom": "2024-12-16T06:51:00.000Z",
                "endTime": "2024-12-16T07:51:00.000Z",
                "selectedSlot": false,
                "jobAvailabilitySlot": {
                    "id": 110,
                    "JobA": "717b33b9-c53f-4bcd-af25-c662f52afe20",
                    "day": "Monday",
                    "time": "12:21",
                    "dura": "1",
                    "avai": false,
                    "jobI": 114,
                    "crea": "2024-12-04T20:07:00.772Z",
                    "upda": "2024-12-04T20:07:00.772Z"
                }
            },
            {
                "slotId": 44,
                "timeFrom": "2024-12-23T06:51:00.000Z",
                "endTime": "2024-12-23T07:51:00.000Z",
                "selectedSlot": false,
                "jobAvailabilitySlot": null
            }
        ]
    },
    "jobDetails": {
        "jobId": "c219c3aa-4e0b-4a2b-a3f2-419d7c7efb2a",
        "jobTitle": "software developer",
        "companyName": "google",
        "minYearsExperience": 1,
        "maxYearsExperience": 2,
        "jobType": "Full-Time",
        "engagementType": "Direct/Internal Hiring",
        "workplaceType": "On-Site",
        "jobLocation": "Agra | Uttar Pradesh | India",
        "interviewRounds": [
            {
                "roundId": 73,
                "roundName": "Behavioral Round",
                "roundNumber": 1
            }
        ]
    },
    "allocatedAvailabilitySlots": [
        {
            "slotId": 22,
            "roundId": 73,
            "referralId": 29,
            "roundStatus": "scheduled",
            "selectedAvailabilitySlotId": 43,
            "selectedAvailabilitySlotDetails": {
                "timeFrom": "2024-12-16T06:51:00.000Z",
                "endTime": "2024-12-16T07:51:00.000Z"
            },
            "roundDetails": {
                "roundName": "Behavioral Round",
                "roundNumber": 1
            }
        }
    ],
    "comment": "comment of recruiter",
    "referralId": 29,
    "talentStatus": "Reffered",
    "askTalentFeedback": false,
    "bellVisibility": false,
    "recruiterScore": 0
},
{
  "talentDetails": {
      "recruiterTalent": true,
      "talentId": "7964e150-9512-48f9-8e9d-ea0e414cb57a",
      "talentName": "Ashutosh",
      "talentMail": "ashutosh@gmail.com",
      "contactNumber": "5356386868",
      "talentGender": "male",
      "collegeName": "DU",
      "yearOfGraduation": "2022-01-01",
      "companyName": "google",
      "talentDesignation": "software developer",
      "talentCurrentLocation": "Agra | Uttar Pradesh | India",
      "officialNoticePeriod": "Serving 7 days",
      "talentReferredDate": "2024-12-09T19:14:38.844Z",
      "availabilitySlots": [
          {
              "slotId": 43,
              "timeFrom": "2024-12-16T06:51:00.000Z",
              "endTime": "2024-12-16T07:51:00.000Z",
              "selectedSlot": false,
              "jobAvailabilitySlot": {
                  "id": 110,
                  "JobA": "717b33b9-c53f-4bcd-af25-c662f52afe20",
                  "day": "Monday",
                  "time": "12:21",
                  "dura": "1",
                  "avai": false,
                  "jobI": 114,
                  "crea": "2024-12-04T20:07:00.772Z",
                  "upda": "2024-12-04T20:07:00.772Z"
              }
          },
          {
              "slotId": 44,
              "timeFrom": "2024-12-23T06:51:00.000Z",
              "endTime": "2024-12-23T07:51:00.000Z",
              "selectedSlot": false,
              "jobAvailabilitySlot": null
          }
      ]
  },
  "jobDetails": {
      "jobId": "c219c3aa-4e0b-4a2b-a3f2-419d7c7efb2a",
      "jobTitle": "software developer",
      "companyName": "google",
      "minYearsExperience": 1,
      "maxYearsExperience": 2,
      "jobType": "Full-Time",
      "engagementType": "Direct/Internal Hiring",
      "workplaceType": "On-Site",
      "jobLocation": "Agra | Uttar Pradesh | India",
      "interviewRounds": [
          {
              "roundId": 73,
              "roundName": "Behavioral Round",
              "roundNumber": 1
          }
      ]
  },
  "allocatedAvailabilitySlots": [
      {
          "slotId": 22,
          "roundId": 73,
          "referralId": 29,
          "roundStatus": "scheduled",
          "selectedAvailabilitySlotId": 43,
          "selectedAvailabilitySlotDetails": {
              "timeFrom": "2024-12-16T06:51:00.000Z",
              "endTime": "2024-12-16T07:51:00.000Z"
          },
          "roundDetails": {
              "roundName": "Behavioral Round",
              "roundNumber": 1
          }
      }
  ],
  "comment": "comment of recruiter",
  "referralId": 29,
  "talentStatus": "Reffered",
  "askTalentFeedback": false,
  "bellVisibility": false,
  "recruiterScore": 0
}
])
  const filteredData = dummyData.filter(item => item.talentDetails.recruiterTalent); 

 
useEffect(() => {
    if (!auth?.user?.name) {
      navigate("/login")
    }
  }, [auth, navigate])

  if (!auth?.user?.name) {
    return null
  }
  
  

  console.log("auth=", auth);
  return (
    <Layout showFooter={true}>
      <div className="p-10 bg-[#F7F7F7] ">
      <div className="h-full z-10 bg-gradient-to-r from-[#AED7FF] to-[#0671e0a6] flex flex-col justify-center items-center rounded-lg py-6">
        <h1 className="font-[Roboto] text-2xl md:text-4xl text-center text-white">
          Hey {auth?.user?.name?.split(" ")[0]}! What's on your mind today?
        </h1>
        <h4 className="font-[Roboto] text-m md:text-m mb-8 text-center text-white">
          Keep teams connected and working together with custom interfaces.
        </h4>
        <div className="flex flex-col justify-center md:flex-row gap-4 md:gap-10 w-[80%] px-5">
          <Link to={routes.create_jobs} className=" w-1/2">
            <button className="font-[Roboto] text-white bg-gradient-to-r from-[#0671e0a6] to-[#AED7FF] border focus:outline-none focus:ring-4 focus:ring-gray-100 rounded-2xl text-xl md:text-2xl px-6 py-2 w-full mb-2 dark:text-white dark:border-white dark:focus:ring-gray-700">
              Start Search for New Talent?
            </button>
          </Link>
          <Link to={routes.active_job_list} className="w-[80%] md:w-1/2">
            <button className="font-[Roboto] text-white bg-gradient-to-r from-[#0671e0a6] to-[#AED7FF] border border-gray-400 focus:outline-none focus:ring-4 focus:ring-gray-100  rounded-2xl text-xl md:text-2xl px-6 py-2 w-full mb-2 dark:text-white dark:border-white dark:focus:ring-gray-700">
              Empower Someone with a Referral?
            </button>
          </Link>
        </div>
      </div>
      
      <div className="gap-6 mt-8">
      <div class="font-[Inter] text-lg text-[#54595E]">
        <h3>Summary of your Job Listing</h3>
        <p className="text-sm text-[#54595Ea6]">Heres the summary of your Job Listed. More to go, Enjoy Reflense!</p>
      </div>
      <div className=" font-[Inter] grid grid-cols-4 gap-1 pt-6 pb-6 w-full">
        <div className="p-4 bg-white hover:bg-[#F7F7F7] shadow rounded">
          <p className="text-xl text-black font-semibold">0</p>
          <h3 className="text-[#A0AEC0] text-sm">All Jobs</h3>
        </div>
        <div className="p-4 bg-white hover:bg-[#F7F7F7] shadow rounded">
          <p className="text-xl text-black font-semibold">0</p>
          <h3 className="text-[#A0AEC0] text-sm">Active Jobs</h3>
        </div>
        <div className="p-4 bg-white hover:bg-[#F7F7F7] shadow rounded">
          <p className="text-xl  text-black font-semibold">0</p>
          <h3 className="text-[#A0AEC0] text-sm">Closed Jobs</h3>
        </div>
        <div className="p-4 bg-white hover:bg-[#F7F7F7] shadow rounded">
          <p className="text-xl  text-black font-semibold">0</p>
          <h3 className="text-[#A0AEC0] text-sm">Draft Jobs</h3>
        </div>
      </div>
      <div className="grid grid-cols-[60%_40%] space-x-3 gap-6 mt-8">
      <div class="font-[Inter] text-lg text-[#54595E]">
        <span className="">Upcoming Job Events</span>
        <a className="p-4 text-sm text-[#54595Ea6]" href="/interviews">View All</a>
        <div className="mt-5 h-96 overflow-y-auto scrollbar-hide">
          <div className="gap-4">
            {filteredData?.map((interview, index) => (
              <InterviewPageTab
              // showSlider={showSlider}
              index={index}
              data={dummyData}
              getStatusColor={getStatusColor}
              calculateEndTime={calculateEndTime}
              setClickedCard={setClickedCard}
              setShowSlider={setShowSlider}
              interview={interview}
              />
            ))}
          </div>
        </div>
      </div>
      <div class="font-[Inter] text-lg text-[#54595E]">
        <h3>Yay, your Job got new Referrals, Check it out!</h3>
        <div className="mt-5 h-96 overflow-y-auto scrollbar-hide">
          {[1, 2, 3, 4].map((x) => (
            <div key={x} className="m-2">
              <div className="font-[Inter] grid grid-cols-2 p-4 bg-white shadow rounded">
                <div>
                  <p className="font-bold capitalize text-[18px]">Software developer</p>
                  <p
                    variant="body2"
                    color="text.secondary"
                    className="mb-1 text-[12px] flex items-center gap-3"
                  >
                    Amazon
                    <div className="w-1.5 h-1.5 bg-gray-300 rounded-full"></div>
                    Remote
                  </p>
                </div>
                <div className="bg-white flex items-center justify-center rounded-sm h-16">
                  <button className="h-1/2 font-[Inter] leading-[140%]  text-[#0671E0] bg-[#F3F8FD]  text-xs font-light px-4 rounded-lg">
                    5 new Applicants
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      
        
       
      </div>
      </div>
      </div>
      

      <div className="grid grid-cols-2 space-x-3 gap-6 mt-8">
      <div class="font-[Inter] text-lg text-[#54595E]">
        <span className="">Feedback Requests</span>
        <a className="p-4 text-sm text-[#54595Ea6]" href="/interviews">View All</a>
        <div className="mt-5 h-84 overflow-y-auto scrollbar-hide">
        <table className="w-full table-auto bg-white rounded-lg shadow-lg border-spacing-0">
            <thead className=" text-left">
              <tr>
                <th className="px-4 py-3 font-semibold text-sm leading-[140%] text-[#6B7280] text-left">Talent Name</th>
                <th className="px-4 py-3 font-semibold text-sm leading-[140%] text-[#6B7280] text-left">Job Title</th>
                <th className="px-4 py-3 font-semibold text-sm leading-[140%] text-[#6B7280] text-left">Request Date</th>
              </tr>
            </thead>
            <tbody className="font-normal text-sm leading-[140%] text-[#6B7280] text-left">
              <tr class="border-t border-gray-300">
                <td className="px-4 py-2 ">Subash babu</td>
                <td className="px-4 py-2 ">Software developer - Amazon</td>
                <td className="px-4 py-2 ">24 Dec 2024</td>
              </tr>
              <tr class="border-t border-gray-300">
                <td className="px-4 py-2 ">Jaivignesh</td>
                <td className="px-4 py-2 ">Ui ux designer - CRED</td>
                <td className="px-4 py-2 ">24 Dec 2024</td>
              </tr>
              <tr class="border-t border-gray-300">
                <td className="px-4 py-2 ">Shifat</td>
                <td className="px-4 py-2 ">SDE 2 - Homestyler</td>
                <td className="px-4 py-2 ">24 Dec 2024</td>
              </tr>
              <tr class="border-t border-gray-300">
                <td className="px-4 py-2 ">Sushil bajpai</td>
                <td className="px-4 py-2 ">Backend developer - Google</td>
                <td className="px-4 py-2 ">24 Dec 2024</td>
              </tr>
              <tr class="border-t border-gray-300">
                <td className="px-4 py-2 ">Sushil bajpai</td>
                <td className="px-4 py-2 ">Backend developer - Google</td>
                <td className="px-4 py-2 ">24 Dec 2024</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="font-[Inter] text-lg text-[#54595E]">
        <span className="">Status Update Needed</span>
        <a className="p-4 text-sm text-[#54595Ea6]" href="/interviews">View All</a>
        <div className="mt-5 h-84 overflow-y-auto scrollbar-hide">
        <table className="w-full table-auto bg-white rounded-lg shadow-lg border-spacing-0">
            <thead className=" text-left">
              <tr>
                <th className="px-4 py-3 font-semibold text-sm leading-[140%] text-[#6B7280] text-left">Talent Name</th>
                <th className="px-4 py-3 font-semibold text-sm leading-[140%] text-[#6B7280] text-left">Job Title</th>
                <th className="px-4 py-3 font-semibold text-sm leading-[140%] text-[#6B7280] text-left">Request Date</th>
              </tr>
            </thead>
            <tbody className="font-normal text-sm leading-[140%] text-[#6B7280] text-left">
              <tr class="border-t border-gray-300">
                <td className="px-4 py-2 ">Subash babu</td>
                <td className="px-4 py-2 ">Software developer - Amazon</td>
                <td className="px-4 py-2 ">24 Dec 2024</td>
              </tr>
              <tr class="border-t border-gray-300">
                <td className="px-4 py-2 ">Jaivignesh</td>
                <td className="px-4 py-2 ">Ui ux designer - CRED</td>
                <td className="px-4 py-2 ">24 Dec 2024</td>
              </tr>
              <tr class="border-t border-gray-300">
                <td className="px-4 py-2 ">Shifat</td>
                <td className="px-4 py-2 ">SDE 2 - Homestyler</td>
                <td className="px-4 py-2 ">24 Dec 2024</td>
              </tr>
              <tr class="border-t border-gray-300">
                <td className="px-4 py-2 ">Sushil bajpai</td>
                <td className="px-4 py-2 ">Backend developer - Google</td>
                <td className="px-4 py-2 ">24 Dec 2024</td>
              </tr>
              <tr class="border-t border-gray-300">
                <td className="px-4 py-2 ">Sushil bajpai</td>
                <td className="px-4 py-2 ">Backend developer - Google</td>
                <td className="px-4 py-2 ">24 Dec 2024</td>
              </tr>
            </tbody>
          </table>
        </div>
      
        
       
      </div>
      </div>

      
      <div className="gap-6 mt-8">
      <div class="font-[Inter] text-lg text-[#54595E]">
        <h3>Summary of your Job Referrals</h3>
        <p className="text-sm text-[#54595Ea6]">Heres the summary of your Referrals. Refer and empower more with Reflense!</p>
      </div>
      <div className=" font-[Inter] grid grid-cols-4 gap-1 pt-6 pb-6 w-full">
        <div className="p-4 bg-white hover:bg-[#F7F7F7] shadow rounded">
          <p className="text-xl text-black font-semibold">8</p>
          <h3 className="text-[#A0AEC0] text-sm">Talents Referred</h3>
        </div>
        <div className="p-4 bg-white hover:bg-[#F7F7F7] shadow rounded">
          <p className="text-xl text-black font-semibold">5</p>
          <h3 className="text-[#A0AEC0] text-sm">Talents In-Process</h3>
        </div>
        <div className="p-4 bg-white hover:bg-[#F7F7F7] shadow rounded">
          <p className="text-xl  text-black font-semibold">2</p>
          <h3 className="text-[#A0AEC0] text-sm">Talents Hired</h3>
        </div>
        <div className="p-4 bg-white hover:bg-[#F7F7F7] shadow rounded">
          <p className="text-xl  text-black font-semibold">0</p>
          <h3 className="text-[#A0AEC0] text-sm">Talent Rejected</h3>
        </div>
      </div>
      <div className="grid grid-cols-[60%_40%] space-x-3 gap-6 mt-8">
      <div class="font-[Inter] text-lg text-[#54595E]">
        <span className="">Upcoming Talent Events</span>
        <a className="p-4 text-sm text-[#54595Ea6]" href="/interviews">View All</a>
        <div className="mt-5 h-96 overflow-y-auto scrollbar-hide">
          <div className="gap-4">
            {filteredData?.map((interview, index) => (
              <InterviewPageTab
              // showSlider={showSlider}
              index={index}
              data={dummyData}
              getStatusColor={getStatusColor}
              calculateEndTime={calculateEndTime}
              setClickedCard={setClickedCard}
              setShowSlider={setShowSlider}
              interview={interview}
              />
            ))}
          </div>
        </div>
      </div>
      <div class="font-[Inter] text-lg text-[#54595E]">
        <h3>Yay, your Talents are growing, Check it out!</h3>
        <div className="mt-5 h-96 overflow-y-auto scrollbar-hide">
          {[1, 2, 3, 4].map((x) => (
            <div key={x} className="m-2">
              <div className="font-[Inter] grid grid-cols-2 p-4 bg-white shadow rounded">
                <div>
                  <p className="font-[Inter] capitalize font-bold text-[18px]">Software developer</p>
                  <p
                    variant="body2"
                    color="text.secondary"
                    className="mb-1 text-[12px] flex items-center gap-3"
                  >
                    Amazon
                    <div className="w-1.5 h-1.5 bg-gray-300 rounded-full"></div>
                    Remote
                  </p>
                </div>
                <div className=" bg-white flex items-center justify-center rounded-sm h-16">
                  <button className={`font-[Inter] leading-[140%] text-xs h-1/2 font-light px-4 rounded-lg ${x!=2? "text-[#359742] bg-[#35974219]": "text-[#FF0000] bg-[#FFE6E6]" }`}>
                    {x != 2 ? "Hired" : "Rejected"}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      
        
       
      </div>
      </div>
      </div>
      {/* <div className="grid grid-cols-2 space-x-3 gap-6 mt-8">
        <div className="">
        <h2 className="text-xl text-gray-600 font-bold mb-4">Status Update Needed</h2>
        <div className="">
          {
            [...Array(2)].map((_,i)=>(
              <div key={i}  className="bg-blue-200 p-4 mb-4 border-4 border-white hover:border-purple-500  rounded-lg">
                <div className="flex  justify-between items-center">
                <div>
                    <p className="font-semibold">Talent Name</p>
                    <p className="font-semibold">Job Title: </p>
                  </div>
                  <div>
                    <p className="font-semibold">Last Interview Data</p>
                    <p className="font-semibold">Interview Round No.</p>
                    
                  </div>
                  <div className="flex flex-col place-items-end">
                  <button
                    className="px-2 py-1 rounded-lg text-black font-bold bg-yellow-400"
                  >
                    {i % 2 === 0 ? 'Recommended' : 'Forwarded'}
                  </button>
                  <MdLaunch className="mt-2"/>
                  
                  </div>
                </div>

              </div>
            ))
          }

        </div>

        <hr className="mt-10 border-gray-500 "/>

        <div className="">
        <table className="w-full table-auto bg-white rounded-lg shadow-lg">
          <thead className=" text-left">
            <tr>
              <th className="px-4 py-3 text-gray-400">Job Title</th>
              <th className="px-4 py-3 text-gray-400">New References</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2 font-bold">Senior Software Engineer</td>
              <td className="border px-4 py-2 font-bold">5 New References</td>
            </tr>
            <tr>
              <td className="border px-4 py-2 font-bold">Software Engineer</td>
              <td className="border px-4 py-2 font-bold">1 New Reference</td>
            </tr>
            <tr>
              <td className="border px-4 py-2 font-bold">Tech Lead</td>
              <td className="border px-4 py-2 font-bold">10 New References</td>
            </tr>
            <tr>
              <td className="border px-4 py-2 font-bold">TPM</td>
              <td className="border px-4 py-2 font-bold">2 New References</td>
            </tr>
            <tr>
              <td className="border px-4 py-2 font-bold">EM</td>
              <td className="border px-4 py-2 font-bold">15 New References</td>
            </tr>
          </tbody>
        </table>
      </div>

        </div>

        <div>
          <h2 className="text-xl text-gray-600 font-bold mb-4">Feedback Requests</h2>
          <div className="space-y-5">
            {[...Array(4)].map((_, i) => (
              <div
                key={i}
                className="p-4 rounded-lg
                  border-white border-4 hover:border-purple-500  bg-blue-200"
              >
                <div className="flex justify-between items-center p-2">
                  <div>
                    <p className="font-semibold">Talent Name</p>
                    <p className="font-semibold">Job Title</p>
                    </div>
                    <div>
                    <p className="font-semibold">Request Data:{"<Data>"}</p>
                    <p className="font-semibold">Talent Score</p>
                  </div>
                  <div className="flex flex-col place-items-end">
                    <div>
                  <button
                    className="px-4 py-2 rounded-md text-white bg-yellow-400"
                  >
                    {i % 2 === 0 ? 'Forwarded' : 'Recommended'}
                  </button>
                  <MdLaunch className="m-2"/>
                  </div>
                  <p className="font-semibold mr-2">Recruiter Score</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      

        
       
      </div> */}
      </div>
    </Layout>
  );
};

export default HomePage;
