import React, { useEffect, useRef, useState } from "react";
import "../pages/login.css";
import Layout from "../Component/Layout";
import toast from "react-hot-toast";
import axios from "axios";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../context/auth";
import { routes } from "../App";
import PhoneInput from "react-phone-input-2";
import LinkedInLoginButton from '../pages/LinkedInLogin';

const Login = () => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [resetStatus, setResetStatus] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loginType, setLoginType] = useState("email");
  const numberOfDigits = 6;
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const [sentOTP, setSentOTP] = useState(false);
  const otpBoxReference = useRef([]);
  const [timer, setTimer] = useState(120);
  const [isLoading, setIsLoading]=useState(false);
  const [isTimerActive, setIsTimerActive] = useState(false);

  useEffect(() => {
    if (auth?.user) {
      navigate("/home");
    }
  }, [auth, navigate]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const areFieldsEmpty = () => {
    return (email === "" && phone === "") || password === "";
  };

  const areOtpFieldsValid = () => {
    return otp.join("").length !== 6 && phone !== "";
  };

  const toggleLoginType = () => {
    setLoginType(loginType === "email" ? "phone" : "email");
  };

  useEffect(() => {
    console.log(process.env.REACT_APP_API_)
    let interval;
    if (isTimerActive) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setIsTimerActive(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerActive]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (areFieldsEmpty()) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      const payload = {};

      if (loginType === "email") {
        payload.email = email;
      } else {
        payload.phone = phone;
      }

      setIsLoading(true);
      payload.password = password;

      const res = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/auth/login`,
        payload
      );

      if (res && res.data.success) {
        toast.success(res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        if (rememberMe) {
          localStorage.setItem(
            "rememberedUser",
            JSON.stringify({ email, phone, password })
          );
          localStorage.setItem("rememberedPassword", password);
        } else {
          localStorage.removeItem("rememberedUser");
        }
        if(localStorage.getItem("redirectAfterLogin")){
          navigate(localStorage.getItem("redirectAfterLogin"))
          localStorage.removeItem("redirectAfterLogin");
        }else{
          navigate(routes.home);
        }
        setIsLoading(false);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("User not found");
      } else if (error.response && error.response.status === 401) {
        toast.error("Incorrect password. Please try again!!!");
      } else {
        toast.error("Something went wrong.");
      }
      setIsLoading(false);
      console.error(error);
    }finally{
      setIsLoading(false);
    }
    console.log("Login credentials:", email, password);
  };

  const generateOtp = async (phoneNumber) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/auth/generateOtp`,
        { phoneNumber }
      );
      console.log(res);

      if (res && res.data.message) {
        toast.success(res.data.message);
        setIsTimerActive(true);
        setTimer(120);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 400) {
        toast.error(
          err.response.data.message ||
            "OTP already sent, please wait until it expires."
        );
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  const verifyOtp = async (phoneNumber, otp) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/auth/verifyOtp`,
        { phoneNumber, otp }
      );
      console.log(res);

      if (res && res.data.message) {
        toast.success(res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
      }
      navigate(routes.home);
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 400) {
        toast.error(
          err.response.data.message ||
            "OTP already sent, please wait until it expires."
        );
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/auth/forgetPassword`,
        { email }
      );

      if (response && response.data.message) {
        toast.success(response.data.message);
        setResetStatus(
          "An email with instructions to reset your password has been sent."
        );
        navigate(routes.home);
      } else {
        setResetStatus("Invalid response from the server.");
        toast.error("Invalid response from the server.");
      }
    } catch (error) {
      setResetStatus("Error sending reset link. Please try again.");
      toast.error("Error sending reset link. Please try again.");
      console.error(error);
    }
  };

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const token = params.get("token");
  //   if (token) {
  //     validateToken(token);
  //   }

  //   const rememberedUser = localStorage.getItem("rememberedUser");
  //   if (rememberedUser) {
  //     const { email, phone, password } = JSON.parse(rememberedUser);
  //     setEmail(email);
  //     setPhone(phone);
  //     setPassword(password);
  //     setRememberMe(true);
  //   }

  //   return () => {
  //     toast.dismiss();
  //   };
  // }, [location.search]);

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }

    console.log(otp, phone);
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  const validateToken = async (token) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/auth/validateToken`,
        { token }
      );
      if (res && res.data.user) {
        toast.success("Login successful");
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate(routes.home);
      } else {
        toast.error("Invalid or expired token.");
      }
    } catch (error) {
      toast.error("Error validating token. Please try again.");
      console.error(error);
    }
  };

  // if(isLoading){
  //   return (
  //     <div className="flex items-center justify-center min-h-screen bg-gray-100">
  //       <div className="w-16 h-16 border-4 border-dashed rounded-full animate-spin border-blue-500"></div>
  //     </div>
  //   )
  // }

  return (
    <div className="flex flex-col bg-gradient-to-r from-blue-50 from-5% to-blue-400 to-100% min-h-screen">
      <div className="flex justify-between items-center p-2 lg:px-8">
        <img
          src="https://i.ibb.co/QQ2BCjZ/reflense-Logo.png"
          alt="Reflense Open"
          className={`h-[4rem] transition-all duration-300 float-left"`}
        />
        <div className="flex flex-col md:flex-row justify-between text-black gap-2 min-w-[15rem] lg:min-w-[30rem] font-semibold text-[1rem]">
          <Link to={"#"}>About</Link>
          <Link to={"#"}>Contact us</Link>
          <Link to={"#"}>Pricing</Link>
        </div>
        <div></div>
        <div></div>
      </div>
      <div className="flex flex-col md:flex-row lg:px-3 py-4 lg:py-10 justify-center w-full m-auto lg:w-[1000px] h-full lg:h-[85vh]">
        <div className="flex w-full lg:w-[40%] rounded-l-xl lg:px-10 bg-white bg-opacity-20 flex-col justify-between p-4">
          <div className="text-center flex flex-col items-center justify-center h-screen mb-32 w-fit m-auto">
            <h1 className="bg-gradient-to-r from-[#5C9CE6] from-25% to-[#000000] to-100% bg-clip-text text-transparent text-[2.5rem] mb-4" style={{fontWeight: 900}}>
              Welcome Back!
            </h1>
            <p className="text-xl font-bold text-black">
              Shall we explore more talents together today?
            </p>
          </div>
        </div>
        <div className="flex w-full lg:w-[60%] pt-2 bg-white h-full rounded-r-xl items-center justify-center">
          <div className="p-4 w-full h-full flex items-center justify-center">
            <form
              onSubmit={handleSubmit}
              className="p-3 lg:px-12 h-full m-auto"
              id="login-form"
            >
              <h1 className="text-3xl font-extrabold lg:mb-5 ml-2 mt-2">
                Enter the Referral Hub!
              </h1>
              <LinkedInLoginButton />
              <div className="flex items-center justify-center my-4">
                <div className="border-t border-gray-300 flex-grow"></div>
                <span className="mx-2 text-gray-500">OR</span>
                <div className="border-t border-gray-300 flex-grow"></div>
              </div>
              <div className="login-input">
                {loginType === "email" ? (
                  <input
                    placeholder="Enter you Email"
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2   border rounded-md pr-10"
                    required
                  />
                ) : (
                  <>
                    <PhoneInput
                      country={"in"} // Assuming India for phone number
                      value={phone}
                      onChange={(value) => setPhone(value)}
                      inputProps={{
                        name: "phone",
                        required: true,
                        style: { border: "none" },
                      }}
                      className="phoneInput"
                      containerClass="w-5 px-2 bg-white py-1 my-1 border bg-white rounded-md"
                      inputClass="form-control"
                    />
                    {sentOTP && (
                      <div className="flex justify-center mt-4">
                        {isTimerActive ? (
                          <p className="text-red-500">{`Resend OTP in ${Math.floor(
                            timer / 60
                          )}:${String(timer % 60).padStart(2, "0")}`}</p>
                        ) : (
                          <button
                            type="button"
                            className="text-blue-700 text-sm hover:underline"
                            onClick={() => generateOtp(phone)}
                          >
                            Resend OTP
                          </button>
                        )}
                      </div>
                    )}
                  </>
                )}
                {/* <div className="flex  justify-end mt-2 hover:underline">
                  <h2>Login with &nbsp;</h2>
                  <p className="cursor-pointer" onClick={toggleLoginType}>
                    {loginType === "email" ? "Number" : "Email"}
                  </p>
                </div> */}
              </div>
              <div className="login-input">
                {loginType === "email" ? (
                  <>
                    <div className="relative" id="login-password">
                      <input
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full px-3 py-2   border rounded-md pr-10"
                        required
                      />
                      <button
                        type="button"
                        className=""
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    {sentOTP && (
                      <>
                        <label name="email" className="py-2">
                          OTP
                        </label>
                        <div className="items-center gap-2">
                          <div className=" flex flex-row gap-1">
                            {otp.map((digit, index) => (
                              <input
                                key={index}
                                value={digit}
                                maxLength={1}
                                onChange={(e) =>
                                  handleChange(e.target.value, index)
                                }
                                onKeyUp={(e) =>
                                  handleBackspaceAndEnter(e, index)
                                }
                                ref={(reference) =>
                                  (otpBoxReference.current[index] = reference)
                                }
                                className={`border small-screen w-10 h-10 text-black p-3 rounded-md block focus:border-2 focus:outline-none appearance-none`}
                              />
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                    {/* <div className="relative" id="login-password">
                    <input
                      placeholder=""
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full px-3 py-2   border rounded-md pr-10"
                      required
                    /> */}
                    {/* <button
                      type="button"
                      className=""
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </button>
                  </div> */}
                  </>
                )}
              </div>

              {loginType === "email" && (
                <div className="flex mt-3 items-center justify-start">
                  <input
                    type="checkbox"
                    className="w-7 h-3"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <span className="mx-2">Remember Me</span>
                </div>
              )}

              <div className="mx-auto mt-2 my-10 text-center">
                {loginType === "email" ? (
                  <div className=" mx-auto flex">
                    {
                      isLoading? <div className="w-8 h-8 border-4 m-auto border-dashed rounded-full text-center animate-spin border-blue-500"></div>:
                      <button
                      type="submit"
                      className={` border-2 text-base text-center p-2 justify-center my-6 w-full lg:w-[471px] h-[44px] bg-[#5C9CE6] font-bold text-black mx-auto rounded-full ${
                        areFieldsEmpty() ? "" : "font-bold"
                      }`}
                      disabled={areFieldsEmpty()}
                    >
                      {"Log In"}
                    </button> 
                    }
                    
                  </div>
                ) : (
                  <div className=" mx-auto flex my-10">
                    {sentOTP ? (
                      <button
                        type="button"
                        onClick={() => verifyOtp(phone, otp.join(""))}
                        className={` border-2 text-lg justify-center my-10 w-48 bg-[#5C9CE6] font-bold text-black mx-auto rounded-full     sm:w-auto ${
                          areOtpFieldsValid() ? "login-disable " : "login-able"
                        }`}
                        disabled={areOtpFieldsValid()}
                        id="login-btn"
                      >
                        Log In
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          if (!phone) {
                            toast.error("Enter the phone number");
                          }
                          generateOtp(phone);
                          setSentOTP(true);
                        }}
                        className={` border-2 text-lg justify-center mx-auto w-48 bg-[#5C9CE6] font-bold text-black rounded-full     sm:w-auto login-able
                    }`}
                        id="login-btn"
                      >
                        Send OTP
                      </button>
                    )}
                  </div>
                )}

                {loginType === "email" && (
                  <div
                    className="mt-4 text-black-500 cursor-pointer ease-in-out duration-400 text-center underline flex justify-center"
                    onClick={handleForgotPassword}
                  >
                    <p className="text-[20px] text-black-500">
                      Forgot Your Password
                    </p>
                  </div>
                )}

                <div className="text-center mt-2 text-gray-400 text-[20px]">
                  Don't have an account?{" "}
                  <Link to={routes.signup} className="text-black underline">
                    Sign up
                  </Link>
                </div>
              </div>
            </form>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Login;
