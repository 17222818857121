import React, { useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Dropdown from "./Dropdown";
import axios from "axios";
import InterviewPageTab from "../pages/InterviewPageTab";
import RescheduleEvent from "./RescheduleEventModal";
import { Close, Schedule } from "@mui/icons-material";
import GetMeetLinkModal from "./GetMeetLinkModal";
import { useNavigate } from "react-router-dom";
import ScheduleEvent from "./ScheduleEvent";
// function convertToISTAndReturnDate(utcDateString) {
//   const date = new Date(utcDateString);

//   // Convert date to IST timezone string and extract only the date part
//   const istDateString = date.toLocaleDateString("en-IN", {
//     timeZone: "Asia/Kolkata",
//   });

//   return istDateString; // Returns the date as a string in 'dd/mm/yyyy' format
// }
const ShortListPopup = ({
  selectedTalent,
  popupClosed,
  onTalentStatusUpdate,
  refreshTalents,
}) => {
  const [showAvailabilityPopup, setShowAvailabilityPopup] = useState(false);
  const [selectedTalentIndex, setSelectedTalentIndex] = useState(null);
  const [showSlotPopup, setShowSlotPopup] = useState(false);
  const [formData, setFormData] = useState({
    availabilitySlots: [],
  });
  const navigate = useNavigate();

  console.log("selectedTalent=", selectedTalent);
  const today = new Date();

  const futureDates =
    selectedTalent?.Talent?.TalentSelectedAvailabilitySlots?.filter((item) => {
      const itemDate = new Date(item.timeFrom);
      return itemDate >= today;
    });

  console.log(futureDates);

  // const sendMail = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_}/api/v1/job/send-email`,
  //       {
  //         to: selectedTalent?.email || "sushilbajpai2003@gmail.com",
  //         subject: "Testing the subject!",
  //         text: "Testing the text...",
  //       }
  //     );
  //     console.log("send mailresponse=", response);
  //   } catch (error) {
  //     setShowSlotPopup(false);
  //     console.error("An error occurred while sending the mail:", error);
  //   }
  // };

  const filterSkills = (talent) => {
    const skills = talent?.Talent?.TalentSkillsMappings?.map((mapping) => {
      const skillData = mapping.Skill || mapping.JobSkillsMapping?.Skill;
      return skillData
        ? {
            skillName: skillData.skillName,
            skillType: skillData.skillType,
            score: mapping.score,
            yearOfExperience: mapping.yearOfExperience,
          }
        : null; // Exclude if no skill data exists
    }).filter(Boolean); // Remove null values

    // console.log(skills);
    return skills;
  };

  // console.log(filterSkills(selectedTalent));

  const toggleAvailabilityPopup = () => {
    // sendMail();
    setShowAvailabilityPopup(!showAvailabilityPopup);
  };
  const handleDateClick = (e, dateTimeObj, index) => {
    setSelectedTalentIndex(index);
  };

  const handleSlotChange = (index, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      availabilitySlots: prevData.availabilitySlots.map((slot, i) =>
        i === index ? { ...slot, [field]: value === "" ? "1" : value } : slot
      ),
    }));
  };

  const addAvailabilitySlot = () => {
    setFormData((prevData) => ({
      ...prevData,
      availabilitySlots: [
        ...prevData.availabilitySlots,
        { day: "", timeFrom: "", duration: "" },
      ],
    }));
  };

  const updateTimeslot = async () => {
    console.log(
      "Updating timeslot for talent:",
      selectedTalent.id,
      selectedTalentIndex,
      formData.availabilitySlots
    );
    // if (selectedTalentIndex === null) return;

    const selectedDateTime = formData.availabilitySlots;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_}/api/v1/job/talent-timeslot/${selectedTalent.id}`,
        { selectedDateTime }
      );
      refreshTalents(selectedTalent.id);
      setShowSlotPopup(false);
      if (response.data.success) {
        // Handle success (you can show a notification or update state)
        console.log("Talent timeslot updated successfully.");
      } else {
        // Handle the case where the API returns a success: false
        console.error("Failed to update talent timeslot.");
      }
    } catch (error) {
      setShowSlotPopup(false);
      console.error(
        "An error occurred while updating the talent timeslot:",
        error
      );
    }
  };

  function getStatusColor(status) {
    switch (status) {
      case "Reffered":
        return "text-[#0671E0] bg-blue-100";
        break;
      case "Rescheduled":
        return "text-[#0671E0] bg-blue-100";
        break;
      case "Cancelled":
        return "text-[#FF0000] bg-red-100";
      case "Completed":
        return "text-[#54C180] bg-green-100";
      case "No Show":
        return "text-[#FF6A6A] bg-red-100";
      case "On Going":
        return "text-[#EF865C] bg-orange-100";
        break;
      default:
        return "text-[#0671E0] bg-blue-100";
    }
  }
  const calculateEndTime = (startTime, duration) => {
    if (!startTime || typeof startTime !== "string") {
      console.error("Invalid startTime provided:", startTime);
      return "00:00";
    }
    const [hours, minutes] = startTime.split(":").map(Number); 
    const durationInMinutes = duration * 60; 
  
    const startDate = new Date();
    startDate.setHours(hours, minutes, 0, 0);
  
    const endDate = new Date(startDate.getTime() + durationInMinutes * 60000);
  
    const endHours = String(endDate.getHours()).padStart(2, "0");
    const endMinutes = String(endDate.getMinutes()).padStart(2, "0");
  
    return `${endHours}:${endMinutes}`;
  };
  const convertToISTAndTime = (utcDateString) => {
    // Create a Date object from the UTC date string
    const date = new Date(utcDateString);
  
    // Convert the date to IST using the 'en-IN' locale and specify time zone as 'Asia/Kolkata'
    const options = { 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: true,  // To return AM/PM
    };
  
    // Convert and return the formatted time as AM/PM in IST
    const istTime = date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', ...options });
    return istTime;
  };

  // const getTimeFromForRound1 = (slots) => {
  //   return slots
  //     .filter(slot => slot?.roundDetails?.roundNumber === 1)  // Filter for roundNumber 1
  //     .map(slot => slot?.selectedAvailabilitySlotDetails?.timeFrom);  // Extract `timeFrom`
  // };

  // const getEndFromForRound1 = (slots) => {
  //   return slots
  //     .filter(slot => slot?.roundDetails?.roundNumber === 1)  // Filter for roundNumber 1
  //     .map(slot => slot?.selectedAvailabilitySlotDetails?.endTime);  // Extract `timeFrom`
  // };

  // const getRoundNameForRound1 = (slots) => {
  //   // Filter slots where roundNumber is 1 and extract the roundName
  //   return slots
  //     .filter(slot => slot?.roundDetails?.roundNumber === 1)  // Filter for roundNumber 1
  //     .map(slot => slot?.roundDetails.roundName);  // Extract roundName
  // };
  return (
    <div className="">
      <div className="fixed left-0 top-0 w-full h-full z-[99] flex justify-center items-center">
        <div className="bg-white flex flex-col mt-6 w-full md:w-[100vw] h-[100vh] p-4 rounded-lg overflow-y-auto">
          <button
            className="flex items-center justify-end"
            onClick={popupClosed}
          >
            <IoMdClose />
          </button>
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col w-[60%]">
              <div className="flex gap-4 mb-6 items-center justify-between">
                <div className="flex flex-col">
                  <p className="text-2xl  text-[#0671E0] font-semibold mx-10 text-left">
                    {selectedTalent.Talent?.talentName}
                  </p>
                  <p className="text-sm text-[#565656] mx-10">
                    {selectedTalent?.talentStatus}
                  </p>
                </div>
                <div className="flex gap-5">
                  <div className="flex items-center cursor-pointer justify-around border border-[#D7D7D7] rounded-md">
                   <div className="ml-4">
                    <svg
                      width="17"
                      height="11"
                      viewBox="0 0 17 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.328 5.50037V2.43762C12.328 2.03148 12.1667 1.64197 11.8795 1.35478C11.5923 1.06759 11.2028 0.90625 10.7966 0.90625H3.13977C2.73363 0.90625 2.34411 1.06759 2.05693 1.35478C1.76974 1.64197 1.6084 2.03148 1.6084 2.43762V8.56311C1.6084 8.96926 1.76974 9.35877 2.05693 9.64595C2.34411 9.93314 2.73363 10.0945 3.13977 10.0945H10.7966C11.2028 10.0945 11.5923 9.93314 11.8795 9.64595C12.1667 9.35877 12.328 8.96926 12.328 8.56311V5.50037ZM12.328 5.50037L15.3907 2.43762V8.56311L12.328 5.50037Z"
                        stroke="#0671E0"
                        stroke-width="1.53137"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    </div>
                    <ScheduleEvent interview={selectedTalent} popupClosed={popupClosed} text="Schedule Interview" />
                    {/* <span className="mx-4 text-[#0671E0] text-lg">Schedule Interview</span> */}
                  </div>
                  {/* <select
                    className="Alpha px-2 h-7 rounded-xl bg-white border border-gray-200"
                    onChange={(e) =>
                      onTalentStatusUpdate(selectedTalent.id, e.target.value)
                    }
                  >
                    <option
                      value="No-Update"
                      className="text-[#5CE1E6] bg-white"
                    >
                      No Update
                    </option>
                    <option value="Select" className="text-[#00BF63] bg-white">
                      Select
                    </option>
                    <option value="On-Hold" className="text-[#F2AD2B] bg-white">
                      On-Hold
                    </option>
                    <option value="Reject" className="text-[#F11010] bg-white">
                      Reject
                    </option>
                    <option
                      value="In-Process"
                      className="text-[#FF914D] bg-white"
                    >
                      In-Process
                    </option>
                  </select> */}
                  {/* <div className={`w-32 px-2 py-1 h-7 rounded-xl text-center text-black text-sm font-semibold items-center justify-center flex`}> */}
                  {/* <Dropdown
                    textStyles="text-black text-sm font-semibold text-center"
                    width="w-32"
                    options={["Reffered", "Shortlisted", "On-Hold", "Rejected"]}
                    selected={selectedTalent.talentStatus}
                    onSelect={(value) =>
                      onTalentStatusUpdate(selectedTalent.refferalId, value)
                    }
                    label="talentStatus"
                    buttonType="button"
                    defaultOption="On-Hold"
                  /> */}
                  {/* </div> */}
                  <select
                    className="Alpha cursor-pointer w-[10rem] text-lg rounded-md text-[#0671E0] border border-[#D7D7D7] h-8 bg-white"
                    onChange={(e) =>
                      onTalentStatusUpdate(selectedTalent.id, e.target.value)
                      // console.log(e.target.value)
                    }
                    defaultValue={selectedTalent.talentStatus}
                  >
                    <option
                      value="Shortlisted"
                      className="text-[#00BF63] bg-white"
                    >
                      Shortlisted
                    </option>
                    <option
                      value="Reffered"
                      className="text-[#F2AD2B] bg-white"
                    >
                      Reffered
                    </option>
                    <option value="onhold" className="text-[#F2AD2B] bg-white">
                      On-Hold
                    </option>
                    <option
                      value="Rejected"
                      className="text-[#F11010] bg-white"
                    >
                      Reject
                    </option>
                    <option
                      value="inprogress"
                      className="text-[#FF914D] bg-white"
                    >
                      In-Process
                    </option>
                    <option value="hired" className="text-[#FF914D] bg-white">
                      Hired
                    </option>
                  </select>
                </div>
              </div>
              <div className="flex flex-col md:flex-row h-fit gap-4 mx-[5vh]">
                <div className="w-[33%] space-y-5 md:w-65">
                  <p className="text-md my-2">
                    <span className="font-semibold">Company Name:</span>
                    <br /> {selectedTalent.Talent?.companyName}
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">Current Designation:</span>
                    <br /> {selectedTalent.Talent?.talentDesignation}
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">Job Seniority Level:</span>
                    <br /> {selectedTalent.Talent?.jobSeniorityLevel}
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">Current Location:</span>
                    <br /> {selectedTalent.Talent?.talentCurrentLocation}
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">Current CTC:</span>
                    <br /> {selectedTalent.Talent?.talentCurrentCTC} PA
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">Total Experience:</span>
                    <br /> {selectedTalent.Talent?.talentTotalExperience}
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">
                      Talent Job Role in 1 line:
                    </span>
                    <br /> {selectedTalent.Talent?.talentJobRoleExplain}
                  </p>
                </div>
                <div className="w-[33%] space-y-5 md:w-65">
                  <p className="text-md my-2">
                    <span className="font-semibold">
                      Current Employment Type:
                    </span>
                    <br /> {selectedTalent.Talent?.talentEmploymentType}
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">
                      Current Workplace Type:
                    </span>
                    <br />{" "}
                    {selectedTalent.Talent?.talentWorkplaceType || "Good"}
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">Year of Graduation:</span>
                    <br />{" "}
                    {new Date(
                      selectedTalent.Talent?.yearOfGraduation
                    ).toLocaleString("en-us", {
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">
                      Talent Preferred Location:
                    </span>
                    <br /> {selectedTalent.Talent?.talentPreferredLocation}
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">Expected CTC:</span>
                    <br /> {selectedTalent.Talent?.talentExpectedCTCMin} PA
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">Year Gap (if any):</span>
                    <br />
                    {selectedTalent.Talent?.yearGap}
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">Recruiter Comment:</span>
                    <br />
                    {selectedTalent.comment}
                  </p>
                  {/* <span className="font-semibold bg-white">
                    Recruiter Comment:
                  </span>
                 
                  <p className="text-md my-2">
                     {selectedTalent.comment}
                    </p> */}
                </div>
                <div className="w-[33%] space-y-5 md:w-65 pr-2">
                  <p className="text-md my-2">
                    <span className="font-semibold">College Name:</span>
                    <br /> {selectedTalent.Talent?.collegeName}
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">Course:</span>
                    <br /> {selectedTalent.Talent?.course}
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">Field:</span>
                    <br /> {selectedTalent.Talent?.field}
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">
                      Official Notice Period:
                    </span>
                    <br /> {selectedTalent.Talent?.officialNoticePeriod}
                  </p>
                  <p className="text-md my-2">
                    <span className="font-semibold">
                      LWD/How soon Talent can Join:
                    </span>
                    <br /> {selectedTalent.Talent?.joiningTime || "any time"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <p className="text-black ml-7 mt-5 font-bold text-2xl">
                    Events
                  </p>
                  <button onClick={()=>navigate("/interviews")} className="cursor-pointer text-[#0671E0]">
                    Go to schedules
                  </button>
                </div>
                <div className="talentCard ml-7">
                   <div
                      className={`shadow-lg border-none h-[22vh] mb-10 rounded-lg p-4 mt-2 bg-[#FAFAFA] "#FFFFFF"
                      }`}
                      // onClick={() => setClickedCard(data[index])}
                    >
                      <div className="flex justify-between items-start border-none w-full">
                        <div className="w-full">
                          <div
                            // onClick={() => setShowSlider(!showSlider)}
                            className="flex w-full items-center justify-between gap-2 mb-2"
                          >
                            <p
                              className="font-bold text-[18px]"
                            >
                              {selectedTalent?.Talent?.talentName}
                            </p>
                            <div
                              label={false}
                              className={`text-base flex gap-2 text-[10px] rounded-2xl items-center justify-center p px-3 ${
                                false === true
                                  ? "text-[#EF865C] border border-[#EF865C]"
                                  : "text-[#DFBE00] border border-[#DFBE00]"
                              }`}
                            >
                              {false === true ? (
                                <svg
                                  width="13"
                                  height="14"
                                  viewBox="0 0 13 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4 9.83333C4 9.83333 8.66667 10.5 10.6667 12.5H11.3333C11.7015 12.5 12 12.2015 12 11.8333V7.79133C12.5751 7.64333 13 7.12127 13 6.5C13 5.87873 12.5751 5.35667 12 5.20867V1.16667C12 0.79848 11.7015 0.5 11.3333 0.5H10.6667C8.66667 2.5 4 3.16667 4 3.16667H1.33333C0.596953 3.16667 0 3.76362 0 4.5V8.5C0 9.2364 0.596953 9.83333 1.33333 9.83333H2L2.66667 13.1667H4V9.83333ZM5.33333 4.27413C5.78887 4.1764 6.35167 4.04129 6.95953 3.86249C8.07833 3.53343 9.5 3.01508 10.6667 2.21639V10.7836C9.5 9.98493 8.07833 9.4666 6.95953 9.13753C6.35167 8.95873 5.78887 8.8236 5.33333 8.72587V4.27413ZM1.33333 4.5H4V8.5H1.33333V4.5Z"
                                    fill={`#EF865C`}
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="13"
                                  height="14"
                                  viewBox="0 0 13 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4 9.83333C4 9.83333 8.66667 10.5 10.6667 12.5H11.3333C11.7015 12.5 12 12.2015 12 11.8333V7.79133C12.5751 7.64333 13 7.12127 13 6.5C13 5.87873 12.5751 5.35667 12 5.20867V1.16667C12 0.79848 11.7015 0.5 11.3333 0.5H10.6667C8.66667 2.5 4 3.16667 4 3.16667H1.33333C0.596953 3.16667 0 3.76362 0 4.5V8.5C0 9.2364 0.596953 9.83333 1.33333 9.83333H2L2.66667 13.1667H4V9.83333ZM5.33333 4.27413C5.78887 4.1764 6.35167 4.04129 6.95953 3.86249C8.07833 3.53343 9.5 3.01508 10.6667 2.21639V10.7836C9.5 9.98493 8.07833 9.4666 6.95953 9.13753C6.35167 8.95873 5.78887 8.8236 5.33333 8.72587V4.27413ZM1.33333 4.5H4V8.5H1.33333V4.5Z"
                                    fill={`#DFBE00`}
                                  />
                                </svg>
                              )}
                  
                              {false===true?"Talent Event":"Job Event"}
                            </div>
                          </div>
                          <div
                            // onClick={() => setShowSlider(true)}
                            className="flex w-full text-gray-500 items-center justify-between gap-1 my-"
                          >
                            <p
                              variant="body2"
                              color="text.secondary"
                              className="mb-1 text-[12px] flex items-center gap-3"
                            >
                              {selectedTalent?.Talent?.talentDesignation}{" "}
                              <div className="w-1.5 h-1.5 bg-gray-300 rounded-full"></div>
                              {selectedTalent?.Talent?.companyName}
                            </p>
                            <p
                              variant="body2"
                              className="flex text-[12px] items-center justify-end text-gray-500"
                            >
                              {"31/12/2024"}
                            </p>
                          </div>
                          <div
                            className="flex text-gray-500 w-full items-center justify-between gap-"
                            // onClick={() => setShowSlider(true)}
                          >
                            <p
                              variant="body2"
                              color="text.secondary"
                              className="mb-2 text-[12px]"
                            >
                              {"technical round"}
                            </p>
                            <div className="text-right flex flex-col justify-center items-cente">
                              <p
                                variant="body2"
                                className=" text-[12px]"
                              >
                                {"12:30PM"} - {"1:20PM"}
                              </p>
                            </div>
                          </div>
                  
                          <div className="flex items-center justify-between space-x-2 mt- text-[#6B7280]">
                            <div
                              className={`flex items-center justify-center rounded-sm font-semibold space-x-2 ${getStatusColor(
                                selectedTalent?.talentStatus
                              )}`}
                            >
                              {(selectedTalent?.talentStatus === "Reffered" ||
                                selectedTalent?.talentStatus === "Reffered") && (
                                <div className="flex gap-4">
                                  <button className="flex items-center justify-center gap-2 px-4 py-2">
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12.7732 10.6519L11.7126 9.59121L12.7732 8.53056C14.2377 7.06611 14.2377 4.69173 12.7732 3.22726C11.3088 1.76279 8.93434 1.76279 7.46989 3.22726L6.40923 4.28792L5.34858 3.22726L6.40923 2.1666C8.45952 0.116343 11.7836 0.116343 13.8339 2.1666C15.8841 4.21685 15.8841 7.54093 13.8339 9.59121L12.7732 10.6519ZM10.6518 12.7732L9.59119 13.8339C7.54099 15.8841 4.21685 15.8841 2.1666 13.8339C0.116343 11.7836 0.116343 8.45946 2.1666 6.40924L3.22725 5.34858L4.28792 6.40924L3.22725 7.46991C1.76279 8.93436 1.76279 11.3087 3.22725 12.7732C4.69172 14.2377 7.06609 14.2377 8.53054 12.7732L9.59119 11.7125L10.6518 12.7732ZM10.1215 4.81825L11.1822 5.87891L5.87891 11.1822L4.81824 10.1215L10.1215 4.81825Z"
                                        fill="#0671E0"
                                      />
                                    </svg>
                                    {/* Join Now */}
                                    <GetMeetLinkModal text='Join Now' />
                                  </button>
                                </div>
                              )}
                              {selectedTalent?.talentStatus === "On Going" && (
                                <div className="flex gap-4">
                                  <button className="flex p-6 items-center justify-center gap-2 px-4 py-2">
                                    <div className="w-2 h-2 text-[14px] bg-[#EF865C] rounded-full"></div>
                                    {selectedTalent?.talentStatus}
                                  </button>
                                </div>
                              )}
                              {selectedTalent?.talentStatus === "Cancelled" && (
                                <div className="flex gap-4">
                                  <button className="flex text-[14px] items-center justify-center gap-2 px-4 py-2">
                                    <Close sx={{ width: "1rem" }} />
                                    {selectedTalent?.talentStatus}
                                  </button>
                                </div>
                              )}
                              {selectedTalent?.talentStatus === "Completed" && (
                                <div className="flex gap-4">
                                  <button className="flex text-[14px] items-center justify-center gap-2 px-4 py-2">
                                    <svg
                                      width="13"
                                      height="13"
                                      viewBox="0 0 13 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M11.9977 7.14725C11.5579 9.34668 9.89949 11.4177 7.5725 11.8804C6.43759 12.1064 5.2603 11.9686 4.20827 11.4866C3.15623 11.0046 2.28308 10.203 1.71315 9.19588C1.14321 8.18876 0.90554 7.02751 1.03398 5.87746C1.16242 4.72741 1.65042 3.6472 2.42849 2.79064C4.02439 1.03286 6.71913 0.548987 8.91855 1.42876"
                                        stroke="#20AE5C"
                                        stroke-width="1.31965"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M4.51953 6.26712L6.71895 8.46655L11.9976 2.74805"
                                        stroke="#20AE5C"
                                        stroke-width="1.31965"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    {selectedTalent?.talentStatus}
                                  </button>
                                </div>
                              )}
                              {selectedTalent?.talentStatus === "No Show" && (
                                <div className="flex gap-4">
                                  <button className="flex text-[14px] items-center text-[#FF6A6A] justify-center gap-2 px-4 py-2">
                                    <svg
                                      width="13"
                                      height="15"
                                      viewBox="0 0 13 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0.0625 2.25L1.0225 1.3125L7.945 8.25L13 13.29L12.0475 14.25L9.7975 12H1V9.75C1 8.355 2.9575 7.44 4.8175 7.02L0.0625 2.25ZM13 9.75V11.385L11.575 9.96V9.75C11.575 9.555 11.2 9.2625 10.6 9L8.5 6.885C10.5325 7.2225 13 8.1825 13 9.75ZM2.425 9.75V10.575H8.3725L6.04 8.25C4.1425 8.475 2.425 9.3375 2.425 9.75ZM7 0C7.79565 0 8.55871 0.31607 9.12132 0.87868C9.68393 1.44129 10 2.20435 10 3C10 4.4625 8.95 5.685 7.5625 5.9475L4.0525 2.4375C4.18394 1.75197 4.55009 1.13364 5.088 0.688807C5.62591 0.243971 6.30198 0.000412436 7 0ZM7 1.5C6.60218 1.5 6.22064 1.65804 5.93934 1.93934C5.65804 2.22064 5.5 2.60218 5.5 3C5.5 3.39782 5.65804 3.77936 5.93934 4.06066C6.22064 4.34196 6.60218 4.5 7 4.5C7.39782 4.5 7.77936 4.34196 8.06066 4.06066C8.34196 3.77936 8.5 3.39782 8.5 3C8.5 2.60218 8.34196 2.22064 8.06066 1.93934C7.77936 1.65804 7.39782 1.5 7 1.5Z"
                                        fill="#FF6A6A"
                                      />
                                    </svg>
                  
                                    {selectedTalent?.talentStatus}
                                  </button>
                                </div>
                              )}
                            </div>
                            {(selectedTalent?.talentStatus === "Rescheduled" ||
                              selectedTalent?.talentStatus === "Scheduled") && (
                              <div className="flex gap-4">
                                <button className="flex text-[14px] border border-gray-100 rounded-md items-center justify-center gap-2 px-4 py-2">
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M3.46257 2.43262C5.21556 0.91688 7.5007 0 10 0C15.5228 0 20 4.47715 20 10C20 12.1361 19.3302 14.1158 18.1892 15.7406L15 10H18C18 5.58172 14.4183 2 10 2C7.84982 2 5.89777 2.84827 4.46023 4.22842L3.46257 2.43262ZM16.5374 17.5674C14.7844 19.0831 12.4993 20 10 20C4.47715 20 0 15.5228 0 10C0 7.86386 0.66979 5.88416 1.8108 4.25944L5 10H2C2 14.4183 5.58172 18 10 18C12.1502 18 14.1022 17.1517 15.5398 15.7716L16.5374 17.5674Z"
                                      fill="#6B7280"
                                    />
                                  </svg>
                                  {/* <RescheduleEvent text={'Reschedule'} />
                                   */}
                                   Reschedule
                                </button>
                                {/* <button className="flex gap-2 px-4 py-2 border-gray-300 text-gray-700 rounded-md hover:bg-gray-50">
                                  <Close sx={{ width: "1.2rem" }} />
                                  Cancel
                                </button> */}
                              </div>
                            )}
                            {(selectedTalent?.talentStatus === "Cancelled" ||
                              selectedTalent?.talentStatus === "Reffered") && (
                              <div className="flex gap-4">
                                <button className="flex border text-[14px] border-gray-100 rounded-lg items-center justify-center gap-2 px-4 py-2">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M3.46257 2.43262C5.21556 0.91688 7.5007 0 10 0C15.5228 0 20 4.47715 20 10C20 12.1361 19.3302 14.1158 18.1892 15.7406L15 10H18C18 5.58172 14.4183 2 10 2C7.84982 2 5.89777 2.84827 4.46023 4.22842L3.46257 2.43262ZM16.5374 17.5674C14.7844 19.0831 12.4993 20 10 20C4.47715 20 0 15.5228 0 10C0 7.86386 0.66979 5.88416 1.8108 4.25944L5 10H2C2 14.4183 5.58172 18 10 18C12.1502 18 14.1022 17.1517 15.5398 15.7716L16.5374 17.5674Z"
                                      fill="#6B7280"
                                    />
                                  </svg>
                                  {/* <RescheduleEvent interview={selectedTalent} text="Reschedule" />
                                   */}
                                   Reschedule
                                </button>
                                <button className="flex border border-gray-100 rounded-md items-center justify-center gap-2 px-4 py-2 text-gray-700 hover:bg-gray-50">
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 14 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7 8.81725C7.1355 8.81725 7.24625 8.77425 7.33225 8.68825C7.41875 8.60225 7.462 8.4915 7.462 8.356C7.462 8.22 7.41875 8.109 7.33225 8.023C7.24625 7.937 7.1355 7.894 7 7.894C6.8645 7.894 6.75375 7.937 6.66775 8.023C6.58175 8.109 6.5385 8.22 6.538 8.356C6.5375 8.492 6.58075 8.60275 6.66775 8.68825C6.75475 8.77375 6.8655 8.81675 7 8.81725ZM7 6.5965C7.107 6.5965 7.19625 6.5605 7.26775 6.4885C7.33925 6.4165 7.375 6.3275 7.375 6.2215V2.41375C7.375 2.30675 7.33925 2.2175 7.26775 2.146C7.19625 2.0745 7.107 2.03875 7 2.03875C6.893 2.03875 6.80375 2.0745 6.73225 2.146C6.66075 2.2175 6.625 2.30675 6.625 2.41375V6.2215C6.625 6.328 6.66075 6.417 6.73225 6.4885C6.80375 6.56 6.893 6.596 7 6.5965ZM2.55775 10.75L1.27975 12.028C1.09025 12.2175 0.87125 12.261 0.62275 12.1585C0.37425 12.056 0.25 11.8698 0.25 11.5998V1.462C0.25 1.1165 0.36575 0.82825 0.59725 0.59725C0.82875 0.36625 1.117 0.2505 1.462 0.25H12.5387C12.8837 0.25 13.172 0.36575 13.4035 0.59725C13.635 0.82875 13.7505 1.117 13.75 1.462V9.53875C13.75 9.88375 13.6345 10.172 13.4035 10.4035C13.1725 10.635 12.8843 10.7505 12.5387 10.75H2.55775ZM2.2375 10H12.5387C12.6538 10 12.7595 9.952 12.856 9.856C12.9525 9.76 13.0005 9.65425 13 9.53875V1.46125C13 1.34625 12.952 1.2405 12.856 1.144C12.76 1.0475 12.6543 0.9995 12.5387 1H1.46125C1.34625 1 1.2405 1.048 1.144 1.144C1.0475 1.24 0.9995 1.34575 1 1.46125V11.2338L2.2375 10Z"
                                      fill="#6B7280"
                                    />
                                  </svg>
                                </button>
                              </div>
                            )}
                            {selectedTalent?.talentStatus === "Completed" && (
                              <div className="flex gap-4"></div>
                            )}
                            {selectedTalent?.talentStatus === "On Going" && (
                              <div className="flex gap-4"></div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div className="ml-0 h-[100vh] w-[30%] border border-[#D1D1D1] rounded-xl md:ml-9">
              <p className="ml-5 my-4 font-semibold text-xl text-[#16161D]">
                Resume
              </p>
              <p className="ml-5 flex-col border rounded-xl border-[#D1D1D1] text-md flex w-[50%] md:w-90">
                <a
                  className="font-semibold flex bg-blue-300 pl-2 pr-3 py-2 rounded-lg"
                  href={selectedTalent.photo}
                  download="filename.pdf"
                >
                  <FaDownload className="mx-2 mt-1" />
                  <iframe
                    src={selectedTalent.photo}
                    className="w-44 h-56"
                  ></iframe>
                </a>
                <span className="text-center underline py-3 text-[#0671E0] cursor-pointer">
                  view
                </span>
              </p>
              <p className="text-lg px-4 text-[#16161D] font-semibold mt-3">
                Primary Skills
              </p>
              {/* <p className="text-md">
                {filterSkills(selectedTalent).map((skill) => (
                  <div className="flex gap-9">

                    <div className="w-40">
                      <p>{skill.skillName}</p>
                    </div>
                    <div>
                      <p>{skill.exp} years</p>
                    </div>
                    <div>
                      <p>{skill.score}/5</p>
                    </div>
                  </div>
                ))}
              </p> */}
              <table className="table-auto w-full">
                <thead>
                  <tr className="">
                    <th className="px-4 py-2  text-left">Skill</th>
                    <th className="px-4 py-2 text-left">Exp</th>
                    <th className="px-4 py-2  text-right">Score</th>
                  </tr>
                </thead>
                <tbody>
                  {filterSkills(selectedTalent)
                    ?.filter((skill) => skill.skillType === "Primary")
                    .map((skill, index) => (
                      <tr key={index} className="">
                        <td className="px-4">{skill.skillName}</td>
                        <td className="px-4">{skill.yearOfExperience} Yrs</td>
                        <td className="px-4 text-right">{skill.score}/5</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <p className="text-lg px-4 text-[#16161D] font-semibold mt-3">
                Secondary Skills
              </p>
              {/* <p className="text-md">
                {selectedTalent?.skills?.map((skill) => (
                  <div className="flex gap-9">
                    <div className="w-40">
                      <p>{skill.skillName}</p>
                    </div>
                    <div>
                      <p>{skill.exp} years</p>
                    </div>
                    <div>
                      <p>{skill.score}/5</p>
                    </div>
                  </div>
                ))}
              </p> */}
              <table className="table-auto w-full mb-5">
                <thead>
                  <tr className="">
                    <th className="px-4 py-2  text-left">Skill</th>
                    <th className="px-4 py-2 text-left">Exp</th>
                    <th className="px-4 py-2  text-right">Score</th>
                  </tr>
                </thead>
                <tbody>
                  {filterSkills(selectedTalent)
                    ?.filter((skill) => skill.skillType === "Secondary")
                    .map((skill, index) => (
                      <tr key={index} className="">
                        <td className="px-4">{skill.skillName}</td>
                        <td className="px-4">{skill.yearOfExperience} Yrs</td>
                        <td className="px-4 text-right">{skill.score}/5</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* <div className=" flex w-full flex-col md:flex-row ">
            {selectedTalent?.talentStatus === "Shortlisted" ? (
              <button
                onClick={toggleAvailabilityPopup}
                className="bg-[#7ED957] rounded-full transition-all duration-200 w-40 h-10 text-black font-semibold"
              >
                Confirm Interview Slot
              </button>
            ) : null}
            {selectedTalent?.talentStatus === "Shortlisted" &&
              futureDates?.length < 1 && (
                <button
                  onClick={() => setShowSlotPopup(true)}
                  className="bg-blue-400 rounded-full transition-all duration-200 w-32 h-10 text-white font-semibold ml-2"
                >
                  Add Slot
                </button>
              )}
            {selectedTalent?.talentStatus === "Shortlisted" &&
              selectedTalent &&
              futureDates && (
                <div className="rounded-lg flex flex-row ml-2">
                  <div className="grid grid-cols-2 gap-2">
                    {futureDates?.map((dateTimeObj, index) => (
                      <p
                        key={index}
                        onClick={(e) => handleDateClick(e, dateTimeObj, index)}
                        className={`text-sm bg-gray-200 w-full cursor-pointer px-2 py-1 my-1 rounded-xl ${
                          selectedTalentIndex === index ? "text-red-500" : ""
                        }`}
                      >
                        {dateTimeObj.date} {dateTimeObj.timeFrom} -{" "}
                        {dateTimeObj.duration} hr
                      </p>
                    ))}
                  </div>
                </div>
              )}
          </div> */}
        </div>
      </div>

      {/* {selectedTalent.talentStatus === "Shortlisted" &&
        selectedTalent.Talent?.TalentSelectedAvailabilitySlots.length < 5 &&
        showSlotPopup && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center z-[99999]">
            <div className="bg-white p-8 rounded-lg relative">
              <IoMdClose
                className="absolute top-2 right-2 text-gray-500 cursor-pointer"
                onClick={() => setShowSlotPopup(false)}
              />
              <h2 className="text-lg font-semibold mb-4">
                {formData.availabilitySlots?.length > 0
                  ? `Availability Slots (${formData.availabilitySlots?.length})`
                  : "No Availability Slots Added"}
              </h2>
              <table>
                {formData.availabilitySlots?.length > 0 && (
                  <thead>
                    <tr className="bg-gray-200 border-b">
                      <th className="px-4 py-2 text-left">Day</th>
                      <th className="px-4 py-2 text-left">Time</th>
                      <th className="px-4 py-2 text-left">Duration</th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {formData.availabilitySlots.map((slot, index) => (
                    <tr key={index}>
                      <td>
                        <select
                          value={slot.day}
                          onChange={(e) =>
                            handleSlotChange(index, "day", e.target.value)
                          }
                          className="border w-24 rounded-md py-[0.9vh] mx-2 focus:outline-none focus:border-blue-500"
                        >
                          <option value="">Select Day</option>
                          <option value="Monday">Monday</option>
                          <option value="Tuesday">Tuesday</option>
                          <option value="Wednesday">Wednesday</option>
                          <option value="Thursday">Thursday</option>
                          <option value="Friday">Friday</option>
                          <option value="Saturday">Saturday</option>
                          <option value="Sunday">Sunday</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="time"
                          value={slot.timeFrom}
                          onChange={(e) =>
                            handleSlotChange(index, "timeFrom", e.target.value)
                          }
                          className="border rounded-md p-1 mx-2 focus:outline-none focus:border-blue-500"
                        />
                      </td>
                      <td>
                        <select
                          value={slot.duration}
                          onChange={(e) =>
                            handleSlotChange(index, "duration", e.target.value)
                          }
                          className="border w-24 rounded-md py-[0.9vh] mx-2 focus:outline-none focus:border-blue-500"
                        >
                          <option value="">Duration</option>
                          <option value="0.5">30 mins</option>
                          <option value="1">1 hrs</option>
                          <option value="1.5">1.5 hrs</option>
                          <option value="2">2 hrs</option>
                          <option value="2.5">2.5 hrs</option>
                          <option value="3">3 hrs</option>
                          <option value="3.5">3.5 hrs</option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="mt-4">
                <button
                  onClick={addAvailabilitySlot}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                >
                  Add Slot
                </button>
                <button
                  onClick={updateTimeslot}
                  className="bg-green-500 text-white px-4 py-2 rounded-md ml-2"
                >
                  Confirm Slot
                </button>
              </div>
            </div>
          </div>
        )} */}
    </div>
  );
};

export default ShortListPopup;
