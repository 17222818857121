import React, { useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const RichTextArea = ({ value, onChange, placeholder }) => {
  const editorRef = useRef(null);

  const handleEditorChange = (content) => {
    // console.log(content)
    onChange({
      target: {
        name: 'roleAndResponsibilites',
        value: content
      }
    });
  };

  return (
    <div className="w-full mt-2">
      <Editor
        apiKey="wpfst2j6p0c0evmgktuaqccc65rtidp6e0ejrn5majormwve" // You'll need to sign up for a free API key
        onInit={(evt, editor) => editorRef.current = editor}
        value={value}
        onEditorChange={handleEditorChange}
        init={{
          height: 192, // Equivalent to h-48
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'charmap',
            'paste', 'help', 'wordcount'
          ],
          toolbar: 'bold italic | bullist numlist | h1 h2 h3 | link | removeformat',
          content_style: `
            body { 
              font-family: system-ui, -apple-system, sans-serif;
              font-size: 14px;
              margin: 12px;
            }
          `,
          placeholder: placeholder,
          statusbar: false,
          // Match your existing styling
          skin: 'oxide',
          content_css: 'default',
          plugins_toolbar: '',
          branding: false,
        }}
      />
    </div>
  );
};

export default RichTextArea;