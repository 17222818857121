import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  Popover,
} from "@mui/material";
import { Close, Refresh, MoreVert } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function AvailabilitySlotsModal({ availabilitySlots }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [slots,setSlots] = useState([]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    handleClose();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const formatTime = (time, addHours = 0) => {
  //   const [hours, minutes] = time.split(":").map(Number);
  //   const totalHours = hours + addHours;
  //   const period = totalHours >= 12 ? "PM" : "AM";
  //   const hour12 = totalHours % 12 || 12;
  //   return `${hour12}${minutes ? `:${minutes}` : ""}${period}`;
  // };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const formattedSlots = availabilitySlots && availabilitySlots.map(slot => {
      const isRecurring = slot.recurring;
    
      return {
        ...slot,
        id: Math.random().toString(36).substr(2, 9), // Add unique random ID
        type: isRecurring ? "recurring" : "one-time", // Set type
        recurring: isRecurring ? "recurring" : "one-time", // Adjust recurring field
        dayOfWeek: slot.day, // Rename 'day' to 'dayOfWeek'
        startTime: slot.timeFrom, // Rename 'timeFrom' to 'startTime'
        ...(isRecurring ? { endDate: slot?.date ? formatDate(slot.date) : "1-23-2024" } : { date: slot?.date ? formatDate(slot.date) : "1-23-2024" }), // Handle 'date' or 'endDate'
      };
    });

    // console.log("slots",formattedSlots);

    setSlots(formattedSlots);
  }, [availabilitySlots])

  // console.log(availabilitySlots);
  

  const formatTimeRange = (startTime, duration) => {
    const start = new Date(`1970-01-01T${startTime}`);
    const end = new Date(start.getTime() + duration * 60000);
    return `${formatTime(start)} - ${formatTime(end)}`;
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const formatDate2 = (dateString) => {
    // Parse the date string (assumes "DD-MM-YYYY" format)
    const [day, month, year] = dateString.split('-');
  
    // Create a Date object
    const date = new Date(`${year}-${month}-${day}`);
  
    // Format the date
    return date.toLocaleDateString('en-US', {
      month: 'short', // Abbreviated month, e.g., "Jan"
      day: 'numeric', // Day of the month, e.g., "30"
      year: 'numeric' // Full year, e.g., "2024"
    });
  };

const formatDayAsPossessive = (dayString) => {
  return `${dayString.trim()}'s`;
};

  // Function to format the full slot time range
  const formatTimeSlot = (slot) => {
    const startTime = formatTime(slot.timeFrom);
    const endTime = formatTime(slot.timeFrom, parseInt(slot.duration));
    return `${startTime} - ${endTime}`;
  };

  return (
    <div>
      <div id={id} className="text">
        <div onClick={handleOpenModal} className="font-semibold text-[14px]">
          <p className="text-blue-600 cursor-pointer">View Availability Slots</p>
        </div>
      </div>

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className="">
              <Typography variant="h6">Availability Slots</Typography>
            </div>
          </div>
          <IconButton onClick={handleCloseModal} size="small">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="my-2 flex flex-col">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {slots?.map((slot) => (
              <div key={slot.id} className="border p-2 rounded-md text-center">
                <span>
                  {slot.type === 'one-time' 
                    ? `${formatDate2(slot.date)} ${formatTimeRange(slot.startTime, slot.duration)}`
                    : `${formatDayAsPossessive(slot.dayOfWeek)} ${formatTimeRange(slot.startTime, slot.duration)} (until ${formatDate2(slot.endDate)})`
                  }
                </span>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
