import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../context/auth";
import toast from "react-hot-toast";
import { routes } from "../App";
import { SyncLoader } from "react-spinners";

const Callback = () => {
  const [searchParams] = useSearchParams();
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
//   const []
  useEffect(() => {
    const code = searchParams.get("code");
    console.log(code,"linkedin code")
    if (code) {
      exchangeCodeForAccessToken(code);
    }
  }, [searchParams]);

  const exchangeCodeForAccessToken = async (code) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_}/api/v1/auth/linkedin`, {
        code,
      });
      console.log("User Info:", response);

      if(response && response.data.success){
        toast.success(response.data.message);
        setAuth({
          ...auth,
          user: response.data.user,
          token: response.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(response.data));
      }else{
        toast.error(response.data.message);
      }
      navigate(routes.home);
    //   navigate(routes.home);
    //   localStorage.setItem(
    //     "rememberedUser",
    //     JSON.stringify({ email, phone, password })
    //   );

    } catch (error) {
      console.error("Error exchanging code:", error);
    }
  };

  return (<div className="flex flex-col items-center justify-center h-screen">
    {/* <h2 className="text-2xl font-bold mb-4">Logging in...</h2> */}
    <h2 className="text-2xl font-bold mb-6">Please wait...</h2>
    <SyncLoader color="#36d7b7" />
  </div>);
};

export default Callback;
