import React, { useState, useCallback } from "react";
import {
  Box,
  Slider,
  SliderProps,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { steps } from "../pages/manager/EditTalent";
import Dropdown from "./Dropdown";

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: "#3b82f6",
  height: 3,
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#e5e7eb",
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 14,
    width: 14,
    backgroundColor: "#3b82f6",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#3b82f6",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
  "& .MuiSlider-mark": {
    display: "none",
  },
}));

export const CustomSlider = ({ value, onChange, ...props }) => {
  const handleChange = useCallback(
    (event, newValue) => {
      console.log("Slider value changing:", newValue);
      onChange(newValue);
    },
    [onChange]
  );

  return <StyledSlider {...props} value={value} onChange={handleChange} />;
};

function ExperienceTab({
  state,
  handleChange,
  errors,
  currentSalary,
  setCurrentSalary,
  currencyType,
  setCurrencyType,
  salaryRange,
  setSalaryRange,
  handleExpectedSalaryChange,
}) {
  const handleCurrentSalaryChange = useCallback((newValue) => {
    setCurrentSalary(newValue);
  }, []);

  const handleSalaryRangeChange = useCallback(
    (newValue) => {
      setSalaryRange(newValue);
      handleExpectedSalaryChange(newValue);
    },
    // [setSalaryRange, handleExpectedSalaryChange]
    []
  );

  // console.log("state -> ", state.formData.jobSeniorityLevel);

  return (
    <div className="flex justify-center min-h-scree h-full overflow-auto no-scrollba">
      <div className="w-full md:w-3/4 overflow-auto no-scrollbar">
        <Box mb={4}>
          <Stepper activeStep={1} alternativeLabel>
            {steps?.map((label, index) => (
              <Step key={index}>
                <StepLabel sx={{ fontWeight: 900 }}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <div>
          <div className="p-4 overflow-auto no-scrollba h-full">
            <div className="flex flex-col gap-4">
              <div className="border bg-white rounded-xl p-4 md:p-6 shadow-lg">
                {/* <div className="text-xl font-bold mb-4">Experience</div> */}
                <div className="grid grid-cols-1 h-full text-[#576675] md:grid-cols-2 gap-4 md:gap-x-8 md:gap-y-2 py-4 no-scrollbar">
                  <label className="block mb-1">
                    Current Company
                    <input
                      type="text"
                      name="companyName"
                      placeholder="Eg,Infosys ltd"
                      value={state.formData.companyName}
                      onChange={handleChange}
                      className={`w-full border rounded-md p-3 focus:outline-none focus:border-blue-500 ${
                        errors.companyName ? "" : ""
                      }`}
                      required
                    />
                    {errors.companyName && !state.formData.companyName && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.companyName}
                      </p>
                    )}
                  </label>
                  <label className="block mb-1">
                    Current Designation
                    <input
                      type="text"
                      name="talentDesignation"
                      placeholder="Eg, Software developer"
                      value={state.formData.talentDesignation}
                      onChange={handleChange}
                      className={`w-full border rounded-md p-3 focus:outline-none focus:border-blue-500 ${
                        errors.talentDesignation ? "" : ""
                      }`}
                      required
                    />
                    {errors.talentDesignation && !state.formData.talentDesignation &&  (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.talentDesignation}
                      </p>
                    )}
                  </label>
                  <label className="block">
                    Current job seniority level
                    <select
                      type="text"
                      // defaultValue={state.formData.jobSeniorityLevel}
                      value={state.formData.jobSeniorityLevel}
                      onChange={handleChange}
                      name="jobSeniorityLevel"
                      id="jobSeniorityLevel"
                      placeholder="Eg, head of development"
                      className="w-[100%] xl:w-[100%] border-2 p-3 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      {/* <option value="">Eg, head of development</option> */}
                      <option value="Internship">Internship</option>
                      <option value="Entry-Level">Entry Level</option>
                      <option value="Associate">Associate</option>
                      <option value="Mid-Senior-Level">Mid-Senior Level</option>
                      <option value="Director">Director</option>
                      <option value="Executive">Executive</option>
                      <option value="Not-Applicable">Not Applicable</option>
                    </select>
                    {errors.jobSeniorityLevel && !state.formData.jobSeniorityLevel && (
                      <Typography color="error" variant="caption">
                        {errors.jobSeniorityLevel}
                      </Typography>
                    )}
                  </label>
                  <label className="block mb-4">
                    Current employment type
                    <select
                      type="text"
                      // defaultValue={state.formData.talentEmploymentType}
                      value={state.formData.talentEmploymentType}
                      onChange={handleChange}
                      name="talentEmploymentType"
                      placeholder="Eg, full time"
                      id="employmentType"
                      className="w-[100%] xl:w-[100%] border-2 p-3 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      {/* <option value="" className="text-gray-400">
                        Eg, full time
                      </option> */}
                      <option value="Full-Time">Full-Time</option>
                      <option value="Part-Time">Part-Time</option>
                      <option value="Contract">Contract</option>
                      <option value="Temporary">Temporary</option>
                      <option value="Volunteer">Volunteer</option>
                      <option value="Consultant">Consultant</option>
                      <option value="Internship">
                      Internship
                      </option>
                    </select>
                    {errors.talentEmploymentType && (
                      <Typography color="error" variant="caption">
                        {errors.talentEmploymentType}
                      </Typography>
                    )}
                  </label>

                  <label className="block mb-4">
                    Total Experience
                    <input
                      type="number"
                      placeholder="Eg, 5 years"
                      name="talenttotalExperience"
                      value={state.formData.talenttotalExperience}
                      onChange={handleChange}
                      className={`w-full border rounded-md p-3 focus:outline-none focus:border-blue-500 ${
                        errors.talenttotalExperience ? "" : ""
                      }`}
                    />
                    {errors.talenttotalExperience && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.talenttotalExperience}
                      </p>
                    )}
                  </label>
                  <label className="block mb-1">
                    Current Industry
                    <input
                      type="text"
                      name="talentCurrentIndustry"
                      placeholder="Eg, Fintech"
                      value={state.formData.talentCurrentIndustry}
                      onChange={handleChange}
                      className={`w-full border rounded-md p-3 focus:outline-none focus:border-blue-500 ${
                        errors.talentCurrentIndustry ? "border-red-500" : ""
                      }`}
                      required
                    />
                    {errors.talentCurrentIndustry && !state.formData.talentCurrentIndustry && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.talentCurrentIndustry}
                      </p>
                    )}
                  </label>
                  <label className="block mb-4">
                    Number of gaps
                    <input
                      type="text"
                      name="yearGap"
                      placeholder="Eg, 3"
                      value={state.formData.yearGap}
                      onChange={handleChange}
                      className={`w-full border rounded-md p-3 focus:outline-none focus:border-blue-500 ${
                        errors.yearGap ? "border-red-500" : ""
                      }`}
                    />
                    {errors.yearGap && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.yearGap}
                      </p>
                    )}
                  </label>

                  <label className="block mb-4">
                    Notice Period
                    <select
                      type="text"
                      defaultValue={state.formData.officialNoticePeriod}
                      value={state.formData.officialNoticePeriod}
                      onChange={handleChange}
                      name="officialNoticePeriod"
                      id="officialNoticePeriod"
                      className="w-[100%] xl:w-[100%] border-2 p-3 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      {/* <option value="Immediate-Joiner" selected>
                        Eg, 50 days
                      </option> */}
                      <option value="Immediate-Joiner">Immediate-Joiner</option>
                      <option value="Serving">Serving</option>
                      <option value="7 days or less">7 days or less</option>
                      <option value="15 days or less">15 days or less</option>
                      <option value="30 days or less">30 days or less</option>
                      {/* <option value="Serving 30 days">Serving 30 days</option> */}
                      <option value="60 days or less">60 days or less</option>
                      <option value="90 days or less">90 days or less</option>
                      <option value="Any">Any</option>
                      {/* <option value="Serving 90 days">Serving 90 days</option>
                      <option value="Not-Serving 90 days">Not-Serving 90 days</option> */}
                      {/* <option value="30 days">Serving 7 days</option>
                      <option value="30 days">Not-Serving 7 days</option> */}
                    </select>
                    {errors.officialNoticePeriod && (
                      <Typography color="error" variant="caption">
                        {errors.officialNoticePeriod}
                      </Typography>
                    )}
                  </label>

                  <div className="mb-8">
                    <h2 className="text-base font-medium mb-4">
                      Current Salary
                    </h2>
                    <div className="flex items-center mb-2">
                      <div className="filters m-auto w-[5rem] bg-white flex h-11 mr-2 p-1 rounded-md border border-gray-200">
                        <Dropdown
                          width="w-full"
                          options={["INR", "USD", "EUR"]}
                          selected={currencyType}
                          buttonType="button"
                          onSelect={setCurrencyType}
                          label="Salary"
                        />
                      </div>
                      <input
                        type="number"
                        value={currentSalary}
                        onChange={(e) =>
                          setCurrentSalary(parseInt(e.target.value))
                        }
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                    <div className="mt-10">
                      <div className="flex justify-between text-base mt-1 text-blue-500 font-semibold">
                        {currentSalary} PA
                      </div>
                      <CustomSlider
                        value={currentSalary}
                        onChange={handleCurrentSalaryChange}
                        color="primary"
                        aria-labelledby="current-salary-slider"
                        valueLabelDisplay="auto"
                        step={0.5}
                        marks
                        min={0}
                        max={50}
                      />
                    </div>
                    {errors.talentCurrentCTC && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.talentCurrentCTC}
                    </p>
                  )}
                  </div>
                  
                  <div>
                    <h2 className="text-base font-medium mb-4">
                      Expected Salary
                    </h2>
                    <div className="flex items-center space-x-4 mb-2">
                      <div className="filters m-auto w-[5rem] bg-white flex h-11 mr-2 p-1 rounded-md border border-gray-200">
                        <Dropdown
                          width="w-full"
                          options={["INR", "USD", "EUR"]}
                          selected={currencyType}
                          buttonType="button"
                          onSelect={setCurrencyType}
                          label="Salary"
                        />
                      </div>
                      <div className="flex items-center flex-1">
                        <input
                          type="number"
                          value={salaryRange[0]}
                          onChange={(e) =>
                            handleSalaryRangeChange([
                              parseInt(e.target.value),
                              salaryRange[1],
                            ])
                          }
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                      <div className="flex items-center flex-1">
                        <input
                          type="number"
                          value={salaryRange[1]}
                          onChange={(e) =>
                            handleSalaryRangeChange([
                              salaryRange[0],
                              parseInt(e.target.value),
                            ])
                          }
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                    <div className="mt-10">
                      <div className="flex justify-between text-base mt-1 text-blue-500 font-semibold">
                        <span>{salaryRange[0]} PA</span>
                        <span>{salaryRange[1]} PA</span>
                      </div>
                      <CustomSlider
                        value={salaryRange}
                        onChange={handleSalaryRangeChange}
                        aria-labelledby="expected-salary-slider"
                        valueLabelDisplay="auto"
                        step={0.5}
                        min={0}
                        max={500000}
                      />
                    </div>
                    {errors.talentExpectedCTC && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.talentExpectedCTC}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExperienceTab;
