import React from 'react'
import Avatar from '@mui/material/Avatar';
import { useAuth } from '../context/auth';

const Navbar = () => {
    const { auth, setAuth } = useAuth();
  return (
    <nav className="flex justify-between items-center px-6 py-3 bg-blue-200 text-white shadow-md">

      {/* Right Side Buttons */}
      <div className="ml-auto flex items-center space-x-4">
        {/* Message Button */}
        <button
          className="bg-[#022369] text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
        >
          Message
        </button>
     
        {/* Avatar */}
        <Avatar className='hover:bg-blue-600 hover:cursor-pointer' sx={{ bgcolor: "#022369" }}>
          {auth?.user?.name.charAt(0).toUpperCase()}
        </Avatar>
      </div>
    </nav>
  )
}

export default Navbar