import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  TextField,
  MenuItem,
  FormControlLabel,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DeleteOutline } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";

const CustomButton = ({ children, addSkill, ...props }) => {
  return (
    <Button
      sx={{
        background: "#f3f3f3",
        borderRadius: "2rem",
        fontSize: ".8rem",
        color: "#69a7e8",
        fontWeight: "600",
        textTransform: "none", // This prevents uppercase transformation
        "&:hover": {
          background: "#e6e6e6", // Optional: lighter shade for hover effect
        },
      }}
      startIcon={<AddIcon sx={{ width: "1rem", marginRight: "-.5rem" }} />}
      onClick={() => addSkill("secondary")}
      className="mt-4 ml-20"
      {...props}
    >
      {children}
    </Button>
  );
};

export default function SkillsExpertiseForm({
  primarySkills,
  secondarySkills,
  softwareSkills,
  certifications,
  industry,
  onPrimarySkillChange,
  onSecondarySkillChange,
  onSoftwareSkillChange,
  onCertificationChange,
  onIndustryChange,
  onAddSkill,
  onAddSoftwareSkill,
  onAddCertification,
  onDeleteSkill,
  onDeleteSoftwareSkill,
  onDeleteCertification,
  errors,
  options,
  fetchPrimarySkills,
  fetchSoftwares,
  fetchCertifications,
  fetchIndustries
}) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const talentId = params.get("talentId");
  const { jobId } = useParams();
  const [firstTime,setFirstTime] = useState(true);

  const [initialCounts, setInitialCounts] = useState(() => {
    // Load persisted data from localStorage, or return default structure if not available
    const persistedCounts = localStorage.getItem("initialCounts");
    return persistedCounts
      ? JSON.parse(persistedCounts)
      : {
          primary: 0,
          secondary: 0,
          software: 0,
          certifications: 0,
        };
  });
  const countWithJobSkillMappingId = primarySkills.filter(
        (skill) => skill.jobSkillMappingId !== null && skill.jobSkillMappingId !== undefined
      ).length;
   const countWithJobSkillMappingIdS = secondarySkills.filter(
        (skill) => skill.jobSkillMappingId !== null && skill.jobSkillMappingId !== undefined
      ).length;
 

  useEffect(() => {

    if(!localStorage.getItem("initialCounts")){
      let newCounts;
      if(talentId){
        newCounts = {
          primary: countWithJobSkillMappingId,
          secondary: countWithJobSkillMappingIdS,
          software: 0,
          certifications: 0,
        };
      }else{
        newCounts = {
          primary: primarySkills.length,
          secondary: secondarySkills.length,
          software: softwareSkills.length,
          certifications: certifications.length,
        };
      }
  
      setInitialCounts(newCounts);
  
      localStorage.setItem("initialCounts", JSON.stringify(newCounts));
    }
   
    
  }, [jobId]);

  // useEffect(()=>{
    
  // }, [])

  useEffect(() => {
    console.log("initialCounts==", initialCounts);
  }, [initialCounts]);
  // console.log("optiosn",options);

  const renderSkillItem = (skill, index, type) => {
    const isPreExisting = index < initialCounts[type];
    const error =
      type === "primary"
        ? errors.primarySkills?.[index]
        : errors.secondarySkills?.[index];
    return (
      <div className="flex flex-col gap-2 p-1 rounded-lg my-3 no-scrollbar">
        <div className="flex gap-2 items-center">
          {isPreExisting ? (
            <FormControlLabel
              control={
                <Checkbox
                  disabled
                  checked={skill.checked || true}
                  onChange={(e) =>
                    type === "primary"
                      ? onPrimarySkillChange(index, "checked", e.target.checked)
                      : onSecondarySkillChange(
                          index,
                          "checked",
                          e.target.checked
                        )
                  }
                />
              }
              sx={{ margin: 0 }}
              label=""
            />
          ) : (
            <IconButton onClick={() => onDeleteSkill(index, type)}>
              <DeleteOutline />
            </IconButton>
          )}
          <div className="w-full flex flex-col gap- lg:flex-row justify-between items-center">
            {isPreExisting ? (<input
              value={skill.skillName}
              onChange={(e) =>
                type === "primary"
                  ? onPrimarySkillChange(index, "skillName", e.target.value)
                  : onSecondarySkillChange(index, "skillName", e.target.value)
              }
              disabled={isPreExisting}
              variant="outlined"
              size="small"
              className={`min-w-[12rem] w-full p-2 border mx-4`}
            />) 
          :
          <Select
                          placeholder="search your Skills"
                          name="primarySkillText"
                          options={options.primarySkillsOptions}
                          value={
                            skill.skillName
                              ? { value: skill.skillName, label: skill.skillName }
                              : null
                          }
                          onChange={(selectedOption) =>
                            type === "primary"
                              ? onPrimarySkillChange(index, "skillName", selectedOption?.value || "")
                              : onSecondarySkillChange(index, "skillName", selectedOption?.value || "")
                          }
                          className={`min-w-[15rem] w-full py-2 mx-4`}
            
                          onInputChange={(inputValue, actionMeta) => {
                            console.log("inputValue=", inputValue, actionMeta);
                            if (actionMeta.action === "input-change") {
                              // setFormData((prevData) => ({
                              //   ...prevData,
                              //   primarySkillText: inputValue,
                              // }));
                              fetchPrimarySkills(inputValue);
                            }

                          }}
                        />
          }
            <input
              value={skill.score}
              onChange={(e) =>
                type === "primary"
                  ? onPrimarySkillChange(index, "score", e.target.value)
                  : onSecondarySkillChange(index, "score", e.target.value)
              }
              variant="outlined"
              // disabled
              size="small"
              className="min-w-[12rem] w-full p-2 border mx-4"
              InputProps={{
                endAdornment: <span className="text-gray-500">/5</span>,
              }}
            />
            <input
              value={skill.years}
              onChange={(e) =>
                type === "primary"
                  ? onPrimarySkillChange(index, "years", e.target.value)
                  : onSecondarySkillChange(index, "years", e.target.value)
              }
              variant="outlined"
              size="small"
              // disabled
              className="min-w-[12rem] w-full p-2 border mx-4"
              InputProps={{
                endAdornment: <span className="text-gray-500">Yrs</span>,
              }}
            />
          </div>
        </div>
        {error && <Typography color="error">{error}</Typography>}
      </div>
    );
  };

  return (
    <div className="p-1 rounded-lg">
      <div className="mb-6">
        <div className="mb-2 flex justify-between my-2 ml-8 lg:ml-20">
          <div className="w-1/3">
            <p>Primary skill</p>
          </div>
          <div className="w-1/3">
            <p className="ml-10">Score</p>
          </div>
          <div className="w-1/3">
            <p className="ml-10 lg:ml-20">Years Of Experience</p>
          </div>
        </div>
        {primarySkills?.map((skill, index) =>
          renderSkillItem(skill, index, "primary")
        )}
        <div className="w-fit m-auto">
          <CustomButton onClick={() => onAddSkill("primary")} className="">
            Skill
          </CustomButton>
        </div>
      </div>

      <Box className="my-6 mt-12">
        <div className="mb-2 flex justify-between my-2 ml-8 lg:ml-20">
          <div className="w-1/3">
            <p>Secondary skill</p>
          </div>
          <div className="w-1/3">
            <p className="ml-10">Score</p>
          </div>
          <div className="w-1/3">
            <p className="ml-10 lg:ml-20">Years Of Experience</p>
          </div>
        </div>
        {secondarySkills?.map((skill, index) =>
          renderSkillItem(skill, index, "secondary")
        )}
        <div className="w-fit m-auto">
          <CustomButton onClick={() => onAddSkill("secondary")} className="">
            Skill
          </CustomButton>
        </div>
      </Box>

      <Box className="my-6 mt-12">
        <div variant="h6" className="mb-2">
          Software Proficiency
        </div>
        {softwareSkills?.map((skill, index) => (
          <div
            key={index}
            className="relative flex justify-center items-center gap-2 mb-2 space-x-4"
          >
            {index < initialCounts.software ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={skill.checked}
                    onChange={(e) =>
                      onSoftwareSkillChange(index, "checked", e.target.checked)
                    }
                  />
                }
                sx={{ margin: 0 }}
                label=""
              />
            ) : (
              <IconButton onClick={() => onDeleteSoftwareSkill(index)}>
                <DeleteOutline />
              </IconButton>
            )}
            <Select
               value={
                skill.name
                  ? { value: skill.name, label: skill.name } // Match the selected value with an option
                  : null
              }
              options={options.softwareOptions} // Pass the options array
              onChange={(selectedOption) =>
                onSoftwareSkillChange(index, "name", selectedOption?.value || "")
              }
              variant="outlined"
              size="small"
              className="w-full h-10 border rounded-lg"
              onInputChange={(inputValue, actionMeta) => {
                if (actionMeta.action === "input-change") {
                  // setFormData((prevData) => ({
                  //   ...prevData,
                  //   softwareSkillText: inputValue,
                  // }));
                  fetchSoftwares(inputValue); // Fetch options dynamically
                }
              }}
            />
            <Select
              value={skill.level ? { value: skill.level, label: skill.level } : null}
              onChange={(selectedOption) =>
                onSoftwareSkillChange(index, "level", selectedOption?.value || "")
              }
              size="small"
              options={[
                { value: "Beginner", label: "Beginner" },
                { value: "Intermediate", label: "Intermediate" },
                { value: "Expert", label: "Expert" },
              ]}
              sx={{ border: "none", minWidth: "9rem", minHeight: "2rem" }}
              className="w-48 bg-gray-100 absolut right-4 border-none"
            />
              
  
          </div>
        ))}
        <div className="w-fit m-auto my-4">
          <CustomButton onClick={onAddSoftwareSkill} className="">
            Software
          </CustomButton>
        </div>
      </Box>

      <Box className="mb-6">
        <div className="mb-2 flex justify-between my-2">
          <div className="w-1/2">
            <p>Certification</p>
          </div>
        </div>
        {certifications?.map((cert, index) => (
          <div key={index} className="flex items-center mb-2 space-x-4">
            {index < initialCounts.certifications ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cert.checked}
                    onChange={(e) =>
                      onCertificationChange(index, "checked", e.target.checked)
                    }
                  />
                }
                sx={{ margin: 0 }}
                label=""
              />
            ) : (
              <IconButton onClick={() => onDeleteCertification(index)}>
                <DeleteOutline />
              </IconButton>
            )}
            <Select
               value={
                cert.name
                  ? { value: cert.name, label: cert.name } // Match the selected value with the options
                  : null
              }
              options={options.certificationOptions} // Pass the certifications array
              onChange={(selectedOption) =>
                onCertificationChange(index, "name", selectedOption?.value || "")
              }
              variant="outlined"
              size="small"
              className="w-full p-3"
              onInputChange={(inputValue, actionMeta) => {
                if (actionMeta.action === "input-change") {
                  // setFormData((prevData) => ({
                  //   ...prevData,
                  //   softwareSkillText: inputValue,
                  // }));
                  fetchCertifications(inputValue); // Fetch options dynamically
                }
              }}
            />
          </div>
        ))}

        <div className="w-fit m-auto my-4">
          <CustomButton onClick={onAddCertification}>
            Certification
          </CustomButton>
        </div>
      </Box>
      <Box>
        <div className="mb-2 flex justify-between my-2">
          <div className="w-1/2">
            <p>Industry Knowledge</p>
          </div>
        </div>
        <Select
           value={
            industry
              ? { value: industry, label: industry } // Match the selected industry with the options
              : null
          }
          options={options.industryOptions}
          onChange={(selectedOption) =>
            onIndustryChange(selectedOption?.value || "")
          }
          size="small"
          className="w-full min-h-12"
          onInputChange={(inputValue, actionMeta) => {
            if (actionMeta.action === "input-change") {
              // setFormData((prevData) => ({
              //   ...prevData,
              //   softwareSkillText: inputValue,
              // }));
              fetchIndustries(inputValue); // Fetch options dynamically
            }
          }}
        />
          
        {errors.industry && (
          <Typography color="error">{errors.industry}</Typography>
        )}
      </Box>
    </div>
  );
}
