import {
  Autocomplete,
  Box,
  Step,
  StepLabel,
  Stepper,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import React from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { steps } from "../pages/manager/EditTalent";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.1),
    border: "1px solid",
    borderColor: theme.palette.divider,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: "100%",
  fontSize: "0.75rem",
  height: "45px",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.selected,
  },
}));

function TalentTab({
  state,
  setState,
  errors,
  setErrors,
  handleLocationSearch,
  locationOptions,
  handleChange,
}) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const talentId = params.get("talentId");
  return (
    <div className="flex justify-center min-h-scree h-full overflow-aut no-scrollbar">
      <div className="w-full md:w-3/4 overflow-auto no-scrollbar">
        <Box mb={4}>
          <Stepper activeStep={0} alternativeLabel>
            {steps?.map((label, index) => (
              <Step key={index}>
                <StepLabel sx={{ fontWeight: 900 }}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <div>
          <div className="p-4 overflow-auto no-scrollbar">
            <div className="flex flex-col gap-4 pb-4">
              <div className="border bg-white rounded-xl p-4 md:p-6 shadow-lg pb-12">
                {/* <div className="text-xl font-bold mb-4">Talent Details</div> */}
                <div className="grid grid-cols-1 md:grid-cols-2 text-[#576675] gap-4 md:gap-x-8 md:gap-y-2 overflow-auto">
                  <label className="block">
                    Talent Name
                    <input
                      type="text"
                      name="talentName"
                      placeholder="Enter talent name"
                      value={state.formData.talentName}
                      onChange={handleChange}
                      className={`w-full border rounded-md p-3 focus:outline-none focus:border-blue-500 ${
                        errors.talentName ? "" : ""
                      }`}
                      required
                    />
                    {errors.talentName && !state.formData.talentName && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.talentName}
                      </p>
                    )}
                  </label>
                  <div className="w-full">
                    <div>Gender</div>
                    <StyledToggleButtonGroup
                      value={state.formData.talentGender}
                      exclusive
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            talentGender: e.target.value,
                          },
                        }))
                      }
                      aria-label="gender"
                      fullWidth
                    >
                      <StyledToggleButton value="male" aria-label="male">
                        Male
                      </StyledToggleButton>
                      <StyledToggleButton value="female" aria-label="female">
                        Female
                      </StyledToggleButton>
                      <StyledToggleButton value="others" aria-label="others">
                        Others
                      </StyledToggleButton>
                    </StyledToggleButtonGroup>
                    {errors.talentGender && !state.formData.talentGender && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.talentGender}
                      </p>
                    )}
                  </div>
                  <label className="block mb-1">
                    <div className="flex items-center gap-2">
                      Email{" "}
                      <span className="flex w-fit">
                        <Tooltip
                          className="flex"
                          title="Your email will only be used for account-related communication and will not be shared with third parties."
                          arrow
                        >
                          <IoMdInformationCircleOutline />
                        </Tooltip>
                      </span>
                    </div>
                    <input
                    disabled={talentId ? true : false}
                      type="email"
                      name="talentMail"
                      placeholder="Eg, xyz@gmail.com"
                      value={state.formData.talentMail}
                      onChange={handleChange}
                      className={`w-full border rounded-md p-3 focus:outline-none focus:border-blue-500 ${
                        errors.talentMail ? "" : ""
                      }`}
                      required
                    />
                    {errors.talentMail && !state.formData.talentMail && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.talentMail}
                      </p>
                    )}
                  </label>
                  <label className="block mb-1">
                    <div className="flex items-center gap-2">
                      Phone Number{" "}
                      <span className="flex w-fit">
                        <Tooltip
                          className="flex"
                          title="Your phone number will only be used for account-related communication and will not be shared with third parties."
                          arrow
                        >
                          <IoMdInformationCircleOutline />
                        </Tooltip>
                      </span>
                    </div>
                    <input
                    disabled={talentId ? true : false}
                      type="tel"
                      name="contactNumber"
                      placeholder="Eg, 9898xxxx97"
                      value={state.formData.contactNumber}
                      onChange={handleChange}
                      className={`w-full border rounded-md p-3 focus:outline-none focus:border-blue-500 ${
                        errors.contactNumber ? "" : ""
                      }`}
                      required
                    />
                    {errors.contactNumber && !state.formData.contactNumber && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.contactNumber}
                      </p>
                    )}
                  </label>
                  <div className="flex flex-col h-12">
                    <label className="block">Current Location</label>
                    <Autocomplete
                      disablePortal
                      options={locationOptions}
                      className="h-8"
                      value={state.formData.talentCurrentLocation}
                      inputProps={{
                        style: { padding: "2px 4px", height: "28px" },
                      }}
                      onChange={(event, newValue) => {
                        setState((prevState) => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            talentCurrentLocation: newValue,
                          },
                        }));
                      }}
                      onInputChange={(event, newInputValue) => {
                        if (errors.talentCurrentLocation) {
                          setErrors((prev) => ({
                            ...prev,
                            talentCurrentLocation: '', // Clear the error
                          }));
                        }
                        handleLocationSearch(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Eg, Chennai"
                          error={!!errors.talentCurrentLocation}
                          helperText={errors.talentCurrentLocation}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              height: 46,
                              padding: 0,
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="block">Preferred Location</label>
                    <Autocomplete
                      disablePortal
                      options={locationOptions}
                      value={state.formData.talentPreferredLocation}
                      onChange={(event, newValue) => {
                        setState((prevState) => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            talentPreferredLocation: newValue,
                          },
                        }));
                      }}
                      onInputChange={(event, newInputValue) => {
                        if (errors.talentPreferredLocation) {
                          setErrors((prev) => ({
                            ...prev,
                            talentPreferredLocation: '', // Clear the error
                          }));
                        }
                        handleLocationSearch(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Eg, Bengaluru"
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              height: 46,
                              padding: 0,
                            },
                          }}
                          error={!!errors.talentPreferredLocation}
                          helperText={errors.talentPreferredLocation}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="border bg-white rounded-xl p-4 md:p-6 shadow-lg">
                {/* <div className="text-xl font-bold mb-4">Education</div> */}
                <div className="grid grid-cols-1 md:grid-cols-2 text-[#576675] gap-4 md:gap-x-8 md:gap-y-2 overflow-auto">
                  <label className="block mb-4">
                    Course
                    <input
                      type="text"
                      name="course"
                      placeholder="Eg, B tech CSE"
                      value={state.formData.course}
                      onChange={handleChange}
                      className={`w-full border rounded-md p-3 focus:outline-none focus:border-blue-500 ${
                        errors.course ? "" : ""
                      }`}
                    />
                    {errors.course && !state.formData.course && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.course}
                      </p>
                    )}
                  </label>
                  <label className="block mb-4">
                    Field
                    <input
                      type="text"
                      name="field"
                      placeholder="Eg, Computer science"
                      value={state.formData.field}
                      onChange={handleChange}
                      className={`w-full border rounded-md p-3 focus:outline-none focus:border-blue-500 ${
                        errors.field ? "" : ""
                      }`}
                    />
                    {errors.field && !state.formData.field && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.field}
                      </p>
                    )}
                  </label>
                  <label className="block mb-4">
                    College Name
                    <input
                      type="text"
                      placeholder="Eg, Anna University"
                      name="collegeName"
                      value={state.formData.collegeName}
                      onChange={handleChange}
                      className={`w-full border rounded-md p-3 focus:outline-none focus:border-blue-500 ${
                        errors.collegeName ? "" : ""
                      }`}
                    />
                    {errors.collegeName && !state.formData.collegeName && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.collegeName}
                      </p>
                    )}
                  </label>
                  <label className="block mb-4">
                    Year of Graduation
                    <input
                      type="text"
                      value={state.formData.yearOfGraduation}
                      onChange={handleChange}
                      placeholder="Eg, 2024"
                      name="yearOfGraduation"
                      id="yearOfGraduation"
                      className={`w-full border rounded-md p-3 focus:outline-none focus:border-blue-500 ${
                        errors.yearOfGraduation ? "" : ""
                      }`}
                    />
                    {errors.yearOfGraduation && !state.formData.yearOfGraduation && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.yearOfGraduation}
                      </p>
                    )}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TalentTab;
