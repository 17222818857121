import React, { useState, useEffect } from "react";
import Layout from "../../Component/Layout";
import axios from "axios";
import toast from "react-hot-toast";
import { Tooltip } from "@material-tailwind/react";
import { FaBell, FaEdit } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { FaRegCalendarAlt, FaDownload } from "react-icons/fa";
import { format, formatDistanceToNow, set } from "date-fns";
import { routes } from "../../App";
import SyncLoader from "react-spinners/SyncLoader";
import { useSearchParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { MoreHorizOutlined, MoreVertOutlined } from "@mui/icons-material";

const JobList = () => {
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  // const [talents, setTalents] = useState([]);
  // const [selectedJob, setSelectedJob] = useState(null);
  // const [selectedTalent, setSelectedTalent] = useState(null);
  // const [showAvailabilityPopup, setShowAvailabilityPopup] = useState(false);

  const [loadingJobs, setLoadingJobs] = useState(false);
  // const [loadingTalents, setLoadingTalents] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredJobs, setFilteredJobs] = useState([]);
  const statusParamValue = searchParams.get("statusParam");
  const [clickedTab,setClickedTab] = useState(statusParamValue);
  const [firstTime,setFirstTime] = useState(true);

  // Update the job status filter
  // const handleStatusChange = (e) => {
  //   const status = e.target.value;
  //   console.log(status);
  //   setSearchParams({ statusParam: status });
  // };

  // useEffect(() => {
  //   const tempJobs = jobs.data?.filter((tempJob) => {
  //     if (statusParamValue) {
  //       return tempJob.jobStatus === statusParamValue;
  //     } else {
  //       return true;
  //     }
  //   });
  //   console.log("tempJobstempJobs=", tempJobs);

  //   setFilteredJobs(tempJobs || []);
  // }, [statusParamValue, jobs]);

  // useEffect(() => {
  //   console.log("Filtered Jobs:", filteredJobs);
  // }, [filteredJobs]); // Update to listen for changes in filteredJobs

  // useEffect(() => {
  //   if (selectedTalent) {
  //     document.body.classList.add("overflow-hidden");
  //   } else {
  //     document.body.classList.remove("overflow-hidden");
  //   }

  //   return () => {
  //     document.body.classList.remove("overflow-hidden");
  //   };
  // }, [selectedTalent]);

  // const [filters, setFilters] = useState({
  //   jobTitle: "",
  //   talenttotalExperience: "",
  //   recruiterScore: "",
  // });
  // console.log("filters -> ");
  // console.log(filters);
  // const handleFilterChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log("e.target");
  //   console.log(e.target);
  //   console.log("name");
  //   console.log(name);
  //   console.log("value");
  //   console.log(value);
  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     [name]: value,
  //   }));
  // };

  // const toggleAvailabilityPopup = () => {
  //   setShowAvailabilityPopup(!showAvailabilityPopup);
  // };

  //sort all jobs
  // const sortJobs = (jobs) => {
  //   return jobs.sort((a, b) => {
  //     const statusOrder = { "Active": 1, "Draft": 2, "Closed": 3 };
  //     return statusOrder[a.jobStatus] - statusOrder[b.jobStatus];
  //   });
  // };

  // get all jobs
  const getMyJobs = async () => {
    try {
      setLoadingJobs(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/get-hiring-manager-jobs`,
        {
          params: {
            searchQuery: statusParamValue,
          },
        }
      );
      //const sortedJobs = sortJobs(response.data); // sort all jobs received
      console.log("response-data-", response);

      if(firstTime){
        setFirstTime(false);
        setJobs(response.data?.data);
        // setFilteredJobs(response.data?.data);
        // setClickedTab("All Jobs");
      }

      // setJobs(response.data?.data);
      setFilteredJobs(response.data?.data);
      // setClickedTab("All Jobs");
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    } finally {
      setLoadingJobs(false);
    }
  };

  useEffect(() => {
    console.log("jobsjobs=", jobs);
  }, [jobs]);

  useEffect(() => {
    getMyJobs();
  }, [statusParamValue]);

  const handleActiveTab = ()=>{
    const filtered=jobs.filter((job)=>{
      return job.jobStatus === "Active"
    }
  )
  // setFilteredJobs(filtered);
  setClickedTab("Active");
  setSearchParams({statusParam:"Active"});
  }
  
  const handleCloseTab = ()=>{
    const filtered=jobs.filter((job)=>{
      return job.jobStatus === "Closed"
    }
  )
  // setFilteredJobs(filtered);
  setClickedTab("Closed");
  setSearchParams({statusParam:"Closed"});
  }

  const handleDraftTab = ()=>{
    const filtered=jobs.filter((job)=>{
      return job.jobStatus === "Draft"
    }
  )
  // setFilteredJobs(filtered);
  setClickedTab("Draft");
  setSearchParams({statusParam:"Draft"});
  }

  const handleAllTab = ()=>{
    // setFilteredJobs(jobs);
  setClickedTab("All Jobs");
  setSearchParams({statusParam:"All Jobs"});
  }
  // delete a job
  // const deleteJob = async (jobId) => {
  //   try {
  //     await axios.delete(
  //       `${process.env.REACT_APP_API_}/api/v1/job/deleteJob/${jobId}`
  //     );
  //     toast.success("Job Deleted Successfully");
  //     // Refresh the job list after deletion
  //     getMyJobs();
  //   } catch (error) {
  //     console.error("Error deleting job:", error.response.data.message);
  //     toast.error("Something went wrong");
  //   }
  // };

  // const updateTalentStatus = async (talentId, newStatus) => {
  //   try {
  //     const response = await axios.put(
  //       `${process.env.REACT_APP_API_}/api/v1/job/talent-status/${talentId}`,
  //       { talentStatus: newStatus }
  //     );
  //     if (response.data.success) {
  //       // window.location.reload(false);
  //       toast.success(`Talent status updated to ${newStatus}`);
  //       // Refresh talent list or update talent status locally
  //     } else {
  //       toast.error("Failed to update talent status");
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Error updating talent status:",
  //       error.response.data.message
  //     );
  //     toast.error("Something went wrong");
  //   }
  // };

  // fetch talents for a specific job
  // const fetchTalentsForJob = async (jobId) => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_}/api/v1/job/get-jobtalent/${jobId}`
  //     );
  //     if (response.data.success) {
  //       setTalents(response.data.data);
  //     } else {
  //       toast.error("Failed to fetch talents");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching talents:", error);
  //     toast.error("Failed to fetch talents");
  //   }
  // };

  // handle change in job selection
  // const handleJobChange = async (event) => {
  //   const selectedValue = event.target.value;

  //   if (selectedValue === "all") {
  //     // Fetch all talents regardless of the job
  //     // await fetchAllTalents();
  //   } else {
  //     // Fetch talents associated with the selected job
  //     setSelectedJob(selectedValue);
  //     // await fetchTalentsForJob(selectedValue);
  //   }
  // };

  // fetch talents for a job
  // const fetchTalents = async (jobId) => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_}/api/v1/job/get-hiring-manager-talent`
  //     );
  //     if (response.data.success) {
  //       setTalents(response.data.data);
  //     } else {
  //       toast.error("Failed to fetch talents");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching talents:", error);
  //     toast.error("Failed to fetch talents");
  //   } finally {
  //     setLoadingTalents(false);
  //   }
  // };

  // fetch talent details
  // const fetchTalentDetails = async (talentId) => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_}/api/v1/job/get-single-talent/${talentId}`
  //     );
  //     if (response.data.success) {
  //       console.log(response);
  //       setSelectedTalent(response.data.data);
  //     } else {
  //       toast.error(response.data.message || "Failed to fetch talent details");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching talent details:", error);
  //     toast.error("Failed to fetch talent details");
  //   }
  // };

  // handle clicking on talent
  // const handleTalentClick = (talentId) => {
  //   console.log(talentId);
  //   fetchTalentDetails(talentId);
  //   setTalentId(talentId);
  //   setIsBellVisible(false);
  // };

  // toggle job status
  const toggleJobStatus = async (jobId, currentStatus) => {
    try {
      const newStatus = currentStatus === "Active" ? "Closed" : "Active";
      await axios.put(
        `${process.env.REACT_APP_API_}/api/v1/job/toggle-jobstatus/${jobId}`,
        { jobStatus: newStatus }
      );
      toast.success(`Job Status Updated to ${newStatus}`);
      // Refresh the job list after status update
      getMyJobs();
    } catch (error) {
      console.error("Error updating job status:", error.response.data.message);
      toast.error("Something went wrong");
    }
  };

  // fetch all talents regardless of the job
  // const fetchAllTalents = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_}/api/v1/job/get-hiring-manager-talent`
  //     );
  //     if (response.data.success) {
  //       setTalents(response.data.data);
  //     } else {
  //       toast.error("Failed to fetch talents");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching talents:", error);
  //     toast.error("Failed to fetch talents");
  //   }
  // };

  // const [talentId, setTalentId] = useState("");
  // const [selectedDate, setSelectedDate] = useState("");
  // const [selectedTimeFrom, setSelectedTimeFrom] = useState("");
  // const [selectedDuration, setSelectedDuration] = useState("");
  // const [selectedTalentIndex, setSelectedTalentIndex] = useState(null);
  // const [isBellVisible, setIsBellVisible] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // let sidebar ;

  // useEffect(()=>{
  //   sidebar = localStorage.getItem("sidebarOpen");
  // },[])

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const filteredTalents = talents.filter((talent) => {
  //   return (
  //     (filters.jobTitle === "" || talent.jobTitle === filters.jobTitle) &&
  //     (filters.talenttotalExperience === "" ||
  //       talent.talenttotalExperience === filters.talenttotalExperience) &&
  //     (filters.recruiterScore === "" ||
  //       talent.recruiterScore === filters.recruiterScore)
  //   );
  // });

  const getStatusColor = (status) => {
    switch (status) {
      case "Active":
        return "text-[#359742] bg-green-100";
      case "Closed":
        return "text-[#FF0000] bg-red-100";
      case "Draft":
        return "text-[#FF8400] bg-yellow-100";
    }
  };

  // console.log("filteredTalents -> ");
  // console.log(filteredTalents);

  // const handleDateClick = (e, dateTimeObj, index) => {
  //   setSelectedTalentIndex(index); // Assuming you have a state for this
  //   setSelectedDate(dateTimeObj.date);
  //   setSelectedTimeFrom(dateTimeObj.timeFrom);
  //   setSelectedDuration(dateTimeObj.duration);

  //   // handleMeetingCreation(dateTimeObj.date, dateTimeObj.timeFrom, dateTimeObj.duration);
  // };

  // const popupClosed = () => {
  //   setSelectedTalentIndex(null);
  //   setSelectedDate(null); // Reset selectedDates to an empty array
  //   setShowAvailabilityPopup(false); // Close the availability popup
  //   setSelectedTalent(null);
  //   document.body.classList.remove("overflow-hidden");
  // };

  // useEffect to perform actions when selectedDate changes
  // useEffect(() => {
  //   if (selectedDate) {
  //     if (selectedDuration) {
  //       handleMeetingCreation();
  //     }
  //   }
  // }, [selectedDate, selectedDuration]);

  // Function to handle meeting creation
  // const handleMeetingCreation = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_}/api/v1/job/arrange-meeting/${talentId}`,
  //       {
  //         selectedDate,
  //         selectedTimeFrom,
  //         selectedDuration,
  //       }
  //     );
  //     toast.success("Interview Scheduled. Please check your Calendar");
  //     // Handle success or redirect as needed
  //   } catch (error) {
  //     console.error("Error creating meeting:", error);
  //     toast.error("Invitation not sent");
  //   }
  // };

  // A reusable component for displaying labeled information
  // const InfoItem = ({ label, value }) => (
  //   <p className="text-sm my-2">
  //     <span className="font-semibold">{label}:</span>
  //     <br />
  //     {value}
  //   </p>
  // );

  // A component for rendering a section of skills
  // const SkillSection = ({ title, skills }) => (
  //   <div className="mb-4">
  //     <p className="text-md font-semibold">{title}</p>
  //     <div className="grid grid-cols-3 gap-2 text-sm">
  //       {skills.map((skill, index) => (
  //         <div key={index}>
  //           <p>{skill.skillName}</p>
  //           <p>
  //             {skill.exp} years, {skill.score}/5
  //           </p>
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // );

  // const formatDate = (timestamp) => {
  //   try {
  //     if (
  //       !timestamp ||
  //       timestamp._seconds === undefined ||
  //       timestamp._nanoseconds === undefined
  //     ) {
  //       return "Invalid date";
  //     }
  //     const milliseconds =
  //       timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6;
  //     const date = new Date(milliseconds);
  //     return date.toLocaleDateString("en-IN", {
  //       day: "numeric",
  //       month: "long",
  //       year: "numeric",
  //     });
  //   } catch (error) {
  //     console.error("Error formatting date:", error);
  //     return "Invalid date";
  //   }
  // };

  // useEffect(() => {
  //   // Check if any talent has askTalentFeedBack true
  //   const hasTalentWithFeedback = talents.some(
  //     (talent) => talent.bellVisibility
  //   );
  //   setIsBellVisible(hasTalentWithFeedback);
  // }, [talents]);

  // lifecycle method
  useEffect(() => {
    getMyJobs();
    // fetchTalents()
  }, []);

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [activeJobId, setActiveJobId] = React.useState(null);

  const handleMenuOpen = (event, jobId) => {
    setMenuAnchor(event.currentTarget);
    setActiveJobId(jobId);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setActiveJobId(null);
  };

  const repostJob = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/job/repost-job/${id}`
      );
      console.log(response);
      getMyJobs();

      toast.success("Job reposted successfully", {
        duration: 4000,
        position: "top-left",
      });
    } catch (error) {
      console.error("Error reposting job:", error);
      toast.error("Error reposting job", {
        duration: 4000,
        position: "top-left",
      });
    }
  };
  // const EditJob = async()=>{

  // }
  return (
    <Layout showFooter={true}>
      <div className="w-full p-10 bg-[#F7F7F7]">
        <div className=" w-full">
          <div className="">
            <div className="flex gap-2 w-full bg-[#F7F7F7] my-4 mb-4 p-1 overflow-auto no-scrollbar">
              <div className={`flex border-b-2 ${ clickedTab === "All Jobs" ? "border-[#0671E0] bg-[#F3F8FD]" : "bg-white"} px-4 justify-between group hover:bg-[#F3F8FD] pl-6 items-center p-2 w-full h-[74px] min-w-[6rem] rounded-lg`}>
                <div onClick={handleAllTab} className="flex flex-col w-full cursor-pointer gap-1">
                  <h1 className="text-[18px] font-bold">{jobs.length}</h1>
                  <p className="text-[#A0AEC0] text-[12px] font-[Inter]">
                    All Jobs
                  </p>
                </div>
              </div>
              <div className={`flex border-b-2 px-4 ${ clickedTab === "Active" ? "border-[#0671E0] bg-[#F3F8FD]" : "bg-white"} justify-between group hover:bg-[#F3F8FD] pl-6 items-center p-2 w-full h-[74px] min-w-[6rem] rounded-lg` }>
                <div onClick={handleActiveTab} className="flex cursor-pointer w-full flex-col gap-1">
                  <h1 className="text-[18px] font-bold">{jobs.filter((job)=>job.jobStatus==="Active").length}</h1>
                  <p className="text-[#A0AEC0] text-[12px] font-[Inter]">
                    Active Jobs
                  </p>
                </div>
              </div>
              <div className={`flex border-b-2 px-4 ${ clickedTab === "Closed" ? "border-[#0671E0] bg-[#F3F8FD]" : "bg-white"} justify-between group hover:bg-[#F3F8FD] pl-6 items-center p-2 w-full  h-[74px] min-w-[6rem] rounded-lg`}>
                <div onClick={handleCloseTab} className="flex cursor-pointer w-full flex-col gap-1">
                  <h1 className="text-[18px] font-bold">{jobs.filter((job)=>job.jobStatus==="Closed").length}</h1>
                  <p className="text-[#A0AEC0] text-[12px] font-[Inter]">
                    Closed Jobs
                  </p>
                </div>
              </div>
              <div className={`flex px-4 border-b-2 ${ clickedTab === "Draft" ? "border-[#0671E0] bg-[#F3F8FD]" : "bg-white"} justify-between group hover:bg-[#F3F8FD] pl-6 items-center p-2 w-full  h-[74px] min-w-[6rem] rounded-lg`}>
                <div onClick={handleDraftTab} className="flex cursor-pointer w-full flex-col gap-1">
                  <h1 className="text-[18px] font-bold">{jobs.filter((job)=>job.jobStatus==="Draft").length}</h1>
                  <p className="text-[#A0AEC0] text-[12px] font-[Inter]">
                    Draft Jobs
                  </p>
                </div>
              </div>
            </div>
        
            {/* Jobs Section */}
            <div className="">
              {loadingJobs ? (
                <div className="flex justify-center items-center w-full h-64">
                  <SyncLoader color="#38e3e0" />
                </div>
              ) : (
                <div className="grid grid-cols-3 justify-center w-full gap-4 p-1 mt-8">
                  { filteredJobs?.length === 0 ? <p className="text-[#A0AEC0] col-span-3 flex items-center justify-center text-[12px] font-[Inter]">No Jobs Found</p> :
                  filteredJobs?.map((p) => (
                    <Card
                      key={p?.jobId}
                      sx={{boxShadow: 'none'}}
                      className="shadow-[0px_-5px_14.8px_rgba(0,0,0,0.05)] w-[100%] bg-white font-[Inter]"
                    >
                      <CardHeader
                        action={
                          <div className="relative">
                            <IconButton
                              aria-label="settings"
                              onClick={(event) =>
                                handleMenuOpen(event, p.jobId)
                              }
                            >
                              <MoreVertOutlined />
                            </IconButton>
                            <Menu
                              className="text-sm"
                              id={`menu-${p.jobId}`}
                              anchorEl={menuAnchor}
                              open={activeJobId === p.jobId}
                              onClose={handleMenuClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              sx={{
                                "& .MuiPaper-root": {
                                  backgroundColor: "white",
                                  borderRadius: "7px",
                                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                  border: "1px solid #E5E7EB",
                                  minWidth: "150px",
                                  padding: "0px",
                                  marginTop: "-5px",
                                },
                              }}
                            >
                              {p.jobStatus !== "Draft" && (
                                <MenuItem
                                  onClick={() => {
                                    toggleJobStatus(p.jobId, p.jobStatus);
                                    handleMenuClose();
                                  }}
                                  sx={{
                                    borderBottom: "solid 1px #E3E3E3",
                                    marginX: "4px",
                                  }}
                                  className="px-4 py-2 text-sm font-medium text-black hover:bg-gray-100 border-b border-gray-200"
                                >
                                  <p className="text-sm">
                                    {p.jobStatus === "Active"
                                      ? "Close Job"
                                      : "Activate Job"}
                                  </p>
                                </MenuItem>
                              )}
                              <MenuItem
                                onClick={handleMenuClose}
                                sx={{
                                  borderBottom: "solid 1px #E3E3E3",
                                  marginX: "4px",
                                }}
                                className="px-4 py-2 text-sm font-medium text-black hover:bg-gray-100 border-b border-gray-200"
                              >
                                <Link
                                  to={`/dashboard/user/edit-jobs?jobId=${p.jobId}`}
                                >
                                  <p className="text-sm">Edit Job Details</p>
                                </Link>
                              </MenuItem>
                              <MenuItem
                                onClick={handleMenuClose}
                                sx={{
                                  borderBottom:
                                    p.jobStatus === "Draft"
                                      ? "0px"
                                      : "solid 1px #E3E3E3",
                                  marginX: "4px",
                                }}
                                className="px-4 py-2 text-sm font-medium text-black hover:bg-gray-100"
                              >
                                <Link to={`/dashboard/user/job/${p.jobId}`}>
                                  <p className="text-sm">View Details</p>
                                </Link>
                              </MenuItem>
                              {p.jobStatus !== "Draft" && (
                                <MenuItem
                                  onClick={() => {
                                    repostJob(p.jobId);
                                    handleMenuClose();
                                  }}
                                  className="px-4 py-2 text-sm font-medium text-black hover:bg-gray-100"
                                >
                                  <p className="text-sm ml-1">Repost</p>
                                </MenuItem>
                              )}
                            </Menu>
                          </div>
                        }
                        title={
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography
                            onClick={()=>{
                              navigate(`/dashboard/user/job/${p.jobId}`)
                            }}
                              variant="h6"
                              component="div"
                              className="text-[#004389] cursor-pointer capitalize font-medium text-base"
                            >
                              {p.jobTitle}
                            </Typography>
                          </Box>
                        }
                        subheader={
                          <Box>
                            <div className="flex gap-2 items-center capitalize text-[#565656] text-[13px]">
                              {p.companyName}{" "}
                              <div className="w-1.5 h-1.5 text-[#565656] bg-gray-300 rounded-full" />{" "}
                              {p.jobType}{" "}
                              {/* <div className="w-1.5 h-1.5 text-gray-300 bg-gray-300 rounded-full" />{" "}
                              {p.workplaceType} */}
                            </div>
                            <div className="flex gap-2 mt-2 text-sm">
                              <div
                                className={`${getStatusColor(
                                  p.jobStatus
                                )} w-fit p-1 px-3 rounded-md`}
                              >
                                {p.jobStatus}
                              </div>
                              {p.jobStatus === "Active" && (
                                <div className="text-blue-600 bg-blue-100 w-fit p-1 px-3 rounded-md">
                                 {`${p.JobReferrals?.filter((ref) => ref.talentStatus === "Reffered").length} New Applicants`}
                                </div>
                              )}
                            </div>
                          </Box>
                        }
                      />
                      <CardContent>
                        <div className="flex flex-col md:flex-row items-center mx-auto justify-between gap-2 w-[calc(100%-10px)]">
                          <div className="border border-gray-100 rounded-lg p-2 shadow-sm min-w-28">
                          <div className="font-medium text-[12px] text-[#A0AEC0]">
                              Shortlisted
                            </div>
                            <div className="font-bold text-[Inter] text-[18px]">{p.JobReferrals?.filter((ref)=>ref.talentStatus==="Shortlisted").length}</div>
                            
                          </div>
                          <div className="border border-gray-100 rounded-lg p-2 shadow-sm min-w-28">
                          <div className="font-medium text-[12px] text-[#A0AEC0]">
                              In Process
                            </div>
                            <div className="font-bold text-[Inter] text-[18px]">{p.JobReferrals?.filter((ref)=>ref.talentStatus==="inprocess").length}</div>
                            
                          </div>
                          <div className="border border-gray-100 rounded-lg p-2 shadow-sm min-w-28">
                          <div className="font-medium text-[12px] text-[#A0AEC0]">
                              Hired
                            </div>
                            <div className="font-bold text-[Inter] text-[18px]">
                            {p.JobReferrals?.filter((ref)=>ref.talentStatus==="hired").length}
                              <span className="text-gray-400 font-bold text-[Inter] text-[18px]">
                              {`/${p.JobReferrals?.length}`}
                              </span>
                            </div>
                           
                          </div>
                        </div>
                        <div className="flex gap-2 mt-3 text-[12px] items-center text-gray-400">
                          <svg
                            width="13"
                            height="14"
                            viewBox="0 0 11 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.75781 0.583008C8.62718 0.583008 10.9531 3.00805 10.9531 5.99967C10.9531 8.9913 8.62718 11.4163 5.75781 11.4163C2.88844 11.4163 0.5625 8.9913 0.5625 5.99967C0.5625 3.00805 2.88844 0.583008 5.75781 0.583008ZM5.75781 1.66634C4.65551 1.66634 3.59835 2.12289 2.8189 2.93555C2.03945 3.7482 1.60156 4.8504 1.60156 5.99967C1.60156 7.14895 2.03945 8.25115 2.8189 9.0638C3.59835 9.87646 4.65551 10.333 5.75781 10.333C6.86012 10.333 7.91728 9.87646 8.69672 9.0638C9.47617 8.25115 9.91406 7.14895 9.91406 5.99967C9.91406 4.8504 9.47617 3.7482 8.69672 2.93555C7.91728 2.12289 6.86012 1.66634 5.75781 1.66634ZM5.75781 2.74967C5.88506 2.74969 6.00788 2.7984 6.10297 2.88656C6.19807 2.97472 6.25882 3.09621 6.27371 3.22797L6.27734 3.29134V5.77543L7.68371 7.24172C7.77689 7.33919 7.83099 7.46999 7.83502 7.60755C7.83905 7.74511 7.7927 7.8791 7.70541 7.98233C7.61811 8.08555 7.4964 8.15026 7.365 8.16331C7.23359 8.17636 7.10236 8.13677 6.99793 8.05259L6.9491 8.00763L5.3905 6.38263C5.30976 6.29837 5.2579 6.18872 5.24296 6.07063L5.23828 5.99967V3.29134C5.23828 3.14768 5.29302 3.00991 5.39045 2.90833C5.48788 2.80674 5.62002 2.74967 5.75781 2.74967Z"
                              fill="#9D9D9D"
                            />
                          </svg>
                          <span> Posted</span>
                          {formatDistanceToNow(new Date(p?.createdAt), {
                            addSuffix: true,
                          })}
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Popup for displaying talent details setSelectedTalent(null) */}
      {/* {selectedTalent && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white mt-12 w-[95vw] md:w-[180vh] h-[90vh] p-4 rounded-lg overflow-y-auto">
            <button onClick={popupClosed}>
              <IoMdClose />
            </button>
            <div className="flex flex-col md:flex-row w-full gap-4 md:gap-[95vh] mx-auto">
              <p className="text-2xl w-full md:w-96 my-4 pl-[vh] font-bold mx-10 text-left">
                {selectedTalent.talentName}
              </p>
              <select
                className="Alpha px-2 h-7 rounded-xl bg-white border border-gray-200"
                onChange={(e) =>
                  updateTalentStatus(selectedTalent.id, e.target.value)
                }
              >
                <option value="No-Update" className="text-[#5CE1E6] bg-white">
                  No Update
                </option>
                <option value="Select" className="text-[#00BF63] bg-white">
                  Select
                </option>
                <option value="On-Hold" className="text-[#F2AD2B] bg-white">
                  On-Hold
                </option>
                <option value="Reject" className="text-[#F11010] bg-white">
                  Reject
                </option>
                <option value="In-Process" className="text-[#FF914D] bg-white">
                  In-Process
                </option>
              </select>
            </div>
            <div className="flex flex-col md:flex-row h-fit gap-4 mx-[5vh]">
              <div className="w-full md:w-64">
                <p className="text-md my-2">
                  <span className="font-semibold">Company Name:</span>
                  <br /> {selectedTalent.companyName}
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">Current Designation:</span>
                  <br /> {selectedTalent.talentDesignation}
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">Job Seniority Level:</span>
                  <br /> {selectedTalent.jobSeniorityLevel}
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">Current Location:</span>
                  <br /> {selectedTalent.talentCurrentLocation}
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">Current CTC:</span>
                  <br /> {selectedTalent.talentCurrentCTC} LPA
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">Total Experience:</span>
                  <br /> {selectedTalent.talentTotalExperience}
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">
                    Talent Job Role in 1 line:
                  </span>
                  <br /> {selectedTalent.talentJobRoleExplain}
                </p>
              </div>
              <div className="w-full md:w-64">
                <p className="text-md my-2">
                  <span className="font-semibold">
                    Current Employment Type:
                  </span>
                  <br /> {selectedTalent.talentEmploymentType}
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">Current Workplace Type:</span>
                  <br /> {selectedTalent.talentWorkplaceType}
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">Year of Graduation:</span>
                  <br />{" "}
                  {new Date(selectedTalent.yearOfGraduation).toLocaleString(
                    "en-us",
                    { month: "long", year: "numeric" }
                  )}
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">
                    Talent Preferred Location:
                  </span>
                  <br /> {selectedTalent.talentPreferredLocation}
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">Expected CTC:</span>
                  <br /> {selectedTalent.talentExpectedCTC} LPA
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">Year Gap (if any):</span>
                  <br />
                  {selectedTalent.yearGap}
                </p>
                <span className="font-semibold bg-white">
                  Recruiter Comment:
                </span>
                <div className="overflow-y-auto w-full md:w-[74vh]">
                  <p className="text-md max-h-[15vh] mt-[-3vh]">
                    <br /> {selectedTalent.comment}
                  </p>
                </div>
              </div>
              <div className="w-full md:w-64 md:border-r-2 pr-2">
                <p className="text-md my-2">
                  <span className="font-semibold">College Name:</span>
                  <br /> {selectedTalent.collegeName}
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">Course:</span>
                  <br /> {selectedTalent.course}
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">Field:</span>
                  <br /> {selectedTalent.field}
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">Official Notice Period:</span>
                  <br /> {selectedTalent.officialNoticePeriod}
                </p>
                <p className="text-md my-2">
                  <span className="font-semibold">
                    LWD/How soon Talent can Join:
                  </span>
                  <br /> {selectedTalent.joiningTime}
                </p>
              </div>
              <div className="ml-0 md:ml-9">
                <p className="text-md flex w-full md:w-96">
                  <a
                    className="font-semibold flex bg-blue-300 pl-2 pr-3 py-2 rounded-lg"
                    href={selectedTalent.photo}
                    download="filename.pdf"
                  >
                    <FaDownload className="mx-2 mt-1" />
                    <iframe
                      src={selectedTalent.photo}
                      className="h-48"
                    ></iframe>
                  </a>
                </p>
                <p className="text-md font-semibold mt-3">Required Skills</p>
                <p className="text-md">
                  {selectedTalent.requiredSkills.map((skill) => (
                    <div className="flex gap-9">
                      <div className="w-40">
                        <p>{skill.skillName}</p>
                      </div>
                      <div>
                        <p>{skill.exp} years</p>
                      </div>
                      <div>
                        <p>{skill.score}/5</p>
                      </div>
                    </div>
                  ))}
                </p>
                <p className="text-md font-semibold mt-3">Additional Skills</p>
                <p className="text-md">
                  {selectedTalent.skills.map((skill) => (
                    <div className="flex gap-9">
                      <div className="w-40">
                        <p>{skill.skillName}</p>
                      </div>
                      <div>
                        <p>{skill.exp} years</p>
                      </div>
                      <div>
                        <p>{skill.score}/5</p>
                      </div>
                    </div>
                  ))}
                </p>
              </div>
            </div>
            <div className="mt-4 flex ">
              <button
                onClick={toggleAvailabilityPopup}
                className="bg-[#7ED957] px-4 py-2 rounded-full transition-all duration-200"
                style={{ width: "auto", height: "auto" }}
              >
                Confirm Interview Slot
              </button>
              {showAvailabilityPopup &&
                selectedTalent &&
                selectedTalent.selectedDateTime && (
                  <div className="absolute m-10 rounded-lg flex flex-col z-40 p-4">
                    <div className="">
                      {selectedTalent.selectedDateTime.map(
                        (dateTimeObj, index) => (
                          <p
                            key={index}
                            onClick={(e) =>
                              handleDateClick(e, dateTimeObj, index)
                            }
                            className={`text-sm bg-gray-200 w-fit cursor-pointer px-2 py-1 my-1 mx-2 rounded-xl ${
                              selectedTalentIndex === index
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {dateTimeObj.date} {dateTimeObj.timeFrom} -{" "}
                            {dateTimeObj.duration} hr
                          </p>
                        )
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      )} */}
    </Layout>
  );
};

export default JobList;
