import React from 'react'
import Layout from '../../Component/Layout'
import MyTalent_Dashboard from '../../Component/MyTalent_Dashboard'

const MyTalent = () => {
  return (
    <Layout>
      <div className='p-10'>
        <MyTalent_Dashboard/>
      </div>
    </Layout>
  )
}

export default MyTalent