import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Helmet } from "react-helmet";
import { useSidebar } from "../context/SidebarContext";
import { MdOutlineUnfoldMore } from "react-icons/md";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import axios from "axios";

const Layout = ({
  children,
  title,
  description,
  keywords,
  author,
  showFooter,
  showNavbar,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    if (typeof window !== "undefined") {
      const saved = localStorage.getItem("sidebarOpen");
      return saved !== null ? JSON.parse(saved) : false;
    }
    return false;
  });
  const [profileCompletion, setProfileCompletion] = useState(null);

  const [sidebarState, setSidebarState] = React.useState(false);
  //when page url is chnaged then sidebar state is changed
  const sidebarmain = document.querySelector(".sidebarmain");

  React.useEffect(() => {
    if (sidebarmain) {
      if (sidebarmain.offsetWidth < 200) {
        handleLocationChange();
      } else {
        handleLocationChange();
      }
    }
  }, [window.location.pathname]);

  //set sidebar context
  const { SidebarOpenProvide, SidebarCloseProvide } = useSidebar();
  const SidebarOpen = () => {
    SidebarOpenProvide();
    setSidebarState(true);
    console.log("Sidebar opened");
    localStorage.setItem("sidebarState", true);
  };

  const SidebarClose = () => {
    SidebarCloseProvide();
    setSidebarState(false);
    console.log("Sidebar closed");
    localStorage.setItem("sidebarState", false);
  };

  const { auth } = useAuth();
  const navigate = useNavigate();
  const [loading2,setLoading2]=useState(true);

  const calculateCompletion = (data1, data2) => {
    const totalFields = Object.keys(data1).length + Object.keys(data2).length + 3;
    const filledFields =
      Object.values(data1).filter((value) => {
        if (typeof value === "string") {
          return value.trim() !== "";
        }
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return false;
      }).length +
      Object.values(data2).filter((value) => {
        if (typeof value === "string") {
          return value.trim() !== "";
        }
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return false;
      }).length +
      3;

      // console.log(totalFields,filledFields)

    // console.log(totalFields, filledFields);
    return Math.round((filledFields / totalFields) * 100);
  };

  
  // console.log(profileCompletion);
  useEffect(() => {
    const fetchUserData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/auth/get-user-data`
      );
      // setUserData(response.data.user);
      if (response.data.success) {
        if (response.data.user?.recruiterData && response.data.user?.employerData) {
          localStorage.setItem(
            "recuiterData",
            JSON.stringify(response.data.user?.recruiterData)
          );
          localStorage.setItem(
            "employerData",
            JSON.stringify(response.data.user?.employerData)
          );
          localStorage.setItem(
            "isVerified",
            JSON.stringify(response.data.user?.isVerified)
          );
          // console.log(response.data.user?.employerData)
          // console.log(response.data.user?.recruiterData)
          setProfileCompletion(
            calculateCompletion(
              response.data.user?.employerData,
              response.data.user?.recruiterData
            )
          );

        } else {
          setProfileCompletion(
            calculateCompletion(
              {
                employerCompanyAddress: "",
                employerCompanySize: "",
                employerCompanyIndustry: "",
              },
              {
                recruiterCompanyName: "",
                recruiterLinkedInUrl: "",
                recruiterCompanyWebsite: "",
                recruiterCurrentLocation: "",
                recruiterCompanySize: "",
                recruiterIndustries: [],
                recriuterSeneorityLevel: [],
                recruiterSkills: [],
                recruiterRoles: [],
              }
            )
          );
        }
        setLoading2(false);
      }
    };
    fetchUserData();
  }, []);

  // useEffect(() => {
  //   if (!auth?.user?.name) {
  //     navigate("/login");
  //   }
  // }, [auth, navigate]);

  const handleSidebarToggle = () => {
    const newState = !isSidebarOpen;
    setIsSidebarOpen(newState);
    localStorage.setItem("sidebarOpen", JSON.stringify(newState));
  };
  const handleLocationChange = () => {
    const newState = false;
    setIsSidebarOpen(newState);
    localStorage.setItem("sidebarOpen", JSON.stringify(newState));
  };
  const handleProfileCompletionClick = ()=>{
    console.log("clicked");
    navigate("/user/profile");
  }
  return (
    <div className="flex bg-[#F7F7F7] h-screen overflow-hidden">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>

      <div className="flex flex-1 overflow-hidden">
        <div
          className={`transition-all duration-300 ${
            isSidebarOpen ? "w-64" : "w-20"
          }`}
        >
          <Sidebar
            isOpen={isSidebarOpen}
            onToggle={handleSidebarToggle}
            sidebarState={sidebarState}
            SidebarOpen={SidebarOpen}
            SidebarClose={SidebarClose}
          />
        </div>
        {
          profileCompletion<80 && !loading2 &&
          <div
          className="fixed top-4 right-[4%] cursor-pointer bg-white shadow-lg rounded-lg p-1 z-50 max-w-sm"
          style={{ width: "calc(100% - 4rem)", maxWidth: "160px" }}
          onClick={handleProfileCompletionClick}
        >
          <h3 className="text-xs font-semibold font-[Inter]">
            Profile Completion: {profileCompletion}%
          </h3>
          <div className="w-full bg-gray-200 rounded-full h-4 mt-1 overflow-hidden">
            <div
              className="bg-blue-500 h-4 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 animate-pulse"
              style={{ width: `${profileCompletion}%` }}
            ></div>
          </div>
        </div>
        }
        

        <div className="flex flex-col flex-1 overflow-hidden">
          <button
            onClick={handleSidebarToggle}
            className={`fixed top-10 left-4 ${
              isSidebarOpen ? "ml-[14rem]" : "ml-14"
            } z-50 p-1 bg-white rounded-md shadow-md`}
            aria-label="Toggle sidebar"
          >
            <MdOutlineUnfoldMore className="rotate-90" />
          </button>
          {showNavbar && <Navbar />}

          <main className="flex-1 overflow-y-auto p-4">{children}</main>

          {showFooter && <Footer />}
        </div>
      </div>
    </div>
  );
};

Layout.defaultProps = {
  title: "Reflense Now",
  description: "This is a talent hiring platform",
  keywords: "tech,job search,job,hiring",
  author: "Reflense",
  showFooter: true,
};

export default Layout;
