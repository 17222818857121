
const moment = require("moment");

exports.generateDateCombinations = (data,duration) => {
    console.log(data,duration);
    const results = [];

    data.forEach((item) => {
      if (!item.recurring) {
        // Handle non-recurring events
        const startDateTime = moment(item.date + " " + item.timeFrom, "YYYY-MM-DD HH:mm");
        const endDateTime = startDateTime.clone().add(item.duration, "minutes");
  
        if (!isPastEvent(startDateTime)) {
          generateIntervals(startDateTime, endDateTime, duration, results);
        }
      } else {
        // Handle recurring events
        const endDate = moment(item.date, "YYYY-MM-DD");
        const today = moment();
        let currentDate = today.clone();
  
        while (currentDate.isBefore(endDate, 'day') || currentDate.isSame(endDate, 'day')) {
          if (currentDate.format("dddd") === item.day) {
            const startDateTime = moment(currentDate.format("YYYY-MM-DD") + " " + item.timeFrom, "YYYY-MM-DD HH:mm");
            const endDateTime = startDateTime.clone().add(item.duration, "minutes");
  
            if (!isPastEvent(startDateTime)) {
              generateIntervals(startDateTime, endDateTime, duration, results);
            }
          }
          currentDate.add(1, "day");
        }
      }
    });

    results.sort((a, b) => {
      const dateA = moment(a.date + " " + a.timeFrom, "YYYY-MM-DD HH:mm");
      const dateB = moment(b.date + " " + b.timeFrom, "YYYY-MM-DD HH:mm");
      return dateA - dateB; // Ascending order
    });
  
    return results;
  }

  function generateIntervals(start, end, intervalDuration, results) {
    let currentStart = start.clone();
  
    while (currentStart.isBefore(end)) {
      const currentEnd = currentStart.clone().add(intervalDuration, "minutes");
      if (currentEnd.isAfter(end)) break; // Stop if interval exceeds original end time
  
      results.push({
        date: currentStart.format("YYYY-MM-DD"),
        timeFrom: currentStart.format("HH:mm"),
        endTime: currentEnd.format("HH:mm"),
      });
  
      currentStart.add(intervalDuration, "minutes");
    }
  }
  
  function isPastEvent(startDateTime) {
    const now = moment();
    return startDateTime.isBefore(now, 'minute');
  }
  