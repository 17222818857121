import React from "react";
import { PiChatsCircleBold } from "react-icons/pi";
import { Link } from "react-router-dom";
const Footer = () => {
  return ( 
    

<footer className="hidden rounded-full shadow h-fit bg-green-200  w-fit m-4">
<a href="#" className='border-2 p-3 fixed z-50  bg-gray-100 
right-[5vh] bottom-[5vh] border-black rounded-full text-3xl w-fit'>
<PiChatsCircleBold /></a>
</footer>


  );
};

export default Footer;