import React from "react";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import { useSearch } from "../context/search";

const SearchInput = ({ setSearchedJobs,
  searchedText 
 }) => {
  const [value, setValue] = useSearch();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    searchedText(value.keyword);
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    try {
      const capitalizedKeyword = capitalize(value.keyword);
      if (!capitalizedKeyword) {
        setSearchedJobs([]); // Clear search results if no keyword
        return;
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/search/${capitalizedKeyword}`
      );

      setSearchedJobs(data.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  const handleChange = (e) => {
    setValue({ ...value, keyword: e.target.value });
   
  }
  return (
    <div className="z-0">
      <form className="flex gap-2" role="search" onSubmit={handleSubmit}>
        <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <FaSearch className="w-4 h-4 text-gray-500" />
          </div>
          <input
            type="search"
            id="default-search"
            className="block px-5 py-4 ps-10 rounded-full text-sm text-gray-900 border border-gray-300 bg-gray-50"
            placeholder="Search Jobs"
            aria-label="Search"
            value={value.keyword}

            onChange={(e) => handleChange(e)}
          />
          <button
            type="submit"
            className="text-white absolute mt-3 h-[35px] end-2.5 bottom-2.5 bg-gray-400 hover:bg-gray-600 focus:ring-4 focus:outline-none font-medium rounded-full text-sm px-4 py-2"
          >
            <FaSearch />
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchInput;
