import React from "react";


const CircularProgress = ({ percentage, size, strokeWidth, color }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke="#e0e0e0"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        fontSize="16"
        fontWeight="bold"
      >
        {percentage}
      </text>
    </svg>
  );
};


export default function Recruiter_Candidate({ recruiterScore = 40, candidateScore = 40 }) {
  return (
    <div className="flex gap-2 mr-14">
      <div className="flex items-center justify-center gap-2 rounded-lg text-black border-black p-1 relative">
        <span className="text-[#A7B5BE] font-[Inter] text-[10px]">Recruiter</span>
        <CircularProgress
          percentage={recruiterScore}
          size={55}
          strokeWidth={5}
          color="#4caf50"
        />
      </div>
      <div className="flex items-center justify-center gap-2 rounded-lg mr-10 text-black border-black p-1 relative">
        <CircularProgress
          percentage={candidateScore}
          size={55}
          strokeWidth={5}
          color="#FF6A6A"
        />
        <span className="text-[#A7B5BE] font-[Inter] text-[10px]">Candidate</span>
      </div>
    </div>
  );
}
