import { Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Pagenotfound from "./pages/Pagenotfound";
import PrivateRoute from "./Component/Routes/Private";
import CreateTalent from "./Component/CreateTalent";
import ManagerRoute from "./Component/Routes/ManagerRoute";
import AdminDashboard from "./Component/AdminDashboard";
import CreateJobs from "./pages/manager/CreateJobs";
import JobList from "./pages/manager/JobList";
import Recruites from "./pages/manager/Recruites";
import Confirmation from "./pages/Confirmation";
import UpdateJob from "./pages/manager/UpdateJob";
import ActiveJobList from "./pages/manager/ActiveJobList";
import Search from "./pages/Search";
import RecruiterDashboard from "./pages/recruiter/RecruiterDashboard";
import ThankJob from "./pages/ThankJob";
import ThankTalent from "./Component/ThankTalent";
import LandingPage from "./pages/LandingPage";
import EmailConfirmation from "./pages/recruiter/EmailConfirmation";
import Callback from "./pages/LinkedinCallback";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import JobDetails from "./pages/manager/JobDetails";
import MyTalent from "./pages/manager/MyTalent";
import TalentDetail from "./pages/recruiter/TalentDetail";
import TalentsDetails from "./pages/TalentsDetails";
import EditTalent from "./pages/manager/EditTalent";
import InterviewPage from "./pages/InterviewPage";
import Profile from "./pages/Profile";

export const routes = {
  home: "/home",
  login: "/login",
  landing: "/",
  signup: "/signup",
  linkedin_callback: "/auth/linkedin/callback",
  active_job_list: "/activejoblist",
  search: "/search",
  EmailConfirmation: "/EmailConfirmation",
  confirm_account: "/confirm-account/:token",
  dashboard: "/dashboard",
  // recruiter: "/dashboard/user/:jobId",
  preRecruiter: "/dashboard/user/",
  hiring_manager: "/dashboard/user",
  recruiter_dashboard: "/dashboard/recruiter-dashboard",
  create_jobs: "/dashboard/user/create-jobs",
  edit_jobs: "/dashboard/user/edit-jobs",
  thank_you_job: "/dashboard/thank-you-job",
  thankyou_navigate: "/dashboard/thank-you-job/",
  thank_you_talent: "/dashboard/thank-you-talent/:jobId",
  thankyou_job_talent: "/dashboard/thank-you-talent/",
  job_list: "/dashboard/user/job-list/:jobId",
  recruites: "/dashboard/user/recruites",
  job_route: "/dashboard/user/",
  edit_job: "/dashboard/user/job-list/",
  forget_password: "/forget-password",
  interviews: "/interviews",
  reset_password: "/reset-password",
  job_details: "/dashboard/user/job/:jobId",
  my_talent: "/dashboard/user/my-talent",
  talent_details: "/dashboard/user/:jobId",
  talent_view: "/dashboard/talent-view/:talentId",
  talent_detail: "/dashboard/talent-details/:talentId",
  profile: "/user/profile",
};
//App
function App() {
  return (
    <>
      <Routes>
        {/* <Route path={routes.landing} element={<LandingPage/>}/> */}
        <Route path={routes.login} element={<Login />} />
        <Route path={routes.landing} element={<Login />} />
        <Route path={routes.search} element={<Search />} />
        <Route path={routes.signup} element={<Signup />} />
        <Route path={routes.linkedin_callback} element={<Callback />} />
        <Route path="*" element={<Pagenotfound />} />
        <Route path={routes.confirm_account} element={<Confirmation />} />

        <Route
          path={routes.EmailConfirmation}
          element={<EmailConfirmation />}
        />
        <Route path={routes.forget_password} element={<ForgotPassword />} />
        <Route path={routes.reset_password} element={<ResetPassword />} />
        <Route element={<PrivateRoute />}>
          <Route path={routes.active_job_list} element={<ActiveJobList />} />
          <Route path={routes.home} element={<HomePage />} />
          <Route path={routes.talent_details} element={<EditTalent />} />
          <Route path={routes.talent_view} element={<TalentsDetails />} />
          {/* <Route path={routes.recruiter} element={<CreateTalent />} /> */}
          <Route path={routes.hiring_manager} element={<JobList />} />
          <Route path={routes.interviews} element={<InterviewPage />} />
          <Route path={routes.talent_detail} element={<TalentDetail />} />
          <Route path={routes.profile} element={<Profile />} />
          <Route
            path={routes.recruiter_dashboard}
            element={<RecruiterDashboard />}
          />
          <Route path={routes.hiring_manager} element={<AdminDashboard />} />
          <Route path={routes.create_jobs} element={<CreateJobs />} />
          <Route path={routes.edit_jobs} element={<CreateJobs />} />
          <Route path={routes.thank_you_job} element={<ThankJob />} />
          <Route path={routes.thank_you_talent} element={<ThankTalent />} />
          <Route path={routes.job_list} element={<UpdateJob />} />
          <Route path={routes.recruites} element={<Recruites />} />
          <Route path={routes.job_details} element={<JobDetails />} />
          <Route path={routes.my_talent} element={<MyTalent />} />
        </Route>

        {/* <Route path={routes.dashboard} element={<ManagerRoute/>}>
      <Route path={routes.hiring_manager} element={<AdminDashboard/>}/>
      <Route path={routes.create_jobs} element={<CreateJobs/>} />
      <Route path={routes.thank_you_job} element={<ThankJob/>}/>
      <Route path={routes.thank_you_talent} element={<ThankTalent/>}/>
      <Route path={routes.job_list} element={<UpdateJob/>}/>
      <Route path={routes.recruites} element={<Recruites/>}/>
     </Route> */}
      </Routes>
    </>
  );
}

export default App;
