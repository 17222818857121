import React from "react";
import { useSearch } from "../context/search";
import { useState } from "react";
import axios from "axios";
import Layout from "../Component/Layout";
import { Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import SearchInput from "../Component/SearchInput";
import { routes } from "../App";

const Search = () => {
  const [value] = useSearch();
  const [showSlider, setShowSlider] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobs, setJobs] = useState([]);
  const getAllJobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/get-all-jobs`
      );
      setJobs(response.data.job);
    } catch (error) {
      console.error("Error fetching job data:", error);
    }
  };

  const handleJobCardClick = (job) => {
    setSelectedJob(job);
    setShowSlider(true);
  };

  return (
    <Layout>
      <div className="container mt-[10vh]">
        <div className="text-center">
          <div className="w-fit mx-auto">
            <SearchInput />
          </div>
          <h6>{value.results?.success && value.results?.message}</h6>
          <div className="grid grid-cols-1 mt-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mx-5">
            {Array.isArray(value.results?.results) &&
            value.results?.results.length > 0 ? (
              value.results?.results.map((p) => (
                <div
                  className="bg-white p-4 shadow-md rounded-md mb-4 border-1 cursor-pointer hover:shadow-xl"
                  onClick={() => handleJobCardClick(p)}
                  key={p._id}
                >
                  <div className="card-body text-left">
                    <h2 className="text-xl font-bold mb-2">{p.jobTitle}</h2>
                    <p className="text-gray-600">{p.jobLocation}</p>
                    <p className="text-gray-700 mt-2">
                      {p.minYearsExperience} - {p.maxYearsExperience} years
                    </p>
                    <p className="text-gray-700 mt-2">
                      {p.minCTC} - {p.maxCTC}
                    </p>
                    <p className="text-gray-600 mb-3 mt-2">
                      <span className="bg-violet-300 px-2 rounded-md">
                        {p.workplaceType}
                      </span>
                    </p>
                    <p className="text-gray-700 mt-2">{p.describeCandidate}</p>
                    <p className="text-gray-700 mb-3 mt-2">
                      <span className="bg-green-300 px-2 rounded-md">
                        {p.jobType}
                      </span>
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p>No Jobs Found</p>
            )}
          </div>
        </div>

        {showSlider && selectedJob && (
          <div className="fixed inset-y-0 w-1/2 right-0 bg-opacity-50 mt-[10px] flex items-center h-full justify-center">
            <div
              className="bg-white px-4 pt-4 mt-[15vh] pb-[9vh] w-full h-lvh overflow-y-auto shadow-md rounded-md"
              style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            >
              <button
                className="text-lg text-gray-400 font-medium rounded-lg px-1 py-1"
                onClick={() => setShowSlider(false)}
              >
                Close
              </button>
              <div className="flex mt-[-2vh] gap-[10vh]">
                <h2 className="text-3xl w-3/4 font-bold mt-5">
                  {selectedJob.companyName}
                </h2>
                <Link to={`${routes.job_route}${selectedJob.jobId}`}>
                  <button className="text-white mt-7 bg-blue-500 w-32 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                    Refer Talent
                  </button>
                </Link>
              </div>
              <hr class="w-full h-1 mx-auto mt-3 bg-gray-100 border-0 rounded dark:bg-gray-700" />
              <div className="flex mt-3 gap-[1vh]">
                <div className=" pr-[10vh] text-md ">
                  <h2 className="text-gray-700 mt-2">
                    <span className="font-semibold">Job Title:</span>
                    <br />
                    {selectedJob.jobTitle}
                  </h2>
                  <p className="text-gray-700 mt-2">
                    <span className="font-semibold">Job Location:</span>
                    <br /> {selectedJob.jobLocation}
                  </p>
                  <p className="text-gray-700 mt-2">
                    <span className="font-semibold">Company Industry:</span>
                    <br /> {selectedJob.companyIndustry}
                  </p>
                  <p className="text-gray-700 mt-2">
                    <span className="font-semibold">Job Senority Level:</span>
                    <br />{" "}
                    <span className="bg-green-300 px-2 rounded-full">
                      {selectedJob.jobLevel}
                    </span>
                  </p>
                  <p className="text-gray-700 mt-2">
                    <span className="font-semibold">
                      Talent Minimum Experience Range:
                    </span>
                    <br /> {selectedJob.minYearsExperience} years
                  </p>
                  <p className="text-gray-700 mt-2">
                    <span className="font-semibold">Minimum Budget:</span>
                    <br /> {selectedJob.minCTC}{" "}
                  </p>
                  <p className="text-gray-700 mt-2">
                    <p className="font-semibold">Primary Skills:</p>
                    {selectedJob.primarySkills?.map((skill, index) => (
                      <span
                        key={index}
                        className="bg-blue-200 px-2 rounded-md mr-2"
                      >
                        {skill.text}
                      </span>
                    ))}
                  </p>

                  <p className="text-gray-700 mt-2">
                    <span className="font-semibold">No of Vaccancies:</span>
                    <br /> {selectedJob.jobNumbers}
                  </p>
                  <p className="text-gray-700 mt-2 mb-5">
                    <span className="font-semibold">
                      Looking for someone who:
                    </span>
                    <br /> {selectedJob.describeCandidate}
                  </p>
                  <p className="text-gray-700 mt-2 mb-5">
                    <span className="font-semibold">
                      Screening Questions for Recruiter:
                    </span>
                    <br /> {selectedJob.screeningQuestion}
                  </p>
                </div>
                <div className="text-md w-96 ">
                  <p className="text-gray-700 mt-2">
                    <span className="font-semibold">Job Status:</span>
                    <br />
                    <span className="bg-blue-200 px-2 rounded-md mr-2">
                      Active
                    </span>
                  </p>
                  <p className="text-gray-700 mt-2">
                    <span className="font-semibold">Workplace Type:</span>
                    <br />
                    <span className="bg-violet-300 px-2 rounded-full">
                      {selectedJob.workplaceType}
                    </span>
                  </p>
                  <p className="text-gray-700 mt-2">
                    <span className="font-semibold">Employment Type:</span>
                    <br />
                    <span className="bg-violet-300 px-2 rounded-full">
                      {selectedJob.jobType}
                    </span>
                  </p>
                  <p className="text-gray-700 mt-2">
                    <span className="font-semibold">
                      Official Notice Period:
                    </span>
                    <br /> {selectedJob.officialNoticePeriod}
                  </p>
                  <p className="text-gray-700 mt-2 w-80">
                    <span className="font-semibold">
                      Talent Maximum Experience :
                    </span>
                    <br /> {selectedJob.maxYearsExperience} years
                  </p>
                  <p className="text-gray-700 mt-2">
                    <span className="font-semibold">Maximum Budget Range:</span>
                    <br /> {selectedJob.maxCTC}
                  </p>
                  <p className="text-gray-700 mt-2">
                    <span className="font-semibold">Secondary Skills:</span>
                    <br />
                    {selectedJob.secondarySkills?.map((skill, index) => (
                      <span
                        key={index}
                        className="bg-blue-200 px-2 rounded-md mr-2"
                      >
                        {skill.text}
                      </span>
                    ))}
                  </p>
                  <p className="text-gray-700 mt-2">
                    <span className="font-semibold">
                      Recruiter Incentive {selectedJob.recruiterIncentiveType}:
                    </span>
                    <br /> {selectedJob.recruiterIncentiveValue}
                  </p>
                  <p className="text-gray-700 mt-2 mb-2">
                    <span className="font-semibold">
                      Role and Responsibilites:
                    </span>
                    <br />
                    <div
                      className="overflow-y-auto"
                      style={{
                        maxHeight: "350px",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                      }}
                    >
                      {/* Assuming selectedJob.roleAndResponsibilites contains the content */}
                      {selectedJob.roleAndResponsibilites}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Search;
