import React, { useCallback, useEffect, useState } from "react";
import Layout from "../Component/Layout";
import axios from "axios";
import { useAuth } from "../context/auth";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import { Autocomplete, debounce, TextField } from "@mui/material";
import { SyncLoader } from "react-spinners";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const rolesOptions = [
  { value: "Full Stack Developer", label: "Full Stack Developer" },
  { value: "Frontend Developer", label: "Frontend Developer" },
  { value: "Backend Developer", label: "Backend Developer" },
  { value: "iOS Developer", label: "iOS Developer" },
  { value: "Android Developer", label: "Android Developer" },
  { value: "Game Developer", label: "Game Developer" },
  { value: "DevOps Engineer", label: "DevOps Engineer" },
  { value: "Cloud Engineer", label: "Cloud Engineer" },
  { value: "Data Engineer", label: "Data Engineer" },
  { value: "Machine Learning Engineer", label: "Machine Learning Engineer" },
  { value: "AI Engineer", label: "AI Engineer" },
  { value: "Blockchain Developer", label: "Blockchain Developer" },
  { value: "Data Scientist", label: "Data Scientist" },
  { value: "Data Analyst", label: "Data Analyst" },
  { value: "Business Analyst", label: "Business Analyst" },
  { value: "Data Engineer", label: "Data Engineer" },
  { value: "Data Architect", label: "Data Architect" },
  { value: "Statistician", label: "Statistician" },
  { value: "Product Manager", label: "Product Manager" },
  { value: "Product Designer", label: "Product Designer" },
  { value: "UX/UI Designer", label: "UX/UI Designer" },
  { value: "Project Manager", label: "Project Manager" },
  { value: "Scrum Master", label: "Scrum Master" },
  { value: "QA Tester", label: "QA Tester" },
  { value: "SDET", label: "SDET" },
  { value: "Technical Writer", label: "Technical Writer" },
  { value: "IT Consultant", label: "IT Consultant" },
  { value: "Cybersecurity Analyst", label: "Cybersecurity Analyst" },
  { value: "AI/ML Researcher", label: "AI/ML Researcher" },
  { value: "Cybersecurity Architect", label: "Cybersecurity Architect" },
  { value: "Metaverse Developer", label: "Metaverse Developer" },
  { value: "IoT Developer", label: "IoT Developer" },
  { value: "IT Support Specialist", label: "IT Support Specialist" },
  { value: "Systems Administrator", label: "Systems Administrator" },
  { value: "Network Administrator", label: "Network Administrator" },
  { value: "IT Project Manager", label: "IT Project Manager" },
  { value: "IT Security Engineer", label: "IT Security Engineer" },
  { value: "Database Administrator", label: "Database Administrator" },
  { value: "Help Desk Technician", label: "Help Desk Technician" },
  { value: "HR Manager", label: "HR Manager" },
  { value: "Recruiter", label: "Recruiter" },
  { value: "HR Generalist", label: "HR Generalist" },
  { value: "Marketing Manager", label: "Marketing Manager" },
  { value: "Sales Manager", label: "Sales Manager" },
  {
    value: "Business Development Manager",
    label: "Business Development Manager",
  },
  {
    value: "Customer Service Representative",
    label: "Customer Service Representative",
  },
  { value: "Account Manager", label: "Account Manager" },
  { value: "Financial Analyst", label: "Financial Analyst" },
  { value: "Accountant", label: "Accountant" },
  { value: "Administrative Assistant", label: "Administrative Assistant" },
  { value: "Receptionist", label: "Receptionist" },
  { value: "Legal Counsel", label: "Legal Counsel" },
  { value: "Operations Manager", label: "Operations Manager" },
  { value: "Project Coordinator", label: "Project Coordinator" },
  { value: "Event Planner", label: "Event Planner" },
  { value: "Writer/Editor", label: "Writer/Editor" },
  { value: "Graphic Designer", label: "Graphic Designer" },
  { value: "Social Media Manager", label: "Social Media Manager" },
  { value: "Content Creator", label: "Content Creator" },
  { value: "Teacher", label: "Teacher" },
  { value: "Professor", label: "Professor" },
  { value: "Researcher", label: "Researcher" },
  { value: "Doctor", label: "Doctor" },
  { value: "Nurse", label: "Nurse" },
  { value: "Lawyer", label: "Lawyer" },
  { value: "Accountant", label: "Accountant" },
  { value: "Financial Advisor", label: "Financial Advisor" },
  { value: "Real Estate Agent", label: "Real Estate Agent" },
  {
    value: "Sales Development Representative",
    label: "Sales Development Representative",
  },
  { value: "Retail Manager", label: "Retail Manager" },
  { value: "Chef", label: "Chef" },
  { value: "Waiter/Waitress", label: "Waiter/Waitress" },
  { value: "Bartender", label: "Bartender" },
  { value: "Hair Stylist", label: "Hair Stylist" },
  { value: "Massage Therapist", label: "Massage Therapist" },
  { value: "Personal Trainer", label: "Personal Trainer" },
  { value: "Fitness Instructor", label: "Fitness Instructor" },
  { value: "Social Worker", label: "Social Worker" },
  { value: "Counselor", label: "Counselor" },
  { value: "Architect", label: "Architect" },
];

const seniorityLevel = [
  { value: "Internship", label: "Internship" },
  { value: "Entry-Level", label: "Entry-Level" },
  { value: "Associate", label: "Associate" },
  { value: "Mid-Senior Level", label: "Mid-Senior Level" },
  { value: "Director", label: "Director" },
  { value: "Executive", label: "Executive" },
  { value: "Not-Applicable", label: "Not-Applicable" },
];

const Profile = () => {
  const { auth } = useAuth();
  const [imageData, setImageData] = useState(null);
  const [jobLocations, setJobLocations] = useState([]);

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const employerData = JSON.parse(localStorage.getItem("employerData"));
  const recruiterData = JSON.parse(localStorage.getItem("recuiterData"));
  const [firstTime, setFirstTime] = useState(true);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: auth ? auth?.user?.name : "",
    email: auth ? auth?.user?.email : "",
    phone: auth ? auth?.user?.phone : "",

    employerCompanyAddress: "",
    employerCompanySize: "1-10",
    employerCompanyIndustry: "",

    recruiterCompanyName: "",
    recruiterLinkedInUrl: "",
    recruiterCompanyWebsite: "",
    recruiterCurrentLocation: "",
    recruiterCompanySize: "1-10",
    recruiterIndustries: [],
    recriuterSeneorityLevel: [],
    recruiterSkills: [],
    recruiterRoles: [],
  });

  const [errors, setErrors] = useState({
    recruiterLinkedInUrl: '',
    recruiterCompanyWebsite: ''
  });

  useEffect(() => {
    if (employerData && recruiterData) {
      if (firstTime) {
        setFormData((prevData) => {
          setSubmitLoading(true);
          const newData = {
            ...prevData,
            employerCompanyAddress: employerData?.companyAddress || "",
            employerCompanySize: employerData?.companySize || "1-10",
            employerCompanyIndustry: employerData?.companyIndustry || "",
            recruiterCompanyName: recruiterData?.companyName || "",
            recruiterCompanyWebsite: recruiterData?.companyWebsite || "",
            recruiterCurrentLocation: recruiterData?.currentLocation || "",
            recruiterCompanySize: recruiterData?.companySize || "1-10",
            recruiterIndustries: recruiterData?.industries || [],
            recriuterSeneorityLevel: recruiterData?.seniorityLevel || [],
            recruiterSkills: recruiterData?.skills || [],
            recruiterRoles: recruiterData?.roles || [],
            recruiterLinkedInUrl: recruiterData?.linkedInUrl || "",
          };
          setSubmitLoading(false);
          setProfileCompletion(calculateCompletion(newData));
          return newData;
        });

        setFirstTime(false);
      }
    }
  }, [employerData, recruiterData]);

  const [state, setState] = useState({
    loading: false,
    Skill: "",
    seniorityLevel: seniorityLevel,
    newSecondarySkill: "",
    skillsOptions: [],
    companyIndustryOption: [],
    roles: rolesOptions,
  });

  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const fetchTopSkills = async () => {
    try {
      // Replace with your actual backend API URL
      const url = `${process.env.REACT_APP_API_}/api/v1/job/top-ten-skills`;

      const response = await axios.get(url);

      updateState(
        "skillsOptions",
        response.data.map((skill) => ({
          value: skill.skillName,
          label: skill.skillName,
        }))
      );
    } catch (err) {
      // setError("Error fetching skills. Please try again.");
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchTopIndustry = async () => {
      try {
        // Replace with your actual backend API URL
        const url = `${process.env.REACT_APP_API_}/api/v1/job/top-ten-industry`;
        const response = await axios.get(url);
        updateState(
          "companyIndustryOption",
          response.data.map((skill) => ({
            value: skill.concatCell,
            label: skill.concatCell,
          }))
        );
      } catch (err) {
        // setError("Error fetching skills. Please try again.");
        console.error(err);
      }
    };
    fetchTopSkills();
    fetchTopIndustry();
  }, []);

  const calculateCompletion = (data) => {
    const totalFields = Object.keys(data).length;
    const filledFields = Object.values(data).filter((value) => {
      if (typeof value === "string") {
        return value.trim() !== "";
      }
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return false;
    }).length;

    // console.log(totalFields, filledFields);
    return Math.round((filledFields / totalFields) * 100);
  };

  const [profileCompletion, setProfileCompletion] = useState(
    calculateCompletion(formData)
  );

  // Add validation functions
  const isValidLinkedInUrl = (url) => {
    const linkedInRegex = /^https:\/\/www\.linkedin\.com\/in\/[\w-]+\/?$/;
    return linkedInRegex.test(url);
  };

  const isValidWebsiteUrl = (url) => {
    const websiteRegex = /^https:\/\/www\..+/;
    return websiteRegex.test(url);
  };

  // Modify handleChange to include inline validation
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Clear error when user starts typing
    setErrors(prev => ({
      ...prev,
      [name]: ''
    }));

    if (name === 'recruiterLinkedInUrl' && value) {
      if (!isValidLinkedInUrl(value)) {
        setErrors(prev => ({
          ...prev,
          recruiterLinkedInUrl: 'Please enter a valid LinkedIn URL (e.g., https://www.linkedin.com/in/username)'
        }));
      }
    }

    if (name === 'recruiterCompanyWebsite' && value) {
      if (!isValidWebsiteUrl(value)) {
        setErrors(prev => ({
          ...prev,
          recruiterCompanyWebsite: 'Please enter a valid website URL (e.g., https://www.company.com)'
        }));
      }
    }

    setFormData(prevData => {
      const newData = {
        ...prevData,
        [name]: value,
      };
      setProfileCompletion(calculateCompletion(newData));
      return newData;
    });
  };

  const fetchJobLocations = useCallback(async (inputValue) => {
    if (inputValue.length < 2) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/locations?search=${inputValue}`
      );
      const locationsToShow = response.data;
      const newOptions = locationsToShow.map((loc) => ({
        value: `${loc.city} | ${loc.state} | ${loc.country}`,
        label: `${loc.city} | ${loc.state} | ${loc.country}`,
      }));
      setJobLocations(newOptions);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    }
  }, []);

  const debouncedFetchJobLocations = useCallback(
    debounce(fetchJobLocations, 300),
    [fetchJobLocations]
  );

  const handleLocationChange = (event, newValue) => {
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        employerCompanyAddress: newValue ? newValue.label : "",
      };
      setProfileCompletion(calculateCompletion(newData));
      return newData;
    });
  };

  const handleLocationChangeRecruiter = (event, newValue) => {
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        recruiterCurrentLocation: newValue ? newValue.label : "",
      };
      setProfileCompletion(calculateCompletion(newData));
      return newData;
    });
  };

  const handleLocationInputChange = (event, newInputValue) => {
    debouncedFetchJobLocations(newInputValue);
  };

  const handleCompanyIndustryChange = async (selectedOption) => {
    if (selectedOption) {
      setFormData((prevData) => {
        const newData = {
          ...prevData,
          employerCompanyIndustry: selectedOption ? selectedOption.label : "",
        };
        setProfileCompletion(calculateCompletion(newData));
        return newData;
      });
    }
  };

  const handleRecruiterIndustryChange = (selectedOptions) => {
    const industries = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        recruiterIndustries: industries,
      };
      setProfileCompletion(calculateCompletion(newData));
      return newData;
    });
  };

  const handleRecruiterSeniorityChange = (selectedOptions) => {
    const level = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        recriuterSeneorityLevel: level,
      };
      setProfileCompletion(calculateCompletion(newData));
      return newData;
    });
  };

  // Modify handleSubmit to include final validation
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate LinkedIn URL and website before submission
    if (formData.recruiterLinkedInUrl && !isValidLinkedInUrl(formData.recruiterLinkedInUrl)) {
      toast.error('Please enter a valid LinkedIn URL');
      return;
    }

    if (formData.recruiterCompanyWebsite && !isValidWebsiteUrl(formData.recruiterCompanyWebsite)) {
      toast.error('Please enter a valid company website URL');
      return;
    }

    setSubmitLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/auth/add-user-data`,
        formData
      );
      if (response.data.success) {
        toast.success("User data updated successfully");
        setSubmitLoading(false);
        navigate("/home");
      } else {
        toast.error("Failed to update user data");
        setSubmitLoading(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    const fetchUserImage = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_}/api/v1/auth/picture/${auth?.user?.id}`
        );

        if (response.data.success) {
          setImageData(response.data.imageData);
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      } finally {
        setLoading(false);
      }
    };
    if (auth?.user?.picture) {
      fetchUserImage();
    }
  }, [auth]);

  const fetchIndustries = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/industries?search=${query}`
      );

      updateState(
        "companyIndustryOption",
        response.data.map((skill) => ({
          value: skill.concatCell,
          label: skill.concatCell,
        }))
      );
    } catch (error) {
      console.error("Error fetching primary skills:", error);
    }
  };

  const handleSkillChange = (selectedOptions) => {
    const skills = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        recruiterSkills: skills,
      };
      setProfileCompletion(calculateCompletion(newData));
      return newData;
    });
  };

  const handleRoleChange = (selectedOptions) => {
    const roles = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        recruiterRoles: roles,
      };
      setProfileCompletion(calculateCompletion(newData));
      return newData;
    });
  };

  const handleCreateOption = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    // setState((prevState) => ({
    //   ...prevState, // Spread the rest of the state properties
    //   skillsOptions: [...prevState.skillsOptions, newOption], // Update skillsOptions by appending newOption
    // }));
    fetchTopSkills();
    setFormData((prevData) => ({
      ...prevData,
      recruiterSkills: [...prevData.recruiterSkills, inputValue],
    }));
  };

  const handleRoleCreateOption = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    // setState((prevState) => ({
    //   ...prevState, // Spread the rest of the state properties
    //   skillsOptions: [...prevState.skillsOptions, newOption], // Update skillsOptions by appending newOption
    // }));
    setFormData((prevData) => ({
      ...prevData,
      recruiterRoles: [...prevData.recruiterRoles, inputValue],
    }));
  };

  const fetchPrimarySkills = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/skills?search=${query}`
      );
      // console.log("responseresponse=", response);
      updateState(
        "skillsOptions",
        response.data.map((skill) => ({
          value: skill.skillName,
          label: skill.skillName,
        }))
      );
    } catch (error) {
      console.error("Error fetching primary skills:", error);
    }
  };

  return (
    <div>
      {submitLoading ? (
              <div className="h-screen flex justify-center items-center gap-2">
                <SyncLoader />
              </div>
            ) : (
      <Layout showFooter={true}>
        {/* <div className="flex flex-col h-full justify-center my-auto items-center">
          <h1 className="text-2xl mb-4 flex justify-center items-center font-bold">
            {auth?.user?.name}
          </h1>

          {imageData ? (
            <img
              className="w-52 h-52 rounded-full"
              src={imageData}
              alt="profile"
            />
          ) : (
            <div className="w-52 h-52 rounded-full text-center bg-gray-300">
              <span className="text-[12rem] w-52 h-52 flex justify-center items-center">
                {auth?.user?.name.charAt(0)}
              </span>
            </div>
          )}
        </div> */}
        <div className=" p-10 bg-[#F7F7F7]">
          <div className="mx-auto ">
            <div className="p-6 bg-white mb-8 shadow-md rounded-lg">
              <div className="flex mt-2 justify-between items-center gap-4">
                <div className=" flex flex-col gap-3">
                  <h2 className="text-xl font-[Inter] underline font-semibold">
                    User Info:
                  </h2>
                  <div>
                    <p className="mt-1 font-[Inter]">{auth?.user?.name}</p>
                  </div>
                  <div>
                    <p className="mt-1 font-[Inter]">{auth?.user?.email}</p>
                  </div>

                  <div className="">
                    <p className=" mt-1 font-[Inter]">
                      {auth?.user?.phone ? auth?.user?.phone : "-"}
                    </p>
                  </div>
                </div>

                {imageData ? (
                  <img
                    className="w-40 h-40  rounded-full"
                    src={imageData}
                    alt="profile"
                  />
                ) : (
                  <div className="w-36 h-36 rounded-full bg-gray-300">
                    <span className="text-[9rem] w-36 h-36 flex justify-center items-center">
                      {auth?.user?.name.charAt(0)}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div
              className="fixed top-4 right-[4%] bg-white shadow-lg rounded-lg p-1 z-50 max-w-sm"
              style={{ width: "calc(100% - 4rem)", maxWidth: "160px" }}
            >
              <h3 className="text-xs font-semibold font-[Inter]">
                Profile Completion: {profileCompletion}%
              </h3>
              <div className="w-full bg-gray-200 rounded-full h-4 mt-1 overflow-hidden">
                <div
                  className="bg-blue-500 h-4 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 animate-pulse"
                  style={{ width: `${profileCompletion}%` }}
                ></div>
              </div>
            </div>
            <form onSubmit={handleSubmit} className="">
              {/* Contact Information Section */}

              <div className="">
                <div className="p-6 mb-8 bg-white shadow-md rounded-lg">
                  <h2 className="text-xl font-[Inter] underline font-semibold">
                    Employer Profile:
                  </h2>
                  <div className="mt-4 grid grid-cols-2 gap-6 ">
                    <div>
                      <label className="block mb-1">
                        Where is your company located?
                      </label>
                      <Autocomplete
                        className="mt-3.5"
                        options={jobLocations}
                        value={
                          formData.employerCompanyAddress
                            ? {
                                label: formData.employerCompanyAddress,
                                value: formData.employerCompanyAddress,
                              }
                            : null
                        }
                        onChange={handleLocationChange}
                        onInputChange={handleLocationInputChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { height: "36px" }, // Reduce the height of the input
                            }}
                            inputProps={{
                              ...params.inputProps,
                              style: { padding: "8px 14px" }, // Adjust padding to center the text vertically
                            }}
                            placeholder="Eg: Bengaluru, Karnataka"
                            className="w-full bg-white border rounded-md mt-2 focus:outline-none focus:border-blue-500"
                          />
                        )}
                        loading={jobLocations.length === 0}
                        loadingText="Loading locations..."
                        noOptionsText="No locations found"
                      />
                    </div>
                    <div>
                      <label className="block mb-1">
                        How big is your company?
                      </label>
                      <select
                        name="employerCompanySize"
                        value={formData.employerCompanySize}
                        onChange={handleChange}
                        className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                      >
                        {/* <option value="">Choose the Job Type</option> */}
                        <option value="1-10">1-10</option>
                        <option value="11-50">11-50</option>
                        <option value="51-200">51-200</option>
                        <option value="201-500">201-500</option>
                        <option value="501-1000">501-1000</option>
                        <option value="1001-5000">1001-5000</option>
                        <option value="5001-10000">5001-10,000</option>
                        <option value="10000+">10,000+</option>
                      </select>
                    </div>
                    <div>
                      <label className="block mb-1">
                        What industry does your company operate in?
                      </label>
                      <Select
                        type="text"
                        name="employerCompanyIndustry"
                        placeholder="search your industry Eg, Fintech"
                        options={state.companyIndustryOption}
                        value={
                          formData.employerCompanyIndustry
                            ? {
                                value: formData.employerCompanyIndustry,
                                label: formData.employerCompanyIndustry,
                              }
                            : null
                        }
                        onChange={handleCompanyIndustryChange}
                        className="w-full border text-[18px] rounded-md mt-2  focus:outline-none focus:border-blue-500"
                        onInputChange={(inputValue, actionMeta) => {
                          if (actionMeta.action === "input-change") {
                            // setFormData((prevData) => ({
                            //   ...prevData,
                            //   companyIndustry: inputValue,
                            // }));
                            // updateState("newCompanyIndustry", inputValue);
                            fetchIndustries(inputValue);
                          }
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "none",
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="bg-white p-6 shadow-md rounded-lg">
                  <h2 className="text-xl font-[Inter] underline font-semibold">
                    Recruiter Profile:
                  </h2>
                  <div className="mt-4 grid grid-cols-2 gap-6">
                    <div>
                      <label className="block mb-1">
                        What's your LinkedIn URL
                      </label>
                      <input
                        type="text"
                        name="recruiterLinkedInUrl"
                        placeholder="Enter LinkedIn URL"
                        value={formData.recruiterLinkedInUrl}
                        onChange={handleChange}
                        className={`w-full border rounded-md mt-2 p-2 focus:outline-none ${
                          errors.recruiterLinkedInUrl ? 'border-red-500' : 'focus:border-blue-500'
                        }`}
                      />
                      {errors.recruiterLinkedInUrl && (
                        <p className="text-red-500 text-sm mt-1">{errors.recruiterLinkedInUrl}</p>
                      )}
                    </div>
                    <div>
                      <label className="block mb-1">
                        What's the name of your company
                      </label>
                      <input
                        type="text"
                        name="recruiterCompanyName"
                        placeholder="Enter company name"
                        value={formData.recruiterCompanyName}
                        onChange={handleChange}
                        className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block mb-1">
                        Could you share your company's website?
                      </label>
                      <input
                        type="text"
                        name="recruiterCompanyWebsite"
                        placeholder="Eg, www.xyz.com"
                        value={formData.recruiterCompanyWebsite}
                        onChange={handleChange}
                        className={`w-full border rounded-md mt-2 p-2 focus:outline-none ${
                          errors.recruiterCompanyWebsite ? 'border-red-500' : 'focus:border-blue-500'
                        }`}
                      />
                      {errors.recruiterCompanyWebsite && (
                        <p className="text-red-500 text-sm mt-1">{errors.recruiterCompanyWebsite}</p>
                      )}
                    </div>
                    <div>
                      <label className="block mb-1">
                        Where are you currently based?
                      </label>
                      <Autocomplete
                        className="mt-3.5"
                        options={jobLocations}
                        value={
                          formData.recruiterCurrentLocation
                            ? {
                                label: formData.recruiterCurrentLocation,
                                value: formData.recruiterCurrentLocation,
                              }
                            : null
                        }
                        onChange={handleLocationChangeRecruiter}
                        onInputChange={handleLocationInputChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { height: "36px" }, // Reduce the height of the input
                            }}
                            inputProps={{
                              ...params.inputProps,
                              style: { padding: "8px 14px" }, // Adjust padding to center the text vertically
                            }}
                            placeholder="Eg: Bengaluru, Karnataka"
                            className="w-full bg-white border rounded-md mt-2 focus:outline-none focus:border-blue-500"
                          />
                        )}
                        loading={jobLocations.length === 0}
                        loadingText="Loading locations..."
                        noOptionsText="No locations found"
                      />
                    </div>
                    <div>
                      <label className="block mb-1">
                        What is the typical company size you recruit for?
                      </label>
                      <select
                        name="recruiterCompanySize"
                        value={formData.recruiterCompanySize}
                        onChange={handleChange}
                        className="w-full border rounded-md mt-2 p-2 focus:outline-none focus:border-blue-500"
                      >
                        {/* <option value="">Choose the Job Type</option> */}
                        <option value="1-10">1-10</option>
                        <option value="11-50">11-50</option>
                        <option value="51-200">51-200</option>
                        <option value="201-500">201-500</option>
                        <option value="501-1000">501-1000</option>
                        <option value="1001-5000">1001-5000</option>
                        <option value="5001-10000">5001-10,000</option>
                        <option value="10000+">10,000+</option>
                      </select>
                    </div>
                    <div>
                      <label className="block mb-1">
                        What industries do you specialize in hiring for?
                      </label>
                      <Select
                        type="text"
                        name="recruiterIndustries"
                        placeholder="search your industry Eg, Fintech"
                        options={state.companyIndustryOption}
                        value={formData.recruiterIndustries.map((industry) => ({
                          value: industry,
                          label: industry,
                        }))}
                        isMulti
                        closeMenuOnSelect={false}
                        components={makeAnimated()}
                        onChange={handleRecruiterIndustryChange}
                        className="w-full border rounded-md mt-2 focus:outline-none focus:border-blue-500"
                        onInputChange={(inputValue, actionMeta) => {
                          if (actionMeta.action === "input-change") {
                            // setFormData((prevData) => ({
                            //   ...prevData,
                            //   companyIndustry: inputValue,
                            // }));
                            updateState("newCompanyIndustry", inputValue);
                            fetchIndustries(inputValue);
                          }
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "1px solid #d1d5db",
                            borderRadius: "0.375rem",
                            padding: "2px",
                          }),
                          multiValue: (provided) => ({
                            ...provided,
                            backgroundColor: "#AED7FF",
                            borderRadius: "0.5rem",
                            padding: "2px 5px",
                          }),
                          multiValueLabel: (provided) => ({
                            ...provided,
                            color: "#0671E0", // Change this to the desired text color
                            // fontWeight: "bold", // Optional: Make the text bold
                          }),
                          multiValueRemove: (provided) => ({
                            ...provided,
                            color: "#0671E0",
                            cursor: "pointer",
                            ":hover": {
                              // backgroundColor: "#ff1744",
                              // color: "white",
                            },
                          }),
                        }}
                      />
                    </div>
                    <div>
                      <label className="block mb-1">
                        What experience range do you usually hire talent for?
                      </label>

                      <Select
                        type="text"
                        name="recriuterSeneorityLevel"
                        placeholder="search your level"
                        options={state.seniorityLevel}
                        value={formData.recriuterSeneorityLevel.map(
                          (level) => ({
                            value: level,
                            label: level,
                          })
                        )}
                        isMulti
                        closeMenuOnSelect={false}
                        components={makeAnimated()}
                        onChange={handleRecruiterSeniorityChange}
                        className="w-full border rounded-md mt-2 focus:outline-none focus:border-blue-500"
                        // onInputChange={(inputValue, actionMeta) => {
                        //   if (actionMeta.action === "input-change") {
                        //     // setFormData((prevData) => ({
                        //     //   ...prevData,
                        //     //   companyIndustry: inputValue,
                        //     // }));
                        //     // updateState("newCompanyIndustry", inputValue);
                        //     // fetchIndustries(inputValue);
                        //   }
                        // }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "1px solid #d1d5db",
                            borderRadius: "0.375rem",
                            padding: "2px",
                          }),
                          multiValue: (provided) => ({
                            ...provided,
                            backgroundColor: "#AED7FF",
                            borderRadius: "0.5rem",
                            padding: "2px 5px",
                          }),
                          multiValueLabel: (provided) => ({
                            ...provided,
                            color: "#0671E0", // Change this to the desired text color
                            // fontWeight: "bold", // Optional: Make the text bold
                          }),
                          multiValueRemove: (provided) => ({
                            ...provided,
                            color: "#0671E0",
                            cursor: "pointer",
                            ":hover": {
                              // backgroundColor: "#ff1744",
                              // color: "white",
                            },
                          }),
                        }}
                      />
                    </div>
                    <div>
                      <label className="block mb-1">
                        What technical skills or tech stack have you
                        successfully hired for?
                      </label>
                      <CreatableSelect
                        type="text"
                        name="skills"
                        placeholder="Search or add skills"
                        options={state.skillsOptions}
                        value={formData.recruiterSkills.map((skill) => ({
                          value: skill,
                          label: skill,
                        }))}
                        isMulti
                        closeMenuOnSelect={false}
                        components={makeAnimated()}
                        onChange={handleSkillChange}
                        onCreateOption={handleCreateOption} // Handle creation of new options
                        className="w-full border rounded-md mt-2 focus:outline-none focus:border-blue-500"
                        onInputChange={(inputValue, actionMeta) => {
                          // console.log("inputValue=", inputValue, actionMeta);
                          if (actionMeta.action === "input-change") {
                            fetchPrimarySkills(inputValue);
                          }
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "1px solid #d1d5db",
                            borderRadius: "0.375rem",
                            padding: "2px",
                          }),
                          multiValue: (provided) => ({
                            ...provided,
                            backgroundColor: "#AED7FF",
                            borderRadius: "0.5rem",
                            padding: "2px 5px",
                          }),
                          multiValueLabel: (provided) => ({
                            ...provided,
                            color: "#0671E0", // Change this to the desired text color
                            // fontWeight: "bold", // Optional: Make the text bold
                          }),
                          multiValueRemove: (provided) => ({
                            ...provided,
                            color: "#0671E0",
                            cursor: "pointer",
                            ":hover": {
                              // backgroundColor: "#ff1744",
                              // color: "white",
                            },
                          }),
                        }}
                      />
                    </div>
                    <div>
                      <label className="block mb-1">
                        What type of roles have you filled the most?
                      </label>
                      <CreatableSelect
                        type="text"
                        name="recruiterRoles"
                        placeholder="Search or add roles"
                        options={state.roles}
                        value={formData.recruiterRoles.map((role) => ({
                          value: role,
                          label: role,
                        }))}
                        isMulti
                        closeMenuOnSelect={false}
                        components={makeAnimated()}
                        onChange={handleRoleChange}
                        onCreateOption={handleRoleCreateOption} // Handle creation of new options
                        className="w-full border rounded-md mt-2 focus:outline-none focus:border-blue-500"
                        // onInputChange={(inputValue, actionMeta) => {
                        //   // console.log("inputValue=", inputValue, actionMeta);
                        //   if (actionMeta.action === "input-change") {

                        //     fetchPrimarySkills(inputValue);
                        //   }
                        // }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "1px solid #d1d5db",
                            borderRadius: "0.375rem",
                            padding: "2px",
                          }),
                          multiValue: (provided) => ({
                            ...provided,
                            backgroundColor: "#AED7FF",
                            borderRadius: "0.5rem",
                            padding: "2px 5px",
                          }),
                          multiValueLabel: (provided) => ({
                            ...provided,
                            color: "#0671E0", // Change this to the desired text color
                            // fontWeight: "bold", // Optional: Make the text bold
                          }),
                          multiValueRemove: (provided) => ({
                            ...provided,
                            color: "#0671E0",
                            cursor: "pointer",
                            ":hover": {
                              // backgroundColor: "#ff1744",
                              // color: "white",
                            },
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="py-6 flex justify-end">
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
            )}
    </div>
  );
};

export default Profile;
