import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../../Component/Layout";
import toast from "react-hot-toast";
import SyncLoader from "react-spinners/SyncLoader";
import {
  FaEdit,
  FaCalendarAlt,
  FaRocket,
  FaMapMarkerAlt,
  FaUser,
  FaClock,
  FaDollarSign,
  FaBriefcase,
  FaBuilding,
  FaGlobe,
  FaLayerGroup,
  FaCertificate,
  FaUsers,
  FaLaptopCode,
} from "react-icons/fa";
import companyLogo from "./reflense-company-image.png";
import { useAuth } from "../../context/auth";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const JobDetails = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const { auth, setAuth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rejected, setRejected] = useState(0);
  const [shortlisted, setShortlisted] = useState(0);
  const [onhold, setOnhold] = useState(0);
  const [inprocess, setInprocess] = useState(0);
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("jobIdjobId=", jobId);
    const fetchJobDetails = async () => {
      try {
        setLoading(true);
        console.log(jobId);
        const response = await axios.get(
          `${process.env.REACT_APP_API_}/api/v1/job/get-single-job/${jobId}`
        );
        console.log("Job details:", response);
        setJob(response.data.job);
      } catch (error) {
        console.error("Error fetching job details:", error);
        setError("Failed to fetch job details. Please try again later.");
        toast.error("Failed to fetch job details");
      } finally {
        setLoading(false);
      }
    };

    const fetchTalentDetails = async () => {
      try {
        const response_talent = await axios.get(
          `${process.env.REACT_APP_API_}/api/v1/job/get-jobtalent/${jobId}`
        );
        const statusCounts = response_talent.data.data.reduce(
          (counts, item) => {
            counts[item.talentStatus] = (counts[item.talentStatus] || 0) + 1;
            return counts;
          },
          {}
        );

        console.log(statusCounts);

        setRejected(statusCounts["Rejected"] || 0);
        setShortlisted(statusCounts["Shortlisted"] || 0);
        setOnhold(statusCounts["Reffered"] || 0);
        setInprocess(statusCounts["inprocess"] || 0);
        setSelected(statusCounts["hired"] || 0);
      } catch (error) {
        console.log("Error fetching talent details:", error);
      }
    };

    fetchJobDetails();
    fetchTalentDetails();
  }, [jobId]);

  if (loading) {
    return (
      <Layout showFooter={true}>
        <div className="flex justify-center items-center h-screen">
          <SyncLoader color="#38e3e0" />
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout showFooter={true}>
        <div className="container mx-auto mt-[10vh] px-4">
          <p className="text-red-500">{error}</p>
        </div>
      </Layout>
    );
  }

  if (!job) {
    return (
      <Layout showFooter={true}>
        <div className="container mx-auto mt-[10vh] px-4">
          <p>No job details found.</p>
        </div>
      </Layout>
    );
  }

  const statusCounts = {
    "Talents Referred": onhold || 0,
    // 'New Talent': job.talentReceived || 0,
    "Shortlisted Talents": shortlisted || 0,
    "In Process Talents": inprocess || 0,
    "Hired Talents": selected || 0, // This should be selected and for that
    // 'selected':selected || 0
    "Rejected Talents": rejected || 0,
  };

  const renderValue = (value) => {
    if (typeof value === "string" || typeof value === "number") {
      return value;
    } else if (Array.isArray(value)) {
      // Check if the array contains skill objects
      if (
        value.length > 0 &&
        value[0].hasOwnProperty("skillName") &&
        value[0].hasOwnProperty("exp")
      ) {
        return value
          ?.map((skill) => `${skill.skillName}: ${skill.exp} yrs`)
          .join(", ");
      }
      return value.join(", ");
    } else if (typeof value === "object" && value !== null) {
      return JSON.stringify(value);
    }
    return "N/A";
  };

  const jobDetails = [
    { label: "Company Name", value: job.companyName },
    { label: "Company Website", value: job.companyWebsite },
    { label: "Job Location", value: job.jobLocation },
    { label: "Job Vacancies", value: job.jobNumbers },
    { label: "Job Level", value: job.jobLevel },
    { label: "Job Type", value: job.jobType },
    { label: "Engagement Type", value: job.engagementType },
    {
      label: "Experience",
      value: `${job.minYearsExperience} - ${job.maxYearsExperience} years`,
    },
    {
      label: "Interview Rounds",
      value: job?.JobInterviewRounds[0]?.roundName,
    },
    {
      label: "CTC Range",
      value: `${job.minCTC} - ${job.maxCTC} LPA`,
    },
    { label: "Workplace Type", value: job.workplaceType },
    {
      label: "Recruiter Incentive",
      value: `${job.recruiterIncentiveType} : ${job.recruiterIncentiveValue}`,
    },
    { label: "Company Industry", value: job.companyIndustry },
    {
      label: "Official Notice Period",
      value: job.officialNoticePeriod,
    },
  ];

  const handleCloseJob = () => {
    console.log("Close Job");
    toast.success("Job Closed");
  };

  const isHTMLContent = (content) => {
    console.log("content=", content);
    const doc = new DOMParser().parseFromString(content, "text/html");
    return Array.from(doc.body.childNodes).some(
      (node) => node.nodeType === 1 || node.nodeType === 3
    );
  };

  return (
    <Layout showFooter={true}>
      <div className="container bg-[#F7F7F7] mx-auto px-4 mt-14">
        {/* Mobile Layout */}
        <div className="md:hidden">
          {/* Logo and Company Name */}
          <div className="mt-2 flex items-center justify-between mb-4">
            <div className="flex items-center">
              <div className="bg-gray-200 w-16 h-16 rounded-full mr-4 flex items-center justify-center text-lg font-bold">
                <img src={companyLogo} alt="company-logo" />
              </div>
              <div>
                <div className="text-gray-600">
                  {renderValue(job.companyName)}
                </div>
                <h1 className="text-2xl font-bold text-blue-600">
                  {renderValue(job.jobTitle)}
                </h1>
              </div>
            </div>
            <Link
              to={`/dashboard/user/edit-jobs?jobId=${jobId}`}
              className="text-blue-600 flex items-center"
            >
              <FaEdit className="mr-1" /> Edit Job Description
            </Link>
          </div>
          <div className="bg-blue-50 p-4 rounded-md mb-4">
            <h2 className="font-bold mb-2">Job Description: </h2>
            <p>{renderValue(job.describeCandidate)}</p>
          </div>
          {/* Status Counts */}
          <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-5 gap-4 mb-6">
            {Object.entries(statusCounts)?.map(([status, count]) => (
              <Link
                to={`/dashboard/user/my-talent?${status}=${count}&jobTitle=${job.jobTitle}`}
              >
                <div
                  key={status}
                  className="bg-blue-100 p-4 rounded text-center"
                >
                  <div className="font-bold text-2xl">{count}</div>
                  <div className="text-sm">{status}</div>
                </div>
              </Link>
            ))}
          </div>
          <div className="bg-white border p-2 rounded-md">
            <h2 className="font-bold mb-2 md:hidden">Job Details:</h2>
            <div className="grid grid-cols-2 gap-4 text-lg">
              {jobDetails?.map((detail, index) => (
                <div key={index} className="flex items-center mb-2">
                  {/* <detail.icon className="mr-2 text-gray-500" /> */}
                  <div>
                    <div className="text-sm text-gray-500">{detail.label}</div>
                    <div>{renderValue(detail.value)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="p-4 rounded-md mb-4">
            <h2 className="font-bold mb-2">Role and Responsibilities:</h2>
            {job?.roleAndResponsibilites ? (
              isHTMLContent(job.roleAndResponsibilites) ? (
                <div
                  className="text-gray-600"
                  dangerouslySetInnerHTML={{
                    __html: job?.roleAndResponsibilites,
                  }}
                ></div>
              ) : (
                <p className="text-gray-600">{job.roleAndResponsibilites}</p>
              )
            ) : (
              <p className="text-gray-600">
                No role and responsibilities provided.
              </p>
            )}
            <p>{renderValue(job.roleAndResponsibilites)}</p>
          </div>
          <div className="p-4 rounded-md mb-4">
            <h2 className="font-bold mb-2">Skills:</h2>
            <div className="flex flex-wrap">
              {job.JobSkillsMappings && job.JobSkillsMappings.length > 0 ? (
                job.JobSkillsMappings?.map((mapping, index) => (
                  <span
                    key={index}
                    className="bg-green-100 text-green-800 px-2 py-1 rounded mr-2 mb-2 text-lg"
                  >
                    {`${mapping.Skill?.skillName}: ${mapping.yearExpRequired} yrs`}
                  </span>
                ))
              ) : (
                <p>No skill requirements listed for this job.</p>
              )}
              {/* {job.secondarySkills && job.secondarySkills?.map((skill, index) => (
                <span key={index} className="bg-green-100 text-green-800 px-2 py-1 rounded mr-2 mb-2 text-lg">
                  {`${skill.skillName}: ${skill.exp} yrs`}
                </span>
              ))} */}
            </div>
          </div>
          <div className="p-4 rounded-md mb-4">
            <h2 className="font-bold text-xl mb-2">Screening Question:</h2>
            <p>{renderValue(job.screeningQuestion)}</p>
          </div>
        </div>

        {/* Layout for large screens */}
        <div className="hidden md:grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-6">
          {Object.entries(statusCounts)?.map(([status, count]) =>
            count > 0 ? (
              <Link
                to={`/dashboard/user/my-talent?${status}=${count}&jobTitle=${job.jobId}`}
              >
                <div key={status} className="bg-[#F3F8FD] p-4 rounded">
                  <div className="font-bold text-[18px] font-[Inter]">
                    {count}
                  </div>
                  <div className="text-[#A0AEC0] text-[12px] font-[Inter]">
                    {status}
                  </div>
                </div>
              </Link>
            ) : (
              <div key={status} className="bg-[#F3F8FD] p-4 rounded">
                <div className="font-bold text-[18px] font-[Inter]">
                  {count}
                </div>
                <div className="text-[#A0AEC0] text-[12px] font-[Inter]">
                  {status}
                </div>
              </div>
            )
          )}
        </div>

        {/* <hr className="w-full"></hr> */}

        <div className="hidden mt-2 md:grid md:grid-cols-3 md:gap-4">
          {/* Left Column */}
          <div className="col-span-2 p-6 rounded-md">
            {/* Logo and Company Name */}
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center">
                <div className="bg-gray-200 w-20 h-20 rounded-full mr-6 flex items-center justify-center text-xl font-bold">
                  <img
                    src={companyLogo}
                    alt="company-logo"
                    className="rounded-full h-20 w-18"
                  />
                </div>
                <div>
                  <div className="text-[#000000] capitalize font-[Inter] font-medium text-[16px]">
                    {renderValue(job.companyName)}
                  </div>
                  <h1 className="text-[24px] font-semibold capitalize font-[Inter] text-[#0671E0]">
                    {renderValue(job.jobTitle)}
                  </h1>
                </div>
              </div>
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  className="border border-[#E3E3E3] hover:bg-[#F3F8FD] rounded-md"
                >
                  <a
                    href={`/dashboard/user/create-jobs?jobId=${jobId}`}
                    className=" text-[#424242] flex items-center font-[Inter] text-[11.25px] justify-end mx-6 my-2"
                  >
                    <FaEdit className="mr-1" /> Edit Job Details
                  </a>
                </button>
                <button
                  type="button"
                  onClick={handleCloseJob}
                  className="border border-[#E3E3E3] hover:bg-[#F3F8FD] rounded-md"
                >
                  <p className=" text-[#FF0000] flex items-center font-[Inter] text-[11.25px] justify-end mx-6 my-2">
                    <svg
                      className="mr-1"
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 1.375C3.5875 1.375 0.875 4.0875 0.875 7.5C0.875 10.9125 3.5875 13.625 7 13.625C10.4125 13.625 13.125 10.9125 13.125 7.5C13.125 4.0875 10.4125 1.375 7 1.375ZM7 12.75C4.1125 12.75 1.75 10.3875 1.75 7.5C1.75 4.6125 4.1125 2.25 7 2.25C9.8875 2.25 12.25 4.6125 12.25 7.5C12.25 10.3875 9.8875 12.75 7 12.75Z"
                        fill="#FF0000"
                      />
                      <path
                        d="M9.3625 10.5625L7 8.2L4.6375 10.5625L3.9375 9.8625L6.3 7.5L3.9375 5.1375L4.6375 4.4375L7 6.8L9.3625 4.4375L10.0625 5.1375L7.7 7.5L10.0625 9.8625L9.3625 10.5625Z"
                        fill="#FF0000"
                      />
                    </svg>
                    Close Job
                  </p>
                </button>
              </div>
            </div>

            {/* Job Description */}
            <div className="bg-[#F3F8FD] rounded-[15px] mb-6 p-4">
              <h4 className="font-semibold text-[20px] font-[Inter] text-[#16161D] mb-3">
                Job Description:
              </h4>
              <span className="text-[16px] font-[Inter] text-[#565656] text-wrap">
                {renderValue(job.describeCandidate)}
              </span>
            </div>

            {/* Role and Responsibilities */}
            <div className="mb-6">
              <h2 className="font-semibold text-[20px] font-[Inter] text-[#16161D] mb-3">
                Role and Responsibilities:
              </h2>
              {job?.roleAndResponsibilites ? (
              isHTMLContent(job.roleAndResponsibilites) ? (
                <div
                  className="text-[16px] font-[Inter] text-[#565656]"
                  dangerouslySetInnerHTML={{
                    __html: job?.roleAndResponsibilites,
                  }}
                ></div>
              ) : (
                <p className="text-[16px] font-[Inter] text-[#565656]">{job.roleAndResponsibilites}</p>
              )
            ) : (
              <p className="text-[16px] font-[Inter] text-[#565656]">
                No role and responsibilities provided.
              </p>
            )}
              
            </div>

            {/* Skills */}
            <div className="mb-6">
              <h2 className="font-semibold text-[20px] font-[Inter] text-[#16161D] mb-3">
                Skills:
              </h2>
              <div className="flex flex-wrap gap-4">
                {job.JobSkillsMappings && job.JobSkillsMappings.length > 0 ? (
                  job.JobSkillsMappings?.map((mapping, index) => (
                    <span
                      key={index}
                      className="text-[15px] font-[Inter] font-medium text-[#4B4B4B] px-3 py-2 rounded-[6px] border border-[#4B4B4B] mr-2 mb-2 text-lg"
                    >
                      {`${mapping.Skill?.skillName || "N/A"}: ${
                        mapping.yearExpRequired
                      } yrs`}
                    </span>
                  ))
                ) : (
                  <p>No skill requirements listed for this job.</p>
                )}
                {/* {job.secondarySkills && job.secondarySkills?.map((skill, index) => (
                  <span key={index} className="bg-green-100 text-green-800 px-3 py-2 rounded text-xl">
                    {`${skill.text}: ${skill.experience} yrs`}
                  </span>
                ))} */}
              </div>
            </div>

            {/* Screening Question */}
            <div>
              <h2 className="font-semibold text-[20px] font-[Inter] text-[#16161D] mb-3">
                Screening Question:
              </h2>
              <p className="text-[16px] font-[Inter] text-[#565656]">
                {renderValue(job.screeningQuestion)}
              </p>
            </div>
          </div>

          {/* Right Column */}
          <div>
            <div className=" border border-[#D1D1D1] p-6 rounded-[15px]">
              <h2 className="font-bold text-xl mb-4 md:hidden">Job Details:</h2>
              <div className="grid grid-cols-2 gap-3 text-base">
                {jobDetails?.map((detail, index) => (
                  <div key={index} className="mr-3 flex items-center">
                    {/* <detail.icon className="mr-3 text-gray-500 text-xl" /> */}
                    <div>
                      <div className="text-[12px] capitalize font-bold font-[Inter] text-[#565656]">
                        {detail.label}
                      </div>
                      <div className="text-[11px] capitalize font-semibold font-[Inter] text-[#000000]">
                        {renderValue(detail.value)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default JobDetails;
