import axios from 'axios';
import { toast } from 'react-hot-toast';
import { routes } from '../App';

const setupAxiosInterceptors = ({setAuth, navigate}) => {

  axios.interceptors.request.use(
    config => {
      const authData = JSON.parse(localStorage.getItem('auth'));
      if (authData?.token) {
        config.headers.Authorization = `Bearer ${authData.token}`;
      }
      return config;
    },
    error => Promise.reject(error)
  );

  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please log in again.');
        setAuth({ user: null, token: null });
        localStorage.removeItem('auth');
        navigate(routes.login);
      }
      return Promise.reject(error);
    }
  );
};

export default setupAxiosInterceptors;
