import { useAuth } from "../context/auth";
import React, { useEffect, useState } from "react";
import Layout from "../Component/Layout";
import axios from "axios";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CloudinaryContext, Image, Transformation } from "cloudinary-react";
import { useDropzone } from "react-dropzone";
import { routes } from "../App";
import Select from "react-select";
import { FaStar } from "react-icons/fa";
import { Card, Tooltip, Typography } from "@material-tailwind/react";
import SyncLoader from "react-spinners/SyncLoader";

const TalentsDetails = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [newJobLocation, setNewJobLocation] = useState(null);
  const [newLocation, setNewLocation] = useState(null);
  const [slots, setSlots] = useState([]);
  const [talentCurrentLocationOption, settalentCurrentLocationOption] =
    useState([]);
  const [talentPreferredLocationOption, settalentPreferredLocationOption] =
    useState([]);
  const [selectedSlotIds, setSelectedSlotIds] = useState([]);
  const [primarySkills, setPrimarySkills] = useState([]);
  const [secondarySkills, setSecondarySkills] = useState([]);
  const [formData, setFormData] = useState({
    talentName: "",
    talentMail: "",
    contactNumber: "",
    talentGender: "",
    collegeName: "",
    yearOfGraduation: "",
    course: "",
    field: "",
    companyName: "",
    talentDesignation: "",
    jobSeniorityLevel: "",
    talentEmploymentType: "",
    talentWorkplaceType: "",
    talentCurrentLocation: "",
    talentPreferredLocation: "",
    talenttotalExperience: 0,
    yearGap: 0,
    talentCurrentCTC: 0,
    talentExpectedCTC: 0,
    officialNoticePeriod: "",
    joiningTime: "",
    talentJobRoleExplain: "",
    comment: "",
    recruiterScore: 0,
    skills: [{ skillName: "", score: 0, exp: 0 }],
    requiredSkills: [{ skillName: "", score: 0, exp: 0 }],
    selectedDateTime: [],
  });
  const { talentId } = useParams();
  console.log("job id -> ");
  console.log(talentId);
  const [photo, setPhoto] = useState("");
  //get Data from user ID
  const fetchTalentDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/get-single-talent/${talentId}`
      );
      console.log("response -> ");
      console.log(response);
      const talentData = response.data.data;
      setFormData(talentData);
      // if (response.status === 200) {
      //   const existingTalent = response.data; // Accessing talent details from response
      //   setFormData(existingTalent);
      // }
    } catch (error) {
      console.error("Error fetching talent details:", error);
      // Handle error, such as displaying an error message to the user
      toast.error(`${error.message}`);
    }
  };
  useEffect(() => {
    fetchtalentCurrentLocation();
    fetchtalentPreferredLocation();
    // const fetchJobDetails = async () => {
    //   try {
    //     const response = await axios.get(
    //       `${process.env.REACT_APP_API_}/api/v1/job/get-single-job/${jobId}`
    //     );
    //     console.log("response -> ");
    //     console.log(response);
    //     if (response.status === 200) {
    //       const existingJob = response.data.job; // Accessing job details from response
    //       console.log(existingJob); // Logging job details to console

    //       // Update state or do further processing with existingJob
    //       const slots = existingJob.availabilitySlots;
    //       setSlots(slots);
    //       const { primarySkills, secondarySkills } = existingJob;
    //       setPrimarySkills(
    //         primarySkills.map((skill) => ({ skillName: skill.text }))
    //       );
    //       setSecondarySkills(
    //         secondarySkills.map((skill) => ({ skillName: skill.text }))
    //       );
    //       return existingJob;
    //     } else {
    //       // Handle case where API request is successful but response indicates failure
    //       toast.error(response.data.message || "Failed to fetch job details");
    //     }
    //   } catch (error) {
    //     console.error("Error fetching job details:", error);
    //     // Handle error, such as displaying an error message to the user
    //     toast.error(`${error.message}`);
    //   }
    // };

    // fetchJobDetails();
    fetchTalentDetails();
  }, [talentId]);
  // /get-single-talent/:talentId
  useEffect(() => {
    fetchTalentDetails();
  }, [talentId]);
  //handle Edit option for Details
  // const handleEdit = (e)=>{

  // }
  //get TalentDetails using Id

  const handleSlotSelection = (e) => {
    const [day, timeFrom, duration] = e.target.value.split("|");
    const selectedSlotId = `${day}|${timeFrom}|${duration}`;
    const isSelected = selectedSlotIds.includes(selectedSlotId);
    const selectedSlot = slots.find(
      (slot) =>
        slot.day === day &&
        slot.timeFrom === timeFrom &&
        slot.duration === duration
    );

    if (!selectedSlot) {
      console.error(
        `Slot with day ${day}, timeFrom ${timeFrom}, duration ${duration} not found`
      );
      return;
    }

    if (isSelected) {
      setSelectedSlotIds((prevSelectedSlotIds) =>
        prevSelectedSlotIds.filter((id) => id !== selectedSlotId)
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        selectedDateTime: prevFormData.selectedDateTime.filter(
          (dateTime) => dateTime._id !== selectedSlotId
        ),
      }));
    } else {
      setSelectedSlotIds((prevSelectedSlotIds) => [
        ...prevSelectedSlotIds,
        selectedSlotId,
      ]);
      const selectedDateTime = {
        _id: selectedSlotId,
        date: calculateDate(selectedSlot.day),
        timeFrom: selectedSlot.timeFrom,
        duration: selectedSlot.duration,
      };
      setFormData((prevFormData) => ({
        ...prevFormData,
        selectedDateTime: [...prevFormData.selectedDateTime, selectedDateTime],
      }));
    }
  };

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const renderSkillRow = (skills) => {
    return skills.map((skill, index) => (
      <tr key={index} className="border-b">
        <td className="py-2 px-4 text-center">{skill.name}</td>
        <td className="py-2 px-4 text-center">
          <input
            type="range"
            value={skill.score}
            min="0"
            max="100"
            className="w-full"
            readOnly
          />
        </td>
        <td className="py-2 px-4 text-center">{skill.experience} years</td>
      </tr>
    ));
  };

  const calculateDate = (dayOfWeek) => {
    const today = new Date();
    const targetDay = days.findIndex((day) => day === dayOfWeek);
    const currentDay = today.getDay();
    const difference = targetDay - currentDay;
    let nearestDate = new Date(
      today.getTime() +
        (difference >= 0 ? difference : difference + 7) * 24 * 60 * 60 * 1000
    );
    console.log(nearestDate);
    return nearestDate.toLocaleDateString("en-in", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const handleNext = () => {
    // Array to store names of empty fields
    const emptyFields = [];

    // // Check if any required fields are empty
    if (formData.talentName.trim() === "") {
      emptyFields.push("Talent Name");
    }
    if (formData.talentMail.trim() === "") {
      emptyFields.push("Talent Mail");
    }
    if (formData.contactNumber.trim() === "") {
      emptyFields.push("Contact Number");
    }
    if (formData.talentGender.trim() === "") {
      emptyFields.push("Talent Gender");
    }
    if (formData.collegeName.trim() === "") {
      emptyFields.push("College Name");
    }
    if (formData.yearOfGraduation.trim() === "") {
      emptyFields.push("Year of Graduation");
    }
    if (formData.course.trim() === "") {
      emptyFields.push("Course");
    }
    if (formData.field.trim() === "") {
      emptyFields.push("Field");
    }
    if (formData.companyName.trim() === "") {
      emptyFields.push("Company Name");
    }
    if (formData.talentDesignation.trim() === "") {
      emptyFields.push("Talent Designation");
    }
    if (formData.jobSeniorityLevel.trim() === "") {
      emptyFields.push("Job Seniority Level");
    }
    if (formData.talentEmploymentType.trim() === "") {
      emptyFields.push("Talent Employment Type");
    }
    if (formData.talentWorkplaceType.trim() === "") {
      emptyFields.push("Talent Workplace Type");
    }
    if (formData.talentCurrentLocation.trim() === "") {
      emptyFields.push("Talent Current Location");
    }
    if (formData.talentPreferredLocation.trim() === "") {
      emptyFields.push("Talent Preferred Location");
    }
    if (formData.officialNoticePeriod.trim() === "") {
      emptyFields.push("Official Notice Period");
    }
    if (formData.joiningTime.trim() === "") {
      emptyFields.push("Joining Time");
    }
    if (formData.talentJobRoleExplain.trim() === "") {
      emptyFields.push("Talent Job Role Explanation");
    }

    if (emptyFields.length > 3) {
      toast.error("Please fill all fields");
      return;
    }

    // Construct the error message for individual empty fields
    if (emptyFields.length > 0) {
      const errorMessage = `Please fill ${emptyFields.join(", ")}`;
      toast.error(errorMessage);
      return;
    }

    setCurrentStep((prevStep) => prevStep + 1);
    console.log(currentStep); // Add this line
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);
    console.log(currentStep); // Add this line
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleStarChange = (value) => {
    setFormData({
      ...formData,
      recruiterScore: value,
    });
  };

  const handleDeleteSkill = (i) => {
    const newSkills = [...formData.skills];
    newSkills.splice(i, 1);
    setFormData((prevData) => ({
      ...prevData,
      skills: newSkills,
    }));
  };

  const handleAddSkill = (tag) => {
    setFormData((prevData) => ({
      ...prevData,
      skills: [...prevData.skills, tag],
    }));
  };

  const stepIndicator = () => {
    const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];
    return (
      <div className="flex items-center justify-center space-x-4 mt-5">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`w-8 h-8 flex items-center justify-center rounded-full ${
              index + 1 === currentStep
                ? "bg-blue-500 text-white"
                : "bg-gray-300"
            }`}
          >
            {index + 1}
          </div>
        ))}
      </div>
    );
  };

  const { auth } = useAuth();
  // const jobId = talentId;
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const combinedSkills = [...primarySkills, ...secondarySkills];
      const requiredSkills = combinedSkills.map((skill) => ({
        skillName: skill.skillName,
        score: skill.score,
        exp: skill.exp,
      }));

      formData.requiredSkills = requiredSkills;
      console.log("formData -> ", formData);
      const updatedFormData = { ...formData };
      console.log("updatedFormData -> ", updatedFormData);
      const formDataToSend = new FormData();

      // Append simple fields
      formDataToSend.append("talentName", formData.talentName);
      formDataToSend.append("talentMail", formData.talentMail);
      formDataToSend.append("contactNumber", formData.contactNumber);
      formDataToSend.append("talentGender", formData.talentGender);
      formDataToSend.append("collegeName", formData.collegeName);
      formDataToSend.append("yearOfGraduation", formData.yearOfGraduation);
      formDataToSend.append("course", formData.course);
      formDataToSend.append("field", formData.field);
      formDataToSend.append("companyName", formData.companyName);
      formDataToSend.append("talentDesignation", formData.talentDesignation);
      formDataToSend.append("jobSeniorityLevel", formData.jobSeniorityLevel);
      formDataToSend.append(
        "talentEmploymentType",
        formData.talentEmploymentType
      );
      formDataToSend.append(
        "talentWorkplaceType",
        formData.talentWorkplaceType
      );
      formDataToSend.append(
        "talentCurrentLocation",
        formData.talentCurrentLocation
      );
      formDataToSend.append(
        "talentPreferredLocation",
        formData.talentPreferredLocation
      );
      formDataToSend.append(
        "talenttotalExperience",
        formData.talenttotalExperience
      );
      formDataToSend.append("yearGap", formData.yearGap);
      formDataToSend.append("talentCurrentCTC", formData.talentCurrentCTC);
      formDataToSend.append("talentExpectedCTC", formData.talentExpectedCTC);
      formDataToSend.append(
        "officialNoticePeriod",
        formData.officialNoticePeriod
      );
      formDataToSend.append("joiningTime", formData.joiningTime);
      formDataToSend.append(
        "talentJobRoleExplain",
        formData.talentJobRoleExplain
      );
      formDataToSend.append("comment", formData.comment);
      formDataToSend.append("recruiterScore", formData.recruiterScore);

      // Append skills array
      formDataToSend.append("skills", JSON.stringify(formData.skills));

      // Append requiredSkills array
      formDataToSend.append(
        "requiredSkills",
        JSON.stringify(formData.requiredSkills)
      );

      // Append selectedDateTime array
      formDataToSend.append(
        "selectedDateTime",
        JSON.stringify(formData.selectedDateTime)
      );

      // Append photo
      if (photo) {
        formDataToSend.append("photo", photo);
      }
      // Log each key-value pair of formDataToSend
      formDataToSend.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      });
      console.log(photo);
      // If there's a new location, create it
      if (newLocation) {
        await createtalentPreferredLocation(newLocation);
      }
      if (newJobLocation) {
        await createtalentCurrentLocation(newJobLocation);
      }
      console.log("formDataToSend -> ", formDataToSend);
      // Send the POST request with formDataToSend
      const response = await axios.put(
        `${process.env.REACT_APP_API_}/api/v1/job/edit-talent/${talentId}`, // Update URL for editing talent
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response
      if (response.data.success) {
        toast.success("TALENT UPDATED SUCCESSFULLY");
        setCurrentStep(1);
        navigate(`${routes.thankyou_job_talent}${talentId}`);
      } else {
        toast.error(response.data.error || "Failed to update talent");
        console.log("response->", response.data);
      }
    } catch (error) {
      console.error("Error updating job:", error.message);
      toast.error(`${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Cleanup function to clear toast messages when unmounting or navigating away
    return () => {
      toast.dismiss(); // Clear all toast messages
    };
  }, []);

  // Function to handle adding a skill item
  const handleAddSkills = () => {
    const newSkill = { skillName: "", score: 0, exp: 0 };
    setFormData((prevData) => ({
      ...prevData,
      skills: [...prevData.skills, newSkill],
    }));
    console.log(newSkill);
  };

  // Function to handle updating a skill item
  const handleSkillChange = (index, field, value) => {
    const updatedSkills = [...formData.skills];
    updatedSkills[index][field] = value;
    setFormData((prevData) => ({
      ...prevData,
      skills: updatedSkills,
    }));
  };

  // Function to handle removing a Skill item
  const handleRemoveSkill = (index) => {
    const updatedSkills = [...formData.skills];
    updatedSkills.splice(index, 1);
    setFormData((prevData) => ({
      ...prevData,
      skills: updatedSkills,
    }));
  };

  const fetchtalentCurrentLocation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/fetch-location`
      );
      if (response.data.success) {
        const options = response.data.data.map((industry) => ({
          value: industry.name,
          label: industry.name,
        }));
        settalentCurrentLocationOption(options);
      }
    } catch (error) {
      console.error("Error fetching company industries:", error);
      return [];
    }
  };

  // Function to handle company industry change
  const handletalentCurrentLocationChange = async (selectedOption) => {
    if (selectedOption) {
      // Update form data with the selected company industry
      setFormData((prevData) => ({
        ...prevData,
        talentCurrentLocation: selectedOption.label,
      }));
    }
  };

  // Function to create a new talentCurrentLocation
  const createtalentCurrentLocation = async (newLocation) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/job/create-location`,
        { name: newLocation }
      );
      if (response.data.success) {
        console.log("New location created:", response.data.name);
        await fetchtalentCurrentLocation();
      } else {
        console.error("Failed to create new location:", response.data.message);
      }
    } catch (error) {
      console.error("Error creating new location:", error);
    }
  };

  const fetchtalentPreferredLocation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/fetch-location`
      );
      if (response.data.success) {
        const options = response.data.data.map((industry) => ({
          value: industry.name,
          label: industry.name,
        }));
        settalentPreferredLocationOption(options);
      }
    } catch (error) {
      console.error("Error fetching company industries:", error);
      return [];
    }
  };

  // Function to handle talentPreferredLocation change
  const handletalentPreferredLocationChange = async (selectedOption) => {
    if (selectedOption) {
      // Update form data with the selected talentPreferredLocation
      setFormData((prevData) => ({
        ...prevData,
        talentPreferredLocation: selectedOption.label,
      }));

      // Check if the selected option exists in the dropdown options
      const optionExists = talentCurrentLocationOption.some(
        (option) => option.label === selectedOption.label
      );
      // If the selected option is not in the dropdown options, store it temporarily
      if (!optionExists) {
        setNewLocation(selectedOption.label);
        console.log("New location set:", selectedOption.label); // Log the new location
      } else {
        // If the selected option exists, clear the new location state
        setNewLocation(null);
        console.log("New location cleared");
      }
    }
  };

  // Function to create a new talentCurrentLocation
  const createtalentPreferredLocation = async (newLocation) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/job/create-location`,
        { name: newLocation }
      );
      if (response.data.success) {
        console.log("New location created:", response.data.name);
        await fetchtalentPreferredLocation();
      } else {
        console.error("Failed to create new location:", response.data.message);
      }
    } catch (error) {
      console.error("Error creating new location:", error);
    }
  };

  const validateTalentMail = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_}/api/v1/job/validate-talent-mail`,
        {
          mail: formData.talentMail,
          phoneNumber: formData.contactNumber,
          jobId: talentId,
        }
      );

      if (res.data.success) {
        return true;
      } else {
        toast.error(res.data.message);
        return false;
      }
    } catch (err) {
      toast.error(err.response ? err.response.data.message : err.message);
      return false;
    }
  };

  const handleValidationAndNext = async () => {
    // const isValid = await validateTalentMail();
    // if (isValid) {
    handleNext();
    // }
  };

  const renderSkillList = () => {
    return (
      <div className="md:table w-full">
        {formData.skills.map((skill, index) => (
          <div
            key={index}
            className="border-b md:table-row flex flex-col md:flex-row items-center"
          >
            <div className="w-full md:table-cell md:w-[30%] py-2 px-4">
              <div className="flex flex-col items-center md:none">
                <label className="w-full md:hidden text-left md:text-right md:pr-4 mb-1 md:mb-0 font-bold">
                  Skill
                </label>
                <input
                  type="text"
                  value={skill.skillName}
                  onChange={(e) =>
                    handleSkillChange(index, "skillName", e.target.value)
                  }
                  placeholder="Skill"
                  className="border-2 p-1 rounded-md shadow-sm w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="w-full md:table-cell md:w-[30%] py-2 px-4 text-center">
              <div className="flex items-center justify-between md:justify-center">
                <label className="w-[89%] md:hidden text-left md:text-right md:pr-4 mb-1 md:mb-0 font-bold">
                  Score
                </label>
                <input
                  type="range"
                  value={skill.score}
                  onChange={(e) =>
                    handleSkillChange(index, "score", parseInt(e.target.value))
                  }
                  min="0"
                  max="5"
                  step="1"
                  className="rounded-md w-[90%] md:w-[80%] focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <span className="ml-2 p-1 md:p-0">{skill.score}</span>
              </div>
            </div>

            <div className="w-full md:table-cell md:w-[33%] py-2 px-4 text-center">
              <div className="flex items-center justify-between md:justify-center">
                <label className="w-full md:hidden text-left md:text-right md:pr-4 mb-1 md:mb-0 font-bold">
                  Experience
                </label>
                <input
                  type="range"
                  value={skill.exp}
                  onChange={(e) =>
                    handleSkillChange(index, "exp", parseInt(e.target.value))
                  }
                  min="0"
                  max="30"
                  step="1"
                  className="rounded-md w-full md:w-[80%] focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <span className="ml-2">{skill.exp}</span>
              </div>
            </div>

            <div className="w-full md:table-cell md:w-[7%] py-2 text-center">
              <button
                type="button"
                onClick={() => handleRemoveSkill(index)}
                className="text-red-500 text-3xl rounded-lg"
              >
                <MdDelete />
              </button>
            </div>
          </div>
        ))}
        <div className="table-row">
          <div className="table-cell py-2 px-4 text-center" colSpan="4">
            <button
              type="button"
              onClick={handleAddSkills}
              className="bg-blue-500 text-white px-3 py-1 rounded-full mt-3 mx-auto"
            >
              Add Additional Skills
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderRequiredSkillsForm = (skills, title, isPrimary) => (
    <div className="w-full table">
      {skills.map((skill, index) => (
        <div
          key={index}
          className="border-b md:table-row flex flex-col md:flex-row items-center"
        >
          {/* Skill Input */}
          <div className="w-full md:table-cell md:w-[30%] py-2 px-4">
            <div className="flex flex-col md:flex-row items-center">
              <label className="w-full md:hidden text-left md:text-right md:pr-4 mb-1 md:mb-0 font-bold">
                Skill
              </label>
              <input
                type="text"
                value={skill.skillName}
                className="border-2 p-1 rounded-md shadow-sm w-full md:w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div className="w-full md:table-cell md:w-[30%] py-2 px-4 text-center">
            <div className="flex items-center justify-between md:justify-center">
              <label className="w-full md:hidden text-left md:text-right md:pr-4 mb-1 md:mb-0 font-bold">
                Score
              </label>
              <input
                type="range"
                value={skill.score}
                onChange={(e) => handleScoreChange(e, index, isPrimary)}
                min="0"
                max="5"
                step="1"
                className="rounded-md w-full md:w-[80%] focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <span className="ml-2 p-1 md:p-0">{skill.score}</span>
            </div>
          </div>

          <div className="w-full md:table-cell md:w-[33%] py-2 px-4 text-center">
            <div className="flex items-center justify-between md:justify-center">
              <label className="w-full md:hidden text-left md:text-right md:pr-4 mb-1 md:mb-0 font-bold">
                Experience
              </label>
              <input
                type="range"
                value={skill.exp}
                onChange={(e) => handleExperienceChange(e, index, isPrimary)}
                min="0"
                max="30"
                step="1"
                className="rounded-md w-full md:w-[80%] focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <span className="ml-2">{skill.exp}</span>
            </div>
          </div>

          <div className="w-full md:table-cell md:w-[7%] py-2 px-4 text-center"></div>
        </div>
      ))}
    </div>
  );

  // const renderSkillList = () => {
  //   return (
  //     <div className="w-[100%] mt-1 ml-[-1vh] ">
  //       <table>
  //         <thead>
  //           <tr>
  //             {/* <th>Skill</th>
  //             <th>Score</th>
  //             <th>Experience</th> */}
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {formData.skills.map((skill, index) => (
  //             <tr key={index} className="gap-3 flex ">
  //               <td>
  //                 <input
  //                   type="text"
  //                   value={skill.skillName}
  //                   onChange={(e) =>
  //                     handleSkillChange(index, "skillName", e.target.value)
  //                   }
  //                   placeholder="Skills"
  //                   className="w-[100%] my-3 mr-7 border-2 p-1 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  //                 />
  //               </td>

  //               <td className="w-[100%] flex ">
  //                 <input
  //                   type="range"
  //                   value={skill.score}
  //                   onChange={(e) =>
  //                     handleSkillChange(
  //                       index,
  //                       "score",
  //                       parseInt(e.target.value)
  //                     )
  //                   }
  //                   min="0"
  //                   max="5"
  //                   step="1"
  //                   className="my-3 p-2 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  //                 />
  //                 <span className="my-3 ml-2">{skill.score}</span>
  //               </td>

  //               <td className="w-[100%] flex ">
  //                 <input
  //                   type="range"
  //                   value={skill.exp}
  //                   onChange={(e) =>
  //                     handleSkillChange(index, "exp", parseInt(e.target.value))
  //                   }
  //                   min="0"
  //                   max="30"
  //                   step="1"
  //                   className="w-[100%] mt-6 h-1 py-1 px-2  rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  //                 />
  //                 <span className="my-3 ml-2">{skill.exp}</span>
  //               </td>
  //               <td>
  //                 <button
  //                   type="button"
  //                   onClick={() => handleRemoveSkill(index)}
  //                   className="text-red-500 my-3 text-3xl rounded-lg ml-[-3vh]"
  //                 >
  //                   <MdDelete />
  //                 </button>
  //               </td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //       <button
  //         type="button"
  //         onClick={handleAddSkills}
  //         className="bg-blue-500 text-white px-3 py-1 rounded-full mt-3"
  //       >
  //         Add Additional Skills
  //       </button>
  //     </div>
  //   );
  // };

  // const renderRequiredSkillsForm = (skills, title, isPrimary) => (
  //   <div className="w-[100%] ml-[-1vh]">
  //     <table>
  //       <tbody>
  //         {skills.map((skill, index) => (
  //           <tr key={index} className=" flex">
  //             <td className="">
  //               <input
  //                 type="text"
  //                 value={skill.skillName}
  //                 className="w-[100%] md:my-3 md:mr-7 border-2 p-1 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  //               />
  //             </td>
  //             <td className="w-[100%] flex gap-2">
  //               <input
  //                 type="range"
  //                 value={skill.score}
  //                 onChange={(e) => handleScoreChange(e, index, isPrimary)}
  //                 min="0"
  //                 max="5"
  //                 step="1"
  //                 className=" my-3 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  //               />
  //               <span className="my-3 mx-1">{skill.score}</span>
  //             </td>
  //             <td className="w-[100%] flex">
  //               <input
  //                 type="range"
  //                 value={skill.exp}
  //                 onChange={(e) => handleExperienceChange(e, index, isPrimary)}
  //                 min="0"
  //                 max="30"
  //                 step="1"
  //                 className="w-[100%] my-3 p-2 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  //               />
  //               <span className="my-3 mx-2">{skill.exp}</span>
  //             </td>
  //           </tr>
  //         ))}
  //       </tbody>
  //     </table>
  //   </div>
  // );

  // Function to handle changes in score for required skills
  const handleScoreChange = (e, index, isPrimary) => {
    const { value } = e.target;
    if (isPrimary) {
      setPrimarySkills((prevSkills) => {
        const updatedSkills = [...prevSkills];
        updatedSkills[index].score = parseInt(value); // Update score at the specified index
        return updatedSkills;
      });
    } else {
      setSecondarySkills((prevSkills) => {
        const updatedSkills = [...prevSkills];
        updatedSkills[index].score = parseInt(value); // Update score at the specified index
        return updatedSkills;
      });
    }
  };

  // Function to handle changes in experience for required skills
  const handleExperienceChange = (e, index, isPrimary) => {
    const { value } = e.target;
    if (isPrimary) {
      setPrimarySkills((prevSkills) => {
        const updatedSkills = [...prevSkills];
        updatedSkills[index].exp = parseInt(value); // Update experience at the specified index
        return updatedSkills;
      });
    } else {
      setSecondarySkills((prevSkills) => {
        const updatedSkills = [...prevSkills];
        updatedSkills[index].exp = parseInt(value); // Update experience at the specified index
        return updatedSkills;
      });
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Update the state with the selected file
      setFormData((prevData) => ({
        ...prevData,
        photo: file,
      }));
    } else {
      // Handle case where no file is selected
      toast.error("Please select a photo.");
    }
  };
  const [email, setEmail] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const fetchTalentInfo = async (email) => {
    console.log("email", email);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_}/api/v1/job/talent-info/${email}`
      );

      const data = response.data.data;

      // Check if data is available, otherwise set default form data with the email
      if (!data || !data.talentMail || response.data.success !== true) {
        setFormData({
          talentMail: email, // Only set the email and leave other fields empty
          talentName: "",
          contactNumber: "",
          talentGender: "",
          collegeName: "",
          yearOfGraduation: "",
          course: "",
          field: "",
          companyName: "",
          talentDesignation: "",
          jobSeniorityLevel: "",
          talentEmploymentType: "",
          talentWorkplaceType: "",
          talentCurrentLocation: "",
          talentPreferredLocation: "",
          talenttotalExperience: 0,
          yearGap: 0,
          talentCurrentCTC: 0,
          talentExpectedCTC: 0,
          officialNoticePeriod: "",
          joiningTime: "",
          talentJobRoleExplain: "",
          comment: "",
          recruiterScore: 0,
          skills: [{ skillName: "", score: 0, exp: 0 }],
          requiredSkills: [{ skillName: "", score: 0, exp: 0 }],
          selectedDateTime: [],
        });
      } else {
        // Set form data if data is available
        setFormData({
          talentName: data.talentName || "",
          talentMail: data.talentMail || "",
          contactNumber: data.contactNumber || "",
          talentGender: data.talentGender || "",
          collegeName: data.collegeName || "",
          yearOfGraduation: data.yearOfGraduation || "",
          course: data.course || "",
          field: data.field || "",
          companyName: data.companyName || "",
          talentDesignation: data.talentDesignation || "",
          jobSeniorityLevel: data.jobSeniorityLevel || "",
          talentEmploymentType: data.talentEmploymentType || "",
          talentWorkplaceType: data.talentWorkplaceType || "",
          talentCurrentLocation: data.talentCurrentLocation || "",
          talentPreferredLocation: data.talentPreferredLocation || "",
          talenttotalExperience: Number(data.talenttotalExperience) || 0,
          yearGap: Number(data.yearGap) || 0,
          talentCurrentCTC: Number(data.talentCurrentCTC) || 0,
          talentExpectedCTC: Number(data.talentExpectedCTC) || 0,
          officialNoticePeriod: data.officialNoticePeriod || "",
          joiningTime: data.joiningTime || "",
          talentJobRoleExplain: data.talentJobRoleExplain || "",
          comment: data.comment || "",
          recruiterScore: Number(data.recruiterScore) || 0,
          skills: data.skills || [{ skillName: "", score: 0, exp: 0 }],
          requiredSkills: data.requiredSkills || [
            { skillName: "", score: 0, exp: 0 },
          ],
          selectedDateTime: data.selectedDateTime || [],
        });
      }

      console.log("response", data);
    } catch (error) {
      // Reset form fields on error
      setFormData({
        talentMail: email,
        talentName: "",
        contactNumber: "",
        talentGender: "",
        collegeName: "",
        yearOfGraduation: "",
        course: "",
        field: "",
        companyName: "",
        talentDesignation: "",
        jobSeniorityLevel: "",
        talentEmploymentType: "",
        talentWorkplaceType: "",
        talentCurrentLocation: "",
        talentPreferredLocation: "",
        talenttotalExperience: 0,
        yearGap: 0,
        talentCurrentCTC: 0,
        talentExpectedCTC: 0,
        officialNoticePeriod: "",
        joiningTime: "",
        talentJobRoleExplain: "",
        comment: "",
        recruiterScore: 0,
        skills: [{ skillName: "", score: 0, exp: 0 }],
        requiredSkills: [{ skillName: "", score: 0, exp: 0 }],
        selectedDateTime: [],
      });

      console.error("Error fetching talent info:", error);
    }
  };

  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="flex justify-items-center flex-col xl:flex-row w-[100%] mx-auto xl:h-[82vh] ">
            <div className="w-[100%] xl:w-1/2 xl:border-r my-6 border-gray-400 md:pr-4">
              <div className="mb-4  mt-5 xl:w-[100%] mx-auto">
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="talentName"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="talentName"
                      onChange={handleChange}
                      value={formData.talentName}
                      id="name"
                      className="w-[100%] xl:w-[100%] mb-2 border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="talentMail"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Email Id
                    </label>
                    <input
                      type="text"
                      name="talentMail"
                      onChange={handleChange}
                      value={formData.talentMail}
                      id="talentMail"
                      className="w-[100%] xl:w-[100%] mb-2 border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="contactNumber"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Contact Number
                    </label>
                    <input
                      type="year"
                      name="contactNumber"
                      onChange={handleChange}
                      value={formData.contactNumber}
                      id="contactNumber"
                      className="w-[100%] xl:w-[100%] border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="talentGender"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Gender
                    </label>
                    <select
                      type="text"
                      name="talentGender"
                      value={formData.talentGender}
                      onChange={handleChange}
                      id="talentGender"
                      className="w-[100%] xl:w-[100%] border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Talent Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="mb-4  mt-5 xl:w-[100%] mx-auto">
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="collegeName"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      College Name
                    </label>
                    <input
                      type="text"
                      value={formData.collegeName}
                      onChange={handleChange}
                      name="collegeName"
                      id="collegeName"
                      className="w-[100%] xl:w-[100%] mb-2 border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="yearOfGraduation"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Year of Graduation
                    </label>
                    <input
                      type="month"
                      value={formData.yearOfGraduation}
                      onChange={handleChange}
                      placeholder="Year of Graduatin"
                      name="yearOfGraduation"
                      id="yearOfGraduation"
                      className="w-[100%] xl:w-[100%] mb-2 border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="course"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Course
                    </label>
                    <select
                      type="text"
                      value={formData.course}
                      onChange={handleChange}
                      name="course"
                      id="course"
                      className="w-[100%] xl:w-[100%] border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Add Course</option>
                      <option value="ME">M.E</option>
                      <option value="E.C.E">E.C.E</option>
                      <option value="CS">CS</option>
                    </select>
                  </div>
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="field"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Field
                    </label>
                    <select
                      type="text"
                      value={formData.field}
                      onChange={handleChange}
                      name="field"
                      id="field"
                      className="w-[100%] xl:w-[100%] border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Choose Talent Field</option>
                      <option value="B.Tech" selected>
                        B.Tech
                      </option>
                      <option value="B.E" selected>
                        B.E
                      </option>
                      <option value="BCA">BCA</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="mb-4  mt-5 xl:w-[100%] mx-auto">
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="companyName"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Company Name
                    </label>
                    <input
                      type="text"
                      value={formData.companyName}
                      onChange={handleChange}
                      name="companyName"
                      id="companyName"
                      className="w-[100%] xl:w-[100%] mb-2 border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="talentDesignation"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Designation
                    </label>
                    <input
                      type="text"
                      value={formData.talentDesignation}
                      onChange={handleChange}
                      name="talentDesignation"
                      id="talentDesignation"
                      className="w-[100%] xl:w-[100%] mb-2 border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="jobSeniorityLevel"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Job Senority Level{" "}
                    </label>
                    <select
                      type="text"
                      value={formData.jobSeniorityLevel}
                      onChange={handleChange}
                      name="jobSeniorityLevel"
                      id="jobSeniorityLevel"
                      className="w-[100%] xl:w-[100%] border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Choose Job Senority Level</option>
                      <option value="Internship">Internship</option>
                      <option value="Entry-Level">Entry Level</option>
                      <option value="Associate">Associate</option>
                      <option value="Mid-Senior-Level">Mid-Senior Level</option>
                      <option value="Director">Director</option>
                      <option value="Executive">Executive</option>
                      <option value="Not-Applicable">Not Applicable</option>
                    </select>
                  </div>
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="talentEmploymentType"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Employment Type
                    </label>
                    <select
                      type="text"
                      value={formData.talentEmploymentType}
                      onChange={handleChange}
                      name="talentEmploymentType"
                      id="employmentType"
                      className="w-[100%] xl:w-[100%] border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Choose Employment Type</option>
                      <option value="Full-time">Full-time</option>
                      <option value="Part-time">Part-time</option>
                      <option value="Contract">Contract</option>
                      <option value="Internship">Internship</option>
                      <option value="C2H-Model">C2H Model</option>
                      <option value="3rd-Party-Payroll">
                        3rd Party Payroll
                      </option>
                    </select>
                  </div>
                </div>
                <div className="md:w-[82.5%] xl:w-[100%]">
                  <label
                    htmlFor="talentWorkplaceType"
                    className="block mt-5 text-sm font-medium text-gray-700"
                  >
                    Talent Workplace Type
                  </label>
                  <select
                    type="text"
                    value={formData.talentWorkplaceType}
                    onChange={handleChange}
                    name="talentWorkplaceType"
                    id="talentWorkplaceType"
                    className="w-[100%] xl:w-[100%] border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="">Choose Workplace Type</option>
                    <option value="On-Site" selected>
                      On-Site: Work in-person
                    </option>
                    <option value="Hybrid">
                      Hybrid: Work on-site and off-site
                    </option>
                    <option value="Remote">Remote: Work off-site</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="w-[100%] xl:w-1/2 my-6 md:pr-4 ml-2">
              <div className="mb-4  mt-5 xl:w-[100%] mx-auto">
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="talenttotalExperience"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Total Experience
                    </label>
                    <input
                      type="number"
                      min="0"
                      value={formData.talenttotalExperience}
                      onChange={handleChange}
                      name="talenttotalExperience"
                      id="talenttotalExperience"
                      className="mb-2 w-[100%] xl:w-[100%] border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="yearGap"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Number of Gap Many years(If Any)?
                    </label>
                    <input
                      type="number"
                      min="0"
                      value={formData.yearGap}
                      onChange={handleChange}
                      name="yearGap"
                      id="yearGap"
                      className="mb-2 w-[100%] xl:w-[100%] border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="officialNoticePeriod"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Official Notice Period
                    </label>
                    <select
                      type="text"
                      value={formData.officialNoticePeriod}
                      onChange={handleChange}
                      name="officialNoticePeriod"
                      id="officialNoticePeriod"
                      className="w-[100%] xl:w-[100%] border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Official Notice Period</option>
                      <option value="Immediate-Joiner" selected>
                        Immediate Joiner
                      </option>
                      <option value="15 days">15 days</option>
                      <option value="30 days">30 days</option>
                      <option value="45 days">45 days</option>
                      <option value="60 days">60 days</option>
                      <option value="90 days">90 days</option>
                    </select>
                  </div>
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="joiningTime"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Lwd/How soon Talent can Join?
                    </label>
                    <input
                      type="date"
                      onChange={handleChange}
                      value={formData.joiningTime}
                      name="joiningTime"
                      id="joiningTime"
                      className="w-[100%] xl:w-[100%] border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4  mt-5 xl:w-[100%] mx-auto">
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="talentCurrentLocation"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Current Location
                    </label>
                    <Select
                      type="text"
                      variant="borderless" // Assuming variant is a prop used to customize styles
                      name="talentCurrentLocation"
                      options={talentCurrentLocationOption}
                      value={{
                        value: formData.talentCurrentLocation,
                        label: formData.talentCurrentLocation,
                      }}
                      onChange={handletalentCurrentLocationChange}
                      required
                      className="w-[100%] xl:w-[100%] border-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onInputChange={(inputValue, actionMeta) => {
                        if (actionMeta.action === "input-change") {
                          setFormData((prevData) => ({
                            ...prevData,
                            talentCurrentLocation: inputValue,
                          }));
                          setNewJobLocation(inputValue);
                        }
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "none", // Remove border
                        }),
                      }}
                    />
                  </div>
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label htmlFor="talentPreferredLocation">
                      Preferred Location
                    </label>
                    <Select
                      type="text"
                      variant="borderless" // Assuming variant is a prop used to customize styles
                      name="talentPreferredLocation"
                      options={talentCurrentLocationOption}
                      value={{
                        value: formData.talentPreferredLocation,
                        label: formData.talentPreferredLocation,
                      }}
                      onChange={handletalentPreferredLocationChange}
                      required
                      className="w-[100%] xl:w-[100%] border-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onInputChange={(inputValue, actionMeta) => {
                        if (actionMeta.action === "input-change") {
                          setFormData((prevData) => ({
                            ...prevData,
                            talentPreferredLocation: inputValue,
                          }));
                          setNewLocation(inputValue);
                        }
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "none", // Remove border
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="talentCurrentCTC"
                      className="block text-sm font-medium text-gray-700 mt-2 mb-1"
                    >
                      Current CTC &#40;INR&#41;
                    </label>
                    <input
                      type="number"
                      min="0"
                      onChange={handleChange}
                      value={formData.talentCurrentCTC}
                      name="talentCurrentCTC"
                      id="talentCurrentCTC"
                      className="w-[100%] xl:w-[100%] border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="talentExpectedCTC"
                      className="block text-sm font-medium text-gray-700 mt-2 mb-1"
                    >
                      Expected CTC &#40;INR&#41;{" "}
                    </label>
                    <input
                      type="number"
                      min="0"
                      onChange={handleChange}
                      value={formData.talentExpectedCTC}
                      name="talentExpectedCTC"
                      id="talentExpectedCTC"
                      className="w-[100%] xl:w-[100%] border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className=" mb-2  mt-5 xl:w-[100%] mx-auto">
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="md:w-[40%] xl:w-[100%]">
                    <label
                      htmlFor="talentJobRoleExplain"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Please explain Talent Job Role in 1 line
                    </label>
                    <input
                      type="text"
                      maxLength="100"
                      onChange={handleChange}
                      value={formData.talentJobRoleExplain}
                      name="talentJobRoleExplain"
                      id="talentJobRoleExplain"
                      className="w-[100%] xl:w-[100%] border-2 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="flex md:px-8 pt-5 flex-col xl:flex-row">
            <div className="w-[100%] xl:w-1/2 xl:border-r my-6 border-gray-400 md:pr-4">
              <div className="mb-4">
                <div className="grid grid-cols-1 gap-4">
                  <div>
                    <label
                      htmlFor="comment"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Recruiter Comment for Talent
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      value={formData.comment}
                      name="comment"
                      id="comment"
                      className="w-full h-[10vh] p-2 border-black border-2 rounded-md "
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <div className="flex-row gap-[250px] mt-5">
                  <div className=" my-2">
                    <Tooltip content="Please remove candidate contact information from Resume">
                      <label className="btn bg-blue-500 rounded-lg text-white p-2 btn-outline-secondary col-md-12">
                        {photo ? photo.name : "Upload Resume"}
                        <input
                          type="file"
                          name="photo"
                          accept="pdf/*"
                          onChange={(e) => setPhoto(e.target.files[0])}
                          hidden
                          className=" w-96 bg-white"
                        />
                      </label>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <label className="text-lg font-normal">Recruiter Score</label>
                <div className="flex">
                  {[...Array(5)].map((star, index) => {
                    const score = index + 1;
                    return (
                      <label key={index}>
                        <input
                          type="radio"
                          name="recruiterScore"
                          value={score}
                          onChange={() => handleStarChange(score)}
                          style={{ display: "none" }}
                        />
                        <FaStar
                          className="star"
                          color={
                            score <= formData.recruiterScore
                              ? "#ffc107"
                              : "#e4e5e9"
                          }
                          size={30}
                        />
                      </label>
                    );
                  })}
                </div>
              </div>
              <div className="my-[40px]">
                <div className="">
                  <div className="mb-4">
                    <div>
                      <label className="block text-lg text-black rounded-xl w-fit px-3 py-1 bg-gray-200 mb-1">
                        Confirm Talent Interview availability
                      </label>
                      {slots.length > 0 ? (
                        slots.map((slot) => {
                          const slotId = `${slot.day}|${slot.timeFrom}|${slot.duration}`;
                          return (
                            <div
                              key={slotId}
                              className="flex ml-2 items-center"
                            >
                              <input
                                type="checkbox"
                                id={slotId}
                                value={slotId}
                                checked={selectedSlotIds.includes(slotId)}
                                onChange={handleSlotSelection}
                                className="mr-2"
                              />
                              <label htmlFor={slotId}>
                                {calculateDate(slot.day)} - {slot.timeFrom} for{" "}
                                {slot.duration} hr
                              </label>
                            </div>
                          );
                        })
                      ) : (
                        <p>No slots available</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-1/2 md:p-4">
              <Card className="w-[100%] p-4">
                <Typography variant="h5" className="font-bold mb-4">
                  Skills
                </Typography>
                <div className="min-w-full border-collapse">
                  <div className="hidden md:table w-full">
                    <div className="table-row border-b">
                      <div className="table-cell w-[30%] py-2 px-4 text-center font-bold">
                        Skill
                      </div>
                      <div className="table-cell w-[30%] py-2 px-4 text-center font-bold">
                        Score
                      </div>
                      <div className="table-cell w-[33%] py-2 px-4 text-center font-bold">
                        Experience
                      </div>
                      <div className="table-cell w-[7%] py-2 px-4 text-center font-bold">
                        Actions
                      </div>
                    </div>
                  </div>
                  <div>
                    {renderRequiredSkillsForm(
                      primarySkills,
                      "Primary Skills",
                      true
                    )}
                    {renderRequiredSkillsForm(
                      secondarySkills,
                      "Secondary Skills",
                      false
                    )}
                    {renderSkillList()}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Layout showFooter={false}>
      {loading && <SyncLoader />}
      <div className="container mx-auto">
        <form
          onSubmit={handleSubmit}
          enctype="multipart/form-data"
          className="mt-12 mx-9 w-6/7"
        >
          {renderFormStep()}

          {/* Navigation buttons */}
          <div className="flex justify-center items-center p-3">
            {currentStep > 1 && (
              <button
                type="button"
                onClick={handlePrev}
                className="bg-blue-500 text-white px-5 py-1 mx-16 my-3 rounded-full"
              >
                Previous
              </button>
            )}

            {currentStep < 2 && (
              <button
                type="button"
                onClick={handleValidationAndNext}
                className="bg-blue-500 text-white px-5 py-1 bottom-[10px] rounded-full"
              >
                Next
              </button>
            )}

            {/* {currentStep === 2 && (
              <button
                type="submit"
                className="bg-green-500 text-white px-5 py-1 mx-16 my-3 rounded-full"
              >
                Submit
              </button>
            )} */}

            {currentStep == 2 && (
              <Link
                to={`/dashboard/talent-details/${talentId}`}
                className="bg-green-500 text-white px-5 py-1 mx-16 my-3 rounded-full"
              >
                Go To Edit Page
              </Link>
            )}
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default TalentsDetails;
