import { useState, useEffect,useContext,createContext } from "react";
import {jwtDecode} from "jwt-decode";

const AuthContext=createContext();

const AuthProvider=({children})=>{
    const [auth, setAuth] = useState(() => {
        // Load initial state from localStorage
        const savedAuth = localStorage.getItem("auth");
        return savedAuth ? JSON.parse(savedAuth) : { user: null, token: "" };
      });
   

    // axios.defaults.headers.common['Authorization']=`Bearer ${auth?.token}`;

    useEffect(()=>{
        const data=localStorage.getItem('auth')
        if(data){
            const parseData=JSON.parse(data);
            const decodedToken = jwtDecode(parseData.token);
            const currentTime = Date.now() / 1000;
            // Check if the token is expired
            if (decodedToken.exp < currentTime) {
                // Token is expired, remove it from storage and reset auth state
                localStorage.removeItem('auth');
                setAuth({
                    user: null,
                    token: ""
                });
            } else {
                // Token is valid, update auth state
                setAuth({
                    user: parseData.user,
                    token: parseData.token
                });
            }
        }//eslint-disable-next-line
    },[])
    return(<AuthContext.Provider value={{auth,setAuth}}>
        {children}
    </AuthContext.Provider>)
}

const useAuth=()=>useContext(AuthContext);
export {useAuth,AuthProvider};